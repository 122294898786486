import classNames from 'classnames';
import React from 'react';
import S from './styles/InfoLabelTile.styl';

interface IInfoLabelTile {
	label: string;
	information: string | null | undefined;
	dateExpire?: boolean;
}

export default function TagLabel(props: IInfoLabelTile) {
	return (
		<div className={classNames(S.infoLabelTile, { [S.infoLabelTileExpired]: props.dateExpire })}>
			<span className={S.infoLabel}>{props.label}</span>
			<span className={S.infoText}>{(props.information) ? props.information : '-'}</span>
		</div>
	);
}
