import { Direction } from '@components/drawing/Door';
import { fromI3OptionsDTO, IDoorState, II3Option, State, TCode, toI3OptionDTO } from '@models';

export interface IDoorGenericOption {
	id: number;
	name: string;
	description: string;
	technicalSheetUrl: string;
	imageUrl: string;
	isIncluded?: boolean;
	price?: number;
	unit?: string;
}

export interface IDoorOptions {
	pedals: IDoorGenericOption[];
	curtains: IDoorGenericOption[];
	windows: IDoorGenericOption[];
	kickplates: IDoorGenericOption[];
}

export interface IDoor {
    id?: number;
	width: number;
	position: number;
	roomWall: {
		id: number;
		name?: string;
	};
	direction: {
		id: number;
		name?: string;
	};
	pedal: IDoorGenericOption;
	curtain: IDoorGenericOption;
	window: IDoorGenericOption;
	kickplate: IDoorGenericOption;
	hasDefaultRoomWall: boolean;
	hasDefaultOpeningDirection: boolean;
	hasDefaultPosition: boolean;
	hasDefaultWidth: boolean;
	optionI3?: II3Option;
    hasRamp: boolean;
}

const DirectionDTOMapping = [
	Direction.DOWN,
	Direction.RIGHT,
	Direction.TOP,
	Direction.LEFT,
];

const OpeningDirectionDTOMapping = [
	'LEFT',
	'RIGHT',
];

export const WallsDTOMapping = {
	A: [1, 2, 3, 4],
	B: [5, 6, 7, 2],
	C: [8, 9, 10, 6],
	D: [11, 12, 13, 9],
};

export function fromDoorDTO(roomCode: TCode, door: IDoor): IDoorState {
	return {
		hasDefaultOpeningDirection: door.hasDefaultOpeningDirection,
		hasDefaultPosition: door.hasDefaultPosition,
		hasDefaultWidth: door.hasDefaultWidth,
        hasRamp: door.hasRamp,
		width: door.width,
		position: door.position,
		roomWall: WallsDTOMapping[roomCode][door.roomWall.id - 1],
		direction: DirectionDTOMapping[door.roomWall.id - 1],
		openingDirection: OpeningDirectionDTOMapping[door.direction.id - 1],
		state: State.Enabled,
		options: {
			pedal: door.pedal && door.pedal,
			curtain: door.curtain && door.curtain,
			window: door.window && door.window,
			kickplate: door.kickplate && door.kickplate,
		},
		i3Option: door.optionI3 ? fromI3OptionsDTO([door.optionI3]) : undefined,
	};
}

export function toDoorDTO(roomCode: TCode, door: IDoorState): IDoor {
	const DTO = {
		width: door.width,
		position: door.position,
		roomWall: {
			id: WallsDTOMapping[roomCode].findIndex((v) => v === door.roomWall) + 1,
		},
		direction: {
			id: OpeningDirectionDTOMapping.indexOf(door.openingDirection) + 1,
		},
		hasDefaultRoomWall: false,
		hasDefaultOpeningDirection: door.hasDefaultOpeningDirection,
		hasDefaultPosition: door.hasDefaultPosition,
		hasDefaultWidth: door.hasDefaultWidth,
		optionI3: door.i3Option ? toI3OptionDTO(door.i3Option) : undefined,
        hasRamp: door.hasRamp,
	} as IDoor;

	if (door.options) {
		if (door.options.pedal) {
			DTO.pedal = door.options.pedal;
		}
		if (door.options.curtain) {
			DTO.curtain = door.options.curtain;
		}
		if (door.options.window) {
			DTO.window = door.options.window;
		}
		if (door.options.kickplate) {
			DTO.kickplate = door.options.kickplate;
		}
	}

	return DTO;
}
