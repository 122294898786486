import { Graphics, Point } from 'pixi.js';

Graphics.prototype.updateCurrentPoint = function(this: Graphics, currentPoint: Point, toX: number, toY: number, updateValue: number): Graphics {
	const lastPosition = this.currentPath.points;

	const lastXIsSmallerOrEqualThanTargetX = (lastPosition[lastPosition.length - 2] || 0) < toX;
	const lastYIsSmallerOrEqualThanTargetY = (lastPosition[lastPosition.length - 1] || 0) < toY;

	if (lastXIsSmallerOrEqualThanTargetX) {
		currentPoint.x =
		currentPoint.x + updateValue < toX
			? currentPoint.x + updateValue
			: toX;
	} else {
		currentPoint.x =
		currentPoint.x - updateValue > toX
			? currentPoint.x - updateValue
			: toX;
	}

	if (lastYIsSmallerOrEqualThanTargetY) {
		currentPoint.y =
		currentPoint.y + updateValue < toY
			? currentPoint.y + updateValue
			: toY;
	} else {
		currentPoint.y =
		currentPoint.y - updateValue > toY
			? currentPoint.y - updateValue
			: toY;
	}

	return this;
};

Graphics.prototype.drawDashLine = function(this: Graphics, toX: number, toY: number, dash: number = 16, gap: number = 8): Graphics {
	const lastPosition = this.currentPath.points;

	const currentPoint = new Point(lastPosition[lastPosition.length - 2] || 0, lastPosition[lastPosition.length - 1] || 0);

	while (currentPoint.x !== toX || currentPoint.y !== toY) {
		this.updateCurrentPoint(currentPoint, toX, toY, dash)
			.lineTo(currentPoint.x, currentPoint.y)
			.updateCurrentPoint(currentPoint, toX, toY, gap)
			.moveTo(currentPoint.x, currentPoint.y);
	}
	return this;
};

export enum LineDirection {
	TopRight,
	TopLeft,
	DownRight,
	DownLeft,
	VerticalTopRight,
	VerticalTopLeft,
	VerticalDownRight,
	VerticalDownLeft,
}

class LinesUtils {
	public static drawFullDashedBorder(
		graphics: Graphics,
		length: number,
		x: number,
		y: number,
		width: number,
		height: number,
		gap: number,
		dash: number,
		lineColor: number,
	) {
		graphics.lineStyle(length, lineColor, 1, 0);
		// TOP LEFT TO RIGHT LINE
		graphics.moveTo(x, y)
			.drawDashLine(x + width, y, dash, gap)
			// RIGHT TOP TO BOTTOM LINE
			.drawDashLine(x + width, y + height + length, dash, gap)
			// BOTTOM RIGHT TO LEFT LINE
			.drawDashLine(x, y + height + length, dash, gap)
			// LEFT BOTTOM TO TOP LINE
			.drawDashLine(x, y, dash, gap);
	}

	public static drawFullBorder(
		x: number,
		y: number,
		width: number,
		height: number,
		graphics: Graphics,
		length: number,
		color: number,
	) {
		graphics.lineStyle(length, color, 1, 1);
		// TOP LEFT TO RIGHT LINE
		graphics.moveTo(x - length, y)
			.lineTo(x + width, y)
			// RIGHT TOP TO BOTTOM LINE
			.lineTo(x + width, y + height)
			// BOTTOM RIGHT TO LEFT LINE
			.lineTo(x, y + height)
			// LEFT BOTTOM TO TOP LINE
			.lineTo(x, y);
	}

	public static drawPartialBorderRight(
		x: number,
		y: number,
		width: number,
		height: number,
		gap: number,
		graphics: Graphics,
		length: number,
		color: number,
		fillingColor: number,
	) {
		graphics.lineStyle(length, color, 1, 1);
		// TOP LEFT TO RIGHT LINE
		graphics.moveTo(x - length, y)
			.lineTo(x + width, y)
			// RIGHT TOP TO BOTTOM LINE
			.moveTo(x + width, y + gap)
			.lineTo(x + width, y + height)
			// BOTTOM RIGHT TO LEFT LINE
			.moveTo(x + width, y + height)
			.lineTo(x, y + height)
			// LEFT BOTTOM TO TOP LINE
			.lineTo(x, y);

		// spaces
		graphics.lineStyle(length, fillingColor, 1, 1);
		graphics.moveTo(x + width, y)
			.lineTo(x + width, y + gap)
			.moveTo(x + width, y + height + gap)
			.lineTo(x + width, y + height);
	}

	public static drawPartialBorderLeft(
		x: number,
		y: number,
		width: number,
		height: number,
		graphics: Graphics,
		length: number,
		color: number,
	) {
		graphics.lineStyle(length, color, 1, 1)
			// TOP LEFT TO RIGHT LINE
			.moveTo(x, y)
			.lineTo(x + width, y)
			// RIGHT TOP TO BOTTOM LINE
			.lineTo(x + width, y + height)
			// BOTTOM RIGHT TO LEFT LINE
			.lineTo(x, y + height);
	}

	public static drawPartialBorders(
		x: number,
		y: number,
		width: number,
		height: number,
		gap: number,
		graphics: Graphics,
		length: number,
		color: number,
		fillingColor: number,
	) {
		graphics.lineStyle(length, color, 1, 1);
		// TOP LEFT TO RIGHT LINE
		graphics.moveTo(x, y)
			.lineTo(x + width, y)
			// RIGHT TOP TO BOTTOM LINE
			.moveTo(x + width, y + gap)
			.lineTo(x + width, y + height)
			// BOTTOM RIGHT TO LEFT LINE
			.moveTo(x + width, y + height)
			.lineTo(x, y + height);

		// White filling
		graphics.lineStyle(length, fillingColor, 1, 1);
		graphics.moveTo(x + width, y)
			.lineTo(x + width, y + gap)
			.moveTo(x + width, y + height + gap)
			.lineTo(x + width, y + height);
	}

	public static drawLineHook(
		x: number,
		y: number,
		direction: LineDirection,
		length: number,
		graphics: Graphics,
		color: number,
	) {
		graphics.lineStyle(1, color, 1, 1);
		graphics.moveTo(x, y);

		switch (direction) {
			case LineDirection.TopRight:
				graphics.lineTo(x + length, y - length)
					.lineTo(x + length + (length * 0.75), y - length);
				break;

			case LineDirection.TopLeft:
				graphics.lineTo(x - length, y - length)
					.lineTo(x - length - (length * 0.75), y - length);
				break;

			case LineDirection.DownRight:
				graphics.lineTo(x + length, y + length)
					.lineTo(x + length + (length * 0.75), y + length);
				break;

			case LineDirection.DownLeft:
				graphics.lineTo(x - length, y + length )
					.lineTo(x - length - (length * 0.75), y + length);
				break;

			case LineDirection.VerticalTopRight:
				graphics.lineTo(x + length, y + length)
					.lineTo(x + length + (length * 0.75), y + length);
				break;

			case LineDirection.VerticalTopLeft:
				graphics.lineTo(x + length, y - length)
					.lineTo(x + length + (length * 0.75), y - length);
				break;

			case LineDirection.VerticalDownRight:
				graphics.lineTo(x - length, y + length)
					.lineTo(x - length - (length * 0.75), y + length );
				break;

			case LineDirection.VerticalDownLeft:
				graphics.lineTo(x - length, y - length )
					.lineTo(x - length - (length * 0.75), y - length);
				break;
		}
	}
}

export default LinesUtils;
