import { IStoreState } from '@store';
import { format } from 'date-fns';
import { IGroup, toGroupDTO } from './group';
import { TCoolingSystemCompleted } from './room';
import { IInstallationLocationState } from './store';

export enum ECurrencyCode {
	USD = 'USD',
	CAD = 'CAD',
}

export enum ETransportType {
	PICK_UP,
	BY_NORBEC,
}

export interface ICustomer {
	active?: boolean; // ajout MOD-427
	activatedLabel?: string; // ajout MOD-717
	address?: string; // TODO: no more use in FE, wait to know if will be remove from BE, if so remove this line
	city?: string;
	country?: string;
	creditHold?: boolean; // ajout MOD-427
	currencyCode?: string; // ajout MOD-427
	customerType?: string; // ajout MOD-427
	email?: string;
	epicorCustId?: string;
	epicorCustNum?: number; // ajout MOD-427
	fax?: string; // ajout MOD-427
	faxNum?: string;
	groupCode?: string; // ajout MOD-427
	id?: number;
	lang?: string;
	markup?: number;
	name?: string;
	phoneNum?: string;
	priceClass?: string;
	salesrepCode?: string;
	state?: string;
	street?: string;
	street2?: string;
	terms?: string;
	territoryId?: string; // ajout MOD-427
	zip?: string; // ajout MOD-427
	zipCode?: string;
}

export interface IProjectRequirements {
	shouldSkipCondenserDistanceStep: boolean;
	shouldSkipComboOptionsStep: boolean;
	shouldSkipFloorMaterial: boolean;
	shouldSkipTransport: boolean;
	optionI3Mandatory: boolean;
	optionSeismicKitMandatory: boolean;
	mandatoryCoolingSystemOption?: number;
	transportPriceAuto: boolean;
	installPriceAuto: boolean;
	hasFloor: boolean;
	roomsCoolingSystemCompleted: Array<TCoolingSystemCompleted<boolean | null>>;
	validPartial: boolean;
	validComplete: boolean;
}

export interface IShippingAddress {
    id?: number;
	street?: string;
	street2?: string;
	city?: string;
	state?: string;
	country?: string;
	zipCode?: string;
	contact?: string;
	email?: string;
	phoneNum?: string;
	phoneExt?: string;
}

export interface IProjectsData {
	countTotal: number;
	projects: IProject[];
}
export interface IContact {
	cellPhoneNum: string;
	custNum?: number;
	conNum?: number;
	email: string;
	epicorConNum: string;
	epicorCustId: string;
	faxNum: string;
	func?: string;
	id: number;
	active?: boolean;
	name: string;
	phoneExt: string;
	phoneNum: string;
	shipToNum?: string;
}
export interface IProject {
    userId?: string;
    priceVersion?: number;
	archived?: boolean;
	completionState: number;
	contact?: IContact;
	currencyCode?: string;
	customer: ICustomer;
	dateCreation?: string;
	dateExpiry?: string;
	dateLastUpdate?: string;
	dateQuoted?: string;
	discountPc?: number | null;
	epicorQuoteNum?: number;
	epicorQuoterId?: string;
	groups: IGroup[];
	id?: number;
	installByNorbec: boolean;
	installPriceAuto: boolean;
	installPriceOverride?: string;
	installValidated?: boolean;
	langId?: string;
	markupPc?: number | null;
	name: string;
	needSeismicKit: boolean;
	quoterEmail?: string;
	quoterName?: string;
	refNumber: string;
	requestDate: string | null;
	requirements: IProjectRequirements;
	salesrepEmail?: string;
	salesrepName?: string;
	shippingAddress?: IShippingAddress;
	status?: number;
	statusArchived?: number;
	transportPriceAuto: boolean;
	transportPriceOverride?: string;
	transportType: ITransportType | null;
	transportValidated?: boolean;
	userName?: string;
}

export interface ITransportType {
	description?: string;
	epicorPartNum?: string;
	id: number;
	imageUrl?: string;
	name?: string;
	needAppointment?: string;
	price?: number;
	technicalSheetUrl?: string;
}

export interface IFilterProjects {
	archived?: number;
	epicorQuoteNum?: string;
	projectName?: string;
	customerName?: string;
	customerEpicorCustId?: string;
	userName?: string;
	refNumber?: string;
	status?: string;
	dateCreation?: string;
	dateQuoted?: string;
	dateLastUpdate?: string;
	search: string | null;
	sortField: string | number | symbol | undefined;
	sortDirection: string;
	pageSize?: number;
	pageNo?: number;
}

export function toInstallationLocationDTO(
	installationLocation: IInstallationLocationState = {},
): IShippingAddress {
	return {
		street: installationLocation.street,
		street2: installationLocation.street2,
		city: installationLocation.city,
		state: installationLocation.cityState,
		country: installationLocation.country,
		zipCode: installationLocation.zipCode,
		contact: installationLocation.contact,
		email: installationLocation.email,
		phoneNum: installationLocation.phoneNum,
		phoneExt: installationLocation.phoneExt,
	};
}

export function toProjectDTO(
	state: IStoreState,
	requestType: string = 'put',
): IProject {
	const { installationDate } = state.project;

	const projectData: any = {
		id: state.project.id,
		name: state.project.name,
		refNumber: state.project.ref,
		completionState: state.layout.latestCompletedStep,
		needSeismicKit: state.project.hasSeismicKit,
		requestDate: installationDate
			? format(installationDate, 'yyyy-MM-dd')
			: null,
		installByNorbec: state.project.installByNorbec,
		groups: state.group.rooms.length > 0 ? [toGroupDTO(state)] : [],
		markupPc:
			Number(state.project.markupPc) > 0
				? Number(state.project.markupPc)
				: 0,
		currencyCode: state.project.currencyCode,
		status: state.project.status,
		customer: {
			epicorCustId: state.project.customer.epicorCustId,
		},
		requirements: {
			shouldSkipCondenserDistanceStep:
				state.layout.requirements.shouldSkipCondenserDistanceStep,
			shouldSkipComboOptionsStep:
				state.layout.requirements.shouldSkipComboOptionsStep,
			shouldSkipFloorMaterial:
				state.layout.requirements.shouldSkipFloorMaterial,
			shouldSkipTransport: state.layout.requirements.shouldSkipTransport,
			optionI3Mandatory: state.layout.requirements.optionI3Mandatory,
			optionSeismicKitMandatory:
				state.layout.requirements.seismicKitMandatory,
			mandatoryCoolingSystemOption:
				state.layout.requirements.mandatoryCoolingSystemOption,
			transportPriceAuto: state.layout.requirements.transportPriceAuto,
			installPriceAuto: state.layout.requirements.installPriceAuto,
			hasFloor: state.layout.requirements.hasFloor,
			roomsCoolingSystemCompleted:
				state.layout.requirements.roomsCoolingSystemCompleted,
		},
		shippingAddress: toInstallationLocationDTO(
			state.project.installationLocation,
		),
		transportType: state.project.transportType,
	};

	if (requestType === 'put') {
		projectData.contact = {
			cellPhoneNum: state.project.contact.cellPhoneNum,
			email: state.project.contact.email,
			epicorConNum: state.project.contact.epicorConNum,
			epicorCustId: state.project.contact.epicorCustId,
			faxNum: state.project.contact.faxNum,
			id: state.project.contact.id,
			name: state.project.contact.name,
			phoneExt: state.project.contact.phoneExt,
			phoneNum: state.project.contact.phoneNum,
		};

		projectData.customer = {
			address: state.project.customer.address, // TODO: no more use in FE, wait to know if will be remove from BE, if so remove this line
			city: state.project.customer.city,
			country: state.project.customer.country,
			email: state.project.customer.email,
			epicorCustId: state.project.customer.epicorCustId,
			faxNum: state.project.customer.faxNum,
			id: state.project.customer.id,
			markup: state.project.customer.markup,
			name: state.project.customer.name,
			phoneNum: state.project.customer.phoneNum,
			salesrepCode: state.project.customer.salesrepCode,
			state: state.project.customer.state,
			street: state.project.customer.street,
			street2: state.project.customer.street2,
			terms: state.project.customer.terms,
			zipCode: state.project.customer.zipCode,
		};

		projectData.salesrepEmail = state.project.salesrepEmail;
		projectData.salesrepName = state.project.salesrepName;
		projectData.epicorQuoterId = state.project.epicorQuoterId;
		projectData.quoterEmail = state.project.quoterEmail;
		projectData.quoterName = state.project.quoterName;
		projectData.langId = state.project.langId;
	}

	return projectData;
}
