import { ERoomTypeKind, IRoomTypeState } from '@models';

export interface IRoomType {
	id: number;
	name: string;
	description: string;
	hasFloor: boolean;
	typeKind?: ERoomTypeKind;
}

export function fromRoomTypesDTO(roomTypes: IRoomType[]): IRoomTypeState[] {
	return roomTypes.map((roomType) => {
		return fromRoomTypeDTO(roomType);
	});
}

// typeKind not coming from BE it is set in the storeState only
export function fromRoomTypeDTO(roomType: IRoomType): IRoomTypeState {
	return {
		id: roomType.id,
		name: roomType.name,
		description: roomType.description,
		hasFloor: roomType.hasFloor,
		typeKind: [1, 2].includes(roomType.id)
			? ERoomTypeKind.COOLER
			: ERoomTypeKind.FREEZER,
	};
}

export function toRoomTypeDTO(roomTypeState: IRoomTypeState): IRoomType {
	return {
		id: roomTypeState.id,
		name: roomTypeState.name,
		description: roomTypeState.description,
		hasFloor: roomTypeState.hasFloor,
	};
}
