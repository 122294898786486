import HyperLink from '@components/buttons/HyperLink';
import AlertContent from '@components/labels/AlertContent';
import PricePerUnitLabel from '@components/labels/PricePerUnitLabel';
import { LoadingElement } from '@components/layout';
import { LargeCheckedCheckbox, LargeUncheckedCheckbox } from '@components/svg';
import { State } from '@models';
import { useStore } from '@store/index';
import classnames from 'classnames';
import React, { CSSProperties } from 'react';
import S from './styles/ComplexLargeCheckbox.styl';

interface IComplexLargeCheckbox {
	readonly imageUrl?: string;
	readonly title: string;
	readonly price?: string;
	readonly units?: string;
	readonly description: string;
	readonly technicalSheetUrl?: string;
	readonly technicalSheetLabel: string;
	readonly onClick?: (checked: boolean) => void;
	readonly state: State;
	readonly isLocked?: boolean;
	readonly imageOnSide?: boolean;
	readonly showPrice?: boolean;
	readonly priceStyle?: CSSProperties;
	readonly titleStyle?: CSSProperties;
	readonly alertInsight?: string;
	readonly alertTitle?: string;
	readonly alertDesc?: string;
	readonly priceIsLoading: boolean;
}

export default function ComplexLargeCheckbox(props: IComplexLargeCheckbox) {
	const { state } = useStore();

	function onClick() {
		if (props.onClick && !props.isLocked) {
			props.onClick(props.state === State.Enabled);
		}
	}
	return (
		<div
			className={classnames(S.complexLargeCheckboxContainer, {
				[S.complexLargeCheckboxContainerFocused]: props.state === State.Focused,
				[S.complexLargeCheckboxContainerSelected]: props.state === State.Selected,
				[S.complexLargeCheckboxContainerHorizontal]: props.imageOnSide,
			})}
			onClick={onClick}
		>
			{props.imageUrl && (
				<div
					className={classnames(S.complexLargeCheckboxImageContainer, {
						[S.complexLargeCheckboxImageContainerSide]: props.imageOnSide,
					})}
				>
					<img
						className={classnames(S.complexLargeCheckboxImage, {
							[S.complexLargeCheckboxImageDisabled]: props.state === State.Disabled,
							[S.complexLargeCheckboxImageHorizontal]: props.imageOnSide,
						})}
						src={props.imageUrl}
					/>
				</div>

			)}
			<div className={S.complexLargeCheckboxContent}>
				<div
					className={classnames(S.complexLargeCheckboxContentHeader, {
						[S.complexLargeCheckboxContentHeaderDisabled]: props.state === State.Disabled,
					})}
				>
					<div className={S.complexLargeCheckboxContentHeaderText}>
						<h2
							className={classnames(S.complexLargeCheckboxContentHeaderTextTitle, {
								[S.complexLargeCheckboxContentHeaderTextTitleFocused]: props.state === State.Focused,
								[S.complexLargeCheckboxContentHeaderTextTitleSelected]: props.state === State.Selected,
								[S.complexLargeCheckboxContentHeaderTextTitleLocked]: !!props.isLocked,
								[S.complexLargeCheckboxContentHeaderTextTitleHorizontal]: props.imageOnSide,
							})}
							style={props.titleStyle}
						>
							{props.title}
						</h2>

						{props.priceIsLoading && (
							<LoadingElement />
						)}

						{props.price && !props.priceIsLoading && props.showPrice &&
							(
								<PricePerUnitLabel
									priceStyle={props.priceStyle}
									price={props.price}
									units={props.units || ''}
									state={props.state}
								/>
							)
						}
					</div>
					<div className={S.complexLargeCheckboxContentCheckboxContainer}>
						{(props.state === State.Enabled || props.state === State.Disabled) &&
						<LargeUncheckedCheckbox stroke={'#9FBBC4'} />
						}
						{props.state === State.Focused &&
						<LargeCheckedCheckbox stroke={'#F49800'} fill={'#F49800'} />
						}
						{props.state === State.Selected &&
						<LargeCheckedCheckbox stroke={'#0095C8'} fill={'#0095C8'} opacity={props.isLocked ? 0.5 : 1} />
						}
					</div>
				</div>

				<p>{props.description}</p>

				{props.technicalSheetUrl && (
					<div className={S.complexLargeCheckboxTechnicalSheet}>
						<HyperLink title={props.technicalSheetLabel} link={props.technicalSheetUrl}/>
					</div>
				)}
			</div>

			{props.alertInsight && props.alertDesc && props.alertTitle && (
				<AlertContent
					alertTitle={props.alertTitle}
					alertDesc={props.alertDesc}
					alertInsight={props.alertInsight}
					showAlert={props.state === State.Enabled}
				/>
			)}

		</div>
	);
}

ComplexLargeCheckbox.defaultProps = {
	technicalSheetLabel: 'Fiche technique',
	priceIsLoading: false,
};
