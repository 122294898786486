import { State } from '@models';
import classnames from 'classnames';
import React from 'react';
import S from './styles/PillLabel.styl';

interface IPillLabelProps {
	state: State;
	title: string;
	smallText?: boolean;
}

export default function PillLabel(props: IPillLabelProps) {
	return (
		<div
			className={classnames(S.pillContainer, {
				[S.pillContainerFocused]: props.state === State.Focused,
				[S.pillContainerSelected]: props.state === State.Selected,
				[S.pillContainerDisabled]: props.state === State.Disabled,
				[S.pillContainerReady]: props.state === State.Ready,
				[S.pillContainerDone]: props.state === State.Done,
				[S.pillContainerEnabled]: props.state === State.Enabled,
				[S.pillContainerSmall]: props.smallText,
			})}
		>
			{props.title}
		</div>
	);
}
