import { State } from '@models';
import React, { CSSProperties } from 'react';
import SmallTagLabel from './SmallTagLabel';
import S from './styles/PricePerUnitLabel.styl';

interface IPricePerUnitLabelProps {
	price: string;
	units: string;
	state?: State;
	priceStyle?: CSSProperties;
}

export default function PricePerUnitLabel(props: IPricePerUnitLabelProps) {
	return (
		<div className={S.pricePerUnitPrice} style={props.priceStyle}>
			<SmallTagLabel text={props.price} state={props.state}/>
			{props.units ? ` / ${props.units}` : ''}
		</div>
	);
}
