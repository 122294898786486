import Group from '@components/drawing/Group';
import DrawingHeader from '@components/layout/DrawingHeader';
import { Loading } from '@components/layout/index';
import Modal from '@components/layout/Modal';
import React, { useState } from 'react';
import S from './styles/DrawingModal.styl';

interface IDrawingModalProps {
	onCloseClick?: () => void;
}

const groupStyle = {
	marginTop: '4px',
	height: '270px',
};

export default function DrawingModal(props: IDrawingModalProps) {
	const isMobile = window.innerWidth < 769;
	const [drawingLoading, setDrawingLoading] = useState<boolean>(false);
	const [timeout, setTimeout] = useState<number>();

	function handleClick() {
		if (props.onCloseClick) {
			props.onCloseClick();
		}
	}

	function handleFullScreenClick() {
		setDrawingLoading(true);
		clearTimeout(timeout);
		setTimeout(window.setTimeout(() => {
			setDrawingLoading(false);
		}, 600));
	}

	return (
		<Modal mobileContentFullPage>
			<div className={S.drawingModal}>
				<div className={S.drawingModalHeader}>
				<DrawingHeader
					closeDrawing
					onClick={handleClick}
					onFullScreenClick={handleFullScreenClick}
				/>
				</div>

				{drawingLoading ? (
					<Loading/>
				) : (
					<div className={S.drawingModalGroup}>
						<Group
							groupStyle={groupStyle}
							showWidthDimensions
							showDepthDimension
							showDoors
							showDrawingButtons
							showLargeGroup
							hideShadow={isMobile}
							centered={isMobile}
						/>
					</div>
				)}

			</div>
		</Modal>
	);
}
