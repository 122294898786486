import BackRoundedLabel from '@components/labels/BackRoundedLabel';
import TagLabel from '@components/labels/TagLabel';
import Modal from '@components/layout/Modal';
import { RefreshIcon } from '@components/svg';
import { useLocale } from '@i18n';
import { State } from '@models';
import { useStore } from '@store/index';
import FormatUtils from '@utils/format';
import classnames from 'classnames';
import React, { useState } from 'react';

import GS from '../../styles/global.styl';
import S from './styles/ErrorSystemModal.styl';

interface IErrorModalProps {
	refNumber: string;
}

export default function ErrorSystemModal(props: IErrorModalProps) {
	const T = useLocale();
	const [buttonState, setButtonState] = useState<State>(State.Enabled);

	function refreshPage() {
		location.reload();
	}

	return (
		<Modal contentContainerStyle={{ height: 'unset' }}>
			<div className={S.errorModal}>
				<TagLabel title={T.ErrorSystemModalTagLabel}/>
				<h1 className={S.errorModalTitle}>
					{T.ErrorSystemModalTitle}
				</h1>
				<p
					className={S.errorModalText}
					dangerouslySetInnerHTML={{ __html: FormatUtils.formatLocaleVariable(T, T.ErrorSystemModalText) }}
				/>

				{props.refNumber && (
					<BackRoundedLabel text={`#${props.refNumber}`} />
				)}

				<button
					type="button"
					className={S.errorModalRefreshButton}
					onMouseOver={() => setButtonState(State.Focused)}
					onMouseLeave={() => setButtonState(State.Enabled)}
					onClick={refreshPage}
				>
					<RefreshIcon stroke={buttonState === State.Enabled ? GS.blueNorbec : GS.orange}/>
					<span
						className={classnames(S.errorModalRefreshButtonText, {
							[S.errorModalRefreshButtonTextFocused]: buttonState === State.Focused,
						})}
					>
						{T.ErrorSystemModalButtonText}
					</span>
				</button>
			</div>
		</Modal>
	);
}
