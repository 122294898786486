import ArrowRightIcon from '@components/svg/ArrowRightIcon';
import React from 'react';
import S from './styles/HyperLink.styl';

interface IHyperLinkProps {
	title: string;
	link?: string;
	targetBlank: boolean;
}

export default function HyperLink(props: IHyperLinkProps) {
	const linkTarget = props.targetBlank ? '_blank' : '_self';
	return (
		<div className={S.hyperLinkContainer}>
			<ArrowRightIcon stroke="#415A62"/>
			<a
				onClick={(event) => event.stopPropagation()}
				href={props.link}
				target={linkTarget}
			>
				{props.title}
			</a>
		</div>
	);
}

HyperLink.defaultProps = {
	targetBlank: true,
};
