import { getContacts } from '@api/contacts';
import { getPriceBreakdown } from '@api/index';
import { putProject } from '@api/project';
import { getCalcSkids } from '@api/skids';
import { ErrorPosition, IInputPropsBase } from '@components/inputs/Input';
import { Loading } from '@components/layout';
import { ErrorIcon } from '@components/svg';
import TransportPriceIcon from '@components/svg/TransportPriceIcon';
import { useLocale } from '@i18n';
import { Tab, Tabs } from '@material-ui/core';
import {
	createStyles,
	makeStyles,
	Theme,
	withStyles,
} from '@material-ui/core/styles';
import {
	ETransportType,
	FormInputState,
	IContact,
	IPricingItem,
	IProject,
	OrderState,
} from '@models';
import {
	DEFAULT_MAX_INPUT_CHAR_COUNT,
	REGEX_EMAIL,
	REGEX_PHONE,
	STREET2_MAX_INPUT_CHAR_COUNT,
} from '@utils/constants';
import FormatUtils, { formatTimestamp } from '@utils/format';
import { installShouldBeShown, transportShouldBeShown } from '@utils/messages';
import { getPriceSectionByName } from '@utils/pricing';
import { cloneDeep, get, isEqual, set } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { EPermissions } from '../../hooks/contexts/AuthContext';
import {
	ToastType,
	useAuthContext,
	useToastContext,
} from '../../hooks/contexts/UseContext';
import MuiTabPanel from './MuiTabPanel';
import S from './styles/Project.styl';
import TabGroup from './TabGroup';

interface IStyledTabsProps {
	value: number;
	onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
	scrollButtons: any;
	variant: any;
}

interface IStyledTabProps {
	label: string;
	icon?: JSX.Element;
}

interface IMuiTabsProps {
	project: IProject;
	setProject: React.Dispatch<React.SetStateAction<IProject | undefined>>;
}

export enum ERowType {
	string,
	number,
	date,
	phone,
	email,
	language,
	status,
	province,
	city,
	country,
	territory,
	vendeur,
	groupeIndustrie,
	potential,
	data,
	checkbox,
	contact,
	radio,
	table,
}

interface ITabInputProps extends IInputPropsBase {
	onChange?: any;
	inputType?: string;
	errorMessagePosition?: ErrorPosition;
}

export interface IRow {
	label: string;
	value: any;
	type: ERowType;
	hiddenBy?: string;
	inputName: string;
	inputProps?: ITabInputProps;
	options?: any;
	cityState?: string;
	inputState?: FormInputState;
	render?: (value: any) => any;
	dateExpire?: boolean;
	hidden?: boolean;
	fullWidthRowInViewMode?: boolean;
	projectOptions?: any;
	hiddenByCondition?: any;
	canEditInViewMode?: boolean;
}

export interface IGroup {
	label: string;
	rows: IRow[];
	isEditable: boolean;
	validateField?: string;
	successMessage?: string;
	addText?: boolean;
	tabContentToggle?: boolean;
	disabledEditButton?: boolean;
	hideEditButton?: boolean;
	refreshPrice?: boolean;
}

export interface ITabStructure {
	label: string;
	hasAlert?: boolean;
	introText?: string;
	groups: IGroup[];
	isBlockedContent?: string;
}

interface IPriceBreakdown {
	transport: null | number;
	installation: null | number;
}

export default function MuiTabs(props: IMuiTabsProps) {
	const T = useLocale();
	const { project, setProject } = props;
	const addToast = useToastContext();
	const [tabValue, setTabValue] = useState(0);
	const [priceBreakdown, setPriceBreakdown] = useState<IPriceBreakdown>({
		transport: null,
		installation: null,
	});
	const [contactList, setContactList] = useState<IContact[] | undefined>();
	const [submitLoading, setSubmitLoading] = useState<boolean>(false);
	const { userHasPermissions } = useAuthContext();

	const handleChange = (event: any, newValue: number) => {
		setTabValue(newValue);
	};

	const getCustomerAddress = () => {
		const { customer } = project;
		let street = customer.street ? customer.street : '';
		if (customer.street2) {
			street = `${street} #${project.customer.street2}`;
		}
		const address = [
			street,
			customer.city,
			customer.state,
			customer.country,
			customer.zipCode,
		]
			.filter(Boolean)
			.join(', ');

		return address ? address : '';
	};

	useEffect(() => {
		getPriceBreakdown(`${project.id}`)
			.then((res: IPricingItem[]) => {
				setPriceBreakdown({
					transport: getPriceSectionByName(res, 'transport')
						.extension,
					installation: getPriceSectionByName(res, 'installation')
						.extension,
				});
			})
			.catch(() => {
				addToast({
					text: T.GeneralFormSubmitMessageError,
					type: ToastType.error,
				});
			});
		getContacts(project.customer.epicorCustId)
			.then(({ data }) => {
				setContactList(data);
			})
			.catch(() => {
				addToast({
					text: T.GeneralFormSubmitMessageError,
					type: ToastType.error,
				});
			});
	}, []);

	const StyledTabs = withStyles({
		indicator: {
			display: 'flex',
			justifyContent: 'center',
			backgroundColor: 'transparent',
			height: '4px',
			'& > span': {
				width: '100%',
				backgroundColor: S.blueNorbec,
			},
		},
	})((props: IStyledTabsProps) => (
		<Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
	));

	const StyledTab = withStyles((theme: Theme) =>
		createStyles({
			root: {
				textTransform: 'uppercase',
				color: S.darkBlueGray,
				fontSize: theme.typography.pxToRem(13),
				fontWeight: +S.fontWeightBold,
				'&:hover': {
					color: S.blueNorbec,
					opacity: 1,
				},
				'&$selected': {
					color: S.blueNorbec,
				},
				'&:focus': {
					color: S.blueNorbec,
				},
			},
			selected: {},
		}),
	)((props: IStyledTabProps) => <Tab {...props} />);

	const getIsBlockedContent = () => {
		if (
			!installShouldBeShown(
				priceBreakdown.installation,
				project.installValidated,
				project.installByNorbec,
				project.installPriceOverride,
			) ||
			!transportShouldBeShown(
				priceBreakdown.transport,
				project.transportType,
				project.transportValidated,
				project.transportPriceOverride,
			)
		) {
			switch (project.status) {
				case OrderState.Active:
					return T.TabTransportInstallationNoCalculationTextActiveStatus;
				case OrderState.Partial:
					return T.TabTransportInstallationNoCalculationText;
			}
		}
		return undefined;
	};

	const useStyles = makeStyles((theme: Theme) => ({
		root: {
			flexGrow: 1,
			borderBottom: '1px solid #ebf1f3',
			margin: '40px 0',
		},
		padding: {
			padding: theme.spacing(3),
		},
		tabsContainer: {
			backgroundColor: '#fff',
		},
	}));

	const getTransportPriceInputValue = () => {
		if (project.transportType?.id === ETransportType.PICK_UP) {
			return 0;
		} else {
			if (project.transportPriceOverride !== null) {
				return project.transportPriceOverride;
			} else {
				if (priceBreakdown.transport && priceBreakdown.transport > 0) {
					return priceBreakdown.transport?.toFixed(2);
				}
			}
		}
		return '';
	};

	const getInstallPriceInputValue = () => {
		if (project.installPriceOverride !== null) {
			return project.installPriceOverride;
		} else {
			if (
				priceBreakdown.installation &&
				priceBreakdown.installation > 0
			) {
				return priceBreakdown.installation?.toFixed(2);
			}
		}
		return '';
	};

	const showAddPriceButton = (type: string): boolean => {
		if (userHasPermissions(EPermissions.editTransportOrInstallationTab)) {
			if (
				project.status === OrderState.Active ||
				project.status === OrderState.Partial
			) {
				if (type === 'transport') {
					if (
						priceBreakdown.transport === 0 &&
						(project.transportPriceOverride === undefined ||
							project.transportPriceOverride === null)
					) {
						return true;
					}
				} else if (type === 'installation') {
					if (
						priceBreakdown.installation === 0 &&
						(project.installPriceOverride === undefined ||
							project.installPriceOverride === null)
					) {
						return true;
					}
				}
			}
		}

		return false;
	};

	const classes = useStyles();

	const alertIcon = (
		<div className={S.iconContainer}>
			<ErrorIcon color={S.orange} />
		</div>
	);

	const renderDate = (value: string) => (value ? formatTimestamp(value) : '');
	const renderPrice = (value: number) =>
		value || value === 0
			? FormatUtils.formatDecimalPrice(Number(value), T.locale)
			: '';
	const renderPercent = (value: string) => (value ? `${value} %` : '');
	const renderPhone = (value: string) => {
		let phone = value ? value.replace('-', '') : '';
		while (phone.indexOf('-') !== -1) {
			phone = phone.replace('-', '');
		}
		return phone
			? `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`
			: '';
	};

	const generateTabsStructure = useCallback(
		(project: IProject) => {
			const tabsStructure: ITabStructure[] = [
				{
					label: T.ProjectDetailsTabDetails,
					groups: [
						{
							label: T.ProjectDetailsDetailsTitle,
							isEditable: false,
							rows: [
								{
									label: T.ProjectDetailsDetailsRefNumberLabel,
									value: project.refNumber,
									type: ERowType.string,
									inputName: 'refNumber',
								},
								{
									label: T.ProjectDetailsDetailsInternalQuoteNumberLabel,
									value: project.epicorQuoteNum,
									type: ERowType.string,
									inputName: 'epicorQuoteNum',
								},
								{
									label: T.ProjectDetailsDetailsLastUpdateLabel,
									value: project.dateLastUpdate,
									type: ERowType.date,
									inputName: 'dateLastUpdate',
									render: renderDate,
								},
								{
									label: T.ProjectDetailsDetailsCurrencyLabel,
									value: project.currencyCode,
									type: ERowType.string,
									inputName: 'currencyCode',
								},
								{
									label: T.ProjectDetailsDetailsCreatedByLabel,
									value: project.userName,
									type: ERowType.string,
									inputName: 'userName',
								},
								{
									label: T.ProjectDetailsDetailsSubmissionDateLabel,
									value: project.dateQuoted,
									type: ERowType.date,
									inputName: 'dateQuoted',
									render: renderDate,
								},
								{
									label: T.ProjectDetailsDetailsExpirationDateLabel,
									value: project.dateExpiry,
									type: ERowType.date,
									inputName: 'dateExpiry',
									dateExpire: project.dateExpiry
										? new Date(project.dateExpiry) <
										new Date()
										: false,
									render: renderDate,
								},
							],
						},
					],
				},
			];

			tabsStructure.push({
				label: T.ProjectDetailsTabClient,
				groups: [
					{
						label: T.ProjectDetailsTabClientGeneralInfoLabel,
						isEditable: false,
						rows: [
							{
								label: T.ProjectDetailsTabClientOrganisationLabel,
								value: project.customer.name,
								type: ERowType.string,
								inputName: 'customer.name',
							},
							{
								label: T.ProjectDetailsTabClientAddressLabel,
								value: getCustomerAddress(),
								type: ERowType.string,
								inputName: '',
							},
							{
								label: T.ProjectDetailsTabClientPhoneLabel,
								value: project.customer.phoneNum,
								type: ERowType.string,
								inputName: 'customer.phoneNum',
							},
							{
								label: T.ProjectDetailsTabClientFaxLabel,
								value: project.customer.faxNum,
								type: ERowType.string,
								inputName: 'customer.faxNum',
							},
							{
								label: T.ProjectDetailsTabClientEmailLabel,
								value: project.customer.email,
								type: ERowType.string,
								inputName: 'customer.email',
							},
						],
					},
					{
						label: T.ProjectDetailsTabClientContactLabel,
						isEditable: true,
						rows: [
							{
								label: T.ProjectDetailsTabClientFullNameLabel,
								value: project.contact?.name,
								type: ERowType.contact,
								inputName: 'contact.name',
								options: contactList,
							},
							{
								label: T.ProjectDetailsTabClientContactPhoneLabel,
								value: project.contact?.phoneNum,
								type: ERowType.string,
								inputName: 'contact.phoneNum',
								render: renderPhone,
								inputProps: {
									isOptional: true,
									errorMessagePosition: ErrorPosition.BOTTOM,
									errorMessage:
										T.QuoteClientSectionPhoneInputError,
									inputType: 'phone',
									validationRegex: REGEX_PHONE,
								},
							},
							{
								label: T.ProjectDetailsTabClientExtensionLabel,
								value: project.contact?.phoneExt,
								type: ERowType.string,
								inputName: 'contact.phoneExt',
								inputProps: {
									isOptional: true,
								},
							},
							{
								label: T.ProjectDetailsTabClientContactEmailLabel,
								value: project.contact?.email,
								type: ERowType.string,
								inputName: 'contact.email',
								inputProps: {
									errorMessage:
										T.QuoteClientSectionEmailInputError,
									inputType: 'email',
									errorMessagePosition: ErrorPosition.BOTTOM,
									validationRegex: REGEX_EMAIL,
									isOptional: false,
								},
							},
							{
								label: T.ProjectDetailsTabClientLanguageLabel,
								value: project.langId,
								type: ERowType.language,
								inputName: 'langId',
								render: (value: string) =>
									value === 'fr'
										? T.ProjectDetailsTabClientFrench
										: value === 'en'
											? T.ProjectDetailsTabClientEnglish
											: undefined,
							},
							{
								label: '',
								value: project.contact?.faxNum,
								type: ERowType.string,
								inputName: 'contact.faxNum',
								hidden: true,
							},
							{
								label: '',
								value: project.contact?.epicorCustId,
								type: ERowType.string,
								inputName: 'contact.epicorCustId',
								hidden: true,
							},
							{
								label: '',
								value: project.contact?.epicorConNum,
								type: ERowType.string,
								inputName: 'contact.epicorConNum',
								hidden: true,
							},
							{
								label: '',
								value: project.contact?.id,
								type: ERowType.string,
								inputName: 'contact.id',
								hidden: true,
							},
						],
					},
				],
			});

			tabsStructure.push({
				label: T.ProjectDetailsTabDelivery,
				introText:
					project.transportType &&
						project.transportType?.id === ETransportType.BY_NORBEC
						? T.TabShippingIntroTextDeliveryByNorbec
						: T.TabShippingIntroTextDeliveryByClient,
				groups: [
					{
						label: T.TabShippingSectionContactTitle,
						isEditable: true,
						rows: [
							{
								label: T.TabShippingSectionContactNameLabel,
								value: project.shippingAddress?.contact,
								type: ERowType.string,
								inputName: 'shippingAddress.contact',
								inputProps: {
									isOptional: true,
								},
							},
							{
								label: T.TabShippingSectionContactPhoneLabel,
								value: project.shippingAddress?.phoneNum,
								type: ERowType.string,
								inputName: 'shippingAddress.phoneNum',
								render: renderPhone,
								inputProps: {
									isOptional: true,
									errorMessagePosition: ErrorPosition.BOTTOM,
									errorMessage:
										T.QuoteClientSectionPhoneInputError,
									inputType: 'phone',
									validationRegex: REGEX_PHONE,
								},
							},
							{
								label: T.TabShippingSectionContactPhoneExtLabel,
								value: project.shippingAddress?.phoneExt,
								type: ERowType.string,
								inputName: 'shippingAddress.phoneExt',
								inputProps: {
									isOptional: true,
								},
							},
							{
								label: T.TabShippingSectionContactEmailLabel,
								value: project.shippingAddress?.email,
								type: ERowType.string,
								inputName: 'shippingAddress.email',
								inputProps: {
									isOptional: true,
									errorMessage:
										T.QuoteClientSectionEmailInputError,
									inputType: 'email',
									errorMessagePosition: ErrorPosition.BOTTOM,
									validationRegex: REGEX_EMAIL,
								},
							},
						],
					},
					{
						label: T.TabShippingSectionAddressAddressTitle,
						isEditable: !!(
							project.shippingAddress?.state &&
							project.shippingAddress?.country
						),
						successMessage: T.AlertDeliveryAddress,
						refreshPrice: true,
						rows: [
							{
								label: T.TabShippingSectionAddressAddressLabel,
								value: project.shippingAddress?.street,
								type: ERowType.string,
								inputName: 'shippingAddress.street',
								inputProps: {
									isOptional: true,
									showCharCounter: true,
									maxLength: DEFAULT_MAX_INPUT_CHAR_COUNT,
								},
							},
							{
								label: T.TabShippingSectionAddressMoreInfoLabel,
								value: project.shippingAddress?.street2,
								type: ERowType.string,
								inputName: 'shippingAddress.street2',
								inputProps: {
									isOptional: true,
									showCharCounter: true,
									maxLength: STREET2_MAX_INPUT_CHAR_COUNT,
								},
							},
							{
								label: T.TabShippingSectionAddressCountryLabel,
								value: project.shippingAddress?.country
									? T[project.shippingAddress?.country]
									: '',
								type: ERowType.string,
								inputName: '',
								inputProps: {
									disabled: true,
								},
							},
							{
								label: T.TabShippingSectionAddressStateLabel,
								value: project.shippingAddress?.state
									? T[project.shippingAddress?.state]
									: '',
								type: ERowType.string,
								inputName: '',
								inputProps: {
									disabled: true,
								},
							},
							{
								label: T.TabShippingSectionAddressCityLabel,
								value: project.shippingAddress?.city,
								type: ERowType.data,
								inputName: 'shippingAddress.city',
								cityState: project.shippingAddress?.state,
								inputProps: {
									isOptional: false,
								},
							},
							{
								label: T.TabShippingSectionAddressZiipCodeLabel,
								value: project.shippingAddress?.zipCode,
								type: ERowType.string,
								inputName: 'shippingAddress.zipCode',
								inputProps: {
									isOptional: true,
								},
							},
						],
					},
					{
						label: T.TabShippingSectionDateTitle,
						isEditable: true,
						addText: project.requestDate ? false : true,
						successMessage: T.AlertDeliveryDate,
						rows: [
							{
								label: T.TabShippingSectionAddressShippingDatelabel,
								value: project.requestDate
									? project.requestDate
									: null,
								type: ERowType.date,
								render: (value) =>
									value
										? formatTimestamp(value, 'yyyy-MM-dd')
										: '',
								inputName: 'requestDate',
							},
						],
					},
				],
			});

			tabsStructure.push({
				label: T.ProjectDetailsTabTransportInstallation,
				hasAlert:
					userHasPermissions(
						EPermissions.editTransportOrInstallationTab,
					) && project.status !== OrderState.Active
						? !installShouldBeShown(
							priceBreakdown.installation,
							project.installValidated,
							project.installByNorbec,
							project.installPriceOverride,
						) ||
						!transportShouldBeShown(
							priceBreakdown.transport,
							project.transportType,
							project.transportValidated,
							project.transportPriceOverride,
						)
						: false,
				isBlockedContent: userHasPermissions(
					EPermissions.editTransportOrInstallationTab,
				)
					? undefined
					: getIsBlockedContent(),
				introText: T.TabTransportInstallationIntroText,
				groups: [
					{
						validateField:
							project.status !== OrderState.Active &&
								project.transportPriceOverride !== null &&
								project.transportValidated === false
								? 'transportValidated'
								: undefined,
						label: T.TabTransportInstallationSectionTransportTitle,
						isEditable: userHasPermissions(
							EPermissions.editTransportOrInstallationTab,
						),
						successMessage: T.AlertChangesSavedSuccess,
						addText: showAddPriceButton('transport'),
						hideEditButton:
							project.transportType?.id ===
							ETransportType.PICK_UP,
						refreshPrice: true,
						rows: [
							{
								label: `${T.TabTransportInstallationSectionTransportCheckboxLabelDeliveryByNorbec},${T.TabTransportInstallationSectionTransportCheckboxLabelPickUpAtNorbec}`,
								options: `${ETransportType.BY_NORBEC},${ETransportType.PICK_UP}`,
								value: project.transportType
									? project.transportType?.id
									: ETransportType.PICK_UP,
								type: ERowType.radio,
								inputName: 'transportType.id',
								hiddenByCondition: ETransportType.PICK_UP,
								canEditInViewMode: true,
								projectOptions: {
									installByNorbec: project.installByNorbec,
									priceTransport: priceBreakdown.transport,
								},
							},
							{
								label: T.TabTransportInstallationInputPriceLabel,
								value: getTransportPriceInputValue(),
								type: ERowType.number,
								inputName: 'transportPriceOverride',
								fullWidthRowInViewMode: true,
								hiddenBy: 'transportType.id',
								render: renderPrice,
								inputProps: {
									toFixed: 2,
									inputAppend: '$',
									isOptional: true,
								},
								projectOptions: {
									transportPriceOverride:
										project.transportPriceOverride,
									priceTransport: priceBreakdown.transport,
								},
							},
						],
					},
					{
						label: T.TabTransportInstallationSectionPalletTitle,
						isEditable: false,
						tabContentToggle: true,
						rows: [
							{
								label: T.TabTransportInstallationCheckboxInstallByNorbecLabel,
								value: project.installByNorbec,
								options: getCalcSkids,
								type: ERowType.table,
								inputName: '',
								projectOptions: {
									id: project.id,
								},
							},
						],
					},
					{
						validateField:
							project.status !== OrderState.Active &&
								project.installPriceOverride !== null &&
								project.installValidated === false
								? 'installValidated'
								: undefined,
						label: T.TabTransportInstallationSectionInstallationTitle,
						isEditable: userHasPermissions(
							EPermissions.editTransportOrInstallationTab,
						),
						successMessage: T.AlertChangesSavedSuccess,
						addText: showAddPriceButton('installation'),
						refreshPrice: true,
						hideEditButton: !project.installByNorbec,
						rows: [
							{
								label: T.TabTransportInstallationCheckboxInstallByNorbecLabel,
								value: project.installByNorbec,
								type: ERowType.checkbox,
								inputName: 'installByNorbec',
								canEditInViewMode: true,
								projectOptions: {
									transportType: project.transportType?.id,
								},
							},
							{
								label: T.TabTransportInstallationInputPriceLabel,
								hiddenBy: 'installByNorbec',
								value: getInstallPriceInputValue(),
								type: ERowType.number,
								inputName: 'installPriceOverride',
								render: renderPrice,
								inputProps: {
									toFixed: 2,
									inputAppend: '$',
									isOptional: true,
								},
								projectOptions: {
									installPriceOverride:
										project.installPriceOverride,
									priceInstallation:
										priceBreakdown.installation,
								},
							},
						],
					},
				],
			});

			if (userHasPermissions(EPermissions.viewDiscountTab)) {
				tabsStructure.push({
					label: T.ProjectDetailsTabDiscount,
					introText: T.TabDiscountIntroText,
					groups: [
						{
							label: T.TabDiscountSectionTitle,
							isEditable: userHasPermissions(
								EPermissions.editDiscountTab,
							),
							addText: project.discountPc ? false : true,
							successMessage: T.AlertDiscount,
							refreshPrice: true,
							rows: [
								{
									label: T.TabDiscountDiscountInputLabel,
									value: project.discountPc,
									type: Number(ERowType.number),
									inputName: 'discountPc',
									render: renderPercent,
									inputProps: {
										toFixed: 1,
										max: 100,
										min: 0,
										inputAppend: '%',
										isOptional: true,
										showOptionalLabel: false,
									},
								},
							],
						},
					],
				});
			}

			if (userHasPermissions(EPermissions.viewMarkupTab)) {
				tabsStructure.push({
					label: T.ProjectDetailsTabMargin,
					introText: T.TabMarkupIntroText,
					groups: [
						{
							label: T.TabMarkupSectionTitle,
							isEditable: userHasPermissions(
								EPermissions.editMarkupTab,
							),
							addText: project.markupPc ? false : true,
							successMessage: T.AlertMargin,
							refreshPrice: true,
							rows: [
								{
									label: T.TabMarkupMarkupInputLabel,
									value: Number(project.markupPc),
									type: ERowType.number,
									inputName: 'markupPc',
									render: renderPercent,
									inputProps: {
										toFixed: 1,
										min: 0,
										max: 100,
										inputAppend: '%',
										isOptional: true,
										showOptionalLabel: false,
									},
								},
							],
						},
					],
				});
			}

			return tabsStructure;
		},
		[project, priceBreakdown, contactList],
	);

	const [tabsStructure, setTabsStructure] = useState(
		generateTabsStructure(project),
	);

	useEffect(() => {
		setTabsStructure(generateTabsStructure(project));
	}, [project, priceBreakdown, contactList]);

	const getValidateMessage = (fieldName: string) => {
		switch (fieldName) {
			case 'installValidated': {
				return T.AlertInstallationValidated;
			}
			case 'transportValidated': {
				return T.AlertTransportValidated;
			}
			default: {
				return T.GeneralFormSubmitMessageSuccess;
			}
		}
	};

	const onValidate = async (fieldName: string) => {
		const newData: IProject = cloneDeep(project);
		set(newData, fieldName, true);
		await putProject(newData)
			.then((projectData: IProject) => {
				addToast({
					text: getValidateMessage(fieldName),
					type: ToastType.success,
				});
				setProject(projectData);
			})
			.catch((error) => {
				addToast({
					text: T.GeneralFormSubmitMessageError,
					type: ToastType.error,
				});
			});
	};

	const onSubmit = async (group: IGroup) => {
		const newData: IProject = cloneDeep(project);
		setSubmitLoading(true);

		if (group.rows.length) {
			group.rows.forEach((row) => {
				if (row.inputName) {
					set(newData, row.inputName, row.value);
				}
			});
		}

		if (!isEqual(newData, project)) {
			const promiseArray: Array<IProject | IPricingItem[]> = [
				await putProject(newData),
			];
			if (group.refreshPrice) {
				promiseArray.push(await getPriceBreakdown(`${project.id}`));
			}

			return await Promise.all<IProject | IPricingItem[]>(promiseArray)
				.then(([project, priceBreakdown]) => {
					setProject(project as IProject);
					if (group.refreshPrice) {
						setPriceBreakdown({
							transport: getPriceSectionByName(
								priceBreakdown as IPricingItem[],
								'transport',
							).extension,
							installation: getPriceSectionByName(
								priceBreakdown as IPricingItem[],
								'installation',
							).extension,
						});
					}

					addToast({
						text:
							group.successMessage ||
							T.GeneralFormSubmitMessageSuccess,
						type: ToastType.success,
					});
					setSubmitLoading(false);
					return true;
				})
				.catch(() => {
					addToast({
						text: T.GeneralFormSubmitMessageError,
						type: ToastType.error,
					});
					setSubmitLoading(false);
					return false;
				});
		}
		setSubmitLoading(false);
		return false;
	};

	const onCancel = (group: IGroup) => {
		group.rows.forEach((row) => {
			if (row.inputName === 'transportPriceOverride') {
				row.value = getTransportPriceInputValue();
			} else if (row.inputName === 'installPriceOverride') {
				row.value = getInstallPriceInputValue();
			} else if (row.inputName) {
				row.value = get(project, row.inputName);
			}
		});
	};

	const hasChanged = (group: IGroup) => {
		const newData: IProject = cloneDeep(project);
		if (group.rows.length) {
			group.rows.forEach((row) => {
				if (row.inputName) {
					set(newData, row.inputName, row.value);
				}
			});
		}
		return !isEqual(newData, project);
	};

	return (
		<div className={S.projectTabs}>
			{submitLoading && <Loading />}

			<div className={S.projectInformation}>
				<div className={classes.root}>
					<div className={classes.tabsContainer}>
						<StyledTabs
							value={tabValue}
							onChange={handleChange}
							aria-label="project-details-tabs"
							scrollButtons="on"
							variant="scrollable"
						>
							{tabsStructure.map((tab, index) => (
								<StyledTab
									key={index}
									label={tab.label}
									icon={tab.hasAlert ? alertIcon : undefined}
								/>
							))}
						</StyledTabs>
					</div>
				</div>
				{tabsStructure.map((tab, index) => (
					<MuiTabPanel key={index} value={tabValue} index={index}>
						{tab.isBlockedContent ? (
							<div className={S.tabIsblockedContainer}>
								<TransportPriceIcon />
								<h5>
									{
										T.TabTransportInstallationNoCalculationTitle
									}
								</h5>
								<p>{tab.isBlockedContent}</p>
							</div>
						) : (
							<>
								<p className={S.projectInformationIntroText}>
									{tab.introText}
								</p>
								{tab.groups.map((group, index) => (
									<TabGroup
										key={index}
										group={group}
										onSubmit={onSubmit}
										onValidate={onValidate}
										onCancel={onCancel}
										hasChanged={hasChanged}
									/>
								))}
							</>
						)}
					</MuiTabPanel>
				))}
			</div>
		</div>
	);
}
