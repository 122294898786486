import { BackButton } from '@components/buttons';
import { TagLabel } from '@components/labels';
import { LinkWrapper } from '@components/layout';
import { IBaseStepProps } from '@models';
import { useStore } from '@store';
import { getAbsoluteRoute, getNext } from '@utils/navigation';
import React from 'react';

import { useLocale } from '@i18n';
import S from './styles/NotAccessible.styl';

export default function NotAccessible(props: IBaseStepProps<null>) {
	const T = useLocale();
	const { state } = useStore();
	const { routes } = state.layout;

	if (!routes) {
		return null;
	}

	const targetStep = getNext(state.layout.latestCompletedStep);
	const url = getAbsoluteRoute(targetStep, routes, T.locale, state.project.id).replace(':id', state.project.ref);

	return (
		<div className={S.notAccessiblePageContainer} style={props.style}>
			<TagLabel title={T.NotAccessibleLabel} />
			<h1 className={S.notAccessiblePageTitle}>
				{T.NotAccessibleOops}
			</h1>

			<h2>
				{T.NotAccessibleTitle}
			</h2>

			<p className={S.notAccessiblePageText}>
				{T.NotAccessibleText}
			</p>

			<LinkWrapper
				to={url}
				step={targetStep}
			>
				<BackButton
					enabled
					text={T.NotAccessibleReturnLink}
				/>
			</LinkWrapper>
		</div>
	);
}
