import { Chevron, ChevronDirection } from '@components/svg';
import { useLocale } from '@i18n';
import FormatUtils from '@utils/format';
import classnames from 'classnames';
import React, { ReactElement, useState } from 'react';
import S from './styles/Accordion.styl';

interface IAccordionProps {
	title: string;
	content: string | ReactElement;
	id: string | number;
}

export default function Accordion(props: IAccordionProps) {
	const T = useLocale();
	const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);

	function getContent() {
		// Test if there is html in string
		if (typeof props.content === 'string' && /<\/?[a-z][\s\S]*>/i.test(props.content)) {
			return (
				<span dangerouslySetInnerHTML={{ __html: FormatUtils.formatLocaleVariable(T, props.content) }} />
			);
		}

		return typeof props.content === 'string' ? FormatUtils.formatLocaleVariable(T, props.content) : props.content;
	}

	return (
		<div className={S.accordionContainer}>
			<div
				className={classnames(S.accordionContainerHeader, {
					[S.accordionContainerHeaderActive]: isAccordionOpen,
				})}
				onClick={() => setIsAccordionOpen(!isAccordionOpen)}
			>
				<div className={S.accordionContainerHeaderTitle}>
					{props.title}
				</div>
				<button  className={S.accordionContainerHeaderToggle}>
					<Chevron
						className={S.accordionContainerHeaderToggleIcon}
						color={S.darkBlueGray}
						direction={isAccordionOpen ? ChevronDirection.UP : ChevronDirection.DOWN}
						transitionDuration={S.transitionDuration}
					/>
				</button>
			</div>
			<div
				className={classnames(S.accordionContainerContentWrapper, {
					[S.accordionContainerContentWrapperIsVisible]: isAccordionOpen,
				})}
			>
				<div className={S.accordionContainerContentWrapperText}>
					{getContent()}
				</div>
			</div>
		</div>
	);
}
