import { CheckIcon } from '@components/svg';
import classnames from 'classnames';
import React from 'react';

import { useLocale } from '@i18n';
import S from './styles/AlertPrice.styl';

interface IAlertPriceProps {
	showAlert: boolean;
}

export default function AlertPrice(props: IAlertPriceProps) {
	const T = useLocale();

	return (
		<div
			className={classnames(S.alertPriceContainer, {
				[S.alertPriceContainerVisible]: props.showAlert,
			}) }
		>
			<CheckIcon />
			<div className={S.alertPriceContainerText}>
				{T.PriceAlertDefaultOptionsAndShipping}
			</div>
		</div>
	);
}

AlertPrice.defaultProps = {
	showAlert: true,
};
