import { getCustomers } from '@api/customers';
import { postToComplete } from '@api/index';
import { archiveProject, getProject, postExtendProject, postProject, putProject } from '@api/project';
import Button from '@components/buttons/Button';
import { IDropdownOption, LargeInput } from '@components/inputs';
import Dropdown from '@components/inputs/Dropdown';
import Input from '@components/inputs/Input';
import {
    ArchiveIcon,
    ArrowForwardIcon,
    DetailIcon,
    DuplicateIcon,
    EditIcon,
    MailIcon,
    MoreIcon,
    PdfIcon,
    PowerIcon,
    TimerIcon,
} from '@components/svg';
import { useLocale } from '@i18n';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FormInputState, IProject, OrderState, Steps } from '@models';
import { useStore } from '@store';
import BrowserUtils from '@utils/browser';
import { getUrlFromStep } from '@utils/navigation';
import { ICustomerListPayload } from 'app/models/customers';
import { cloneDeep, isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EPermissions } from '../../hooks/contexts/AuthContext';
import {
    ModalIconType,
    ToastType,
    useAuthContext,
    useModalContext,
    useToastContext,
} from '../../hooks/contexts/UseContext';
import { ERoutes, getPathByRouteName } from '../../routes';
import S from './styles/ActionsMenu.styl';

interface IAction {
    icon: JSX.Element;
    label: string;
    action: () => void;
}

interface IActionsMenu {
    project: IProject;
    fetchProject: () => void;
    viewDetails?: boolean;
    firstActionBtn?: boolean;
    refreshLoading?: (e: boolean) => void | undefined;
}

export default function ActionsMenu({ project, viewDetails, firstActionBtn, fetchProject, refreshLoading }: IActionsMenu) {
    const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
    const open = Boolean(anchorEl);
    const history = useHistory();
    const T = useLocale();
    const addToast = useToastContext();
    const { useModal, changeYesActionState } = useModalContext();
    const { userHasPermissions } = useAuthContext();

    const { state } = useStore();

    const [selectedCustomer, setSelectedCustomer] = useState<string>('');
    const [newProjectName, setNewProjectName] = useState<string>(project.name);
    const [modalSelected, setModalSelected] = useState<boolean>(false);

    useEffect(() => {
        if (selectedCustomer && newProjectName && modalSelected) {
            createProject();
        }
    }, [modalSelected]);

    const createProject = async () => {
        const newProject = {
            customer: {
                epicorCustId: selectedCustomer,
            },
            name: newProjectName,
        };

        let cloneProject;

        getProject(Number(project.id)).then((resProject) => {
            cloneProject = cloneDeep(resProject);
            return postProject(newProject);
        }).then((newProject) => {
            cloneProject.id = newProject.id;
            cloneProject.name = newProject.name;
            cloneProject.refNumber = newProject.refNumber;
            cloneProject.shippingAddress.id = newProject.shippingAddress.id;
            cloneProject.userId = newProject.userId;
            cloneProject.priceVersion = newProject.priceVersion;
            cloneProject.epicorQuoteNum = newProject.epicorQuoteNum;
            cloneProject.dateCreation = newProject.dateCreation;
            cloneProject.dateLastUpdate = newProject.dateLastUpdate;
            cloneProject.dateQuoted = newProject.dateQuoted;
            cloneProject.dateExpiry = newProject.dateExpiry;
            cloneProject.transportType = newProject.transportType;
            cloneProject.customer = newProject.customer;
            cloneProject.contact = newProject.contact;
            cloneProject.groups.forEach((group) => {
                group.id = 0;
                group.rooms.forEach((room) => {
                    room.id = 0;
                    room.doors.forEach((door) => {
                        door.id = 0;
                    });
                });
            });
            return putProject(cloneProject);
        }).then((response) => {
            addToast({
                text: T.GeneralFormSubmitMessageSuccess,
                type: ToastType.success,
            });
        }).catch((error) => {
            addToast({
                text: T.GeneralFormSubmitMessageError,
                type: ToastType.error,
            });
        }).finally(() => {
            window.setTimeout(() => { refreshPage(); }, 1200);
        });
    };

    function getRoute(customerParameter: string): string {
        if (T.locale === 'en') {
            return `/en/project?epicorCustId=${customerParameter}`;
        }
        else {
            return `/fr/projet?epicorCustId=${customerParameter}`;
        }
    }

    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (action: any) => {
        setAnchorEl(null);
        if (action.action) {
            action.action();
        }
    };

    const extendProjectClicked = () => {
        changeYesActionState(true);
        useModal({
            content: (
                <div className={S.extendModalDescription}>
                    {T.ExtendConfirmationDesc}
                </div>
            ),
            title: T.ExtendConfirmationTitle,
            iconType: ModalIconType.Timer,
            color: S.blueNorbec,
            exitLabel: T.GeneralNo,
            yesLabel: T.GeneralYes,
            yesAction: () => {
                if (project.id) {
                    postExtendProject(project.id)
                        .then(() => {
                            addToast({
                                text: T.AlertExtend,
                                type: ToastType.success,
                            });
                            fetchProject();
                        })
                        .catch(() => {
                            addToast({
                                text: T.GeneralFormSubmitMessageError,
                                type: ToastType.error,
                            });
                        });
                }
                return true;
            },
        });
    };

    function refreshPage() {
        location.reload();
    }

    const updateArchivedProperty = async (isArchived: boolean) => {
        await archiveProject(project.id, isArchived)
            .then(() => {
                addToast({
                    text: T.GeneralFormSubmitMessageSuccess,
                    type: ToastType.success,
                });
            })
            .catch(() => {
                addToast({
                    text: T.GeneralFormSubmitMessageError,
                    type: ToastType.error,
                });
            })
            .finally(() => {
                window.setTimeout(() => { refreshPage(); }, 1200);
            });
    };

    const updateProjectStatus = () => {
        if (!isUndefined(refreshLoading)) { refreshLoading(true); }
        if (project.status !== OrderState.Expired) {
            postToComplete(`${project.id}`).then(() => {
                history.push(getPathByRouteName(ERoutes.Quote, T.locale, [{ slug: ':id', value: `${project.id}` }]));
            });
        } else {
            history.push(getPathByRouteName(ERoutes.Quote, T.locale, [{ slug: ':id', value: `${project.id}` }]));
        }
    };

    const onSelect = (event: IDropdownOption) => {
        setSelectedCustomer(event?.value !== '' ? event?.value : '');
        changeYesActionState(!!event?.value);
    };

    function onMenuOpen() {
        const modalContent = document.querySelector('.modalContent') as HTMLElement;
        if (modalContent) {
            modalContent.style.overflow = BrowserUtils.isMobileDevice() ? 'auto' : 'visible';
        }
    }

    function onMenuClose() {
        const modalContent = document.querySelector('.modalContent') as HTMLElement;
        if (modalContent) {
            modalContent.style.overflow = 'auto';
        }
    }

    function handleChange(e: any, inputState: FormInputState) {
        const { value } = e.target;
        if (inputState === FormInputState.Valid) {
            setNewProjectName(value);
        }
    }

    const duplicateProject = () => {
        const projectName = project.name + T.DuplicateProjectCopy;
        const optionList: IDropdownOption[] = cloneDeep(state.data.optionsCustomers);
        changeYesActionState(true);
        useModal({
            content: (
                <div>
                    <div className={S.duplicateProjectDescription}>{T.DuplicateProjectDesc}</div>
                    <Dropdown
                        options={optionList}
                        placeholder={T.CreateProjectPlaceholder}
                        label={T.CreateProjectLabel}
                        onSelect={(e) => onSelect(e)}
                        value={selectedCustomer}
                        disabled={false}
                        isClearable={true}
                        isSearchable={true}
                        isMulti={false}
                        isOptional={false}
                        onMenuClose={onMenuClose}
                        onMenuOpen={onMenuOpen}
                    />
                    <div className={S.duplicateInputDescription} />
                    <Input
                        value={projectName}
                        showOptionalLabel={false}
                        labelText={T.ProjectNameTitle}
                        inputType="text"
                        name=""
                        maxLength={100}
                        min={0}
                        max={100}
                        timeoutMs={700}
                        errorMessage={T.GeneralErrorMessageRequiredField}
                        disabled={false}
                        onChange={
                            (e, inputState) => handleChange(e, inputState)
                        }
                    />
                </div>
            ),
            title: T.DuplicateProjectTitle,
            iconType: ModalIconType.Plus,
            color: S.blueNorbec,
            exitLabel: T.GeneralNo,
            yesLabel: T.GeneralYes,
            yesAction: () => { setModalSelected(true); return true; },
        });
        changeYesActionState(false);
    };

    const generateActions = (project: IProject) => {
        const actions: IAction[] = [];

        const next = {
            label: T.ActionMenuContinue,
            icon: <ArrowForwardIcon color={S.darkBlueGray} />,
            action: () => history.push(getUrlFromStep(project)),
        };
        const details = {
            label: T.ActionMenuDetails,
            icon: <DetailIcon color={S.darkBlueGray} />,
            action: () =>
                history.push(
                    getPathByRouteName(ERoutes.Project, T.locale, [
                        { slug: ':id', value: `${project.id}` },
                    ]),
                ),
        };
        const modify = {
            label: T.ActionMenuModify,
            icon: <EditIcon color={S.darkBlueGray} />,
            action: () =>
                history.push(getUrlFromStep(project, Steps.COMBO_WIDTH)),
        };
        const submission = {
            label: T.ActionMenuSubmission,
            icon: <PdfIcon color={S.darkBlueGray} />,
            action: () => updateProjectStatus(),
        };
        const extend = {
            label: T.ActionMenuExtend,
            icon: <TimerIcon color={S.darkBlueGray} />,
            action: () => project.id && extendProjectClicked(),
        };
        // const contact = { label: T.ActionMenuContact, icon: (<MailIcon color={S.darkBlueGray} />), action: () => console.log('do action') }; // TODO: set action (Out of MVP scope)
        const archive = { label: T.ActionMenuArchive, icon: (<ArchiveIcon color={S.darkBlueGray} />), action: () => updateArchivedProperty(true) }; // TODO: set action (Out of MVP scope)
        const restore = { label: T.ActionMenuRestore, icon: (<PowerIcon color={S.darkBlueGray} />), action: () => updateArchivedProperty(false) }; // TODO: set action (Out of MVP scope)

        const duplicate = { label: T.ActionMenuDuplicate, icon: (<DuplicateIcon color={S.darkBlueGray} />), action: () => duplicateProject() }; // TODO: set action (Out of MVP scope)

        switch (true) {
            case project.status === OrderState.Active && project.archived === false:
                actions.push(next);
                actions.push(archive);
                if (
                    project.completionState === 3200 &&
                    project.requirements.validPartial
                ) {
                    actions.push(submission);
                }
                if (userHasPermissions(EPermissions.viewContactSizeIt)) {
                    // actions.push(contact);
                }
                break;
            case project.status === OrderState.Partial && project.archived === false:
                actions.push(modify);
                actions.push(submission);
                actions.push(archive);
                actions.push(duplicate);
                if (userHasPermissions(EPermissions.viewContactSizeIt)) {
                    // actions.push(contact);
                }
                break;
            case project.status === OrderState.Completed && project.archived === false:
                actions.push(submission);
                if (userHasPermissions(EPermissions.editExtendProject)) {
                    actions.push(extend);
                }
                actions.push(modify);
                actions.push(archive);
                actions.push(duplicate);
                if (userHasPermissions(EPermissions.viewContactSizeIt)) {
                    // actions.push(contact);
                }
                break;
            case project.status === OrderState.Ordered && project.archived === false:
                actions.push(submission);
                actions.push(archive);
                if (userHasPermissions(EPermissions.viewContactSizeIt)) {
                    // actions.push(contact);
                }
                break;
            case (project.status === OrderState.Expired && project.archived === false) || (project.status === OrderState.Expired && project.archived === true):
                if (userHasPermissions(EPermissions.editExtendProject)) {
                    actions.push(extend);
                }
                actions.push(submission);
                actions.push(duplicate);
                // actions.push(archive);
                if (userHasPermissions(EPermissions.viewContactSizeIt)) {
                    // actions.push(contact);
                }
                break;
            case project.archived === true:
                if (userHasPermissions(EPermissions.editReopenExpiredProject)) {
                    // actions.push(restore);
                }
                actions.push(submission);
                actions.push(restore);
                break;
        }

        if (viewDetails && project.status !== OrderState.Expired && project.archived !== true) {
            actions.splice(1, 0, details);
        }

        return actions;
    };

    const [actions, setActions] = useState<IAction[]>(generateActions(project));

    useEffect(() => {
        setActions(generateActions(project));
    }, [project]);

    return (
        <>
            {(actions.length > 1 || viewDetails) && (
                <div className={S.actionsMenuContainer}>
                    <IconButton
                        aria-label="actions"
                        aria-controls="action-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreIcon />
                    </IconButton>
                    <Menu
                        id="action-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        transformOrigin={{
                            vertical: -60,
                            horizontal: 0,
                        }}
                        PaperProps={{
                            style: {
                                maxHeight: 48 * 4.5,
                                width: '30ch',
                            },
                        }}
                    >
                        {actions
                            .slice(firstActionBtn ? 1 : 0)
                            .map((action, index) => (
                                <MenuItem
                                    key={index}
                                    onClick={() => handleClose(action)}
                                >
                                    {action.icon}
                                    {action.label}
                                </MenuItem>
                            ))}
                    </Menu>
                </div>
            )}
            {firstActionBtn && actions[0] && (
                <Button onClick={actions[0].action} icon={actions[0].icon}>
                    {actions[0].label}
                </Button>
            )}
        </>
    );
}
