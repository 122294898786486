import React from 'react';

import GS from '../../styles/global.styl';

interface ICloseMvpIconProps {
	stroke: string;
}

export default function CloseMvpIcon(props: ICloseMvpIconProps) {
	return (
		<svg className="svg-close-mvp-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g className="svg-close-mvp-icon-fill-color" fill={props.stroke} fillRule="evenodd">
				<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" transform="translate(-726 -551) translate(440 330) translate(40 209) translate(246 12)"/>
			</g>
		</svg>

	);
}

CloseMvpIcon.defaultProps = {
	fill: GS.darkBlueGray,
};
