import { IRoomState, Position, State } from '@models';
import classnames from 'classnames';
import React from 'react';
import S from './styles/SmallRoom.styl';

interface ISmallRoomProps {
	room: IRoomState;
	onClick?: () => void;
	stateEnabled: boolean;
}

export default function SmallRoom(props: ISmallRoomProps) {
	function handleOnClick() {
		if (props.onClick) {
			props.onClick();
		}
	}

	function getState() {
		if (props.stateEnabled) {
			return props.room.state;
		}
		return State.Enabled;
	}

	return (
		<div
			className={classnames('', {
				[S.smallRoomWrapperLocked]: getState() === State.Disabled,
			})}
		>
			<div
				onClick={handleOnClick}
				className={classnames(S.smallRoomContainer, {
					[S.smallRoomContainerSingle]: props.room.position === Position.Single,
					[S.smallRoomContainerStart]: props.room.position === Position.Start,
					[S.smallRoomContainerEnd]: props.room.position === Position.End,
					[S.smallRoomContainerSelected]: getState() === State.Selected,
					[S.smallRoomContainerFocused]:  getState() === State.Focused,
					[S.smallRoomContainerLocked]: getState() === State.Disabled,
				})}
			>
				<div
					className={classnames(S.smallRoomText, {
						[S.smallRoomTextSelected]: getState() === State.Selected,
						[S.smallRoomTextFocused]: getState() === State.Focused,
						[S.smallRoomTextReady]: getState() === State.Ready,
					})}
				>
					{props.room.code}
				</div>
			</div>
		</div>
	);
}
