import React from 'react';

interface IArrowLeftIcon {
	stroke: string;
	strokeWidth: string;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
}

export default function ArrowLeftIcon(props: IArrowLeftIcon) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 15 15" onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} style={{ transform: 'rotate(180deg)' }}>
			<g fill="none" fillRule="evenodd" stroke={props.stroke} strokeWidth={props.strokeWidth} strokeLinecap="round" strokeLinejoin="round">
				<path d="M6.923 14.423l7.487-6.61M.577 7.788h13.269M6.634.878l7.789 6.923" />
			</g>
		</svg>
	);
}

ArrowLeftIcon.defaultProps = {
	strokeWidth: '1px',
};
