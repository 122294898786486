import React from 'react';

interface ISeismicIconProps {
	stroke: string;
}

export default function SeismicIcon(props: ISeismicIconProps) {
	return (
		<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
			<g id="Maquettes-UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
				<g id="UI-kit" transform="translate(-125.000000, -3529.000000)" stroke={props.stroke}>
					<g id="ico-sismic" transform="translate(125.000000, 3529.000000)">
						<g id="check-circle-1" transform="translate(1.000000, 1.000000)">
							<circle id="Oval" cx="11" cy="11" r="11"/>
						</g>
						<path d="M22.4082218,15.4593008 L20.5069538,11.6567647 C20.3834592,11.4097754 20.0831226,11.3096633 19.8361334,11.4331579 C19.7245486,11.4889503 19.637852,11.5844419 19.5930674,11.700882 L18.0438498,15.7288476 C17.9447205,15.9865839 17.6554237,16.1151602 17.3976875,16.0160309 C17.2498225,15.9591598 17.1376584,15.835578 17.0953429,15.6829092 L14.332,5.71314286 L14.332,5.71314286 C14.2427005,5.53250654 14.0586469,5.41817933 13.8571429,5.41817933 C13.6556388,5.41817933 13.4715852,5.53250654 13.3822857,5.71314286 L9.90342857,18.4297143 C9.81412906,18.6103506 9.63007548,18.7246778 9.42857143,18.7246778 C9.22706738,18.7246778 9.0430138,18.6103506 8.95371429,18.4297143 L6.44034624,8.703967 C6.37125418,8.43660788 6.09850661,8.27588067 5.83114749,8.34497272 C5.66337707,8.38832865 5.53001586,8.51545905 5.47868816,8.6809647 L4.6184671,11.4547388 C4.53667122,11.7184887 4.25655097,11.8659917 3.992801,11.7841958 C3.86266006,11.7438356 3.75462732,11.6521118 3.69369194,11.530241 L2,8.14285714 L2,8.14285714" id="Path" transform="translate(12.500000, 12.071429) scale(1, -1) translate(-12.500000, -12.071429) "/>
					</g>
				</g>
			</g>
		</svg>
	);
}
