import React, { useEffect } from 'react';

interface IZoomInIconProps {
	stroke: string;
	opacity?: string;
}

export default function ZoomInIcon(props: IZoomInIconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" opacity={props.opacity || '1'}>
			<g stroke={props.stroke} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" transform="translate(1 1)">
				<circle cx="6.133" cy="6.133" r="6.133"/>
				<path d="M4.133 6.133h4M6.133 4.133v4M10.47 10.47l4.863 4.863"/>
			</g>
		</svg>
	);
}
