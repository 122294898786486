import { filters, Text } from 'pixi.js';

export default class TextUtils {
	public static createCenteredText(
		text: string,
		fontFamily: string,
		fontSize: number,
		fill: number,
		x: number,
		y: number,
		parentWidth: number,
		parentHeight: number,
		fontWeight: string = 'normal',
		alpha: number = 1,
		angle: number = 0,
	): Text {
		const centeredText = new Text(text, {
			fontFamily,
			fontSize,
			fontWeight,
			fill,
		});
		centeredText.anchor.set(0.5);
		centeredText.x = x + (parentWidth / 2);
		centeredText.y = y + (parentHeight / 2);
		centeredText.resolution = 3;
		centeredText.alpha = alpha;
		centeredText.angle = angle;
		return centeredText;
	}

	public static createCenteredBlurredText(
		text: string,
		fontFamily: string,
		fontSize: number,
		fill: number,
		x: number,
		y: number,
		parentWidth: number,
		parentHeight: number,
		fontWeight: string = 'normal',
		alpha: number = 1,
		blurStrength: number = 8,
	) {
		const centeredText = TextUtils.createCenteredText(
			text,
			fontFamily,
			fontSize,
			fill,
			x,
			y,
			parentWidth,
			parentHeight,
			fontWeight,
			alpha,
		);
		const blurFilter = new filters.BlurFilter(blurStrength);
		centeredText.filters = [blurFilter];
		return centeredText;
	}
}
