import React from 'react';

export default function ErrorIcon({ color }: {color?: string}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fillRule="evenodd">
				<g fill={color ? color : '#FFF'}>
					<g>
						<g>
							<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" transform="translate(-40 -968) translate(20 956) translate(20 12)"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
