import { useLocale } from '@i18n';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { ERoutes, getPathByRouteName } from '.';

interface IProtectedRouteProps {
	children: JSX.Element | JSX.Element[] | string | never[];
	needAuth?: boolean;
	isAuthenticated?: boolean;
}

export default function ProtectedRoute(props: IProtectedRouteProps) {
	const { children, needAuth, isAuthenticated } = props;
	const T = useLocale();

	return (
		<>
			{needAuth && !isAuthenticated ?
				<Redirect to={getPathByRouteName(ERoutes.Login, T.locale)} />
			:
				children
			}
		</>
	);
}
