import React, { useContext } from 'react';

import { DEFAULT_LOCALE, LOCALES, SUPPORTED_LOCALES } from './locales';
import { ILocaleData } from './types';

export { ILocaleData, LOCALES, DEFAULT_LOCALE, SUPPORTED_LOCALES };

export const LocaleContext = React.createContext<ILocaleData>(resolveLocale(getCurrentLang()));

export function useLocale(): ILocaleData {
	return useContext(LocaleContext);
}

export function getCurrentLang() {
	return location.pathname.substr(1, 2);
}

export function getNavigatorLangFormat() {
	if (navigator.language.search('US') > -1) {
		return 'MM-dd-yyyy';
	} else if (navigator.language.search('CA') > -1) {
		return 'yyyy-MM-dd';
	} else {
		return 'dd-MM-yyyy';
	}
}

export function resolveLocale(locale: string | ILocaleData): ILocaleData {
	if (typeof locale === 'string') {
		document.documentElement.setAttribute('lang', locale);
		return LOCALES[locale] || DEFAULT_LOCALE;
	}
	else {
		return locale;
	}
}
