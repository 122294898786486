import React from 'react';

export default function SizeItLogoBig(props: { lang: string, color: string, showText: boolean }) {
	return (
		props.lang === 'fr' ? (
			<svg xmlns="http://www.w3.org/2000/svg" width="161" height="32" viewBox="0 0 161 23">
				<g fill={props.color} fillRule="evenodd">
					<path d="M52.203 5.528c4.953 0 7.684 3.621 7.684 8.1v2.32H49.663c0 1.397 1.08 2.668 3.016 2.668 1.81 0 2.603-.509 3.525-1.43l3.111 3.113C57.57 22.047 55.855 23 52.68 23c-3.779 0-8.128-1.367-8.128-8.736 0-5.592 3.175-8.736 7.652-8.736zM9.335 0c3.239 0 5.683.763 7.62 2.7l-3.493 3.495C12.034 4.765 10.16 4.86 9.176 4.86c-1.937 0-2.73 1.112-2.73 2.097 0 .285.095.698.444 1.048.286.286.762.571 1.556.666l2.73.382c2.033.286 3.493.921 4.446 1.874 1.207 1.176 1.683 2.86 1.683 4.955 0 4.607-3.97 7.117-8.732 7.117-3.461 0-6.287-.636-8.573-2.986l3.556-3.559c1.175 1.176 3.239 1.59 5.049 1.59 2.19 0 3.238-.732 3.238-2.034 0-.54-.127-.984-.444-1.334-.286-.286-.762-.509-1.493-.604l-2.73-.381c-2-.286-3.525-.953-4.541-2.001-1.047-1.08-1.556-2.605-1.556-4.543C1.08 3.018 4.191 0 9.335 0zm16.512 6.386v16.423H20.64V6.386h5.207zm16.226-.668v3.844l-6.637 8.545h6.637v4.702H28.769v-3.844l6.509-8.545H29.15V5.718h12.923zm39.725.668v16.423H76.59V6.386h5.208zm9.526-4.893v4.893h2.699v4.002h-2.7v6.989c0 .667.35 1.048 1.049 1.048h1.65v4.384H91.42c-3.778 0-5.303-2.7-5.303-5.051v-7.37h-1.555V6.386h1.555V1.493h5.208zm9.272 10.588v.884H99.27v4.195h-.991v-4.195h-1.326v-.884h3.643zm1.782 0l1.327 2.746 1.318-2.746H106v5.079h-.991V14.2l-.97 1.925h-.67l-.976-1.925v2.96h-.992v-5.08h.977zm-28.842-1.09v4.892H63.122v-4.891h10.414zM52.203 9.817c-1.239 0-1.937.604-2.254 1.335-.223.444-.286.921-.286 1.334h5.08c0-.413-.063-.89-.286-1.334-.317-.731-1.016-1.335-2.254-1.335zM25.847 0v3.971H20.64V0h5.207zm55.95 0v3.971H76.59V0h5.208z"/>
					{props.showText && (
						<text fontFamily="Assistant-ExtraBold, Assistant" fontSize="12" fontWeight="800">
							<tspan x="116" y="11">PAR</tspan> <tspan x="116" y="22">NORBEC</tspan>
						</text>
					)}
				</g>
			</svg>
		) : (
			<svg xmlns="http://www.w3.org/2000/svg" width="161" height="32" viewBox="0 0 161 23">
				<g fill={props.color} fillRule="evenodd">
					<path d="M52.203 5.528c4.953 0 7.684 3.621 7.684 8.1v2.32H49.663c0 1.397 1.08 2.668 3.016 2.668 1.81 0 2.603-.509 3.525-1.43l3.111 3.113C57.57 22.047 55.855 23 52.68 23c-3.779 0-8.128-1.367-8.128-8.736 0-5.592 3.175-8.736 7.652-8.736zM9.335 0c3.239 0 5.683.763 7.62 2.7l-3.493 3.495C12.034 4.765 10.16 4.86 9.176 4.86c-1.937 0-2.73 1.112-2.73 2.097 0 .285.095.698.444 1.048.286.286.762.571 1.556.666l2.73.382c2.033.286 3.493.921 4.446 1.874 1.207 1.176 1.683 2.86 1.683 4.955 0 4.607-3.97 7.117-8.732 7.117-3.461 0-6.287-.636-8.573-2.986l3.556-3.559c1.175 1.176 3.239 1.59 5.049 1.59 2.19 0 3.238-.732 3.238-2.034 0-.54-.127-.984-.444-1.334-.286-.286-.762-.509-1.493-.604l-2.73-.381c-2-.286-3.525-.953-4.541-2.001-1.047-1.08-1.556-2.605-1.556-4.543C1.08 3.018 4.191 0 9.335 0zm16.512 6.386v16.423H20.64V6.386h5.207zm16.226-.668v3.844l-6.637 8.545h6.637v4.702H28.769v-3.844l6.509-8.545H29.15V5.718h12.923zm39.725.668v16.423H76.59V6.386h5.208zm9.526-4.893v4.893h2.699v4.002h-2.7v6.989c0 .667.35 1.048 1.049 1.048h1.65v4.384H91.42c-3.778 0-5.303-2.7-5.303-5.051v-7.37h-1.555V6.386h1.555V1.493h5.208zm9.272 10.588v.884H99.27v4.195h-.991v-4.195h-1.326v-.884h3.643zm1.782 0l1.327 2.746 1.318-2.746H106v5.079h-.991V14.2l-.97 1.925h-.67l-.976-1.925v2.96h-.992v-5.08h.977zm-28.842-1.09v4.892H63.122v-4.891h10.414zM52.203 9.817c-1.239 0-1.937.604-2.254 1.335-.223.444-.286.921-.286 1.334h5.08c0-.413-.063-.89-.286-1.334-.317-.731-1.016-1.335-2.254-1.335zM25.847 0v3.971H20.64V0h5.207zm55.95 0v3.971H76.59V0h5.208z"/>
					{props.showText && (
						<text fontFamily="Assistant-ExtraBold, Assistant" fontSize="12" fontWeight="800">
							<tspan x="116" y="11">BY</tspan> <tspan x="116" y="22">NORBEC</tspan>
						</text>
					)}
				</g>
			</svg>
		)
	);
}

SizeItLogoBig.defaultProps = {
	lang: 'fr',
	color: '#9FBBC4',
	showText: true,
};
