import { getPriceBreakdown } from '@api/index';
import ButtonLegacy from '@components/buttons/ButtonLegacy';
import AlertPrice from '@components/labels/AlertPrice';
import PriceModalContent from '@components/layout/PriceModalContent';
import { useLocale } from '@i18n';
import { useStore } from '@store/index';
import BrowserUtils from '@utils/browser';
import classnames from 'classnames';
import React, { useState } from 'react';

import S from './styles/PriceModalContainer.styl';

export default function PriceModalContainer() {
	const T = useLocale();
	const { state, dispatch, actions } = useStore();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [priceList, setPriceList] = useState<any>();

	function handleClick() {
		dispatch(actions.LayoutActions.setPriceDetailsIsShown(true));

		setIsLoading(true);
		if (state.project.id) {
			getPriceBreakdown(state.project.id.toString()).then((results) => {
				setPriceList(results);
				setIsLoading(false);
			});
		}
	}

	function heightMobileException() {
		const isMobileDevice = BrowserUtils.isMobileDevice();
		const isChrome = BrowserUtils.isChrome();
		const isSafari = BrowserUtils.isSafari();
		const isFirefox = BrowserUtils.isFirefox();
		const isIosFirefox = BrowserUtils.isIosFirefox();
		return !isFirefox && !isIosFirefox && isMobileDevice && (isChrome || isSafari);
	}

	return (
		<>
			<div
				className={classnames(S.priceModalContainer, {
					[S.priceModalContainerVisible]: state.layout.priceDetailsVisible,
					[S.priceModalContainerMobile]: state.layout.isMobile,
					[S.priceModalContainerMobileVisible]: state.layout.priceDetailsVisible && state.layout.isMobile,
				})}
			>
				<div
					className={classnames(S.priceModalContainerContent, {
						[S.priceModalContainerContentVisible]: state.layout.priceDetailsVisible,
						[S.priceModalContainerContentMobileException]: heightMobileException(),
					})}
				>
					<PriceModalContent
						onClick={() => dispatch(actions.LayoutActions.setPriceDetailsIsShown(false))}
						priceFetch={priceList}
						isLoading={isLoading}
					/>
				</div>
			</div>

			{!state.layout.isMobile && (
				<div
					className={classnames(S.priceModalContainerButton, {
						[S.priceModalContainerButtonVisible]: !state.layout.priceDetailsVisible,
					})}
				>
					<div className={S.priceModalContainerAlertPrice}>
						{/* //TODO: change condition */}
						<AlertPrice showAlert={!state.layout.requirements.installPriceAuto || !state.layout.requirements.transportPriceAuto} />
					</div>
					<ButtonLegacy
						enabled={true}
						text={T.PriceButtonText}
						onClick={() => handleClick()}
						containerBackgroundColor={S.darkBlueGray}
					/>
				</div>
			)}

			{state.layout.isMobile && (
				<div
					className={S.priceModalContainerButtonMobile}
					onClick={() => handleClick()}
				>
					<div className={S.priceModalContainerButtonMobileIcon}>
						<div className={S.dollarIcon}>
							<span>$</span>
						</div>
					</div>
					<div
						className={classnames(S.priceModalContainerButtonMobileText, {
							[S.priceModalContainerButtonMobileTextClose]: state.layout.priceDetailsVisible,
						})}
					>
						{T.GeneralPrice}
					</div>
				</div>
			)}
		</>
	);
}
