const SESSION_TOKEN = 'SESSION_TOKEN';

export function getSessionToken() {
	return window.localStorage.getItem(SESSION_TOKEN);
}

export function removeSessionToken() {
	return window.localStorage.removeItem(SESSION_TOKEN);
}

export function setSessionToken(token: string) {
	return window.localStorage.setItem(SESSION_TOKEN, token);
}
