import React from 'react';

import classnames from 'classnames';
import S from './styles/Summary.styl';

interface ISummarySectionProps {
	children?: React.ComponentElement<any, any>;
	title: string;
	large?: boolean;
}

export default function SummarySection({ children, title, large }: ISummarySectionProps) {
	return (
		<div className={S.summarySection}>
			<div
				className={classnames(S.summarySectionHeader, {
					[S.summarySectionHeaderLarge]: large,
				})}
			>
				{title}
			</div>
			<div className={S.summarySectionContent}>
				{children}
			</div>
		</div>
	);
}
