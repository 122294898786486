import PillLabel from '@components/labels/PillLabel';
import SummaryElementLabel from '@components/labels/SummaryElementLabel';
import { useLocale } from '@i18n';
import { State, Steps } from '@models';
import { useStore } from '@store';
import FormatUtils from '@utils/format';
import { shouldShowSection } from '@utils/navigation';
import React from 'react';

import S from './styles/SummaryGroupSpecs.styl';

const enabledGroupSteps = [
    Steps.COMBO_SYSTEM,
    Steps.CONDENSER_DISTANCE,
    Steps.COMBO_OPTIONS,
    Steps.DOORS_WALL,
    Steps.DOORS_RAMPE,
    Steps.DOORS_WIDTH,
    Steps.DOORS_POSITION,
    Steps.DOORS_OPENING_DIRECTION,
    Steps.DOORS_OPTIONS,
    Steps.COMBO_FLOOR_FINISH,
    Steps.COMBO_FLOOR_TYPE,
    Steps.ROOM_MOTION_DETECTOR,
];

export default function SummaryGroupSpecs() {
    const T = useLocale();
    const { state } = useStore();

    function getGroupState() {
        if (enabledGroupSteps.includes(state.layout.currentStep)) {
            return State.Enabled;
        }
        if (anyRoomHasState(State.Selected)) {
            return State.Selected;
        } else if (anyRoomHasState(State.Focused)) {
            return State.Focused;
        } else if (allRoomsHaveState(State.Done)) {
            return State.Done;
        } else if (allRoomsHaveState(State.Disabled)) {
            return State.Disabled;
        }
        return State.Enabled;
    }

    function anyRoomHasState(roomState: State) {
        return state.group.rooms.find((room) => room.state === roomState);
    }

    function allRoomsHaveState(roomState: State) {
        return state.group.rooms.find((room) => room.state !== roomState) === undefined;
    }

    function comboWidthHasBeenModified() {
        return state
            .group
            .rooms
            .find((room) => room.hasDefaultWidth === false) !== undefined;
    }

    function comboDepthHasBeenModified() {
        return state
            .group
            .rooms
            .find((room) => room.hasDefaultDepth === false) !== undefined;
    }

    function comboHeightHasBeenModified() {
        return state
            .group
            .rooms
            .find((room) => room.hasDefaultHeight === false) !== undefined;
    }

    function getGroupLabelState(step: Steps) {
        if (state.layout.currentStep === step) {
            return getGroupState() === State.Focused ? State.Focused : State.Selected;
        }
        return State.Enabled;
    }

    function getRoomsTotalWidth(): number {
        let width = state.settings.commonWallWidth;
        for (const room of state.group.rooms) {
            width += (room.width + room.borderLength);
        }
        return width;
    }

    function getRoomsTotalDepth(): number {
        const room = state.group.rooms[0];
        return room.depth + room.borderLength * 2;
    }

    function getRoomsTotalHeight(): number {
        const room = state.group.rooms[0];
        return room.height;
    }

    function getRoomsTotalWidthFormatted(): string {
        return comboWidthHasBeenModified() ?
            FormatUtils.formatInches(getRoomsTotalWidth()) :
            T.ExteriorWidthPendingLabel;
    }

    function getRoomsTotalDepthFormatted(): string {
        return comboDepthHasBeenModified() ?
            FormatUtils.formatInches(getRoomsTotalDepth()) :
            T.ExteriorDepthPendingLabel;
    }

    function getRoomsTotalHeightFormatted(): string {
        return comboHeightHasBeenModified() ?
            FormatUtils.formatInches(getRoomsTotalHeight()) :
            T.ExteriorHeightPendingLabel;
    }

    function getPillTitle() {
        if (state.group.rooms.length <= 1) {
            return T.GeneralRoom;
        }
        return T.GeneralCombo;
    }

    return (
        <div className={S.groupSpecs}>
            <PillLabel
                smallText={true}
                title={getPillTitle()}
                state={getGroupState()}
            />
            {shouldShowSection(Steps.COMBO_WIDTH, state) && (
                <SummaryElementLabel
                    title={T.SummaryTotalExteriorWidth}
                    value={getRoomsTotalWidthFormatted()}
                    valueState={getGroupLabelState(Steps.COMBO_WIDTH)}
                />
            )}
            {shouldShowSection(Steps.COMBO_DEPTH, state) && (
                <SummaryElementLabel
                    id={Steps.COMBO_DEPTH.toString()}
                    title={T.SummaryTotalExteriorDepth}
                    value={getRoomsTotalDepthFormatted()}
                    valueState={getGroupLabelState(Steps.COMBO_DEPTH)}
                />
            )}
            {shouldShowSection(Steps.COMBO_HEIGHT, state) && (
                <SummaryElementLabel
                    id={Steps.COMBO_HEIGHT.toString()}
                    title={T.SummaryExteriorHeight}
                    value={getRoomsTotalHeightFormatted()}
                    valueState={getGroupLabelState(Steps.COMBO_HEIGHT)}
                />
            )}
        </div>
    );
}
