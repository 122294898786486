import { RoomSettings, State } from '@models';
import { Graphics as PIXIGraphics } from 'pixi.js';
import RoomUtils from '../room';
import LineUtils, { LineDirection } from './lines';
import ShapesUtils from './shapes';
import TextUtils from './text';

export function renderDimension(
	graphics: PIXIGraphics,
	x: number,
	y: number,
	lengthInches: number,
	withState: boolean,
	doorState: State,
	textFormatter: (v: number) => string,
	position?: LineDirection,
	shortHooks: boolean = false,
	biggerDimension: boolean = false,
) {
	const length = RoomUtils.toPixels(lengthInches);
	const {
		TopLeft, TopRight, DownLeft, DownRight,
		VerticalDownLeft, VerticalDownRight, VerticalTopLeft, VerticalTopRight,
	} = LineDirection;

	const roomBiggerDimensionHeight = RoomSettings.ROOM_DIMENSION_HEIGHT_BIGGER - 2;

	const text = TextUtils.createCenteredText(
		textFormatter(lengthInches),
		'Assistant',
		biggerDimension ? 14 : 12,
		withState ?
		RoomUtils.getDimensionDoorTextColor(doorState) :
		RoomUtils.getDimensionTextColor(doorState),
		x,
		biggerDimension ? y + 3 : y,
		length,
		RoomSettings.ROOM_DIMENSION_HEIGHT,
		RoomUtils.getCodeFontWeight(doorState),
	);

	ShapesUtils.drawRoundedRectangle(
		graphics,
		x,
		y,
		length,
		biggerDimension ? roomBiggerDimensionHeight : RoomSettings.ROOM_DIMENSION_HEIGHT,
		withState ?
		RoomUtils.getBackgroundDoorDimensionColor(doorState) :
		0xffffff,
		1,
	);

	if (text.width + 1 >= length) {
		const hookLength = shortHooks ? 4 : 20;
		let hookYOffset = 0;
		let hookXOffset = 0;
		let textXOffset = 0;
		let textYOffset = 0;
		let hookDirection = TopLeft;

		switch (position) {
			case DownLeft:
				hookXOffset = x + text.width;
				hookYOffset = y - 5;
				textXOffset = x + text.width + hookLength + (hookLength * 0.75) + length;
				textYOffset = y - hookLength - (RoomSettings.ROOM_DIMENSION_HEIGHT / 2);
				hookDirection = TopRight;
				hookDirection = TopRight;
				if (biggerDimension) {
					hookXOffset = (x - 8) + text.width;
					textXOffset = textXOffset + (text.width > 20 ? x - 16 : x - 8);
				}
				break;

			case DownRight:
				hookXOffset = x;
				hookYOffset = y - 5;
				textXOffset = x - hookLength - (hookLength * 0.75) - length;
				textYOffset = y - hookLength - (RoomSettings.ROOM_DIMENSION_HEIGHT / 2);
				hookDirection = TopLeft;
				if (biggerDimension) {
					hookXOffset = (x - 8) + text.width;
					textXOffset = text.width > 20 ? textXOffset + 24 : textXOffset + 8;
				}
				break;

			case TopLeft:
				hookXOffset = x + text.width;
				hookYOffset = y + 5 + RoomSettings.ROOM_DIMENSION_HEIGHT;
				textXOffset = x + text.width + hookLength + (hookLength * 0.75) + length;
				textYOffset = y + hookLength + (RoomSettings.ROOM_DIMENSION_HEIGHT * 1.5);
				hookDirection = DownRight;
				if (biggerDimension) {
					hookXOffset = (x - 8) + text.width;
					hookYOffset = y + 5 + roomBiggerDimensionHeight;
					textXOffset = textXOffset + (text.width > 20 ? x - 16 : x - 8);
					textYOffset = textYOffset + 5;
				}
				break;

			case TopRight:
				hookXOffset = x;
				hookYOffset = y + 5 + RoomSettings.ROOM_DIMENSION_HEIGHT;
				textXOffset = x - hookLength - (hookLength * 0.75) - length;
				textYOffset = y + hookLength + (RoomSettings.ROOM_DIMENSION_HEIGHT * 1.5);
				hookDirection = DownLeft;
				if (biggerDimension) {
					hookXOffset = (x - 8) + text.width;
					hookYOffset = y + 5 + roomBiggerDimensionHeight;
					textXOffset = text.width > 20 ? textXOffset + 24 : textXOffset + 8;
					textYOffset = textYOffset + 5;
				}
				break;

			case VerticalDownLeft:
				hookXOffset = x + text.width;
				hookYOffset = y + 5 + RoomSettings.ROOM_DIMENSION_HEIGHT;
				textXOffset = x + text.width + hookLength + (hookLength * 0.75) + length;
				textYOffset = y + hookLength + (RoomSettings.ROOM_DIMENSION_HEIGHT * 1.5);
				hookDirection = VerticalTopRight;
				if (biggerDimension) {
					hookXOffset = (x - 8) + text.width;
					hookYOffset = y + 5 + roomBiggerDimensionHeight;
					textXOffset = textXOffset + (text.width > 20 ? x - 16 : x - 8);
					textYOffset = textYOffset + 5;
				}
				break;

			case VerticalDownRight:
				hookXOffset = x + RoomSettings.ROOM_DIMENSION_HEIGHT;
				hookYOffset = y - 5;
				textXOffset = x + text.width + hookLength + (hookLength * 0.75) + length;
				textYOffset = y - hookLength - (RoomSettings.ROOM_DIMENSION_HEIGHT / 2);
				hookDirection = VerticalTopLeft;
				if (biggerDimension) {
					hookXOffset = (x - 8) + text.width;
					textXOffset = textXOffset + (text.width > 20 ? x - 16 : x - 8);
				}
				break;

			case VerticalTopLeft:
				hookXOffset = x + (text.width * 0.25);
				hookYOffset = y + (RoomSettings.ROOM_DIMENSION_HEIGHT * 1.5);
				textXOffset = x - hookLength - (hookLength * 0.75) - length;
				textYOffset = y + hookLength + (RoomSettings.ROOM_DIMENSION_HEIGHT * 1.5);
				hookDirection = VerticalDownRight;
				if (biggerDimension) {
					hookXOffset = (x - 8) + text.width;
					hookYOffset = y + 5 + roomBiggerDimensionHeight;
					textXOffset = text.width > 20 ? textXOffset + 24 : textXOffset + 8;
					textYOffset = textYOffset + 5;
				}
				break;

			case VerticalTopRight:
				hookXOffset = x + (text.width * 0.25);
				hookYOffset = y - (RoomSettings.ROOM_DIMENSION_HEIGHT * 0.5);
				textXOffset = x - hookLength - (hookLength * 0.75) - length;
				textYOffset = y - hookLength - (RoomSettings.ROOM_DIMENSION_HEIGHT / 2);
				hookDirection = VerticalDownLeft;
				if (biggerDimension) {
					hookXOffset = (x - 8) + text.width;
					textXOffset = text.width > 20 ? textXOffset + 24 : textXOffset + 8;
				}
				break;
		}

		LineUtils.drawLineHook(
			hookXOffset,
			hookYOffset,
			hookDirection,
			hookLength,
			graphics,
			0x415a62,
		);

		text.x = textXOffset;
		text.y = textYOffset;
		text.style.fill = RoomUtils.getDimensionTextColor(doorState);
	}

	graphics.addChild(text);
}
