import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { AnimationSettings } from '../../utils/animation';
import S from './styles/NumberInput.styl';

interface INumberInputProps {
	readonly value: number;
	readonly onChange: Function;
	readonly max?: number;
	readonly min?: number;
	readonly disabled?: boolean;
}

export default function NumberInput(props: INumberInputProps) {
	const [isAnimatingLess, setIsAnimatingLess] = useState(false);
	const [isAnimatingMore, setIsAnimatingMore] = useState(false);
	const [lessTimeout, setLessTimeout] = useState<number>();
	const [moreTimeout, setMoreTimeout] = useState<number>();

	const handleClick = (value: number) => {
		if (value < props.value) {
			setIsAnimatingLess(true);
		} else {
			setIsAnimatingMore(true);
		}
		props.onChange(value);
	};

	useEffect(() => {
		if (isAnimatingLess) {
			if (lessTimeout) {
				clearTimeout(lessTimeout);
			}
			setLessTimeout(window.setTimeout(() => setIsAnimatingLess(false), AnimationSettings.FADE_DELAY));
		}

	}, [isAnimatingLess]);

	useEffect(() => {
		if (isAnimatingMore) {
			if (moreTimeout) {
				clearTimeout(moreTimeout);
			}
			setMoreTimeout(window.setTimeout(() => setIsAnimatingMore(false), AnimationSettings.FADE_DELAY));
		}
	}, [isAnimatingMore]);

	const lessDisabled = (props.min && props.value - 1 < props.min) || props.disabled;
	const moreDisabled = (props.max && props.value + 1 > props.max) || props.disabled;

	return (
		<div className={S.number_input_container}>
			<div
				className={classnames(S.number_input_button, {
					[S.number_input_button_clicked]: isAnimatingLess,
					[S.number_input_button__disabled]: lessDisabled,
				})}
			>
				<div
					className={S.number_input_button_text}
					onClick={() => lessDisabled ? {} : handleClick(props.value - 1)}
				>
					-
				</div>
			</div>

			<div className={classnames(S.number_input_input, { [S.number_input_input_clicked]: isAnimatingMore || isAnimatingLess })}>
				{props.value}
			</div>

			<div
				className={classnames(S.number_input_button, {
					[S.number_input_button_clicked]: isAnimatingMore,
					[S.number_input_button__disabled]: moreDisabled,
				})}
			>
				<div
					className={S.number_input_button_text}
					onClick={() => moreDisabled ? {} : handleClick(props.value + 1)}
				>
					+
				</div>
			</div>
		</div>
	);
}
