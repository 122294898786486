import { getProject, postProject, updateProject } from '@api/index';
import NextButton from '@components/buttons/NextButton';
import { DrawingHeader, Loading } from '@components/layout';
import PriceModalContainer from '@components/layout/PriceModalContainer';
import { AlertCircleIcon } from '@components/svg';
import { DEFAULT_LOCALE, useLocale } from '@i18n';
import { IProject, IStoreState, Steps } from '@models';
import { useStore } from '@store';
import { log } from '@utils/logger';
import { getAbsoluteRoute, getNextStep, getStepFromUrl, shouldSkipStep } from '@utils/navigation';
import classnames from 'classnames';
import { cloneDeep, debounce } from 'lodash';
import React, { CSSProperties, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import NotAccessible from '../../routes/NotAccessible';

import S from './styles/StepContainer.styl';

interface IStepContainerProps {
    shouldShowNextButton: boolean;
    warning?: string;
    isNextSection?: boolean;
    nextButtonLabelOverride?: string;
    nextButtonEnabled: boolean;
    nextButtonCallback?: () => any;
    children: ReactElement;
    isLoading?: boolean;
    optionsFetcherWithProjectId?: boolean;
    optionsFetcher?: (...args: any) => Promise<any>;
    style?: CSSProperties;
    projectStyle?: CSSProperties;
}

export default function StepContainer(props: IStepContainerProps) {
    const { state, dispatch, actions } = useStore();
    const [isSlidingUp, setIsSlidingUp] = useState<boolean>(state.layout.isLoading);
    const [isAppearing, setIsAppearing] = useState<boolean>(false);
    const [previousStateHash, setPreviousStateHash] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [options, setOptions] = useState<any>(undefined);
    const [expectedLatestCompletedStep, setExpectedLatestCompletedStep] = useState<Steps>(state.layout.latestCompletedStep);
    const { pathname } = useLocation();
    const T = useLocale();
    const params = useParams<{ id: string }>();
    const history = useHistory();

    const { currentStep, latestCompletedStep, requirements, routes } = state.layout;

    if (!routes) {
        return null;
    }

    const locale = T.locale || DEFAULT_LOCALE.locale;
    const path = pathname.replace(params.id, ':id').replace(`/${locale}`, '');
    const route = getAbsoluteRoute(currentStep, routes, locale, state.project.id);
    const isNotAccessible = checkIfNotAccessible();
    const childrenContainerRef = useRef<HTMLDivElement>(null);
    const stepObject = getStepFromUrl(path, routes);
    const requestedStep = stepObject?.step;

    function checkIfNotAccessible() {
        return !isLoading &&
            (requestedStep && ((requestedStep > getNextStep(latestCompletedStep, latestCompletedStep, requirements, state.project.transportType) && latestCompletedStep === expectedLatestCompletedStep)
                || shouldSkipStep(requestedStep, requirements)));
    }

    function appear() {
        setIsAppearing(true);
        setTimeout(() => {
            setIsAppearing(false);
        }, 500);
    }

    async function fetchOptions(id?: string) {
        if (props.optionsFetcher) {
            return props.optionsFetcher(props.optionsFetcherWithProjectId ? id : null)
                .then((response) => {
                    setOptions(response);
                });
        }
        return Promise.resolve();
    }

    const debouncedUpdate = useCallback(debounce((currentState: IStoreState) => {
        log('Autosaving...');

        updateProject(currentState)
            .then((project: IProject) => {
                if (currentState.project.id) {
                    dispatch(actions.ProjectActions.restoreRequirements(project.requirements));
                }
            });
    }, 750), []);

    function saveCurrencyToLocalStorage(priceVersion: string, curencyCode: string) {
        window.localStorage.setItem('currency', JSON.stringify({ 'priceVersion': priceVersion, 'currencyCode': curencyCode }));
    }

    function fetchProject(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (params.id) {
                getProject(params.id)
                    .then((response) => {

                        saveCurrencyToLocalStorage(response.priceVersion, response.currencyCode);

                        dispatch(actions.ProjectActions.restoreProject(response));
                        dispatch(actions.ProjectActions.restoreRequirements(response.requirements));

                        fetchOptions(response.id?.toString())
                            .then(() => {
                                if (state.layout.shouldAnimate) {
                                    appear();
                                }
                                setIsSlidingUp(false);
                                resolve();
                            });
                    })
                    .catch(() => {
                        dispatch(actions.LayoutActions.setErrorModalIsShown(true));
                        reject();
                    });
            } else {
                if (!route.includes(':id')) {
                    fetchOptions()
                        .then(() => {
                            setIsSlidingUp(false);
                            resolve();
                        });
                } else {
                    resolve();
                }
            }
        });
    }

    function goToNextStepUrl(id: string = state.project.id!.toString()) {
        window.setTimeout(() => {
            if (routes) {
                const nextStep = getNextStep(currentStep, latestCompletedStep, state.layout.requirements, state.project.transportType);
                const nextRoute = getAbsoluteRoute(nextStep, routes, locale, state.project.id);

                history.push(nextRoute.replace(':id', id));
            }
        }, 250);
    }

    async function handleClick() {
        if (props.nextButtonEnabled) {
            if (isNotAccessible) {
                if (props.nextButtonCallback) {
                    props.nextButtonCallback();
                }
            } else {
                setIsSlidingUp(true);
                setOptions(undefined);
                debouncedUpdate.cancel();

                const clonedState = cloneDeep(state);
                if (state.project.id) {
                    clonedState.layout.latestCompletedStep = Math.max(currentStep, latestCompletedStep);

                    setExpectedLatestCompletedStep(clonedState.layout.latestCompletedStep);
                    setPreviousStateHash(clonedState.hash);

                    updateProject(clonedState)
                        .then(() => {
                            if (props.nextButtonCallback) {
                                props.nextButtonCallback();
                            }

                            if (currentStep < Steps.QUOTE) {
                                goToNextStepUrl(state.project.id?.toString());
                            }
                        });
                } else {
                    const epicorCustId = getEpicorCutomerId();

                    clonedState.layout.latestCompletedStep = clonedState.layout.currentStep;
                    clonedState.project.customer.epicorCustId = epicorCustId;
                    return postProject(clonedState)
                        .then((response) => {
                            if (props.nextButtonCallback) {
                                props.nextButtonCallback();
                            }

                            goToNextStepUrl(response.id?.toString());
                        });
                }
            }
        }
    }

    function getEpicorCutomerId() {
        let epicorCustomerId = '';
        const queryParams = window.location.search;
        const id = queryParams.slice(queryParams.indexOf('epicorCustId=') + 13);
        if (id) {
            epicorCustomerId = id;
        }
        return epicorCustomerId;
    }

    // Handles auto-save
    useEffect(() => {
        if (state.project.id && !isNotAccessible && !state.layout.isLoading) {
            if (previousStateHash && previousStateHash !== state.hash) {
                debouncedUpdate(state);
            }
        }
        setPreviousStateHash(state.hash);
    }, [state.project, state.group.rooms]);

    useEffect(() => {
        dispatch(actions.LayoutActions.setLoading(true));

        fetchProject()
            .then(() => {
                dispatch(actions.LayoutActions.setCurrentStep(requestedStep || Steps.PROJECT_NAME));
                document.title = `Size-it${stepObject ? ` - ${stepObject.stepName}` : ''}`;
                setIsLoading(false);
                dispatch(actions.LayoutActions.setLoading(false));
            });
    }, []);

    const hasDrawerVisible = state.layout.configurationDrawerVisible ||
        state.layout.optionsDrawerVisible ||
        state.layout.installationDrawerVisible;

    function shouldShowPriceSection() {
        const requirements = state.layout.requirements;
        if (requirements.shouldSkipCondenserDistanceStep && requirements.shouldSkipComboOptionsStep) {
            return state.layout.latestCompletedStep >= Steps.COMBO_SYSTEM;
        } else if (requirements.shouldSkipComboOptionsStep && !requirements.shouldSkipCondenserDistanceStep) {
            return state.layout.latestCompletedStep >= Steps.CONDENSER_DISTANCE;
        } else {
            return state.layout.latestCompletedStep >= Steps.COMBO_OPTIONS;
        }
    }

    return (
        <div
            className={S.stepContainer}
            style={hasDrawerVisible && !state.layout.isMobile ? { ...props.style, top: '45px' } : { ...props.style }}
        >
            {!state.layout.isDesktop && !isLoading && currentStep >= Steps.ROOM_TYPES &&
                (
                    <div className={S.stepContainerHeader}>
                        <DrawingHeader
                            showDrawing
                            onClick={() => dispatch(actions.LayoutActions.setModalIsShown(true))}
                            titleStyle={S.stepContainerHeaderTitle}
                        />
                    </div>
                )
            }
            {isNotAccessible ? (
                <NotAccessible />
            ) : (
                isLoading ? (
                    <Loading delay={300} />
                ) : (
                    <div
                        className={classnames(S.stepContainerProject, {
                            [S.stepContainerProjectSliding]: isSlidingUp,
                            [S.stepContainerProjectAppearing]: isAppearing,
                        })}
                        ref={childrenContainerRef}
                        style={props.projectStyle}
                    >
                        {/* Render children with the extra options props */
                            React.cloneElement(props.children, { options: options || [] })
                        }
                    </div>
                )
            )}

            {props.warning && (
                <div>
                    <div className={S.stepContainerWarning}>
                        <div className={S.stepContainerWarningIcon}>
                            <AlertCircleIcon dark />
                        </div>
                        <div className={S.stepContainerWarningText}>
                            <div dangerouslySetInnerHTML={{ __html: props.warning }} />
                        </div>
                    </div>
                </div>
            )}

            {props.shouldShowNextButton && (
                <div className={S.stepContainerFooter}>
                    <NextButton
                        enabled={props.nextButtonEnabled && !state.layout.isLoading}
                        onClick={handleClick}
                        isNextSection={props.isNextSection}
                        label={props.nextButtonLabelOverride || (props.isNextSection ? T.NextStepLabel : T.NextQuestionLabel)}
                    />

                    {shouldShowPriceSection() && state.layout.isMobile && (
                        <PriceModalContainer />
                    )}
                </div>
            )}
        </div>
    );
}
