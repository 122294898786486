import { ILocaleData } from '@i18n';
import { useStore } from '@store';
import React from 'react';

import PrintablePageFooter from './PrintablePageFooter';
import PrintablePageHeader from './PrintablePageHeader';

export const PageWidth = 816;
export const PageHeight = 1056;

export interface IPrintablePageProps {
	scale: number;
	children?: React.ReactNode;
	pageNumber: number;
	pageTotal: number;
	isLastPage?: boolean;
	locale?: ILocaleData;
}

export default function PrintablePage(props: IPrintablePageProps) {
	const { state } = useStore();

	const getQuoteNumber = () => {
		// if (state.project.epicorQuoteNum && state.project.epicorQuoteNum > 0) {
		// 	return `${state.project.epicorQuoteNum}`;
		// }

		return state.project.ref;
	};

	return (
		<div
			style={{
				width: `${PageWidth}px`,
				height: `${PageHeight}px`,
				backgroundColor: 'white',
				display: 'flex',
				flexDirection: 'column',
				fontSize: '12px',
				marginLeft: 'auto',
				marginRight: 'auto',
				marginTop: '30px',
				pageBreakAfter: props.isLastPage ? 'avoid' : 'always',
				transform: `scale(${props.scale})`,
			}}
		>
			{props.locale && (
				<PrintablePageHeader
					locale={props.locale}
					quoteDate={new Date()}
					quoteNumber={getQuoteNumber()}
				/>
			)}

			<div
				style={{
					flexGrow: 1,
				}}
			>
				{props.children}
			</div>

			{props.locale && (
				<PrintablePageFooter
					locale={props.locale}
					pageNumber={props.pageNumber}
					pageTotal={props.pageTotal}
				/>
			)}
		</div>
	);
}

PrintablePage.defaultProps = {
	scale: 1,
};
