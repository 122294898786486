import { useLocale } from '@i18n';
import React, { CSSProperties } from 'react';

import S from './styles/Copyright.styl';

interface ICopyrightProps {
	textColor?: string;
	textUppercase: boolean;
	fontSize: string;
}

export default function Copyright(props: ICopyrightProps) {
	const T = useLocale();

	function copyrightStyles(): CSSProperties {
		const styles: CSSProperties = {
			fontSize: props.fontSize,
			color: S.darkBlueGray,
			textTransform: 'none',
		};

		if (props.textUppercase) {
			styles.textTransform = 'uppercase';
		}

		if (props.textColor) {
			styles.color = props.textColor;
		}

		return styles;
	}

	return (
		<div className={S.copyrightContainer} style={copyrightStyles()}>
			<span>
				{T.GeneralCopyright}
			</span>
		</div>
	);
}

Copyright.defaultProps = {
	textUppercase: false,
	fontSize: '10px',
};
