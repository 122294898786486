import { ILocaleData } from '@i18n';

export enum State {
	Selected = 1,
	Focused = 2,
	Enabled = 3,
	Disabled = 4,
	Ready = 5,
	Done = 6,
}

export enum StepState {
	Completed,
	Active,
	Added,
	Enabled,
	Disabled,
}

export enum OrderState {
	Active = 1,
	Partial,
	Completed,
	Ordered,
	Archived,
	Expired = 9,
}

export const TableOrderState = (T: ILocaleData) => ({
	1: T.LabelStatusActive,
	2: T.LabelStatusPartial,
	3: T.LabelStatusCompleted,
	// 4: T.LabelStatusOrdered,
	5: T.LabelStatusArchived,
	9: T.LabelStatusExpired,
});
