import { useLocale } from '@i18n';
import { OrderState } from '@models';
import classnames from 'classnames';
import React from 'react';
import S from './styles/TagLabel.styl';

interface ITagLabelProps {
	title?: string;
	state?: OrderState;
	archived?: boolean;
}

export default function TagLabel(props: ITagLabelProps) {
	const T = useLocale();

	function getName(): string {
		if (props.title) {
			return props.title;
		}
		else {
			switch (true) {
				case props.state === OrderState.Active && props.archived === false: {
					return T.LabelStatusActive;
				}
				case props.state === OrderState.Partial && props.archived === false: {
					return T.LabelStatusPartial;
				}
				case props.state === OrderState.Completed && props.archived === false: {
					return T.LabelStatusCompleted;
				}
				case (props.state === OrderState.Expired && props.archived === false) || (props.state === OrderState.Expired && props.archived === true): {
					return T.LabelStatusExpired;
				}
				case props.state === OrderState.Ordered && props.archived === false: {
					return T.LabelStatusOrdered;
				}
				case props.archived === true: {
					return T.LabelStatusArchived;
				}
				default: {
					return '';
				}
			}
		}
	}

	return (
		<div
			className={classnames(S.tagLabel, {
				[S.tagLabelDefault]: props.state == null,
				[S.tagLabelActive]: props.state === OrderState.Active && props.archived === false,
				[S.tagLabelPartial]: props.state === OrderState.Partial && props.archived === false,
				[S.tagLabelCompleted]: props.state === OrderState.Completed && props.archived === false,
				[S.tagLabelExpired]: (props.state === OrderState.Expired && props.archived === false) || (props.state === OrderState.Expired && props.archived === true),
				[S.tagLabelOrdered]: props.state === OrderState.Ordered && props.archived === false,
				[S.tagLabelArchived]: (props.state === OrderState.Active && props.archived === true) || (props.state === OrderState.Partial && props.archived === true) || (props.state === OrderState.Completed && props.archived === true) || (props.state === OrderState.Ordered && props.archived === true),
			})}
		>
			{getName()}
		</div>
	);
}
