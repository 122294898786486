import React from 'react';

export default function TransportPriceIcon({ color }: {color?: string}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="85" height="50" viewBox="0 0 85 50">
			<g fill="none" fillRule="evenodd">
				<g>
					<g>
						<g transform="translate(-598 -683) translate(493 683) translate(105)">
							<ellipse cx="42.5" cy="43.5" fill="#F5F8F9" rx="42.5" ry="6.5"/>
							<path fill={color ? color : '#D7E3E7'} d="M64.955 10.75h-8.046V0H19.364C16.414 0 14 2.419 14 5.375v29.563h5.364c0 4.46 3.593 8.062 8.045 8.062s8.046-3.601 8.046-8.063h16.09C51.545 39.4 55.14 43 59.591 43s8.045-3.601 8.045-8.063H73V21.5l-8.045-10.75zM27.409 38.969c-2.226 0-4.023-1.8-4.023-4.032 0-2.23 1.797-4.03 4.023-4.03s4.023 1.8 4.023 4.03c0 2.231-1.797 4.032-4.023 4.032zM63.614 14.78L68.87 21.5H56.91v-6.719h6.704zM59.59 38.97c-2.226 0-4.023-1.8-4.023-4.032 0-2.23 1.797-4.03 4.023-4.03s4.023 1.8 4.023 4.03c0 2.231-1.797 4.032-4.023 4.032z"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
