import { postLogin } from '@api/auth';
import { Loading } from '@components/layout';
import { useLocale } from '@i18n';
import { StoreProvider } from '@store';
import React, { lazy, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ModalContextProvider } from '../hooks/contexts/ModalContext';
import { ToastContextProvider } from '../hooks/contexts/ToastContext';
import { useAuthContext } from '../hooks/contexts/UseContext';
import { ELocales } from '../i18n/locales';
import MyAccount from './account/MyAccount';
import CreateCustomer from './cpanel/CreateCustomer';
import Customers from './cpanel/Customers';
import Dashboard from './cpanel/Dashboard';
import EditCustomer from './cpanel/EditCustomer';
import Faq from './faq/Faq';
import ForgotPassword from './password/ForgotPassword';
import TokenExpired from './password/TokenExpired';
import Project from './project/Project';
import { getAllValidRoutes } from './routes-definitions';
import Terms from './terms/Terms';

// TODO add lazy-loading for each routes
const CreatePassword = lazy(() => import('./password/CreatePassword'));
const QuotePreview = lazy(() => import('./size-it/quote-preview/QuotePreview'));
const SizeIt = lazy(() => import('./size-it/SizeIt'));

export interface IRoutes {
    name: ERoutes;
    provider: any[];
    component: (props: any) => JSX.Element | any;
    path: { [lang: string]: string[]; };
    exact?: boolean;
    needAuth?: boolean;
    header?: boolean;
}

export enum ERoutes {
    Home,
    Login,
    Configurations,
    Customers,
    PreviewCustomer,
    EditCustomer,
    AddCustomer,
    Project,
    CreatePassword,
    ResetPassword,
    Faq,
    Terms,
    AutoLogin,
    ForgotPassword,
    MyAccount,
    Quote,
    SizeIt,
    TokenExpired,
}

const sizeItRoutes: IRoutes[] = [
    {
        name: ERoutes.Quote,
        provider: [StoreProvider, ModalContextProvider, ToastContextProvider],
        exact: true,
        header: true,
        needAuth: true,
        component: QuotePreview,
        path: {
            en: ['/quote/:id/preview'],
            fr: ['/soumission/:id/apercu'],
        },
    },
];

export const routes: IRoutes[] = [
    {
        name: ERoutes.Home,
        provider: [StoreProvider, ModalContextProvider, ToastContextProvider],
        exact: true,
        header: true,
        needAuth: true,
        component: Dashboard,
        path: {
            en: ['/', '/dashboard'],
            fr: ['/', '/tableau-de-bord'],
        },
    },
    {
        name: ERoutes.Login,
        provider: [ToastContextProvider],
        exact: true,
        component: lazy(() => import('./login/Login')),
        path: {
            en: ['/login'],
            fr: ['/login'],
        },
    },
    {
        name: ERoutes.CreatePassword,
        provider: [ToastContextProvider],
        exact: true,
        component: () => <CreatePassword onboarding />,
        path: {
            en: ['/create-password'],
            fr: ['/creation-mot-de-passe'],
        },
    },
    {
        name: ERoutes.TokenExpired,
        provider: [],
        exact: true,
        component: TokenExpired,
        path: {
            en: ['/token-expired'],
            fr: ['/token-expire'],
        },
    },
    {
        name: ERoutes.ResetPassword,
        provider: [ToastContextProvider],
        exact: true,
        component: CreatePassword,
        path: {
            en: ['/reset-password'],
            fr: ['/reinitialiser-mot-de-passe'],
        },
    },
    {
        name: ERoutes.ForgotPassword,
        provider: [ToastContextProvider],
        exact: true,
        needAuth: false,
        component: ForgotPassword,
        path: {
            en: ['/forgot-password'],
            fr: ['/mot-de-passe-oublie'],
        },
    },
    {
        name: ERoutes.Project,
        provider: [ModalContextProvider, ToastContextProvider],
        exact: true,
        header: true,
        needAuth: true,
        component: Project,
        path: {
            en: ['/project-details/:id'],
            fr: ['/details-projet/:id'],
        },
    },
    {
        name: ERoutes.Faq,
        provider: [ModalContextProvider],
        exact: true,
        header: true,
        needAuth: false,
        component: Faq,
        path: {
            en: ['/faq'],
            fr: ['/faq'],
        },
    },
    {
        name: ERoutes.Terms,
        provider: [ModalContextProvider],
        exact: true,
        header: true,
        needAuth: false,
        component: Terms,
        path: {
            en: ['/terms-of-use'],
            fr: ['/conditions-utilisation'],
        },
    },
    {
        name: ERoutes.Customers,
        provider: [ToastContextProvider],
        exact: true,
        header: true,
        needAuth: true,
        component: Customers,
        path: {
            en: ['/customers'],
            fr: ['/clients'],
        },
    },
    {
        name: ERoutes.AddCustomer,
        provider: [ModalContextProvider, ToastContextProvider],
        exact: true,
        header: true,
        needAuth: true,
        component: CreateCustomer,
        path: {
            en: ['/add-customer'],
            fr: ['/ajouter-client'],
        },
    },
    {
        name: ERoutes.PreviewCustomer,
        provider: [ToastContextProvider],
        exact: true,
        header: true,
        needAuth: true,
        component: () => <EditCustomer />,
        path: {
            en: ['/previewCustomer'],
            fr: ['/apercuClient'],
        },
    },
    {
        name: ERoutes.EditCustomer,
        provider: [ToastContextProvider],
        exact: true,
        header: true,
        needAuth: true,
        component: () => <EditCustomer />,
        path: {
            en: ['/editCustomer/:id'],
            fr: ['/modifierClient/:id'],
        },
    },
    // {
    // 	name: ERoutes.Contact,
    // 	provider: [ModalContextProvider],
    // 	exact: true,
    // 	header: true,
    // 	needAuth: true,
    // 	component: () => <div>Page Contact</div>,
    // 	path: {
    // 		en: ['/contact'],
    // 		fr: ['/contact'],
    // 	},
    // },
    {
        name: ERoutes.MyAccount,
        provider: [ToastContextProvider, ModalContextProvider],
        exact: true,
        header: true,
        needAuth: true,
        component: MyAccount,
        path: {
            en: ['/my-account'],
            fr: ['/mon-compte'],
        },
    },
    ...sizeItRoutes,
    {
        name: ERoutes.SizeIt,
        provider: [StoreProvider, ModalContextProvider],
        needAuth: true,
        component: SizeIt,
        path: {
            en: ['', ...getAllValidRoutes()],
            fr: ['', ...getAllValidRoutes(undefined, true)],
        },
    },
];

// Auto login for local development
if (ENV.localEnvironment) {
    routes.unshift(
        {
            name: ERoutes.AutoLogin,
            provider: [],
            exact: true,
            component: () => {
                const { authenticate } = useAuthContext();
                const history = useHistory();
                const T = useLocale();
                useEffect(() => {
                    postLogin({
                        email: 'aguessous@norbec.com',
                        password: 'Apstrlp311/*',
                    })
                        .then((response) => {
                            authenticate(response.data);
                            history.replace(getPathByRouteName(ERoutes.Home, T.locale));
                        });
                }, []);

                return (<Loading />);
            },
            path: {
                en: ['/loginauto'],
                fr: ['/loginauto'],
            },
        },
    );
}

export const getPathByRouteName = (name: ERoutes, locale: ELocales | string, params?: Array<{ slug: string, value: string }>) => {
    let path = `/${locale}${routes.find((route) => route.name === name)?.path[locale][0]}`;
    params?.forEach((param) => {
        path = path.replace(param.slug, param.value);
    });
    return path;
};

export const getPathForLanguageSwitch = () => {
    const locale = useLocale().locale;
    const targetLocale = locale === ELocales.en ? ELocales.fr : ELocales.en;
    let goodPath = `/${targetLocale}`;
    const currentPath = window.location.pathname.slice(3);
    const currentPathArray = window.location.pathname.slice(4).split('/');
    routes.forEach((route) => {
        route.path[locale].forEach((path, pathIndex) => {
            let comparePath = '';
            let newPath = '';
            path.slice(1).split('/').forEach((subpath, subpathIndex) => {
                if (subpath.includes(':')) {
                    comparePath += `/${currentPathArray[subpathIndex]}`;
                    newPath += `/${currentPathArray[subpathIndex]}`;
                } else {
                    comparePath += `/${subpath}`;
                    newPath += `/${route.path[targetLocale][pathIndex].slice(1).split('/')[subpathIndex]}`;
                }
            });
            if (comparePath === currentPath) {
                goodPath = `/${targetLocale}${newPath}${window.location.search}`;
            }
        });
    });
    return goodPath;
};
