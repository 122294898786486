import { putProject } from '@api/project';
import { getQuoters } from '@api/quoters';
import { IDropdownOption } from '@components/inputs';
import Dropdown from '@components/inputs/Dropdown';
import ContactBox from '@components/layout/ContactBox';
import { useLocale } from '@i18n';
import { IProject } from '@models';
import BrowserUtils from '@utils/browser';
import { IQuotersPayload } from 'app/models/quoters';
import React, { useEffect, useState } from 'react';
import { EPermissions } from '../../hooks/contexts/AuthContext';
import {
	ModalIconType,
	ToastType,
	useAuthContext,
	useModalContext,
	useToastContext,
} from '../../hooks/contexts/UseContext';
import S from './styles/ProjectContacts.styl';

interface IProjectContactsProps {
	project: IProject;
	setProject: React.Dispatch<React.SetStateAction<IProject | undefined>>;
}

export default function ProjectContacts(props: IProjectContactsProps) {

	const T = useLocale();
	const addToast = useToastContext();
	const { project, setProject } = props;
	const { userHasPermissions } = useAuthContext();
	const [quoters, setQuoters] = useState<IQuotersPayload[]>([]);
	const { useModal, changeYesActionState } = useModalContext();
	const [modalSelected, setModalSelected] = useState<boolean>(false);
	const [selectedQuoter, setSelecterQuoter] = useState<IQuotersPayload>();

	useEffect(() => {
		fetchQuoters();
	}, []);

	useEffect(() => {
		if (selectedQuoter && modalSelected) {
			onSubmit(selectedQuoter);
		}
	}, [modalSelected]);

	function onMenuOpen() {
		const modalContent = document.querySelector('.modalContent') as HTMLElement;
		if (modalContent) {
			modalContent.style.overflow = BrowserUtils.isMobileDevice() ? 'auto' : 'visible';
		}
	}

	function onMenuClose() {
		const modalContent = document.querySelector('.modalContent') as HTMLElement;
		if (modalContent) {
			modalContent.style.overflow = 'auto';
		}
	}

	const fetchQuoters = () => {
		getQuoters()
			.then((response) => {
				setQuoters(response.data);
			})
			.catch((error) => {
				addToast({
					text: T.GeneralFormSubmitMessageError,
					type: ToastType.error,
				});
			});
	};

	const onSelect = (event: IDropdownOption) => {
		const val = quoters.find((e) => e.id === event.value);
		setSelecterQuoter(val);
		changeYesActionState(true);
	};

	const onSubmit = async (quoter: IQuotersPayload) => {
		const newProject = {
			...project,
			epicorQuoterId: quoter.id,
			quoterEmail: quoter.email,
			quoterName: quoter.name,
		};
		await putProject(newProject)
		.then((projectData: IProject) => {
			addToast({
				text: T.GeneralFormSubmitMessageSuccess,
				type: ToastType.success,
			});
			setProject(projectData);
		})
		.catch(() => {
			addToast({
				text: T.GeneralFormSubmitMessageError,
				type: ToastType.error,
			});
		});
		setModalSelected(false);
	};

	const onClickModifyProjectManager = () => {
		useModal({
			content: (
				<div>
					<p>{T.ContactBoxChooseProjectManagerLabel}</p>
					<Dropdown
						options={quoters.map((item) => ({
							label: item.name,
							value: item.id,
						}))}
						placeholder={T.GeneralEnter}
						label=""
						onSelect={(e) => onSelect(e)}
						value={''}
						disabled={false}
						isClearable={false}
						isSearchable={true}
						isMulti={false}
						isOptional={false}
						onMenuClose={onMenuClose}
						onMenuOpen={onMenuOpen}
					/>
				</div>
			),
			title: T.ProjectDetailsContactProjectManager,
			iconType: ModalIconType.Contact,
			color: S.blueNorbec,
			exitLabel: T.GeneralCancel,
			yesLabel: T.GeneralSave,
			yesAction: () => { setModalSelected(true); return true; },
		});
		changeYesActionState(false);
	};

	return (
		<div className={S.projectContacts}>
			<ContactBox
				title={T.ProjectDetailsContactSalesRep}
				name={project.salesrepName}
				email={project.salesrepEmail}
			/>
			<ContactBox
				title={T.ProjectDetailsContactProjectManager}
				name={project.quoterName}
				email={project.quoterEmail}
				onClick={() => onClickModifyProjectManager()}
				showActionButton={userHasPermissions(EPermissions.editProjectManager)}
			/>
		</div>
	);
}
