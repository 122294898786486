import React from 'react';

import S from './styles/LoadingElement.styl';

interface ILoadingElementProps {
	color?: string;
}

export default function LoadingElement(props: ILoadingElementProps) {
	const loaderStyle = {
		background: props.color ? props.color : S.blueNorbec,
	};

	return (
		<div className={S.ldsEllipsis}>
			<div style={loaderStyle} />
			<div style={loaderStyle} />
			<div style={loaderStyle} />
			<div style={loaderStyle} />
		</div>
	);
}
