import { IDropdownOption } from '@components/inputs/SimpleDropdown';
import { Chevron, ChevronDirection, CloseMvpIcon } from '@components/svg';
import { useLocale } from '@i18n';
import React from 'react';
import Select, { components, IndicatorProps, OptionTypeBase, StylesConfig } from 'react-select';
import S from './styles/Dropdown.styl';

interface IDropdownProps {
	options: IDropdownOption[];
	placeholder: string;
	label: string;
	onSelect: (e: any) => void;
	value: string;
	disabled?: boolean;
	isClearable: boolean;
	isSearchable: boolean;
	isMulti: boolean;
	isOptional: boolean;
	onMenuOpen?: () => void;
	onMenuClose?: () => void;
}

function getInputStyle(hasValue: boolean, isMenuOpen: boolean, isDisabled: boolean) {

	return {
		border: `1px solid ${isMenuOpen ? S.blueNorbec : hasValue ? S.darkBlueGray : S.blueGray50
			} !important`,
		borderRadius: '8px',
		boxShadow: 'none !important',
		transition: '500ms',
		cursor: 'pointer !important',
	};
}

function getValueOption(value: string, options: IDropdownOption[]) {
	return options.find((opt) => opt.value === value);
}

const StyledChevron = (props: IndicatorProps<OptionTypeBase>) => {
	return (
		<components.DropdownIndicator {...props}>
			<Chevron
				color={props.selectProps.menuIsOpen ? S.blueNorbec : S.darkBlueGray}
				direction={props.selectProps.menuIsOpen ? ChevronDirection.UP : ChevronDirection.DOWN}
			/>
		</components.DropdownIndicator>
	);
};

const StyledClearIndicator = (props: IndicatorProps<OptionTypeBase>) => {
	return (
		<components.ClearIndicator {...props}>
			<CloseMvpIcon stroke={props.selectProps.menuIsOpen ? S.blueNorbec : S.darkBlueGray} />
		</components.ClearIndicator>
	);
};

const colourStyles: StylesConfig = {
	option: (styles, { hasValue, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			backgroundColor: isFocused
				? S.blueGray50ish
				: 'white',
			color: isDisabled
				? S.lightGray
				: isSelected
					? S.blueNorbec
					: isFocused
						? S.darkGray
						: S.darkGray,
			cursor: isDisabled ? 'not-allowed' : 'default',
			borderBottom: `1px solid ${S.blueGray25}`,
		};
	},
	menu: (styles, { hasValue, isDisabled, isFocused, isSelected }) => ({
		...styles,
		borderRadius: '8px',
		backgroundColor: 'white',
		marginTop: '10px',
		zIndex: 3,
	}),
	menuList: (styles, { hasValue, isDisabled, isFocused, isSelected }) => ({
		...styles,
		borderRadius: '8px',
		padding: 0,
		backgroundColor: 'white',
	}),
	control: (styles, { hasValue, menuIsOpen, isDisabled, isFocused }) => ({
		...styles,
		...getInputStyle(hasValue, menuIsOpen, isDisabled),
		color: S.darkGray,
		fontSize: S.fontSizeNormal,
		fontWeight: S.fontWeightRegular as any,
		height: '48px',
		width: '100%',
	}),
	placeholder: (styles) => ({ ...styles }),
	valueContainer: (styles, { data }) => ({ ...styles, padding: '2px 20px' }),
	dropdownIndicator: (styles) => ({ ...styles, marginRight: '20px' }),
};

export default function Dropdown(props: IDropdownProps) {
	const T = useLocale();

	const NoOptionsMessage = (props: any) => {
		return (
			<components.NoOptionsMessage {...props} >
				<span>{T.GeneralDropdownNoOptions}</span>
			</components.NoOptionsMessage>
		);
	};

	function handleDropdownChange(selectedOption: any) {
		props.onSelect(selectedOption);
	}

	return (
		<>
			<label className={S.dropdownLabel}>
				<span>{props.label}</span>

				{props.isOptional && (
					<span className={S.dropdownLabelOptional}>
						{T.GeneralOptionalField}
					</span>
				)}
			</label>
			<Select
				isDisabled={props.disabled}
				components={{
					IndicatorSeparator: null,
					DropdownIndicator: StyledChevron,
					ClearIndicator: StyledClearIndicator,
					NoOptionsMessage,
				}}
				placeholder={props.placeholder}
				options={props.options}
				onChange={handleDropdownChange}
				value={getValueOption(props.value, props.options)}
				styles={colourStyles}
				isClearable={props.isClearable}
				isMulti={props.isMulti}
				isSearchable={props.isSearchable}
				className="dropdown"
				onMenuOpen={() => props.onMenuOpen && props.onMenuOpen()}
				onMenuClose={() => props.onMenuClose && props.onMenuClose()}
			/>
		</>
	);
}

Dropdown.defaultProps = {
	isMulti: false,
	isSearchable: true,
	isClearable: true,
};
