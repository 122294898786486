import { IFloorTextureState } from '@models';
import { State } from './state';

export interface IFloorTexture {
	id: FloorTextureType;
	name: string;
	isIncluded: boolean;
	description: string;
	price?: number;
	unit?: string;
	technicalSheetUrl?: string;
	imageUrl: string;
}

export enum FloorTextureType {
	Smooth = 1,
	NonSkid = 2,
}

export function fromFloorTexturesDTO(floorTextures: IFloorTexture[]): IFloorTextureState[] {
	return floorTextures.map((floorTexture) => {
		return fromFloorTextureDTO(floorTexture);
	});
}

export function fromFloorTextureDTO(floorTexture: IFloorTexture): IFloorTextureState {
	return {
		id: floorTexture.id,
		name: floorTexture.name,
		isIncluded: floorTexture.isIncluded,
		description: floorTexture.description,
		price: floorTexture.price,
		unit: floorTexture.unit,
		technicalSheetUrl: floorTexture.technicalSheetUrl,
		imageUrl: floorTexture.imageUrl,
		state: State.Enabled,
	};
}

export function toFloorTextureDTO(floorTextureState: IFloorTextureState): IFloorTexture {
	return {
		id: floorTextureState.id,
		name: floorTextureState.name,
		isIncluded: floorTextureState.isIncluded,
		description: floorTextureState.description,
		price: floorTextureState.price,
		unit: floorTextureState.unit,
		technicalSheetUrl: floorTextureState.technicalSheetUrl,
		imageUrl: floorTextureState.imageUrl,
	};
}
