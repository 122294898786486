import ArrowLeftIcon from '@components/svg/ArrowLeftIcon';
import { State } from '@models';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import { AnimationSettings } from '../../utils/animation';
import S from './styles/BackButton.styl';

interface IBackButtonProps {
	onClick?: () => void;
	enabled: boolean;
	text: string;
}

export default function BackButton(props: IBackButtonProps) {
	const [state, setState] = useState<State>(getState());
	const [timeout, setTimeout] = useState<number>();

	useEffect(() => {
		return () => {
			clearTimeout(timeout);
		};
	}, []);

	useEffect(() => {
		setState(getState());
	}, [props.enabled]);

	function getState(): State {
		if (props.enabled) {
			return State.Enabled;
		}
		return State.Disabled;
	}

	function blur() {
		if (state !== State.Disabled) {
			setState(State.Enabled);
		}
	}

	function focus() {
		if (state !== State.Disabled) {
			setState(State.Focused);
		}
	}

	function getArrowColor(): string {
		return state === State.Focused ? '#f49800' : '#0095C8';
	}

	function onClick() {
		if (props.onClick && state !== State.Disabled) {
			props.onClick();
			focus();

			if (timeout) {
				window.clearTimeout(timeout);
			}

			setTimeout(window.setTimeout(() => {
				blur();
			}, AnimationSettings.FADE_DELAY));
		}
	}

	return (
		<div
			onMouseOver={focus}
			onMouseLeave={blur}
			onClick={(_) => onClick()}
			className={classnames(S.backButton, {
				[S.backButtonDisabled]: state === State.Disabled,
			})}
		>
			<ArrowLeftIcon stroke={getArrowColor()}/>
			<div
				className={classnames(S.backButtonTitle, {
					[S.backButtonTitleDisabled]: state === State.Disabled,
					[S.backButtonTitleFocused]: state === State.Focused,
				})}
			>
				{props.text}
			</div>
		</div>
	);
}
