import { State } from '@models';
import classnames from 'classnames';
import React from 'react';
import S from './styles/SmallTagLabel.styl';

interface ITagLabelProps {
	text: string;
	state?: State;
}

export default function SmallTagLabel(props: ITagLabelProps) {
	return (
			<span
				className={classnames(S.smallTagLabel, {
					[S.smallTagLabelFocused]: props.state === State.Focused,
					[S.smallTagLabelSelected]: props.state === State.Selected,
				})}
			>
				{props.text}
			</span>
	);
}
