import Group from '@components/drawing/Group';
import DrawingHeader from '@components/layout/DrawingHeader';
import { Steps } from '@models';
import { useStore } from '@store';
import React from 'react';
import S from './styles/MiniatureDrawing.styl';

const groupModalStyle = {
	height: '100px',
};

export default function MiniatureDrawing() {
	const { state, dispatch, actions } = useStore();

	return (
		<div className={S.miniatureDrawing}>
			<DrawingHeader
				showDrawing
				onClick={() => dispatch(actions.LayoutActions.setModalIsShown(true))}
			/>
			<Group
				groupStyle={groupModalStyle}
				hideShadow
				centered
				disableDrag
				showLargeGroup
				showDoors={state.layout.currentStep >= Steps.DOORS_WALL}
			/>
		</div>
	);
}
