import {
	IActions,
	IActionWithoutPayload,
	IActionWithPayload,
	IContact,
	IDataActions,
	IGroupActions,
	IInstallationLocationState,
	ILayoutActions,
	IProject,
	IProjectActions,
	IProjectRequirements,
	IQuotersPayload,
	IRoomTypePayload,
	IRoutes,
	ISettingsActions,
	IUserActions,
	State,
	Steps,
} from '@models';

function createActionWithPayload<TypePayload>(actionType: string):
		(payload: TypePayload) => IActionWithPayload<TypePayload> {
	return (p: TypePayload): IActionWithPayload<TypePayload> => {
		return {
			payload: p,
			type: actionType,
		};
	};
}

function createAction(actionType: string): () => IActionWithoutPayload {
	return (): IActionWithoutPayload => {
		return {
			type: actionType,
		};
	};
}

export enum LayoutActionsNames {
	SET_MOBILE = 'SET_MOBILE',
	SET_TABLET = 'SET_TABLET',
	SET_DESKTOP = 'SET_DESKTOP',
	TOGGLE_SUMMARY = 'TOGGLE_SUMMARY',
	TOGGLE_CONFIGURATION = 'TOGGLE_CONFIGURATION',
	TOGGLE_CONFIGURATION_DRAWER = 'TOGGLE_CONFIGURATION_DRAWER',
	TOGGLE_INSTALLATION = 'TOGGLE_INSTALLATION',
	TOGGLE_INSTALLATION_DRAWER = 'TOGGLE_INSTALLATION_DRAWER',
	TOGGLE_OPTIONS = 'TOGGLE_OPTIONS',
	TOGGLE_OPTIONS_DRAWER = 'TOGGLE_OPTIONS_DRAWER',
	TOGGLE_PRICE = 'TOGGLE_PRICE',
	TOGGLE_PRICE_DETAILS = 'TOGGLE_PRICE_DETAILS',
	TOGGLE_MENU = 'TOGGLE_MENU',
	SET_CURRENT_STEP = 'SET_CURRENT_STEP',
	SET_LOADING = 'SET_LOADING',
	SET_MODAL_IS_SHOWN = 'SET_MODAL_IS_SHOWN',
	SET_ERROR_MODAL_IS_SHOWN = 'SET_ERROR_MODAL_IS_SHOWN',
	SET_IS_INSTALLATION_PLACE_FORM_VALID = 'SET_IS_INSTALLATION_PLACE_FORM_VALID',
	SET_IS_QUOTE_FORM_VALID = 'SET_IS_QUOTE_FORM_VALID',
	SET_ROUTES = 'SET_ROUTES',
	SET_PRICE_DETAILS_IS_SHOWN = 'SET_PRICE_DETAILS_IS_SHOWN',
	SET_IS_DRAWING_MODAL_FULL_SCREEN = 'SET_IS_DRAWING_MODAL_FULL_SCREEN',
}

export enum ProjectActionsNames {
	RESTORE_PROJECT = 'RESTORE_PROJECT',
	RESTORE_PROJECT_REQUIREMENTS = 'RESTORE_PROJECT_REQUIREMENTS',
	RESET_PROJECT = 'RESET_PROJECT',
	SET_PROJECT_NAME = 'SET_PROJECT_NAME',
	SET_PROJECT_REF_NUMBER = 'SET_PROJECT_REF_NUMBER',
	SET_HAS_SEISMIC_KIT = 'SET_HAS_SEISMIC_KIT',
	SET_IS_INSTALLATION_BY_NORBEC = 'SET_IS_INSTALLATION_BY_NORBEC',
	SET_TRANSPORT_TYPE = 'SET_TRANSPORT_TYPE',
	SET_PROJECT_QUOTE = 'SET_PROJECT_QUOTE',
	SET_PROJECT_CONTACT = 'SET_PROJECT_CONTACT',
	SET_PROJECT_STATE = 'SET_PROJECT_STATE',
	SET_INSTALLATION_DATE = 'SET_INSTALLATION_DATE',
	SET_INSTALLATION_LOCATION = 'SET_INSTALLATION_LOCATION',
	SET_PROJECT_MARKUP = 'SET_PROJECT_MARKUP',
	SET_PROJECT_LANG = 'SET_PROJECT_LANG',
	SET_PROJECT_EPICOR_QUOTE_NUM = 'SET_PROJECT_EPICOR_QUOTE_NUM',
	SET_PROJECT_QUOTER = 'SET_PROJECT_QUOTER',
	SET_TRANSPORT_PRICE_OVERRIDE = 'SET_TRANSPORT_PRICE_OVERRIDE',
	SET_TRANSPORT_PRICE_VALIDATED = 'SET_TRANSPORT_PRICE_VALIDATED',
	SET_INSTALL_PRICE_OVERRIDE = 'SET_INSTALL_PRICE_OVERRIDE',
	SET_INSTALL_PRICE_VALIDATED = 'SET_INSTALL_PRICE_VALIDATED',
}

export enum GroupActionsNames {
	SET_ROOMS = 'SET_ROOMS',
	SET_ROOM_NUMBER = 'SET_ROOM_NUMBER',
	SELECT_FOCUSED_ROOMS = 'SELECT_FOCUSED_ROOMS',
	FOCUS_ROOM = 'FOCUS_ROOM',
	FOCUS_ROOMS = 'FOCUS_ROOMS',
	SELECT_ROOM = 'SELECT_ROOM',
	SELECT_ROOMS = 'SELECT_ROOMS',
	SET_ROOM_TYPE = 'SET_ROOM_TYPE',
	DISABLE_ROOMS = 'DISABLE_ROOMS',
	RESET_ROOMS_STATE = 'RESET_ROOMS_STATE',
	SELECT_ROOMS_WITH_FLOOR = 'SELECT_ROOMS_WITH_FLOOR',
	FOCUS_ROOMS_WITH_FLOOR = 'FOCUS_ROOMS_WITH_FLOOR',
	ENABLE_ROOMS = 'ENABLE_ROOMS',
	SET_ROOMS_TO_DONE = 'SET_ROOMS_TO_DONE',
}

export enum SettingsActionsNames {
	SET_ROOM_SETTINGS = 'SET_ROOM_SETTINGS',
}

export enum UserActionsNames {
	SET_USER = 'SET_USER',
}

export enum DataActionsNames {
	SET_CUSTOMERS = 'SET_CUSTOMERS',
    SET_OPTIONS_CUSTOMERS = 'SET_OPTIONS_CUSTOMERS',
}

const ProjectActions: IProjectActions = {
	resetProject: createAction(ProjectActionsNames.RESET_PROJECT),
	restoreProject: createActionWithPayload<IProject>(ProjectActionsNames.RESTORE_PROJECT),
	setProjectName: createActionWithPayload<string>(ProjectActionsNames.SET_PROJECT_NAME),
	setProjectRefNumber: createActionWithPayload<string>(ProjectActionsNames.SET_PROJECT_REF_NUMBER),
	setProjectHasSeismicKit: createActionWithPayload<boolean>(ProjectActionsNames.SET_HAS_SEISMIC_KIT),
	setProjectInstallationByNorbec: createActionWithPayload<boolean>(ProjectActionsNames.SET_IS_INSTALLATION_BY_NORBEC),
	setProjectTransportType: createActionWithPayload<number>(ProjectActionsNames.SET_TRANSPORT_TYPE),
	restoreRequirements: createActionWithPayload<IProjectRequirements>(ProjectActionsNames.RESTORE_PROJECT_REQUIREMENTS),
	setProjectState: createActionWithPayload<State>(ProjectActionsNames.SET_PROJECT_STATE),
	setInstallationDate: createActionWithPayload<Date | null>(ProjectActionsNames.SET_INSTALLATION_DATE),
	setProjectInstallationLocation: createActionWithPayload<IInstallationLocationState>(ProjectActionsNames.SET_INSTALLATION_LOCATION),
	setProjectContact: createActionWithPayload<IContact>(ProjectActionsNames.SET_PROJECT_CONTACT),
	setProjectMarkupPc: createActionWithPayload<string | null>(ProjectActionsNames.SET_PROJECT_MARKUP),
	setProjectQuoter: createActionWithPayload<IQuotersPayload>(ProjectActionsNames.SET_PROJECT_QUOTER),
	setProjectLang: createActionWithPayload<string>(ProjectActionsNames.SET_PROJECT_LANG),
	setProjectEpicorQuoteNum: createActionWithPayload<number>(ProjectActionsNames.SET_PROJECT_EPICOR_QUOTE_NUM),
	setProjectTransportPriceOverride: createActionWithPayload<string | null>(ProjectActionsNames.SET_TRANSPORT_PRICE_OVERRIDE),
	setProjectTransportValidated: createActionWithPayload<boolean | null>(ProjectActionsNames.SET_TRANSPORT_PRICE_VALIDATED),
	setProjectInstallPriceOverride: createActionWithPayload<string | null>(ProjectActionsNames.SET_INSTALL_PRICE_OVERRIDE),
	setProjectInstallValidated: createActionWithPayload<boolean | null>(ProjectActionsNames.SET_INSTALL_PRICE_VALIDATED),
};

const LayoutActions: ILayoutActions = {
	setMobile: createActionWithPayload<boolean>(LayoutActionsNames.SET_MOBILE),
	setTablet: createActionWithPayload<boolean>(LayoutActionsNames.SET_TABLET),
	setDesktop: createActionWithPayload<boolean>(LayoutActionsNames.SET_DESKTOP),
	toggleSummary: createAction(LayoutActionsNames.TOGGLE_SUMMARY),
	toggleConfiguration: createAction(LayoutActionsNames.TOGGLE_CONFIGURATION),
	toggleConfigurationDrawer: createAction(LayoutActionsNames.TOGGLE_CONFIGURATION_DRAWER),
	toggleOptions: createAction(LayoutActionsNames.TOGGLE_OPTIONS),
	toggleOptionsDrawer: createAction(LayoutActionsNames.TOGGLE_OPTIONS_DRAWER),
	toggleInstallation: createAction(LayoutActionsNames.TOGGLE_INSTALLATION),
	toggleInstallationDrawer: createAction(LayoutActionsNames.TOGGLE_INSTALLATION_DRAWER),
	togglePrice: createAction(LayoutActionsNames.TOGGLE_PRICE),
	togglePriceDetails: createAction(LayoutActionsNames.TOGGLE_PRICE_DETAILS),
	toggleMenu: createAction(LayoutActionsNames.TOGGLE_MENU),
	setCurrentStep: createActionWithPayload<Steps>(LayoutActionsNames.SET_CURRENT_STEP),
	setLoading: createActionWithPayload<boolean>(LayoutActionsNames.SET_LOADING),
	setModalIsShown: createActionWithPayload<boolean>(LayoutActionsNames.SET_MODAL_IS_SHOWN),
	setPriceDetailsIsShown: createActionWithPayload<boolean>(LayoutActionsNames.SET_PRICE_DETAILS_IS_SHOWN),
	setErrorModalIsShown: createActionWithPayload<boolean>(LayoutActionsNames.SET_ERROR_MODAL_IS_SHOWN),
	setIsInstallationPlaceFormValid: createActionWithPayload<boolean>(LayoutActionsNames.SET_IS_INSTALLATION_PLACE_FORM_VALID),
	setIsQuoteFormValid: createActionWithPayload<boolean>(LayoutActionsNames.SET_IS_QUOTE_FORM_VALID),
	setRoutes: createActionWithPayload<IRoutes>(LayoutActionsNames.SET_ROUTES),
	setIsDrawingModalFullScreen: createActionWithPayload<boolean>(LayoutActionsNames.SET_IS_DRAWING_MODAL_FULL_SCREEN),
};

const GroupActions: IGroupActions = {
	setRooms: createActionWithPayload(GroupActionsNames.SET_ROOMS),
	setRoomNumber: createActionWithPayload<number>(GroupActionsNames.SET_ROOM_NUMBER),
	selectFocusedRooms: createAction(GroupActionsNames.SELECT_FOCUSED_ROOMS),
	focusRoom: createActionWithPayload(GroupActionsNames.FOCUS_ROOM),
	focusRooms: createAction(GroupActionsNames.FOCUS_ROOMS),
	selectRoom: createActionWithPayload(GroupActionsNames.SELECT_ROOM),
	selectRooms: createAction(GroupActionsNames.SELECT_ROOMS),
	setRoomType: createActionWithPayload<IRoomTypePayload>(GroupActionsNames.SET_ROOM_TYPE),
	disableRooms: createAction(GroupActionsNames.DISABLE_ROOMS),
	selectRoomsWithFloor: createAction(GroupActionsNames.SELECT_ROOMS_WITH_FLOOR),
	focusRoomsWithFloor: createAction(GroupActionsNames.FOCUS_ROOMS_WITH_FLOOR),
	resetRoomsState: createAction(GroupActionsNames.RESET_ROOMS_STATE),
	enableRooms: createAction(GroupActionsNames.ENABLE_ROOMS),
	setRoomsToDone: createAction(GroupActionsNames.SET_ROOMS_TO_DONE),
};

const SettingsActions: ISettingsActions = {
	setSettings: createActionWithPayload(SettingsActionsNames.SET_ROOM_SETTINGS),
};

const UserActions: IUserActions = {
	setUser: createActionWithPayload(UserActionsNames.SET_USER),
};

const DataActions: IDataActions = {
	setCustomers: createActionWithPayload(DataActionsNames.SET_CUSTOMERS),
    setOptionsCustomers: createActionWithPayload(DataActionsNames.SET_OPTIONS_CUSTOMERS),
};

export const Actions: IActions = {
	ProjectActions,
	LayoutActions,
	GroupActions,
	SettingsActions,
	UserActions,
    DataActions,
};
