import React from 'react';

import GS from '../../styles/global.styl';

interface ICalendarIconProps {
	readonly stroke?: string;
	readonly className?: string;
}

export default function CalendarIcon({ stroke, className }: ICalendarIconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={className}>
			<g className="svg-stroke-color" fill="none" fillRule="evenodd" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" transform="translate(1)">
				<rect width="21" height="20" x=".5" y="2.501" rx="1"/>
				<path d="M4.5.501v5M17.5.501v5M.5 7.501h21"/>
			</g>
		</svg>
	);
}

CalendarIcon.defaultProps = {
	stroke: GS.darkBlueGray,
};
