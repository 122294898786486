import {
	ICoolingSystem,
	ICoolingSystemOption,
	IDoor,
	IFloorMaterial,
	IFloorTexture,
	ILightOption,
	IMotionDetectorOption,
	IRoomRequirements,
	IRoomState,
	IRoomType,
	toCoolingSystemDTO,
	toCoolingSystemOptionsDTO,
	toDoorDTO,
	toFloorMaterialDTO,
	toFloorTextureDTO,
	toLightOptionDTO,
	toMotionDetectorOptionDTO,
	toRoomRequirementsDTO,
	toRoomTypeDTO,
} from '@models';

export enum RoomSettings {
	PIXEL_PER_INCH = 1.65,
	EMPTY_ROOM_BORDER_LENGTH = 2,
	ROOM_PADDING = 20,
	ZOOM_SCALE = 0.1,
	MIN_ZOOM = 0.5,
	MAX_ZOOM = 1.5,
	ROOM_WIDTH_DIMENSION_PADDING = 15,
	ROOM_DEPTH_DIMENSION_PADDING = 15,
	ROOM_DIMENSION_HEIGHT = 10,
	ROOM_DIMENSION_HEIGHT_BIGGER = 17.5,
	ROOM_DOOR_HEIGHT = 30,
}

export type TCode = 'A' | 'B' | 'C' | 'D';

// RoomTypeId [1, 2] = COOLER
// RoomTypeId [3, 4] = FREEZER
export enum ERoomTypeKind {
	COOLER,
	FREEZER,
}

export enum ERoomIndex {
	'A',
	'B',
	'C',
	'D',
}

export enum LightType {
	DEL_1808 = 1,
	DEL_1809 = 2,
}

export type TCoolingSystemCompleted<T> = {
	0: T;
	1: T;
	2: T;
	3: T;
} & T[];

export interface IRoom {
	id?: number;
	width: number;
	height: number;
	depth: number;
	name: string;
	hasSelectedNoCoolingSystem: boolean;
	hasDefaultWidth: boolean;
	hasDefaultDepth: boolean;
	hasDefaultHeight: boolean;
	hasDefaultCondenserDistance: boolean;
	coolingDistance: number;
	coolingType?: ICoolingSystem;
	dividerWallSize: number;
	roomType?: IRoomType;
	intellirefStacked: ICoolingSystemOption[];
	floorMaterial?: IFloorMaterial;
	doors: IDoor[];
	floorTexture?: IFloorTexture;
	motionDetector?: IMotionDetectorOption;
	lightType?: ILightOption;
	requirements?: IRoomRequirements;
}

export interface IRoomSettings {
	roomCntMin: number;
	roomCntMax: number;
	roomDefaultHeight: number;
	roomDefaultWidth: number;
	roomDefaultDepth: number;
	roomWallThickness: number;
	roomHeights: number[];
	roomWidthMin: number;
	roomWidthMax: number;
	roomDepthMin: number;
	roomDepthMax: number;
	roomWallIncr: number;
	condensingDefaultDistance: number;
	condensingDistanceMin: number;
	condensingDistanceMax: number;
	condensingDistanceIncr: number;
	condensingDefaultDistancePro3: number;
	doorCntMax: number;
	doorDefaultWidth: number;
	doorDefaultWall: number; // We don't use this
	doorDefaultOpeningDirection: number;
	doorPanelWidth: number;
	doorWidths: number[];
	serviceOfferInfoSheetUrl: string;
	transportTypesTechnicalSheetUrl: string;
	doorPosIncr: number;
	doorGapAtWallEnd: number;
	wallMinPanelWidth: number;
	seismicKitName: string;
	seismicKitDescription: string;
	seismicKitTechnicalSheetUrl: string;
}

export function toRoomDTO(roomState: IRoomState): IRoom {
	return {
		width: roomState.width,
		height: roomState.height,
		depth: roomState.depth,
		name: roomState.code,
		hasDefaultWidth: roomState.hasDefaultWidth,
		hasDefaultDepth: roomState.hasDefaultDepth,
		hasDefaultHeight: roomState.hasDefaultHeight,
		hasDefaultCondenserDistance: roomState.hasDefaultCondenserDistance,
		hasSelectedNoCoolingSystem: roomState.hasSelectedNoCoolingSystem,
		coolingDistance: roomState.condenserDistance,
		coolingType: roomState.coolingSystem ? toCoolingSystemDTO(roomState.coolingSystem) : undefined,
		roomType: roomState.type ? toRoomTypeDTO(roomState.type) : undefined,
		requirements: roomState.requirements ? toRoomRequirementsDTO(roomState.requirements) : undefined,
		dividerWallSize: roomState.borderLength,
		intellirefStacked: toCoolingSystemOptionsDTO(roomState.coolingSystemOptions),
		floorMaterial: roomState.floorMaterial ? toFloorMaterialDTO(roomState.floorMaterial) : undefined,
		floorTexture: roomState.floorTexture ? toFloorTextureDTO(roomState.floorTexture) : undefined,
		motionDetector: roomState.motionDetector ? toMotionDetectorOptionDTO(roomState.motionDetector) : undefined,
		lightType: roomState.lightOption ? toLightOptionDTO(roomState.lightOption) : undefined,
		doors: roomState.door ? [toDoorDTO(roomState.code, roomState.door)] : [],
	};
}
