import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import S from './styles/LargeInput.styl';

interface ILargeInputProps {
	maxLetterCount: number;
	onChange: ((value: string) => void);
	value: string;
}

enum InputState {
	Selected,
	Unselected,
}

export default function LargeInput(props: ILargeInputProps) {
	const textareaRef = useRef<HTMLTextAreaElement>(null);
	const [letterCount, setLetterCount] = useState(props.value.length || 0);
	const [inputContent, setInputContent] = useState(props.value);
	const [inputState, setInputState] = useState(InputState.Unselected);

	useEffect(() => {
		setInputContent(props.value);
	}, [props.value]);

	function onKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	}

	function onInputChange(event: any) {
		const input = event.target.value;
		if (input.length <= props.maxLetterCount) {
			props.onChange(input);
			setInputContent(input);
			setLetterCount(input.length);
		}
	}

	function onContainerClicked() {
		setInputState(InputState.Selected);
		textareaRef.current?.focus();
	}

	return (
		<div
			className={classnames(S.container, {
				[S.container__selected]: inputState === InputState.Selected,
				[S.container__unselected__unfilled]: inputState === InputState.Unselected && letterCount === 0,
				[S.container__unselected__filled]: inputState === InputState.Unselected && letterCount !== 0,

			} )}
			onBlur={(_) => setInputState(InputState.Unselected)}
			onClick={(_) => onContainerClicked()}
		>
			<TextareaAutosize
				inputRef={textareaRef}
				className={S.container__input}
				onKeyDown={(event) => onKeyDown(event)}
				onChange={(event) => onInputChange(event)}
				value={inputContent}
			/>
			<div
				className={classnames(S.container__counter, {
					[S.container__counter__selected]: inputState === InputState.Selected,
					[S.container__counter__unselected__unfilled]: inputState === InputState.Unselected && letterCount === 0,
					[S.container__counter__unselected__filled]: inputState === InputState.Unselected && letterCount !== 0,
				})}
			>
				<div
					className={classnames(S.container__counter__count, {
						[S.container__counter__count__selected]: inputState === InputState.Selected,
						[S.container__counter__count__unselected__unfilled]: inputState === InputState.Unselected && letterCount === 0,
						[S.container__counter__count__unselected__filled]: inputState === InputState.Unselected && letterCount !== 0,
					})}
				>
					{letterCount} &nbsp;
				</div>
				<div className={S.container__counter__limit}>/ {props.maxLetterCount}</div>
			</div>
		</div>
	);
}
