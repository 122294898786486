import ErrorSystemModal from '@components/layout/ErrorSystemModal';
import {
    PageHeight,
    PageWidth,
} from '@components/printable-page/PrintablePage';
import {
    fromCitiesDTO,
    fromCoolingSystemOptionsDTO,
    fromCoolingSystemsDTO,
    fromFloorMaterialsDTO,
    fromFloorTexturesDTO,
    fromI3OptionsDTO,
    fromInstallationOriginDTO,
    fromLightOptionsDTO,
    fromMotionDetectorOptionDTO,
    fromPricingItemsDTO,
    fromRoomTypesDTO,
    ICity,
    ICityViewModel,
    ICoolingSystem,
    ICoolingSystemOption,
    ICoolingSystemState,
    IDoorOptions,
    IDoorOptionsState,
    IFloorMaterial,
    IFloorMaterialState,
    IFloorTexture,
    IFloorTextureState,
    II3Option,
    II3OptionState,
    IInstallationOrigin,
    IInstallationOriginState,
    ILightOption,
    ILightOptionState,
    IMotionDetectorOption,
    IMotionDetectorOptionState,
    IPricingItem,
    IProject,
    IRoomSettings,
    IRoomType,
    IRoomTypeState,
    IStoreState,
    toProjectDTO,
} from '@models';
import React from 'react';
import ReactDOM from 'react-dom';
import { authenticatedHeaders } from './headers';

const POST_PROJECT = `${ENV.apiURL}/api/v1/app/project`;
const POST_PDF_AND_SEND_BY_EMAIL = `${ENV.apiURL}/api/v1/svc/sendPdf`;
const POST_PDF_FILE = `${ENV.apiURL}/api/v1/svc/generatePdfFile`;
const GET_PROJECT = `${ENV.apiURL}/api/v1/app/project/:id`;
const GET_ROOM_SETTINGS = `${ENV.apiURL}/api/v1/cfg/settings`;
const GET_COOLING_SYSTEMS = `${ENV.apiURL}/api/v1/svc/coolingtype/:id`;
const GET_ROOM_TYPES = `${ENV.apiURL}/api/v1/cfg/room/roomtype`;
const GET_COOLING_SYSTEM_OPTIONS = `${ENV.apiURL}/api/v1/cfg/group/intelliref`;
const GET_FLOOR_MATERIALS = `${ENV.apiURL}/api/v1/cfg/room/floormaterial`;
const GET_FLOOR_TEXTURES = `${ENV.apiURL}/api/v1/cfg/room/floortexture`;
const GET_DOOR_OPTIONS = `${ENV.apiURL}/api/v1/cfg/door/option`;
const GET_I3_OPTION = `${ENV.apiURL}/api/v1/cfg/door/i3`;
const GET_MOTION_DETECTOR = `${ENV.apiURL}/api/v1/cfg/room/motiondetector`;
const GET_LIGHT_OPTIONS = `${ENV.apiURL}/api/v1/cfg/room/lighttype`;
const GET_CITIES = `${ENV.apiURL}/api/v1/cfg/city`;
const GET_PRICE_BREAKDOWN = `${ENV.apiURL}/api/v1/svc/pricebreakdown/:id`;
const POST_GENERATE_PDF = `${ENV.apiURL}/api/v1/svc/generatePdf`;
const GET_INSTALLATION_ORIGIN = `${ENV.apiURL}/api/v1/svc/installOrigin/:state/:city`;
const GET_LIST_ALL_OPTIONS = `${ENV.apiURL}/api/v1/cfg/listalloptions`;
const POST_TO_COMPLETE = `${ENV.apiURL}/api/v1/app/project/toComplete/:id`;
const GET_COOLING_SYSTEMS_AVAILABILITY = `${ENV.apiURL}/api/v1/svc/coolingSystems/:id`;

interface ICurrency {
    priceVersion: string;
    currencyCode?: string;
}

function getItemLocalStorage(item: string) {
    const currency = window.localStorage.getItem(item);
    const retrievedObject: ICurrency = currency !== null ? JSON.parse(currency) : '';
    return retrievedObject;
}

export function postProject(body: IStoreState): Promise<IProject | any> {
    return fetch(POST_PROJECT, {
        method: 'POST',
        headers: authenticatedHeaders(),
        body: JSON.stringify(toProjectDTO(body, 'post')),
    })
        .then(handleErrors)
        .then((postProjectResponse: IProject) => {
            return postProjectResponse;
        })
        .catch(() => {
            showErrorPopup(body.project.ref);
        });
}

export function updateProject(body: IStoreState): Promise<IProject | any> {
    return fetch(POST_PROJECT, {
        method: 'PUT',
        headers: authenticatedHeaders(),
        body: JSON.stringify(toProjectDTO(body, 'put')),
    })
        .then(handleErrors)
        .then((project: IProject) => {
            return project;
        })
        .catch(() => {
            showErrorPopup(`MS-${body.project.id}`);
        });
}

export function getProject(id: string): Promise<IProject | any> {
    const endpoint = GET_PROJECT.replace(':id', id);
    return fetch(endpoint, {
        method: 'GET',
        headers: authenticatedHeaders(),
    })
        .then(handleErrors)
        .then((project: IProject) => {
            return project;
        })
        .catch(() => {
            showErrorPopup(`MS-${id}`);
        });
}

export function getRoomSettings(): Promise<IRoomSettings | any> {
    return fetch(GET_ROOM_SETTINGS, {
        method: 'GET',
        headers: authenticatedHeaders(),
    })
        .then(handleErrors)
        .then((settings: IRoomSettings) => {
            return settings;
        })
        .catch(() => {
            showErrorPopup('');
        });
}

export function getCoolingSystems(
    id?: string,
): Promise<ICoolingSystemState[] | any> {
    return fetch(GET_COOLING_SYSTEMS.replace(':id', id || ''), {
        method: 'GET',
        headers: authenticatedHeaders(),
    })
        .then(handleErrors)
        .then((options: ICoolingSystem[]) => {
            return fromCoolingSystemsDTO(options);
        })
        .catch(() => {
            showErrorPopup(`MS-${id}` || '');
        });
}

export function getFloorMaterials(): Promise<IFloorMaterialState[] | any> {
    return fetch(GET_FLOOR_MATERIALS, {
        method: 'GET',
        headers: authenticatedHeaders(),
    })
        .then(handleErrors)
        .then((options: IFloorMaterial[]) => {
            return fromFloorMaterialsDTO(options);
        })
        .catch(() => {
            showErrorPopup('');
        });
}

export function getRoomTypes(): Promise<IRoomTypeState[] | any> {
    return fetch(GET_ROOM_TYPES, {
        method: 'GET',
        headers: authenticatedHeaders(),
    })
        .then(handleErrors)
        .then((roomTypes: IRoomType[]) => {
            return fromRoomTypesDTO(roomTypes);
        })
        .catch(() => {
            showErrorPopup('');
        });
}

export function getCoolingSystemOptions(): Promise<
    ICoolingSystemOption[] | any
> {
    return fetch(`${GET_COOLING_SYSTEM_OPTIONS}?priceVersion=${getItemLocalStorage('currency').priceVersion}&currency=${getItemLocalStorage('currency').currencyCode}`, {
        method: 'GET',
        headers: authenticatedHeaders(),
    })
        .then(handleErrors)
        .then((coolingSystemOptions: ICoolingSystemOption[]) => {
            return fromCoolingSystemOptionsDTO(coolingSystemOptions);
        })
        .catch(() => {
            showErrorPopup('');
        });
}

export function getCoolingSystemAvailability(
    id: string,
): Promise<IProject | any> {
    const endpoint = GET_COOLING_SYSTEMS_AVAILABILITY.replace(':id', id);
    return fetch(endpoint, {
        method: 'GET',
        headers: authenticatedHeaders(),
    })
        .then(handleErrors)
        .then((response) => {
            return response;
        })
        .catch(() => {
            showErrorPopup('');
        });
}

export function getFloorTextures(): Promise<IFloorTextureState[] | any> {
    return fetch(GET_FLOOR_TEXTURES, {
        method: 'GET',
        headers: authenticatedHeaders(),
    })
        .then(handleErrors)
        .then((options: IFloorTexture[]) => {
            return fromFloorTexturesDTO(options);
        })
        .catch(() => {
            showErrorPopup('');
        });
}

export function getDoorOptions(): Promise<IDoorOptionsState | any> {
    return fetch(`${GET_DOOR_OPTIONS}?priceVersion=${getItemLocalStorage('currency').priceVersion}&currency=${getItemLocalStorage('currency').currencyCode}`, {
        method: 'GET',
        headers: authenticatedHeaders(),
    })
        .then(handleErrors)
        .then((options: IDoorOptions) => {
            return {
                pedal: options.pedals && options.pedals[0],
                curtain: options.curtains && options.curtains[0],
                window: options.windows && options.windows[0],
                kickplate: options.kickplates && options.kickplates[0],
            };
        })
        .catch(() => {
            showErrorPopup('');
        });
}

export function getI3Option(): Promise<II3OptionState | any> {
    return fetch(GET_I3_OPTION, {
        method: 'GET',
        headers: authenticatedHeaders(),
    })
        .then(handleErrors)
        .then((options: II3Option[]) => {
            return fromI3OptionsDTO(options);
        })
        .catch(() => {
            showErrorPopup('');
        });
}

export function getMotionDetector(): Promise<IMotionDetectorOptionState | any> {
    return fetch(`${GET_MOTION_DETECTOR}?priceVersion=${getItemLocalStorage('currency').priceVersion}&currency=${getItemLocalStorage('currency').currencyCode}`, {
        method: 'GET',
        headers: authenticatedHeaders(),
    })
        .then(handleErrors)
        .then((options: IMotionDetectorOption[]) => {
            return fromMotionDetectorOptionDTO(options);
        })
        .catch(() => {
            showErrorPopup('');
        });
}

export function getLightOptions(): Promise<ILightOptionState[] | any> {
    return fetch(`${GET_LIGHT_OPTIONS}?priceVersion=${getItemLocalStorage('currency').priceVersion}&currency=${getItemLocalStorage('currency').currencyCode}`, {
        method: 'GET',
        headers: authenticatedHeaders(),
    })
        .then(handleErrors)
        .then((options: ILightOption[]) => {
            return fromLightOptionsDTO(options);
        })
        .catch(() => {
            showErrorPopup('');
        });
}

/**
 * Get cities from Canada by province
 * @param cityState 2 letters province string (i.e.: QC, ON, etc..)
 * @param search query string
 * @param deliveryOnly returns only cities where delivery is available
 * @param installOnly returns only cities where installation is available
 */
export function getCitiesForProvince(
    cityState: string = '',
    search: string = '',
    deliveryOnly: boolean = true,
    installOnly: boolean = true,
): Promise<ICityViewModel[] | any> {
    return fetch(
        `${GET_CITIES}?state=${cityState}&search=${search}&delivery=${deliveryOnly}&install=${installOnly}`,
        {
            method: 'GET',
            headers: authenticatedHeaders(),
        },
    )
        .then(handleErrors)
        .then((cities: ICity[]) => {
            if (cities.length > 0) {
                return fromCitiesDTO(cities);
            }

            return [];
        })
        .catch(() => {
            showErrorPopup('');
        });
}

export function getPriceBreakdown(id: string, langId?: string, markupPc?: string): Promise<IPricingItem[] | any> {
    !!langId ? langId = langId : langId = 'fr';
    !!markupPc && markupPc === '0' ? markupPc = '0' : markupPc = '1';
    const url = `${GET_PRICE_BREAKDOWN}/${langId}/${markupPc}`;
    return fetch(url.replace(':id', id || ''), {
        method: 'GET',
        headers: authenticatedHeaders(),
    })
        .then(handleErrors)
        .then((priceBreakdown: IPricingItem[]) => {
            return fromPricingItemsDTO(priceBreakdown);
        })
        .catch(() => {
            showErrorPopup('');
        });
}

export function generatePdf(
    projectId: number,
    content: string,
    filename: string,
    pageNumber: number,
    backEnd: boolean,
): Promise<any> {
    return fetch(POST_GENERATE_PDF, {
        method: 'POST',
        headers: authenticatedHeaders(),
        body: JSON.stringify({
            projectId,
            htmlViewerWidth: PageWidth,
            htmlViewerHeight: PageHeight * pageNumber,
            pdfPageSize: 'letter',
            html: content,
        }),
    })
        .then((response: any) => response.blob())
        .then((blob) => {
            if (!backEnd) {
                const file = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = file;
                a.setAttribute('download', filename);
                document.body.appendChild(a);
                a.style.display = 'none';
                a.click();
                window.URL.revokeObjectURL(file);
            }
        })
        .catch(() => {
            showErrorPopup(projectId.toString());
        });
}

export function generatePdfFile(
    projectId: number,
    content: string,
    filename: string,
    pageNumber: number,
    backEnd: boolean,
): Promise<any> {
    return fetch(POST_PDF_FILE, {
        method: 'POST',
        headers: authenticatedHeaders(),
        body: JSON.stringify({
            projectId,
            htmlViewerWidth: PageWidth,
            htmlViewerHeight: PageHeight * pageNumber,
            pdfPageSize: 'letter',
            html: content,
        }),
    })
        .then((response: any) => response.json())
        .then((json) => {
            if (!backEnd) {
                const hostedUrl = json.pdfFileUrl;
                const a = document.createElement('a');
                a.href = hostedUrl;
                a.id = 'downloadButton';
                document.body.appendChild(a);
                a.style.display = 'none';
                a.click();
                const element = document.getElementById('downloadButton');
                if (element) {
                    element.remove();
                }
            }
        })
        .catch(() => {
            showErrorPopup(projectId.toString());
        });
}

export function generatePdfAndSendEmail(
    projectId: number,
    content: string,
    pageNumber: number,
    tos: string[],
): Promise<any> {
    return fetch(POST_PDF_AND_SEND_BY_EMAIL, {
        method: 'POST',
        headers: authenticatedHeaders(),
        body: JSON.stringify({
            projectId,
            htmlViewerWidth: PageWidth,
            htmlViewerHeight: PageHeight * pageNumber,
            pdfPageSize: 'letter',
            html: content,
            tos,
        }),
    })
        .then(handleErrors)
        .then((response) => {
            return response;
        })
        .catch(() => {
            showErrorPopup(projectId.toString());
        });
}

export function getInstallationOrigin(
    state: string | undefined,
    city: string | undefined,
): Promise<IInstallationOriginState | any> {
    const url = GET_INSTALLATION_ORIGIN.replace(':state', state || '').replace(
        ':city',
        city || '',
    );
    return fetch(encodeURI(url), {
        method: 'GET',
        headers: authenticatedHeaders(),
    })
        .then(handleErrors)
        .then((installationOrigin: IInstallationOrigin) => {
            return fromInstallationOriginDTO(installationOrigin);
        })
        .catch(() => {
            showErrorPopup('');
        });
}
export function getListAllOptions(langId?: string): Promise<IAllOptions> {

    const url = !!langId
        ? `${GET_LIST_ALL_OPTIONS}/${langId}?priceVersion=${getItemLocalStorage('currency').priceVersion}`
        : `GET_LIST_ALL_OPTIONS?priceVersion=${getItemLocalStorage('currency').priceVersion}`;
    return fetch(url, {
        method: 'GET',
        headers: authenticatedHeaders(),
    })
        .then(handleErrors)
        .then((response) => {
            return response;
        })
        .catch(() => {
            showErrorPopup('');
        });
}

export function postToComplete(id: string): Promise<any> {
    const endpoint = POST_TO_COMPLETE.replace(':id', id);
    return fetch(endpoint, {
        method: 'POST',
        headers: authenticatedHeaders(),
    })
        .then(handleErrors)
        .then((response) => {
            return response;
        })
        .catch(() => {
            showErrorPopup(`MS-${id}`);
        });
}

function handleErrors(response: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
        if (response.status < 400) {
            resolve(response.json());
        } else {
            reject(response.statusText);
        }
    });
}

function showErrorPopup(refNumber: string) {
    ReactDOM.render(
        <ErrorSystemModal refNumber={refNumber} />,
        document.getElementById('root'),
    );
}

import {
    deleteLogout,
    getRequestResetPassword,
    postChangePassword,
    postLogin,
    postLoginWithToken,
    postResetPassword,
    postValidateToken,
} from './auth';

import { tabTitleStepNumber } from '@components/layout/styles/TabDrawer.styl';
import { IAllOptions } from 'app/models/allOptions';
import { getPermissions, putNewsletter } from './user';

export {
    deleteLogout,
    getRequestResetPassword,
    postChangePassword,
    postLogin,
    postValidateToken,
    postLoginWithToken,
    postResetPassword,
    getPermissions,
    putNewsletter,
};
