import { getProject } from '@api/project';
import ActionsMenu from '@components/buttons/ActionsMenu';
import BackButtonMvp from '@components/buttons/BackButtonMvp';
import TagLabel from '@components/labels/TagLabel';
import { Loading } from '@components/layout';
import { LayoutFullWidth } from '@components/layout/Layout';
import { useLocale } from '@i18n';
import { IProject } from '@models';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ERoutes, getPathByRouteName } from '..';
import MuiTabs from './MuiTabs';
import ProjectContacts from './ProjectContacts';
import S from './styles/Project.styl';

interface IProjectDetailsParams {
	id: string;
}

export default function Project() {

	const T = useLocale();
	const history = useHistory();
	const [project, setProject] = useState<IProject | undefined>();
	const { id } = useParams<IProjectDetailsParams>();

	const [loading, setLoading] = useState<boolean>(false);

	const getProjectById = (id: string) => {
		getProject(+id)
			.then((response) => {
				setProject(response);
				if (response.status === 9) {
					history.replace(getPathByRouteName(ERoutes.Home, T.locale));
				}
			})
			.catch(() => {
				history.replace(getPathByRouteName(ERoutes.Home, T.locale));
			});
	};

	useEffect(() => {
		if (id) {
			getProjectById(id);
		}
		else {
			history.replace(getPathByRouteName(ERoutes.Home, T.locale));
		}
	}, []);

	if (!project || loading) {
		return <Loading />;
	}

	return (
		<LayoutFullWidth>
			<div className={S.projectContainer}>
				<div className={S.projectHeader}>
					<BackButtonMvp href={getPathByRouteName(ERoutes.Home, T.locale)} />
					<ActionsMenu project={project} firstActionBtn fetchProject={() => getProjectById(id)} refreshLoading={(e) => setLoading(e)} />
				</div>

				<div className={S.projectTitle}>
					<TagLabel state={project.status} />
					<h1>{project.name}</h1>
				</div>

				<ProjectContacts project={project} setProject={setProject} />

				<MuiTabs project={project} setProject={setProject} />
			</div>
		</LayoutFullWidth>
	);
}
