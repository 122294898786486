import { getCitiesForProvince } from '@api/index';
import { LocationRadio } from '@components/inputs';
import { TagLabel } from '@components/labels';
import { useLocale } from '@i18n';
import { IBaseStepProps, ICityViewModel, IInstallationLocationState, State, Steps } from '@models';
import { useStore } from '@store';
import { goToSummary } from '@utils/navigation';
import React, { useState } from 'react';

import { AnimationSettings } from '../../../utils/animation';
import S from './styles/InstallationLocationStep.styl';

export const CountryCodes = {
	CAN: 'CAN',
	USA: 'USA',
};

export const canadaProvinces = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK', 'NT', 'NU', 'YT'];
export const UsaStates = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

export default function InstallationLocationStep(props: IBaseStepProps<null>) {
	const T = useLocale();
	const { state, actions, dispatch } = useStore();
	const { installationLocation } = state.project;

	const [canadaState, setCanadaState] = useState<State>(installationLocation?.country === CountryCodes.CAN ? State.Selected : State.Enabled);
	const [usaState, setUsaState] = useState<State>(installationLocation?.country === CountryCodes.USA ? State.Selected : State.Enabled);
	const [timeout, setTimeout] = useState<number>(0);
	const [cities, setCities] = useState<ICityViewModel[]>([]);
	const [isLoading, setIsLoadding] = useState<boolean>(false);

	function handleLocationChange(
		location: IInstallationLocationState | null,
		customHandler: Function = () => void (0),
		callback: Function = () => void (0),
	) {
		goToSummary(Steps.INSTALLATION_LOCATION.toString());
		if (location) {
			dispatch(actions.ProjectActions.setProjectInstallationLocation(location));
		}
		dispatch(actions.ProjectActions.setProjectState(State.Focused));
		customHandler();

		if (timeout) {
			window.clearTimeout(timeout);
		}

		setTimeout(window.setTimeout(() => {
			dispatch(actions.ProjectActions.setProjectState(State.Selected));
			callback();
		}, AnimationSettings.FADE_DELAY));
	}

	function selectCityState(cityState: string) {
		handleLocationChange({
			...installationLocation,
			cityState,
			city: undefined,
		});
	}

	function selectCity(city: ICityViewModel) {
		handleLocationChange({
			...installationLocation,
			city,
		});
	}

	function selectCanada() {
		handleLocationChange(state.project.installationLocation?.country === CountryCodes.CAN ? null : {
			city: undefined,
			cityState: undefined,
			country: CountryCodes.CAN,
		},
			() => {
				setCanadaState(State.Focused);
				setUsaState(State.Enabled);
			},
			() => {
				setCanadaState(State.Selected);
			});
	}

	function selectUsa() {
		handleLocationChange(state.project.installationLocation?.country === CountryCodes.USA ? null : {
			city: undefined,
			cityState: undefined,
			country: CountryCodes.USA,
		},
			() => {
				setCanadaState(State.Enabled);
				setUsaState(State.Focused);
			},
			() => {
				setUsaState(State.Selected);
			});
	}

	function handleCitiesFetch(city: string) {
		setIsLoadding(true);
		getCitiesForProvince(state.project.installationLocation?.cityState, city, false, true).then((results) => {
			setCities(results);
			setIsLoadding(false);
		});
	}

	const isDisabled = state.layout.latestCompletedStep >= Steps.ROOM_TYPES;
	return (
		<div className={S.installationLocationStep} style={props.style}>
			<TagLabel title={T.ConfiguratorProjectGroupTitle} />
			<h1 className={S.installationLocationStepName}>
				{T.InstallationLocationTitle}
			</h1>

			<div>{T.InstallationLocationDescription}</div>

			<div className={S.installationLocationStepLocations}>
				<LocationRadio
					classname={S.installationLocationStepLocationsRadioLeft}
					disabled={isDisabled}
					country={CountryCodes.CAN}
					countryLabel={T.CAN}
					state={canadaState}
					cities={cities}
					cityStates={(canadaState === State.Selected || canadaState === State.Focused) ? canadaProvinces : undefined}
					isLoading={isLoading}
					onCityFetch={handleCitiesFetch}
					onCityClear={() => setCities([])}
					onCountrySelected={selectCanada}
					onCitySelected={selectCity}
					onCityStateSelected={selectCityState}
				/>

				<LocationRadio
					disabled={isDisabled}
					country={CountryCodes.USA}
					countryLabel={T.USA}
					state={usaState}
					cities={cities}
					cityStates={(usaState === State.Selected || usaState === State.Focused) ? UsaStates : undefined}
					onCountrySelected={selectUsa}
					onCityFetch={handleCitiesFetch}
					onCityClear={() => setCities([])}
					onCitySelected={selectCity}
					onCityStateSelected={selectCityState}
				/>
			</div>
		</div>
	);
}
