import React from 'react';

interface IInfoNorbecIcon {
	stroke: string;
	fill: string;
}

export default function InfoNorbecIcon(props: IInfoNorbecIcon) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none">
				<path fill={props.fill} d="M21 16.693V14h-2.127v3.884L18.01 14H14.5v7h2.127v-4.044L17.549 21H21v-2.673c-.006-.827-.006-.44 0-1.634z"/>
				<circle cx="17.5" cy="17.5" r="6" stroke={props.stroke}/>
				<circle cx="5.5" cy="4" r="3.5" stroke={props.stroke} />
				<path stroke="#9FBBC4" d="M10.013 11.848c-1.006-2.11-3.339-3.24-5.618-2.724C2.116 9.64.5 11.666.5 14.004v3.5H3l.5 6h4l.5-6h1.482"/>
			</g>
		</svg>
	);
}
