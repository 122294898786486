import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

import S from '@components/buttons/styles/NavLinkButton.styl';

interface INavLinkButtonProps {
	onClick?: () => void;
	icon: string | ReactElement;
	title: string;
	urlTo: string;
	className?: string;
}

export default function NavLinkButton(props: INavLinkButtonProps) {
	return (
		<div className={S.navLinkContainer}>
			<NavLink
				exact
				to={props.urlTo}
				onClick={props.onClick}
				className={props.className}
			>
				<div className={S.navLinkContainerContent} >
					<div className={S.navLinkContainerContentIcon}>
						{props.icon}
					</div>
					<div className={S.navLinkContainerContentTitle}>
						{props.title}
					</div>
				</div>
			</NavLink>
		</div>
	);
}
