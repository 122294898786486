import { IContact } from '@models';
import axiosInstance from './axiosInstance';

const GET_CONTACTS = `/erp/contacts`;

const POST_CONTACT = `/erp/custcnt`;
const PUT_CONTACT = `/erp/custcnt`;

export async function getContacts(epicorCustId: string): Promise<{ data: IContact[] }> {
	return await axiosInstance.get(`${GET_CONTACTS}/${epicorCustId}`);
}

export async function PostContact(body: IContact): Promise<IContact> {
	const response = await axiosInstance.post(POST_CONTACT, body );
	return response.data;
}

export async function PutContact(body: IContact): Promise<IContact> {
	const response = await axiosInstance.put(PUT_CONTACT, body );
	return response.data;
}
