import Button from '@components/buttons/Button';
import {
	CartIcon,
	CheckInCircleIcon,
	CheckMarkIcon,
	ContactCheckedIcon,
	InfoIcon,
	MailIcon,
	PlusIcon,
	PowerIcon,
	TimerIcon,
	TrashIcon,
	WarningIcon,
} from '@components/svg';
import React from 'react';
import ReactDOM from 'react-dom';
import { ModalIconType } from '../../hooks/contexts/UseContext';

import S from './styles/Modal.styl';

export interface IModal {
	iconType: ModalIconType;
	title: string;
	exitLabel: string;
	content: JSX.Element | string;
	color?: string;
	yesLabel?: string;
	yesAction?: () => boolean;
	yesActionState?: boolean;
}

interface IModalProps {
	modal: IModal;
	removeModal: () => void;
}

export default function Modal(props: IModalProps) {
	const { modal, removeModal } = props;
	const { content, iconType, title, exitLabel, yesLabel, yesAction, yesActionState } = modal;
	const color = modal.color ? modal.color : S.blueNorbec;
	let icon;

	const onDialogClicked = (event: React.MouseEvent<HTMLDialogElement, MouseEvent>) => {
		if ((event.target as Element).className.includes('modal-enter-done')) {
			removeModal();
		}
	};

	switch (iconType) {
		case ModalIconType.CheckMark:
			icon = <CheckMarkIcon color={color} />;
			break;
		case ModalIconType.Mail:
			icon = <MailIcon color={color} />;
			break;
		case ModalIconType.Info:
			icon = <InfoIcon color={color} />;
			break;
		case ModalIconType.Warning:
			icon = <WarningIcon color={color} />;
			break;
		case ModalIconType.Timer:
			icon = <TimerIcon color={color} />;
			break;
		case ModalIconType.Power:
			icon = <PowerIcon color={color} />;
			break;
		case ModalIconType.Plus:
			icon = <PlusIcon color={color} />;
			break;
		case ModalIconType.Contact:
			icon = <ContactCheckedIcon color={color} />;
			break;
		case ModalIconType.Trash:
			icon = <TrashIcon color={color} />;
			break;
		case ModalIconType.Cart:
			icon = <CartIcon color={color} />;
			break;
		case ModalIconType.CheckInCircle:
			icon = <CheckInCircleIcon color={color} />;
			break;
	}

	return ReactDOM.createPortal(
		(
			<dialog className={S.modal} onClick={onDialogClicked}>
				<div
					className={S.modalContent}
				>
					<div className={S.iconContainer}>
						{icon}
					</div>
					<h1 className={S.modalTitle} style={modal.color ? { color } : {}} >
						{title}
					</h1>
					{content}
					<div className={S.buttonContainer}>
						<Button secondary={!!yesLabel && !!yesAction} center={!yesLabel || !yesAction} onClick={removeModal}>
							{exitLabel}
						</Button>
						{yesLabel && yesAction &&
							(
								<Button onClick={() => { if (yesAction()) { removeModal(); } }} disabled={!yesActionState}>
									{yesLabel}
								</Button>
							)
						}
					</div>
				</div>
			</dialog>
		),
		document.getElementById('modal')!,
	);
}
