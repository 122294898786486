import { getContacts } from '@api/contacts';
import { getCustomersGroups, getCustomersTerritoriesCAN, getCustomersTerritoriesUSA, PutCustomer } from '@api/customers';
import { authenticatedHeaders } from '@api/headers';
import { getCitiesForProvince } from '@api/index';
import { getSalesReps } from '@api/salesreps';
import { IDropdownOption } from '@components/inputs';
import { ErrorPosition } from '@components/inputs/Input';
import { Loading } from '@components/layout';
import { LayoutFullWidth } from '@components/layout/Layout';
import { useLocale } from '@i18n';
import { CircularProgress, Container, Paper } from '@material-ui/core';
import { IContact, ICustomer, IPricingItem, IProject } from '@models';
import { REGEX_EMAIL, REGEX_PHONE } from '@utils/constants';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ERoutes, getPathByRouteName } from '..';
import Button from '../../components/buttons/Button';
import { EPermissions } from '../../hooks/contexts/AuthContext';
import { ToastType, useAuthContext, useToastContext } from '../../hooks/contexts/UseContext';
import MuiTabPanel from '../project/MuiTabPanel';
import { ERowType, IGroup, IRow } from '../project/MuiTabs';
import S from '../project/styles/Project.styl';
import TabGroup from '../project/TabGroup';
import { canadaProvinces, UsaStates, UsaTerritories } from './CreateCustomer';
import Customers from './Customers';
import MUIContactsTable from './MUIContactsTable';
import { customersHeadContainer } from './styles/Customers.styl';

export default function EditCustomer() {

    const T = useLocale();
    const history = useHistory();
    const addToast = useToastContext();

    const { user, userHasPermissions } = useAuthContext();
    const [isAuthorized, setIsAuthorized] = useState(false);

    const POST_CONTACT = `${ENV.apiURL}/api/v1/erp/custcnt`;

    const [customer, setCustomer] = useState<any>();

    const [paysClient, setPaysClient] = useState<any>(null);
    const [optionsVendeurs, setOptionsVendeurs] = useState<IDropdownOption[]>([]);
    const [optionsGrpIndustrie, setOptionsGrpIndustrie] = useState<IDropdownOption[]>([]);
    const [territoiresCAN, setTerritoiresCAN] = useState<IDropdownOption[]>([]);
    const [territoiresUSA, setTerritoiresUSA] = useState<IDropdownOption[]>([]);

    const [tableContacts, setTableContacts] = useState<any[]>([]);

    const [processing, setProcessing] = useState<boolean>(false);

    const [loadingSalesResp, setLoadingSalesResp] = useState<boolean>(false);
    const [loadingCustomersGroups, setLoadingCustomersGroups] = useState<boolean>(false);
    const [loadingTerritoriesCAN, setLoadingTerritoriesCAN] = useState<boolean>(false);
    const [loadingTerritoriesUSA, setLoadingTerritoriesUSA] = useState<boolean>(false);
    const [loadingContacts, setLoadingContacts] = useState<boolean>(false);

    function getStateOptions(state: string): IDropdownOption[] {
        const provinces: any[] = [{ label: '', value: '' }];
        if (state === 'CAN') {
            canadaProvinces?.map((cityState) => {
                provinces.push({
                    label: T[cityState],
                    value: cityState,
                });
            });
        } else if (state === 'USA') {
            UsaStates?.map((cityState) => {
                provinces.push({
                    label: T[cityState],
                    value: cityState,
                });
            });
        }
        return provinces;
    }

    const [optionsVilles, setOptionsVilles] = useState<IDropdownOption[]>([]);

    function getCities(provinceClient: any) {
        return getCitiesForProvince(provinceClient, '', false, true).then((results) => {
            setOptionsVilles(formatCitiesForProvince(results));
        });
    }

    function formatCitiesForProvince(results: any[]): IDropdownOption[] {
        const villes: any[] = [{ label: '', value: '' }];
        results?.map((ville) => {
            villes.push({
                label: ville,
                value: ville,
            });
        });
        return villes || [];
    }

    const optionsPays = [
        { label: T.CreateCustomerOptionCAN, value: 'CAN', provinces: getStateOptions('CAN'), territoires: territoiresCAN },
        { label: T.CreateCustomerOptionUSA, value: 'USA', provinces: getStateOptions('USA'), territoires: territoiresUSA },
    ];

    const optionsPotentiels = [
        { label: T.CreateCustomerOptionPotentielA, value: 'A' },
        { label: T.CreateCustomerOptionPotentielB, value: 'B' },
    ];

    const optionsTerritoires = [
        {
            label: 'Canada',
            options: territoiresCAN,
        },
        {
            label: 'USA',
            options: territoiresUSA,
        },
    ];

    const renderPhone = (value: string) => {
        let phone = value ? value.replace('-', '') : '';
        while (phone.indexOf('-') !== -1) {
            phone = phone.replace('-', '');
        }
        return phone
            ? `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`
            : '';
    };

    const renderSalesRep = (value: string) => {
        if (value != null) {
            const splitted = value.split(';');
            let salesReps: string = '';
            splitted.forEach((element) => {
                const item: IDropdownOption | undefined = optionsVendeurs.find((vendeur: IDropdownOption) => vendeur.value === element);
                if (item) { salesReps = salesReps.concat(item.label as string).concat(', '); }
            });
            salesReps = salesReps.substring(0, salesReps.length - 2);
            return (salesReps);
        }
    };
    const fetchContacts = (custNum: string) => {
        setLoadingContacts(true);
        getContacts(custNum)
            .then((response) => {
                if (response.data.length > 0) { response.data.map((contact) => { contact.active = true; }); }
                setTableContacts(response.data);
                setLoadingContacts(false);
            })
            .catch((error) => {
                setLoadingContacts(false);
                addToast({
                    text: T.GeneralFormSubmitMessageError,
                    type: ToastType.error,
                });
            });
    };

    const fetchSalesReps = () => {
        getSalesReps()
            .then((response) => {
                if (user.identityRole !== 'modlr_SuperAdmin' && user.identityRole !== 'modlr_SalesAdmin') {
                    const temp = [];
                    if (user.epicorSalesrepCode !== '') {
                        const salesReps = user.epicorSalesrepCode.split(';');
                        response.data.forEach((item) => salesReps.includes(item.id) ? temp.push(item) : '');
                    }
                    response.data = temp;
                }
                const optionList: IDropdownOption[] = [];
                response.data.map((item) =>
                    optionList.push({
                        label: `${item.id} - ${item.name}`,
                        value: item.id,
                    }),
                );
                setOptionsVendeurs(optionList);
            })
            .catch((error) => {
                addToast({
                    text: T.GeneralFormSubmitMessageError,
                    type: ToastType.error,
                });
            });
    };

    const fetchCustomersGroups = () => {
        getCustomersGroups()
            .then((response) => {
                const optionList: IDropdownOption[] = [];
                response.data.map((item) =>
                    optionList.push({
                        label: item.descBil,
                        value: item.code,
                    }),
                );
                setOptionsGrpIndustrie(optionList);
            })
            .catch((error) => {
                addToast({
                    text: T.GeneralFormSubmitMessageError,
                    type: ToastType.error,
                });
            });
    };

    const fetchCustomersTerritoriesCAN = () => {
        const optionList: IDropdownOption[] = [];
        canadaProvinces?.map((item) => {
            optionList.push({
                label: T[item],
                value: item,
            });
        });
        optionList.push({
            label: T[`NDEF`],
            value: 'ND',
        });
        setTerritoiresCAN(optionList);
        // getCustomersTerritoriesCAN()
        //     .then((response) => {
        //         const optionList: IDropdownOption[] = [{ label: '', value: '' }];
        //         response.data.map((item) =>
        //             optionList.push({
        //                 label: item.territoryName,
        //                 value: item.territoryId,
        //             }),
        //         );
        //         setTerritoiresCAN(optionList);
        //     })
        //     .catch((error) => {
        //         addToast({
        //             text: T.GeneralFormSubmitMessageError,
        //             type: ToastType.error,
        //         });
        //     });
    };

    const fetchCustomersTerritoriesUSA = () => {
        const optionList: IDropdownOption[] = [];
        UsaTerritories?.map((item) => {
            optionList.push({
                label: T[item],
                value: item,
            });
        });
        setTerritoiresUSA(optionList);
        // getCustomersTerritoriesUSA()
        //     .then((response) => {
        //         const optionList: IDropdownOption[] = [{ label: '', value: '' }];
        //         response.data.map((item) =>
        //             optionList.push({
        //                 label: item.territoryName,
        //                 value: item.territoryId,
        //             }),
        //         );
        //         setTerritoiresUSA(optionList);
        //     })
        //     .catch((error) => {
        //         addToast({
        //             text: T.GeneralFormSubmitMessageError,
        //             type: ToastType.error,
        //         });
        //     });
    };

    const groups = [
        {
            label: T.CreateCustomerLabelGeneralInfos,
            isEditable: true,
            rows: [
                {
                    label: T.CreateCustomerInputName,
                    value: customer?.name,
                    type: ERowType.string,
                    inputName: 'name',
                },
                {
                    label: T.CreateCustomerInputEmail,
                    value: customer?.email,
                    type: ERowType.string,
                    inputName: 'email',
                    inputProps: {
                        errorMessage:
                            T.QuoteClientSectionEmailInputError,
                        inputType: 'email',
                        errorMessagePosition: ErrorPosition.BOTTOM,
                        validationRegex: REGEX_EMAIL,
                        isOptional: false,
                    },
                },
                {
                    label: T.CreateCustomerInputPhoneNum,
                    value: customer?.phoneNum,
                    type: ERowType.string,
                    inputName: 'phoneNum',
                    render: renderPhone,
                    inputProps: {
                        isOptional: false,
                        errorMessagePosition: ErrorPosition.BOTTOM,
                        errorMessage: T.CreateCustomerPhoneInputError,
                        inputType: 'phone',
                        validationRegex: REGEX_PHONE,
                    },
                },
                {
                    label: T.CreateCustomerInputStatus,
                    value: customer?.active.toString(),
                    type: ERowType.status,
                    inputName: 'active',
                    render: (value: string) => value === 'true' ? T.CustomerStatusActive : T.CustomerStatusInactive,
                    inputProps: {
                        disabled: customer?.customerType === 'CUS' ? true : false,
                    },
                },
            ],
        },
        {
            label: T.CreateCustomerLabelAdresse,
            isEditable: true,
            rows: [
                {
                    label: T.CreateCustomerInputStreet1,
                    value: customer?.street,
                    type: ERowType.string,
                    inputName: 'street',
                },
                {
                    label: T.CreateCustomerInputStreet2,
                    value: customer?.street2,
                    type: ERowType.string,
                    inputName: 'street2',
                },
                {
                    label: T.CreateCustomerInputCountry,
                    value: customer?.country,
                    type: ERowType.country,
                    inputName: 'country',
                    // render: (value: string) => value === 'CAN' ? T.CAN : value === 'USA' ? T.USA : undefined,
                    options: optionsPays,
                },
                {
                    label: T.CreateCustomerInputProvince,
                    value: customer?.state,
                    type: ERowType.province,
                    inputName: 'state',
                    render: (value: string) => T[customer?.state],
                    options: getStateOptions(customer?.country),
                },
                {
                    label: T.CreateCustomerInputCity,
                    value: customer?.city,
                    type: ERowType.city,
                    inputName: 'city',
                    options: optionsVilles,
                },
                {
                    label: T.CreateCustomerInputCodePostal,
                    value: customer?.zip,
                    type: ERowType.string,
                    inputName: 'zip',
                    inputProps: {
                        isOptional: true,
                    },
                },
            ],
        },
        {
            label: T.CreateCustomerLabelDetails,
            isEditable: true,
            rows: [
                {
                    label: T.CreateCustomerInputTerritory,
                    value: customer?.territoryId,
                    type: ERowType.territory,
                    inputName: 'territoryId',
                    options: optionsTerritoires,
                    render: (value: string) => territoiresCAN.find((territoire: IDropdownOption) => territoire.value === value)?.label || territoiresUSA.find((territoire: IDropdownOption) => territoire.value === value)?.label,
                    inputProps: {
                        disabled: !isAuthorized,
                    },
                },
                {
                    label: T.CreateCustomerInputSalesRep,
                    value: customer?.salesrepCode,
                    type: ERowType.vendeur,
                    inputName: 'salesrepCode',
                    options: optionsVendeurs,
                    render: renderSalesRep,
                    inputProps: {
                        disabled: !isAuthorized,
                    },
                },
                {
                    label: T.CreateCustomerInputGrpIndustrie,
                    value: customer?.groupCode,
                    type: ERowType.groupeIndustrie,
                    inputName: 'groupCode',
                    options: optionsGrpIndustrie,
                    render: (value: string) => optionsGrpIndustrie.find((code: IDropdownOption) => code.value === value)?.label,
                    inputProps: {
                        disabled: !isAuthorized,
                    },
                },
                {
                    label: T.CreateCustomerInputPotentiel,
                    value: customer?.priceClass,
                    type: ERowType.potential,
                    inputName: 'priceClass',
                    options: optionsPotentiels,
                    render: (value: string) => value === 'A' ? T.CreateCustomerOptionPotentielA : value === 'B' ? T.CreateCustomerOptionPotentielB : value === 'C' ? T.CreateCustomerOptionPotentielC : undefined,
                    inputProps: {
                        disabled: !isAuthorized,
                    },
                },
                {
                    label: T.CreateCustomerInputLangue,
                    value: customer?.lang,
                    type: ERowType.language,
                    inputName: 'lang',
                    render: (value: string) => value?.indexOf('fr') >= 0 ? T.ProjectDetailsTabClientFrench : value?.indexOf('en') >= 0 ? T.ProjectDetailsTabClientEnglish : undefined,
                },
            ],
        },
    ];

    const onValidate = async (fieldName: string) => { };

    const updateCustomer = (customer: ICustomer) => {
        setProcessing(true);
        PutCustomer(customer)
            .then((response) => {
                setCustomer(response);
                localStorage.setItem('customerSelected', JSON.stringify(response));
                setProcessing(false);
                addToast({
                    text: T.GeneralFormSubmitMessageSuccess,
                    type: ToastType.success,
                });
            })
            .catch((error) => {
                setProcessing(false);
                addToast({
                    text: T.GeneralFormSubmitMessageError,
                    type: ToastType.error,
                });
            });
    };

    const onSubmit = async (group: IGroup) => {

        const clientUpdated: ICustomer = {
            epicorCustId: customer?.epicorCustId,
            epicorCustNum: customer?.epicorCustNum,
            name: groups[0].rows[0].value,
            email: groups[0].rows[1].value,
            phoneNum: groups[0].rows[2].value,
            active: JSON.parse(groups[0].rows[3].value),
            street: groups[1].rows[0].value,
            street2: groups[1].rows[1].value,
            country: groups[1].rows[2].value,
            state: groups[1].rows[3].value,
            city: groups[1].rows[4].value,
            zip: groups[1].rows[5].value,
            territoryId: groups[2].rows[0].value,
            salesrepCode: groups[2].rows[1].value,
            groupCode: groups[2].rows[2].value,
            priceClass: groups[2].rows[3].value,
            lang: groups[2].rows[4].value,
            // "terms": "5050",
            // "currencyCode": "CAD",
            // "epicorCustNum": 1777,
            // "customerType": "PRO",
            // "active": true,
            // "creditHold": true
        };
        // group.rows.forEach((row) => {
        //     customer[row.inputName] = row.value;
        // });

        updateCustomer(clientUpdated);

        const promiseArray: Array<IProject | IPricingItem[]> = [];
        return await Promise.all<IProject | IPricingItem[]>(promiseArray)
            .then(([project, priceBreakdown]) => {
                return true;
            })
            .catch(() => {
                return false;
            });
    };

    const onCancel = (group: IGroup) => {
        group.rows.forEach((row) => {
            row.value = customer[row.inputName].toString();
        });
    };

    const hasChanged = (group: IGroup) => {
        return true;
    };

    useEffect(() => {
        if (customer === undefined) {
            setCustomer(JSON.parse(localStorage.getItem('customerSelected') || '{}'));
        }
    }, []);

    useEffect(() => {
        if (customer !== undefined) {
            if (userHasPermissions(EPermissions.userManage) || customer?.customerType === 'P') {
                setIsAuthorized(true);
            }
            fetchSalesReps();
            fetchCustomersGroups();
            fetchCustomersTerritoriesCAN();
            getCities(customer?.state);
            fetchCustomersTerritoriesUSA();
            fetchContacts(customer?.epicorCustId);
        }
    }, [customer]);

    function renameAttributes(contactList: IContact[], custumerNum: number) {
        const contactsUpdated: IContact[] = [];
        contactList.map((contact: IContact) => {
            const newContact: any = {
                custNum: 0,
                conNum: 0,
                name: '',
                email: '',
                phoneNum: '',
                func: '',
                shipToNum: '',
                active: true,
            };
            newContact.custNum = custumerNum;
            newContact.conNum = contact.epicorConNum || contact.conNum;
            newContact.name = contact.name;
            newContact.email = contact.email;
            newContact.phoneNum = contact.phoneNum ? contact.phoneNum : '';
            newContact.func = contact.func;
            newContact.shipToNum = '';
            newContact.active = contact.active;
            contactsUpdated.push(newContact);
        });
        return contactsUpdated;
    }

    function findWithAttr(array: string | any[], attr: string | number, value: any) {
        for (let i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    function apiCallWithReflexion(url, methode, contacts, retryLimit, retryCount) {
        return fetch(url, { method: methode, headers: authenticatedHeaders(), body: JSON.stringify(contacts[0]) }).then((res) => {
            return res.json();
        }).then((res) => {
            if (retryCount < retryLimit) {
                contacts.shift();
                return apiCallWithReflexion(url, methode, contacts, retryLimit, retryCount + 1);
            }
        });
    }

    const handleMuiContactsTableCb = (contacts: any[]) => {
        const modifiedContacts = [];
        for (let i = 0; i < contacts.length; i++) {
            if (JSON.stringify(contacts[i]) !== JSON.stringify(tableContacts[i])) { modifiedContacts.push(contacts[i]); }
        }
        setTableContacts(contacts);

        const lescontacts = renameAttributes(modifiedContacts, customer?.epicorCustNum);

        const contactsDeleted = lescontacts.filter((contact) => contact.conNum !== undefined && contact.active === false);
        let contactsUpdated = lescontacts.filter((contact) => contact.conNum !== undefined && contact.active === true);
        const contactCreated = lescontacts.filter((contact) => contact.conNum === undefined && contact.active === true);

        contactsUpdated = contactsUpdated.concat(contactsDeleted);

        if (contactsUpdated.length > 0 && contactCreated.length === 0) {
            setProcessing(true);
            apiCallWithReflexion(POST_CONTACT, 'PUT', contactsUpdated, contactsUpdated.length, 1).then(() => {
                setProcessing(false);
                addToast({
                    text: T.GeneralFormSubmitMessageSuccess,
                    type: ToastType.success,
                });
            }).catch(() => {
                setProcessing(false);
                addToast({
                    text: T.GeneralFormSubmitMessageError,
                    type: ToastType.error,
                });
            }).finally(() => {
                fetchContacts(customer?.epicorCustId);
            });

        } else if (contactsUpdated.length === 0 && contactCreated.length > 0) {
            setProcessing(true);
            apiCallWithReflexion(POST_CONTACT, 'POST', contactCreated, contactCreated.length, 1).then(() => {
                setProcessing(false);
                addToast({
                    text: T.GeneralFormSubmitMessageSuccess,
                    type: ToastType.success,
                });
            }).catch(() => {
                setProcessing(false);
                addToast({
                    text: T.GeneralFormSubmitMessageError,
                    type: ToastType.error,
                });
            }).finally(() => {
                fetchContacts(customer?.epicorCustId);
            });

        } else {
            setProcessing(true);
            apiCallWithReflexion(POST_CONTACT, 'PUT', contactsUpdated, contactsUpdated.length, 1).then(() => {
                apiCallWithReflexion(POST_CONTACT, 'POST', contactCreated, contactCreated.length, 1).then(() => {
                    setProcessing(false);
                    addToast({
                        text: T.GeneralFormSubmitMessageSuccess,
                        type: ToastType.success,
                    });
                }).catch(() => {
                    setProcessing(false);
                    addToast({
                        text: T.GeneralFormSubmitMessageError,
                        type: ToastType.error,
                    });
                });
            }).catch(() => {
                setProcessing(false);
                addToast({
                    text: T.GeneralFormSubmitMessageError,
                    type: ToastType.error,
                });
            }).finally(() => {
                fetchContacts(customer?.epicorCustId);
            });
        }
    };

    if (optionsVendeurs.length === 0 || territoiresUSA.length === 0 || territoiresCAN.length === 0 || optionsGrpIndustrie.length === 0) {
        return <Loading />;
    }

    if (processing) {
        return <Loading />;
    }

    return (
        <LayoutFullWidth>
            <h1>{T.CreateCustomerEditTitle}</h1>
            <div className={S.projectContainer}>
                <div className={S.projectTabs}>
                    <div className={S.projectInformation}>
                        <>
                            {groups.map((group, index) => (
                                <TabGroup
                                    key={index}
                                    group={group}
                                    onSubmit={onSubmit}
                                    onValidate={onValidate}
                                    onCancel={onCancel}
                                    hasChanged={hasChanged}
                                />
                            ))}
                        </>
                    </div>
                </div>
                {!loadingContacts ? <MUIContactsTable mode={'edit'} contactList={tableContacts} refreshContacts={(e: any[]) => handleMuiContactsTableCb(e)} /> : <CircularProgress />}
            </div>
        </LayoutFullWidth>
    );
}
