import React from 'react';

export default function LanguageIcon({ color }: {color?: string}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g className="svg-fill-color" fill={color ? color : '#9FBBC4'} fillRule="evenodd">
				<path d="M7.5 16c4.142 0 7.5 3.358 7.5 7.5 0 .276-.224.5-.5.5H.5c-.276 0-.5-.224-.5-.5C0 19.358 3.358 16 7.5 16zm0 1c-3.356 0-6.118 2.543-6.463 5.807L1.019 23H13.98l-.017-.193c-.337-3.188-2.98-5.688-6.23-5.803zm0-11c2.623 0 4.75 2.127 4.75 4.75S10.123 15.5 7.5 15.5s-4.75-2.127-4.75-4.75S4.877 6 7.5 6zM3.99 9.424l-.06.177c-.117.362-.18.748-.18 1.149 0 2.071 1.679 3.75 3.75 3.75 2.071 0 3.75-1.679 3.75-3.75l-.003.133c-2.506.811-5.26.257-7.256-1.46zM19 0c2.761 0 5 2.239 5 5 0 2.689-2.122 4.882-4.783 4.995L19 10l-.824-.001-2.364 1.891c-.305.245-.748.058-.806-.309L15 11.5V9.999L13.5 10c-.245 0-.45-.177-.492-.41L13 9.5c0-.245.177-.45.41-.492L13.5 9h2c.245 0 .45.177.492.41L16 9.5v.959l1.688-1.35c.066-.053.145-.088.228-.102L18 9h1c2.21 0 4-1.79 4-4 0-2.142-1.684-3.891-3.8-3.995L19 1h-6c-2.038 0-3.75 1.53-3.976 3.556-.03.274-.278.472-.553.44-.274-.03-.472-.277-.44-.552.274-2.456 2.298-4.331 4.745-4.44L13 0h6zM7.5 7c-1.237 0-2.333.599-3.017 1.522 1.797 1.65 4.365 2.167 6.66 1.34C10.745 8.22 9.265 7 7.5 7zM19 4.25h.018l.038.003.046.004c.366.05.648.363.648.743 0 .414-.336.75-.75.75s-.75-.336-.75-.75c0-.38.282-.693.648-.743l.046-.004.04-.003H19zm-3 0h.018l.038.003.046.004c.366.05.648.363.648.743 0 .414-.336.75-.75.75s-.75-.336-.75-.75c0-.38.282-.693.648-.743l.046-.004.04-.003H16zm-3 0h.018l.038.003.046.004c.366.05.648.363.648.743 0 .414-.336.75-.75.75s-.75-.336-.75-.75c0-.38.282-.693.648-.743l.046-.004.04-.003H13z" transform="translate(-110 -1499) translate(110 1499)"/>
			</g>
		</svg>
	);
}
