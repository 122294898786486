import ArrowLeftIcon from '@components/svg/ArrowLeftIcon';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import S from './styles/BackButtonMvp.styl';

interface IBackButtonMvc {
	href?: string;
}

export default function BackButtonMvp(props: IBackButtonMvc) {
	const history = useHistory();

	return (
		<>
			{props.href ? (
				<Link
					to={props.href}
					className={S.backButtonMvp}
				>
					<ArrowLeftIcon stroke={S.darkBlueGray} strokeWidth="2px"/>
				</Link>
			) : (
				<button className={S.backButtonMvp} onClick={history.goBack}>
					<ArrowLeftIcon stroke={S.darkBlueGray} strokeWidth="2px"/>
				</button>
			)}
		</>
	);
}
