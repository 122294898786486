import HyperLink from '@components/buttons/HyperLink';
import FloorPill from '@components/labels/FloorPill';
import PricePerUnitLabel from '@components/labels/PricePerUnitLabel';
import { useLocale } from '@i18n';
import { State, TCode } from '@models';
import classnames from 'classnames';
import React, { ChangeEvent, useState } from 'react';
import Checkbox from './Checkbox';
import RadioButton from './RadioButton';

import S from './styles/MultiInput.styl';

type TRadioOnChange = (event: ChangeEvent<HTMLInputElement>) => void;
type TCheckboxOnChange = (code: TCode, value: boolean) => void;
type TCheckboxAllRoomOnChange = (id: number, value: boolean) => void;

export interface ICheckedValues {
    [key: string]: boolean | undefined;
    A?: boolean;
    B?: boolean;
    C?: boolean;
    D?: boolean;
}

interface IDisabledValues {
    [key: string]: boolean;
    A?: boolean;
    B?: boolean;
    C?: boolean;
    D?: boolean;
}

interface IMultiInputProps {
    readonly title: string;
    readonly description: string;
    readonly checkedValues: ICheckedValues;
    readonly locked?: boolean;
}

interface ISimpleMultiInputProps extends IMultiInputProps {
    readonly label?: string;
    readonly hollowLabel?: boolean;
    readonly onChange: TRadioOnChange;
}

interface IComplexMultiInputProps extends IMultiInputProps {
    readonly technicalSheetUrl?: string;
    readonly technicalSheetText?: string;
    readonly imageUrl?: string;
    readonly imageOnSide?: boolean;
    readonly price?: string;
    readonly units?: string;
    readonly children?: React.ReactElement;
    readonly width?: string;
    readonly checkAllRoom?: boolean;
    readonly onAllRoomsCheckChanged?: TCheckboxAllRoomOnChange;
    readonly checkboxAllRoomsState?: State;
    readonly partialLocked?: string[];
    readonly id?: number;
}

interface IComplexMultiRadioProps extends IComplexMultiInputProps {
    readonly onChange: TRadioOnChange;
}

interface IComplexMultiCheckboxProps extends IComplexMultiInputProps {
    readonly onChange: TCheckboxOnChange;
    readonly twoRowsButtons?: boolean;
}

function getRadioButtons(onChange: TRadioOnChange, checkedValues: ICheckedValues, locked: boolean = false, partialLocked: string[] = []) {
    const [activeValues, setActiveValues] = useState<ICheckedValues>({ A: false, B: false, C: false, D: false });
    const [timers, setTimers] = useState<{ A: number, B: number, C: number, D: number }>({ A: 0, B: 0, C: 0, D: 0 });

    const onChangeMiddleware = (code: TCode) => (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event);
        setActiveValues({ ...activeValues, [code]: true });

        timers[code] = window.setTimeout(() => {
            timers[code] = 0;
            const values = {
                A: !!timers.A,
                B: !!timers.B,
                C: !!timers.C,
                D: !!timers.D,
            };
            setActiveValues(values);
        }, 500);
        setTimers(timers);
    };

    const numberOfInputs = Object.keys(checkedValues).length;
    return (
        <div
            className={classnames(S.multiInputButtonsRow, {
                [S.multiInputButtonsRowOne]: numberOfInputs === 1,
                [S.multiInputButtonsRowTwo]: numberOfInputs === 2,
                [S.multiInputButtonsRowThree]: numberOfInputs === 3,
            })}
        >
            {checkedValues && Object.keys(checkedValues).map((code) => {
                let isLocked = locked;

                if (partialLocked.length > 0) {
                    isLocked = partialLocked.includes(code);
                }

                return (
                    <div
                        key={code}
                        className={classnames(S.multiInputButtons, {
                            [S.multiInputButtonsChecked]: checkedValues[code],
                            [S.multiInputButtonsActive]: activeValues[code as TCode],
                            [S.multiInputButtonsOne]: numberOfInputs === 1,
                            [S.multiInputButtonsTwo]: numberOfInputs === 2,
                            [S.multiInputButtonsThree]: numberOfInputs === 3,
                        })}
                    >
                        <RadioButton
                            label={code}
                            locked={isLocked}
                            onChange={onChangeMiddleware(code as TCode)}
                            checked={checkedValues[code] || activeValues[code]}
                            active={!!activeValues[code as TCode]}
                        />
                    </div>
                );
            })}
        </div>
    );
}

function ComplexLayout(props: IComplexMultiRadioProps | IComplexMultiCheckboxProps) {
    const T = useLocale();

    const onChangeCheckboxMiddleware = (id: number) => (checked: boolean) => {
        if (props.onAllRoomsCheckChanged) {
            props.onAllRoomsCheckChanged(id, checked);
        }
    };

    return (
        <div
            className={classnames(S.multiInputContainer, {
                [S.multiInputContainerSideImage]: props.imageOnSide,
            })}
            style={props.width ? { width: props.width, minWidth: 'unset' } : {}}
        >
            {props.imageUrl && (
                <div
                    className={classnames(S.multiInputImageContainer, {
                        [S.multiInputImageContainerSide]: props.imageOnSide,
                    })}
                >
                    <img src={props.imageUrl} />
                </div>
            )}
            <div
                className={classnames(S.multiInputTextContainer, {
                    [S.multiInputTextContainerSideImage]: props.imageOnSide,
                })}
            >
                <div className={S.multiInputTextContainerText}>
                    <h2>{props.title}</h2>

                    {props.price && props.units && (
                        <PricePerUnitLabel
                            price={props.price}
                            units={props.units}
                            state={State.Enabled}
                        />
                    )}

                    <p>{props.description}</p>

                    {props.technicalSheetUrl && (
                        <div
                            className={classnames(S.multiInputTechnicalSheet, {
                                [S.multiInputTechnicalSheetLessMarginBottom]: props.checkAllRoom,
                            })}
                        >
                            <HyperLink title={props.technicalSheetText || 'Fiche Technique'} link={props.technicalSheetUrl} />
                        </div>
                    )}

                    {props.checkAllRoom && (
                        <Checkbox
                            state={props.checkboxAllRoomsState || State.Enabled}
                            title={T.GeneralAllRooms}
                            onChange={onChangeCheckboxMiddleware(props.id || 0)}
                            isMultiInput={false}
                        />
                    )}

                </div>

                {props.children}
            </div>
        </div>
    );
}

export function SimpleMultiRadio(props: ISimpleMultiInputProps) {
    return (
        <div className={classnames(S.multiInputContainer, S.multiInputContainerSimple)}>
            <div className={classnames(S.multiInputTextContainer, S.multiInputTextContainerSimple)}>
                <h2>{props.title}</h2>

                <div className={S.multiInputSimpleDescription}>
                    {props.description}
                </div>

                <div className={S.multiInputSpacer} />

                {props.label && (
                    <FloorPill text={props.label} hollow={props.hollowLabel} />
                )}
            </div>

            {getRadioButtons(props.onChange, props.checkedValues, props.locked)}
        </div>
    );
}

function getCheckBoxes(onChange: TRadioOnChange, checkedValues: ICheckedValues, locked: boolean = false) {
    const [activeValues, setActiveValues] = useState<ICheckedValues>({ A: false, B: false, C: false, D: false });
    const [timers, setTimers] = useState<{ A: number, B: number, C: number, D: number }>({ A: 0, B: 0, C: 0, D: 0 });

    const onChangeMiddleware = (code: TCode) => (checked: boolean) => {
        onChange({ target: { checked, name: code } } as unknown as ChangeEvent<HTMLInputElement>);
        setActiveValues({ ...activeValues, [code]: true });

        timers[code] = window.setTimeout(() => {
            timers[code] = 0;
            const values = {
                A: !!timers.A,
                B: !!timers.B,
                C: !!timers.C,
                D: !!timers.D,
            };
            setActiveValues(values);
        }, 500);
        setTimers(timers);
    };

    function getState(code: TCode) {
        if (activeValues[code]) {
            return State.Focused;
        } else if (checkedValues[code]) {
            return State.Selected;
        }
        return State.Enabled;
    }

    const checkedValuesKeys = Object.keys(checkedValues);
    const numberOfInputs = checkedValuesKeys.length;

    return (
        <div
            className={classnames(S.multiInputButtonsRow, {
                [S.multiInputButtonsRowOne]: numberOfInputs === 1,
                [S.multiInputButtonsRowTwo]: numberOfInputs === 2,
                [S.multiInputButtonsRowThree]: numberOfInputs === 3,
            })}
        >
            {checkedValues && checkedValuesKeys.map((code) => {
                return (
                    <div
                        key={code}
                        className={classnames(S.multiInputButtons, S.multiInputButtonsCheckboxes, {
                            [S.multiInputButtonsChecked]: checkedValues[code],
                            [S.multiInputButtonsActive]: activeValues[code as TCode],
                            [S.multiInputButtonsOne]: numberOfInputs === 1,
                            [S.multiInputButtonsTwo]: numberOfInputs === 2,
                            [S.multiInputButtonsThree]: numberOfInputs === 3,
                        })}
                    >
                        <Checkbox title={code} onChange={onChangeMiddleware(code as TCode)} state={getState(code as TCode)} />
                    </div>
                );
            })}
        </div>
    );
}

function getCheckBoxes2(onChange: TRadioOnChange, checkedValues: ICheckedValues, locked: boolean = false, disabledValues: IDisabledValues = {}) {
    const [activeValues, setActiveValues] = useState<ICheckedValues>({ A: false, B: false, C: false, D: false });
    const [timers, setTimers] = useState<{ A: number, B: number, C: number, D: number }>({ A: 0, B: 0, C: 0, D: 0 });

    const onChangeMiddleware = (code: TCode) => (checked: boolean) => {
        onChange({ target: { checked, name: code } } as unknown as ChangeEvent<HTMLInputElement>);
        setActiveValues({ ...activeValues, [code]: true });

        timers[code] = window.setTimeout(() => {
            timers[code] = 0;
            const values = {
                A: !!timers.A,
                B: !!timers.B,
                C: !!timers.C,
                D: !!timers.D,
            };
            setActiveValues(values);
        }, 500);
        setTimers(timers);
    };

    function getState(code: TCode) {
        if (activeValues[code]) {
            return State.Focused;
        } else if (checkedValues[code]) {
            return State.Selected;
        }
        return State.Enabled;
    }

    const checkedValuesKeys = Object.keys(checkedValues);
    const numberOfInputs = checkedValuesKeys.length;

    return (
        <div
            className={classnames(S.multiInputButtonsRow, {
                [S.multiInputButtonsRowOne]: numberOfInputs === 1,
                [S.multiInputButtonsRowTwo]: numberOfInputs === 2,
                [S.multiInputButtonsRowThree]: numberOfInputs === 3,
            })}
        >
            {checkedValues && Object.keys(checkedValues).map((code) => {
                const isDisabled = disabledValues[code] ?? false;
                return (
                    <div
                        key={code}
                        className={classnames(S.multiInputButtons, S.multiInputButtonsCheckboxes, {
                            [S.multiInputButtonsChecked]: checkedValues[code],
                            [S.multiInputButtonsActive]: activeValues[code as TCode],
                            [S.multiInputButtonsOne]: numberOfInputs === 1,
                            [S.multiInputButtonsTwo]: numberOfInputs === 2,
                            [S.multiInputButtonsThree]: numberOfInputs === 3,
                        })}
                        style={{ cursor: isDisabled ? 'not-allowed' : 'default' }}
                    >
                        <Checkbox
                            title={code}
                            onChange={isDisabled ? undefined : onChangeMiddleware(code as TCode)}
                            state={getState(code as TCode)}
                            disabled={isDisabled}
                        />
                    </div>
                );
            })}
        </div>
    );
}

export function SimpleMultiCheckbox(props: ISimpleMultiInputProps & { disabledValues?: IDisabledValues }) {
    return (
        <div className={classnames(S.multiInputContainer, S.multiInputContainerSimple)}>
            <div className={classnames(S.multiInputTextContainer, S.multiInputTextContainerSimple)}>
                <h2>{props.title}</h2>

                <div className={S.multiInputSimpleDescription}>
                    {props.description}
                </div>

                <div className={S.multiInputSpacer} />

                {props.label && (
                    <FloorPill text={props.label} hollow={props.hollowLabel} />
                )}
            </div>

            {getCheckBoxes2(props.onChange, props.checkedValues, props.locked, props.disabledValues)}
        </div>
    );
}

export function ComplexMultiRadio(props: IComplexMultiRadioProps) {
    return (
        <ComplexLayout {...props}>
            {getRadioButtons(props.onChange, props.checkedValues, props.locked, props.partialLocked)}
        </ComplexLayout>
    );
}

export function ComplexMultiCheckbox(props: IComplexMultiCheckboxProps) {
    const { checkedValues, onChange } = props;
    const [activeValues, setActiveValues] = useState({ A: false, B: false, C: false, D: false });
    const [timers, setTimers] = useState<{ A: number, B: number, C: number, D: number }>({ A: 0, B: 0, C: 0, D: 0 });

    const onChangeMiddleware = (code: TCode) => (value: boolean) => {
        onChange(code, value);
        setActiveValues({ ...activeValues, [code]: true });

        timers[code] = window.setTimeout(() => {
            timers[code] = 0;
            const values = {
                A: !!timers.A,
                B: !!timers.B,
                C: !!timers.C,
                D: !!timers.D,
            };
            setActiveValues(values);
        }, 500);
        setTimers(timers);
    };

    function getState(code: TCode) {
        if (activeValues[code]) {
            return State.Focused;
        } else if (checkedValues[code]) {
            return State.Selected;
        }
        return State.Enabled;
    }

    const checkedValuesKeys = Object.keys(checkedValues);
    const numberOfInputs = checkedValuesKeys.length;
    return (
        <ComplexLayout {...props}>
            {props.twoRowsButtons ? (
                <>
                    <div
                        className={classnames(S.multiInputButtonsRow)}
                    >
                        {checkedValues && ['A', 'B'].map((code) => {
                            return (
                                <React.Fragment key={code}>
                                    {checkedValuesKeys.includes(code) && (
                                        <div
                                            className={classnames(S.multiInputButtons, S.multiInputButtonsCheckboxes, S.multiInputButtonsTwoRows, {
                                                [S.multiInputButtonsChecked]: checkedValues[code],
                                                [S.multiInputButtonsActive]: activeValues[code as TCode],
                                                [S.multiInputButtonsOne]: numberOfInputs === 1,
                                                [S.multiInputButtonsTwo]: numberOfInputs >= 2,
                                            })}
                                        >
                                            <Checkbox title={code} onChange={onChangeMiddleware(code as TCode)} state={getState(code as TCode)} />
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>

                    {checkedValuesKeys.includes('C') && (
                        <div
                            className={classnames(S.multiInputButtonsRow)}
                        >
                            {checkedValues && ['C', 'D'].map((code) => {
                                return (
                                    <React.Fragment key={code}>
                                        {checkedValuesKeys.includes(code) && (
                                            <div
                                                className={classnames(S.multiInputButtons, S.multiInputButtonsCheckboxes, S.multiInputButtonsTwoRows, {
                                                    [S.multiInputButtonsChecked]: checkedValues[code],
                                                    [S.multiInputButtonsActive]: activeValues[code as TCode],
                                                    [S.multiInputButtonsOne]: numberOfInputs === 3,
                                                    [S.multiInputButtonsTwo]: numberOfInputs === 4,
                                                })}
                                            >
                                                <Checkbox title={code} onChange={onChangeMiddleware(code as TCode)} state={getState(code as TCode)} />
                                            </div>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    )}
                </>
            ) : (
                <div
                    className={classnames(S.multiInputButtonsRow, {
                        [S.multiInputButtonsRowOne]: numberOfInputs === 1,
                        [S.multiInputButtonsRowTwo]: numberOfInputs === 2,
                        [S.multiInputButtonsRowThree]: numberOfInputs === 3,
                    })}
                >
                    {checkedValues && checkedValuesKeys.map((code) => {
                        return (
                            <div
                                key={code}
                                className={classnames(S.multiInputButtons, S.multiInputButtonsCheckboxes, {
                                    [S.multiInputButtonsChecked]: checkedValues[code],
                                    [S.multiInputButtonsActive]: activeValues[code as TCode],
                                    [S.multiInputButtonsOne]: numberOfInputs === 1,
                                    [S.multiInputButtonsTwo]: numberOfInputs === 2,
                                    [S.multiInputButtonsThree]: numberOfInputs === 3,
                                })}
                            >
                                <Checkbox title={code} onChange={onChangeMiddleware(code as TCode)} state={getState(code as TCode)} />
                            </div>
                        );
                    })}
                </div>
            )}
        </ComplexLayout>
    );
}
