import { useLocale } from '@i18n';
import { Graphics } from '@inlet/react-pixi';
import { RoomSettings, State } from '@models';
import ShapesUtils from '@utils/drawing/shapes';
import TextUtils from '@utils/drawing/text';
import FormatUtils from '@utils/format';
import RoomUtils from '@utils/room';
import { Graphics as PIXIGraphics } from 'pixi.js';
import React from 'react';

export enum IRoomDimensionAligment {
	HORIZONTAL,
	VERTICAL,
}

interface IRoomDimensionProps {
	hasExtraCommonWall: boolean;
	commonWallLengthInch: number;
	isDirty: boolean;
	state: State;
	lengthInch: number;
	x: number;
	y: number;
	alignment: IRoomDimensionAligment;
	biggerDimension?: boolean;
}

export default function RoomDimension(props: IRoomDimensionProps) {
	const T = useLocale();
	const commonWallLength = props.commonWallLengthInch * RoomSettings.PIXEL_PER_INCH;
	const length = props.lengthInch * RoomSettings.PIXEL_PER_INCH;
	const commonWallTextColor = 0x415A62;
	const fontSize: number = 12;
	const biggerFontSize: number = 18;
	const biggerSizeUnit: number = 5;

	function renderCommonWallDimension(graphics: PIXIGraphics, x: number, y: number) {
		if (props.biggerDimension) {
			graphics.addChild(TextUtils.createCenteredText(
				FormatUtils.formatInches(props.commonWallLengthInch),
				'Assistant',
				biggerFontSize,
				commonWallTextColor,
				x,
				y + (biggerSizeUnit + 2),
				commonWallLength + biggerSizeUnit,
				RoomSettings.ROOM_DIMENSION_HEIGHT + biggerSizeUnit,
			));
		} else {
			graphics.addChild(TextUtils.createCenteredText(
				FormatUtils.formatInches(props.commonWallLengthInch),
				'Assistant',
				fontSize,
				commonWallTextColor,
				x,
				y,
				commonWallLength,
				RoomSettings.ROOM_DIMENSION_HEIGHT,
			));
		}
	}

	function renderRoomDimension(graphics: PIXIGraphics, x: number, y: number) {
		if (props.biggerDimension) {
			ShapesUtils.drawRoundedRectangle(
				graphics,
				x + (biggerSizeUnit + 1),
				y + biggerSizeUnit,
				length - (biggerSizeUnit * 1.5),
				RoomSettings.ROOM_DIMENSION_HEIGHT_BIGGER,
				RoomUtils.getBackgroundDimensionColor(props.state),
				RoomUtils.getRoomBackgroundAlpha(props.state),
			);
			graphics.addChild(TextUtils.createCenteredText(
				`${FormatUtils.formatInches(props.lengthInch)}`,
				'Assistant',
				biggerFontSize,
				RoomUtils.getDimensionTextColor(props.state),
				x,
				y + (biggerSizeUnit + 2),
				length  + biggerSizeUnit,
				RoomSettings.ROOM_DIMENSION_HEIGHT  + biggerSizeUnit,
				RoomUtils.getCodeFontWeight(props.state),
			));
		} else {
			ShapesUtils.drawRoundedRectangle(
				graphics,
				x,
				y,
				length,
				RoomSettings.ROOM_DIMENSION_HEIGHT,
				RoomUtils.getBackgroundDimensionColor(props.state),
				RoomUtils.getRoomBackgroundAlpha(props.state),
			);
			graphics.addChild(TextUtils.createCenteredText(
				`${FormatUtils.formatInches(props.lengthInch)}`,
				'Assistant',
				fontSize,
				RoomUtils.getDimensionTextColor(props.state),
				x,
				y,
				length,
				RoomSettings.ROOM_DIMENSION_HEIGHT,
				RoomUtils.getCodeFontWeight(props.state),
			));
		}
	}

	return (
		<Graphics
			draw={(graphics) => {
				graphics.clear();
				graphics.removeChildren();
				graphics.position.x = props.x;
				graphics.position.y = props.y;

				let roomX = 0;
				const roomY = 0;

				renderCommonWallDimension(graphics, roomX, roomY);

				roomX += commonWallLength;
				renderRoomDimension(graphics, roomX, roomY);

				if (props.hasExtraCommonWall) {
					roomX += length + 2;
					renderCommonWallDimension(graphics, roomX, roomY);
				}

				if (props.alignment === IRoomDimensionAligment.VERTICAL) {
					graphics.rotation = 1.5 * Math.PI;
				}
			}}
		/>
	);
}
