import { FormInputState, State } from '@models';
import { AnimationSettings } from '@utils/animation';
import classnames from 'classnames';
import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { CSSTransition } from 'react-transition-group';
import S from './styles/AutoComplete.styl';

interface IAutoCompleteProps {
	placeholder?: string;
	noResultsText?: string;
	label: string;
	alert?: string;
	alertState?: State;
	shouldRenderAlert?: boolean;
	value: ISuggestion | undefined;
	suggestions: any[];
	suggestionNotice: string;
	onChange: (e: any, inputState?: FormInputState) => void;
	onFetch: (value: any) => void;
	onClear: () => void;
	isLoading: boolean;
	disabled?: boolean;
	autoComplete?: string;
	isOptional?: boolean;
}

export type ISuggestion = string;

const getSuggestionValue = (suggestion: ISuggestion) => suggestion;

const renderSuggestion = (suggestion: ISuggestion) => (
	<div className={S.autocompleteSuggestionsContainerRenderedItem}>
		{suggestion}
	</div>
);

export default function AutoComplete(props: IAutoCompleteProps) {
	const [state, setState] = useState<State>(State.Enabled);
	const [inputHasFocus, setInputHasFocus] = useState<boolean>(false);
	const [timer, setTimer] = useState<number>(0);

	let { value } = props;
	if (!value) {
		value = '';
	}

	function handleChange(_: any, { newValue }: any) {
		setState(State.Focused);

		let inputState = FormInputState.Valid;
		if (!props.isOptional) {
			inputState = newValue !== '' && newValue !== null ? FormInputState.Valid : FormInputState.Error;
		}

		props.onChange(newValue, inputState);

		clearTimeout(timer);

		setTimer(window.setTimeout(() => {
			setState(State.Selected);
		}, AnimationSettings.FADE_DELAY));
	}

	function hasNoResult(): boolean {
		return props.suggestions.length === 0;
	}

	function renderSuggestionsContainer({ containerProps, children, query }: any) {
		if (hasNoResult() && !value || !inputHasFocus) {
			return null;
		}

		return (
			<div
				{...containerProps}
				style={{ width: document.getElementById('autocomplete-hook')?.offsetWidth || '300px' }}
				className={S.autocompleteSuggestionsContainer}
			>
				{props.suggestionNotice && (
					<div className={S.autocompleteSuggestionsContainerNotice}>
						{props.suggestionNotice}
						<div className={S.autocompleteSuggestionsContainerNoticeLine} />
					</div>
				)}

				{hasNoResult() && !props.isLoading ? (
					<div className={classnames(S.autocompleteSuggestionsContainerRenderedItem, S.autocompleteSuggestionsContainerRenderedItemEmpty)}>
						{props.noResultsText}
					</div>
				) : (
					children
				)}
			</div>
		);
	}

	return (
		<>
			<div
				className={classnames(S.autocompleteLabel, {
					[S.autocompleteLabelError]: !props.isOptional && value === '',
				})}
			>
				{props.label}
			</div>

			<CSSTransition
				in={props.shouldRenderAlert}
				timeout={AnimationSettings.FADE_DELAY}
				classNames={S.autocompleteAlert}
				appear
				mountOnEnter
				unmountOnExit
			>
				<div
					className={classnames(S.autocompleteAlert, {
						[S.autocompleteAlertFocused]: props.alertState === State.Focused,
						[S.autocompleteAlertSelected]: props.alertState === State.Selected,
					})}
				>
					{props.alert}
				</div>
			</CSSTransition>

			<Autosuggest

				suggestions={props.suggestions}
				onSuggestionsFetchRequested={(e) => props.onFetch(e.value)}
				onSuggestionsClearRequested={props.onClear}
				getSuggestionValue={getSuggestionValue}
				renderSuggestion={renderSuggestion}
				renderSuggestionsContainer={renderSuggestionsContainer}
				focusInputOnSuggestionClick={false}
				inputProps={{
					disabled: props.disabled,
					id: 'autocomplete-hook',
					placeholder: props.placeholder,
					autoComplete: props.autoComplete ? props.autoComplete : 'off',
					value,
					onChange: handleChange,
					onFocus: () => setInputHasFocus(true),
					onBlur: () => setInputHasFocus(false),
					className: classnames(S.autocompleteInput, {
						[S.autocompleteInputSelected]: inputHasFocus,
						[S.autocompleteInputUnselectedFilled]: !inputHasFocus && value !== '',
						[S.autocompleteInputUnselectedUnfilled]: !inputHasFocus && value === '',
						[S.autocompleteInputError]: !props.isOptional && !inputHasFocus && value === '',
					}),
				}}
			/>
		</>
	);
}

AutoComplete.defaultProps = {
	placeholder: '',
	noResultsText: '',
	shouldRenderAlert: false,
	isLoading: false,
	suggestionNotice: '',
	isOptional: true,
};
