import { getAllCustomers } from '@api/customers';
import ActionsMenuCustomers from '@components/buttons/ActionsMenuCustomers';
import { Loading } from '@components/layout';
import { LayoutFullWidth } from '@components/layout/Layout';
import { PlusIcon } from '@components/svg';
import { useLocale } from '@i18n';
import { ICustomer } from '@models';
import { ICustomerListPayload } from 'app/models/customers';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/buttons/Button';
import { ToastType, useToastContext } from '../../hooks/contexts/UseContext';
import { ERoutes, getPathByRouteName } from '../index';

import S from './styles/Customers.styl';

const Customers = () => {
    const tableRef: any = React.createRef();
    const T = useLocale();
    const history = useHistory();
    const addToast = useToastContext();
    const [customers, setCustomers] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const columns = [
        { title: T.CustomerTitleName, field: 'name', width: '150%' },
        { title: T.CustomerTitleEpicorCustId, field: 'epicorCustId', width: '150%' },
        { title: T.CustomerTitleType, field: 'customerType', width: '75%' },
        { title: T.CustomerTitleSalesrepCode, field: 'salesrepCode', width: '75%' },
        { title: T.CustomerTitleVille, field: 'city', width: '75%' },
        { title: T.CustomerTitlePays, field: 'country', width: '75%' },
        { title: T.CustomerTitlePhone, field: 'phoneNum', width: '75%' },
        { title: T.CustomerTitleTerms, field: 'terms', width: '75%' },
        { title: T.CustomerTitleActive, field: 'activatedLabel', width: '75%' },
        { title: '', field: 'actions', export: false, sorting: false, filtering: false, render: (customerRow: ICustomer) => (<div className={S.actionMenuContainer}> <ActionsMenuCustomers customer={customerRow} viewDetails /> </div>) },
    ];

    useEffect(() => {
        fetchCustomers();
    }, []);

    const fetchCustomers = () => {
        setIsLoading(true);
        getAllCustomers()
            .then((response) => {
                actualiserActifInactif(response.data);
                setCustomers(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                addToast({
                    text: T.GeneralFormSubmitMessageError,
                    type: ToastType.error,
                });
                setIsLoading(false);
            });
    };

    const handleOpen = () => {
        history.push(getPathByRouteName(ERoutes.AddCustomer, T.locale));
    };

    function actualiserActifInactif(data: ICustomer[]) {
        data.forEach((custommer: ICustomer) => {
            switch (T.locale) {
                case 'fr':
                    custommer.active
                        ? (custommer.activatedLabel = T.CustomerStatusActive)
                        : (custommer.activatedLabel = T.CustomerStatusInactive);
                    break;
                case 'en':
                    custommer.active
                        ? (custommer.activatedLabel = T.CustomerStatusActive)
                        : (custommer.activatedLabel = T.CustomerStatusInactive);
            }
        });
    }

    return (
        <>
            <LayoutFullWidth>
                <div className={S.customersHeadContainer}>
                    <h1>{T.MenuLinkLabelClients} </h1>
                    <Button icon={<PlusIcon />} onClick={() => handleOpen()}>
                        {T.GeneralCreate}
                    </Button></div>
                {isLoading ? (
                    <Loading />
                ) : (
                    <MaterialTable
                        title={''}
                        tableRef={tableRef}
                        data={customers}
                        columns={columns}
                        options={{
                            search: true,
                            filtering: true,
                            sorting: true,
                            exportButton: false,
                            searchFieldAlignment: 'left',
                            pageSizeOptions: [5, 10, 20, 100, 250],
                        }}
                    />
                )}
            </LayoutFullWidth>
        </>

    );
};

export default Customers;
