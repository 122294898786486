import { getCustomersGroups, getCustomersTerritoriesCAN, getCustomersTerritoriesUSA, PostCustomer } from '@api/customers';
import { authenticatedHeaders } from '@api/headers';
import { getCitiesForProvince } from '@api/index';
import { getSalesReps } from '@api/salesreps';
import { IDropdownOption } from '@components/inputs';
import Input, { ErrorPosition } from '@components/inputs/Input';
import { Loading } from '@components/layout';
import { LayoutFullWidth } from '@components/layout/Layout';
import { useLocale } from '@i18n';
import { Grid } from '@material-ui/core';
import { IContact, ICustomer } from '@models';
import { REGEX_EMAIL, REGEX_PHONE } from '@utils/constants';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { ERoutes, getPathByRouteName } from '..';
import { ToastType, useAuthContext, useToastContext } from '../../hooks/contexts/UseContext';

import S1 from '../project/styles/Project.styl';
import MUIContactsTable from './MUIContactsTable';
import S from './styles/CreateCustomer.styl';

const styleDropDown = {
    container: (provided: any, state: any) => ({
        ...provided,
        paddingTop: '8px',
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        width: '100%',
        minHeight: '46px',
        color: S.darkGray,
        fontSize: S.fontSizeNormal,
        fontWeight: S.fontWeightRegular as any,
        borderRadius: '8px',
        borderColor: S.blueGray50,
    }),
    menu: (provided: any, state: any) => ({
        ...provided,
        borderRadius: '8px',
    }),
    menuList: (provided: any, state: any) => ({
        ...provided,
        borderRadius: '8px',
        padding: 0,
        backgroundColor: 'white',
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? S.blueGray50ish : 'white',
        color: state.isDisabled
            ? S.lightGray
            : state.isSelected
                ? S.blueNorbec
                : state.isFocused
                    ? S.darkGray
                    : S.darkGray,
        borderBottom: `1px solid ${S.blueGray25}`,
    }),
    indicatorSeparator: (provided: any) => ({
        ...provided,
        display: 'none',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        transition: 'all .2s ease',
        transform: state.isFocused ? 'rotate(180deg)' : null,
    }),
};

export const CountryCodes = {
    CAN: 'CAN',
    USA: 'USA',
};

export const canadaProvinces = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK', 'NT', 'NU', 'YT'];
export const UsaStates = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

// 'US01', 'US02', 'US03', 'US04', 'US05', 'US06', 'US07', 'US11', 'US12', 'US13', 'US14', 'US15', 'US16', 'US17', 'US18', 'US19', 'US20', 'US21', 'US22', 'US24', 'US25',
export const UsaTerritories = ['USA'];

export default function CreateCustomer() {
    const T = useLocale();
    const addToast = useToastContext();
    const history = useHistory();
    const tableRef: any = React.createRef();

    const { user } = useAuthContext();

    const POST_CONTACT = `${ENV.apiURL}/api/v1/erp/custcnt`;

    const optionsPays = [
        { label: T.CreateCustomerOptionCAN, value: 'CAN' },
        { label: T.CreateCustomerOptionUSA, value: 'USA' },
    ];

    const optionsLangues = [
        { label: T.CreateCustomerOptionLangueFR, value: 'FR' },
        { label: T.CreateCustomerOptionLangueEN, value: 'EN' },
    ];

    const optionsPotentiels = [
        { label: T.CreateCustomerOptionPotentielA, value: 'A' },
        { label: T.CreateCustomerOptionPotentielB, value: 'B' },
    ];

    // customer num
    const [custNum, setCustNum] = useState<number>(0);

    // infos générales
    const [nomClient, setNomClient] = useState('');
    const [courrielClient, setCourrielClient] = useState('');
    const [telClient, setTelClient] = useState('');
    // const [faxClient, setFaxClient] = useState('');

    // Adresse
    const [adresse1Client, setAdresse1Client] = useState('');
    const [adresse2Client, setAdresse2Client] = useState('');
    const [paysClient, setPaysClient] = useState<any>(null);
    const [provinceClient, setProvinceClient] = useState<any>(null);
    const [optionsProvinces, setOptionsProvinces] = useState<IDropdownOption[]>([]);
    const [villeClient, setVilleClient] = useState<any>(null);
    const [optionsVilles, setOptionsVilles] = useState<IDropdownOption[]>([]);
    const [codePostalClient, setCodePostalClient] = useState('');

    // Détails
    const [territoireClient, setTerritoireClient] = useState<any>(null);
    const [territoiresCAN, setTerritoiresCAN] = useState<IDropdownOption[]>();
    const [territoiresUSA, setTerritoiresUSA] = useState<IDropdownOption[]>();
    const [vendeur, setVendeur] = useState<any>(null);
    const [optionsVendeurs, setOptionsVendeurs] = useState<IDropdownOption[]>([]);
    const [groupeIndustrie, setGroupeIndustrie] = useState<any>(null);
    const [optionsGrpIndustrie, setOptionsGrpIndustrie] = useState<IDropdownOption[]>([]);
    const [potentiel, setPotentiel] = useState<any>(null);
    const [langueCommunication, setLangueCommunication] = useState<any>(null);

    // Status
    const [type, setType] = useState(null);
    const [status, setStatus] = useState(null);
    const [creditHold, setCreditHold] = useState(null);

    // Contacts
    const [contacts, setContacts] = useState<IContact[]>([]);
    const [modalOptionsContacts, setModalOptionsContacts] = useState<IDropdownOption[]>([]);
    const [tableContacts, setTableContacts] = useState<any[]>([]);

    // Button Create
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [creationProcess, setCreationProcess] = useState<boolean>(false);

    const optionsTerritoires = [
        {
            label: 'Canada',
            options: territoiresCAN,
        },
        {
            label: 'USA',
            options: territoiresUSA,
        },
    ];

    function getStateOptions(): IDropdownOption[] {
        if (paysClient?.value === CountryCodes.CAN) {
            return canadaProvinces?.map((cityState) => {
                return {
                    label: T[cityState],
                    value: cityState,
                };
            });
        } else if (paysClient?.value === CountryCodes.USA) {
            return UsaStates?.map((cityState) => {
                return {
                    label: T[cityState],
                    value: cityState,
                };
            }) || [];
        } else {
            return [];
        }
    }

    useEffect(() => {
        fetchSalesReps();
        fetchCustomersGroups();
        fetchCustomersTerritoriesCAN();
        fetchCustomersTerritoriesUSA();
    }, []);

    useEffect(() => {
        setProvinceClient(null);
        setVilleClient(null);
        setTerritoireClient(null);
        setOptionsProvinces(getStateOptions());
    }, [paysClient]);

    useEffect(() => {
        setVilleClient(null);
        setOptionsVilles([]);
    }, [provinceClient]);

    const fetchSalesReps = () => {
        getSalesReps()
            .then((response) => {
                if (user.identityRole !== 'modlr_SuperAdmin' && user.identityRole !== 'modlr_SalesAdmin') {
                    const temp = [];
                    if (user.epicorSalesrepCode !== '') {
                        const salesReps = user.epicorSalesrepCode.split(';');
                        response.data.forEach((item) => salesReps.includes(item.id) ? temp.push(item) : '');
                    }
                    response.data = temp;
                }
                const optionList: IDropdownOption[] = [];
                response.data.map((item) =>
                    optionList.push({
                        label: `${item.id} - ${item.name}`,
                        value: item.id,
                    }),
                );
                setOptionsVendeurs(optionList);
            })
            .catch((error) => {
                addToast({
                    text: T.GeneralFormSubmitMessageError,
                    type: ToastType.error,
                });
            });
    };

    const fetchCustomersGroups = () => {
        getCustomersGroups()
            .then((response) => {
                const optionList: IDropdownOption[] = [];
                response.data.map((item) =>
                    optionList.push({
                        label: T.locale === 'fr' ? item.desc : item.descBil,
                        value: item.code,
                    }),
                );
                setOptionsGrpIndustrie(optionList);
            })
            .catch((error) => {
                addToast({
                    text: T.GeneralFormSubmitMessageError,
                    type: ToastType.error,
                });
            });
    };

    const fetchCustomersTerritoriesCAN = () => {
        const optionList: IDropdownOption[] = [];
        canadaProvinces?.map((item) => {
            optionList.push({
                label: T[item],
                value: item,
            });
        });
        optionList.push({
            label: T[`NDEF`],
            value: 'ND',
        });
        setTerritoiresCAN(optionList);
        // getCustomersTerritoriesCAN()
        //     .then((response) => {
        //         const optionList: IDropdownOption[] = [];
        //         response.data.filter((item) => {
        //             return item.territoryId !== 'Québec';
        //         }).map((item) =>
        //             optionList.push({
        //                 label: item.regionCode === 'ND' ? T[`NDEF`] : T[item.territoryId],
        //                 value: item.territoryId,
        //             }),
        //         );
        //         setTerritoiresCAN(optionList);
        //     })
        //     .catch((error) => {
        //         addToast({
        //             text: T.GeneralFormSubmitMessageError,
        //             type: ToastType.error,
        //         });
        //     });
    };

    const fetchCustomersTerritoriesUSA = () => {
        const optionList: IDropdownOption[] = [];
        UsaTerritories?.map((item) => {
            optionList.push({
                label: T[item],
                value: item,
            });
        });
        setTerritoiresUSA(optionList);
        // getCustomersTerritoriesUSA()
        //     .then((response) => {
        //         console.log(response.data);
        //         const optionList: IDropdownOption[] = [];
        //         response.data.map((item) =>
        //             optionList.push({
        //                 label: T[item.territoryId],
        //                 value: item.territoryId,
        //             }),
        //         );
        //         setTerritoiresUSA(optionList);
        //     })
        //     .catch((error) => {
        //         addToast({
        //             text: T.GeneralFormSubmitMessageError,
        //             type: ToastType.error,
        //         });
        //     });
    };

    const handleVendeurChange = (vendeur: any) => {
        setVendeur(vendeur);
    };

    const handleGroupeIndustrieChange = (group: any) => {
        setGroupeIndustrie(group);
    };

    const handlePaysClientChange = (pays: any) => {
        setPaysClient(pays);
    };

    function handleCityStateChange(e: any) {
        setProvinceClient(e);
    }

    function handleCityInputChange(e: any) {
        if (e !== null && villeClient === null) {
            getCitiesForProvince(provinceClient?.value, e, false, true).then((results) => {
                setOptionsVilles(formatCitiesForProvince(results));
            });
        }
    }

    function formatCitiesForProvince(results: any[]): IDropdownOption[] {
        return results?.map((ville) => {
            return {
                label: ville,
                value: ville,
            };
        }) || [];
    }

    function handleCityChange(e: any) {
        setVilleClient(e);
    }

    function handleTerritoryChange(e: any) {
        setTerritoireClient(e);
    }

    const handleClickImport = () => {
        // inputFile.current.click();

    };

    const onCancel = () => {
        history.push(getPathByRouteName(ERoutes.Customers, T.locale));
    };

    const getEpicorSalesrepCode = () => {
        let str = '';
        if (vendeur) {
            vendeur.map((item: IDropdownOption) => {
                str = str + item.value + ';';
            });
        }
        return str.substring(0, str.length - 1);
    };

    function getContactsToCreate(contactList: IContact[], custumerNum: number) {
        const contactsUpdated: IContact[] = [];
        contactList.map((contact: IContact) => {
            if (contact.active === true) {
                const newContact: any = {
                    custNum: 0,
                    name: '',
                    email: '',
                    phoneNum: '',
                    func: '',
                    shipToNum: '',
                };
                newContact.custNum = custumerNum;
                newContact.name = contact.name;
                newContact.email = contact.email;
                newContact.phoneNum = contact.phoneNum;
                newContact.func = contact.func;
                newContact.shipToNum = '';
                contactsUpdated.push(newContact);
            }
        });
        return contactsUpdated;
    }

    const createCustomer = (customer: ICustomer) => {
        setCreationProcess(true);
        PostCustomer(customer)
            .then((response) => {
                addToast({
                    text: T.GeneralFormSubmitMessageSuccess,
                    type: ToastType.success,
                });
                if (tableContacts.filter((contact) => contact.active === true).length === 0) { setCreationProcess(false); }
                // setTimeout(() => {
                setCustNum(response.epicorCustNum);
                // }, 2000);
            })
            .catch((error) => {
                setCreationProcess(false);
                addToast({
                    text: T.GeneralFormSubmitMessageError,
                    type: ToastType.error,
                });
            });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const newClient: ICustomer = {
            name: nomClient,
            email: courrielClient,
            phoneNum: telClient,
            fax: '',
            street: adresse1Client,
            street2: adresse2Client,
            country: paysClient?.value,
            state: provinceClient?.value,
            city: villeClient?.value,
            zip: codePostalClient,
            territoryId: territoireClient?.value,
            salesrepCode: vendeur?.length > 1 ? getEpicorSalesrepCode() : vendeur?.value,
            groupCode: groupeIndustrie?.value,
            priceClass: potentiel?.value,
            lang: langueCommunication?.value,
        };
        createCustomer(newClient);
    };

    function postWithReflexion(url, contacts, retryLimit, retryCount) {
        return fetch(url, { method: 'POST', headers: authenticatedHeaders(), body: JSON.stringify(contacts[0]) }).then(() => {
            if (retryCount < retryLimit) {
                contacts.shift();
                return postWithReflexion(url, contacts, retryLimit, retryCount + 1);
            }
        });
    }

    useEffect(() => {
        if (custNum !== 0) {
            const lescontacts = getContactsToCreate(tableContacts, custNum);

            if (lescontacts.length > 0) {
                setCreationProcess(true);
                postWithReflexion(POST_CONTACT, lescontacts, lescontacts.length, 1).then(() => {
                    setCreationProcess(false);
                    addToast({
                        text: T.GeneralFormSubmitMessageSuccess,
                        type: ToastType.success,
                    });
                }).catch(() => {
                    setCreationProcess(false);
                    addToast({
                        text: T.GeneralFormSubmitMessageError,
                        type: ToastType.error,
                    });
                }).finally(() => {
                    setTimeout(() => {
                        history.push(
                            getPathByRouteName(ERoutes.Customers, T.locale),
                        );
                    }, 2000);
                });
            } else {
                setTimeout(() => {
                    history.push(
                        getPathByRouteName(ERoutes.Customers, T.locale),
                    );
                }, 2000);
            }
        }
    }, [custNum]);

    const handleMuiContactsTableCb = (contacts: any[]) => {
        setTableContacts(contacts);
    };

    useEffect(() => {
        if (nomClient && courrielClient && telClient && adresse1Client && paysClient && provinceClient && villeClient && vendeur && groupeIndustrie && potentiel && langueCommunication && tableContacts.filter((contact) => contact.active === true).length !== 0) {
            setIsDisabled(false);
        } else { setIsDisabled(true); }
    }, [nomClient, courrielClient, telClient, adresse1Client, paysClient, provinceClient, villeClient, vendeur, groupeIndustrie, potentiel, langueCommunication, tableContacts]);

    if (!optionsVendeurs && !territoiresUSA && !territoiresCAN && !optionsGrpIndustrie) {
        return <Loading />;
    }

    if (creationProcess) {
        return <Loading />;
    }

    return (
        <LayoutFullWidth>
            <h1>{T.CreateCustomerTitle}</h1>
            <div className={S1.projectContainer}>
                <div className={S.createCustomerDescription}>
                    {T.CreateCustomerDescription}
                </div>
                <h3>{T.CreateCustomerLabelGeneralInfos}</h3>
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} sm={12} md={6} style={{ padding: '10px', paddingBottom: '0px' }}>
                        <Input
                            className={'admin-client'}
                            onChange={(e) => setNomClient(e?.target.value !== '' ? e?.target.value : '')}
                            autoComplete={nomClient}
                            labelText={T.CreateCustomerInputName}
                            placeHolder=""
                            inputType="text"
                            name="nomClient"
                            showOptionalLabel={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} style={{ padding: '10px', paddingBottom: '0px' }}>
                        <Input
                            className={'admin-client'}
                            onChange={(e) => setCourrielClient(e?.target.value !== '' ? e?.target.value : '')}
                            autoComplete={courrielClient}
                            labelText={T.CreateCustomerInputEmail}
                            placeHolder=""
                            inputType="email"
                            errorMessage={
                                T.QuoteClientSectionEmailInputError
                            }
                            errorMessagePosition={ErrorPosition.BOTTOM}
                            validationRegex={REGEX_EMAIL}
                            name="courrielClient"
                            isOptional
                            showOptionalLabel={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} style={{ padding: '10px', paddingBottom: '0px' }}>
                        <Input
                            className={'admin-client'}
                            onChange={(e) => setTelClient(e?.target.value !== '' ? e?.target.value : '')}
                            autoComplete={telClient}
                            labelText={T.CreateCustomerInputPhoneNum}
                            placeHolder="000-000-0000"
                            inputType="tel"
                            errorMessage={T.CreateCustomerPhoneInputError}
                            errorMessagePosition={ErrorPosition.BOTTOM}
                            validationRegex={REGEX_PHONE}
                            timeoutMs={700}
                            name="telephoneClient"
                            isOptional
                            showOptionalLabel={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} style={{ padding: '10px', paddingBottom: '0px' }}>
                        {/* <Input
                            className={'admin-client'}
                            onChange={(e) => setFaxClient(e?.target.value !== '' ? e?.target.value : '')}
                            autoComplete={faxClient}
                            labelText={T.CreateCustomerInputFax}
                            placeHolder="000-000-0000"
                            inputType="tel"
                            errorMessage={T.CreateCustomerPhoneInputError}
                            errorMessagePosition={ErrorPosition.BOTTOM}
                            validationRegex={REGEX_PHONE}
                            name="faxClient"
                            isOptional
                            showOptionalLabel={true}
                        /> */}
                    </Grid>
                </Grid>
                <h3>{T.CreateCustomerLabelAdresse}</h3>
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} sm={12} md={6} style={{ padding: '10px', paddingBottom: '0px' }}>
                        <Input
                            className={'admin-client'}
                            onChange={(e) => setAdresse1Client(e?.target.value !== '' ? e?.target.value : '')}
                            autoComplete={adresse1Client}
                            labelText={T.CreateCustomerInputStreet1}
                            placeHolder=""
                            inputType="text"
                            name="adresse1Client"
                            isOptional
                            showOptionalLabel={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} style={{ padding: '10px', paddingBottom: '0px' }}>
                        <Input
                            className={'admin-client'}
                            onChange={(e) => setAdresse2Client(e?.target.value !== '' ? e?.target.value : '')}
                            autoComplete={adresse2Client}
                            labelText={T.CreateCustomerInputStreet2}
                            placeHolder=""
                            inputType="text"
                            name="adresse2Client"
                            isOptional
                            showOptionalLabel={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} style={{ padding: '10px', paddingBottom: '0px' }}>
                        <b>{T.CreateCustomerInputCountry}</b>
                        <Select
                            styles={styleDropDown}
                            placeholder={T.CreateProjectPlaceholder}
                            value={paysClient}
                            options={optionsPays}
                            onChange={handlePaysClientChange}
                            getOptionLabel={(x) => x.label}
                            getOptionValue={(x) => x.value}
                            isDisabled={false}
                        />
                        <div style={{ paddingTop: '20px' }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} style={{ padding: '10px', paddingBottom: '0px' }}>
                        <b>{T.CreateCustomerInputProvince}</b>
                        <Select
                            styles={styleDropDown}
                            placeholder={T.CreateProjectPlaceholder}
                            value={provinceClient}
                            options={optionsProvinces}
                            onChange={handleCityStateChange}
                            getOptionLabel={(x) => x.label}
                            getOptionValue={(x) => x.value}
                            isDisabled={paysClient === null}
                        />
                        <div style={{ paddingTop: '20px' }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} style={{ padding: '10px', paddingBottom: '0px' }}>
                        <b>{T.CreateCustomerInputCity}</b>
                        <Select
                            styles={styleDropDown}
                            placeholder={T.CreateProjectPlaceholder}
                            value={villeClient}
                            options={optionsVilles}
                            onInputChange={handleCityInputChange}
                            onChange={handleCityChange}
                            getOptionLabel={(x) => x.label}
                            getOptionValue={(x) => x.value}
                            isDisabled={provinceClient === null}
                        />
                        <div style={{ paddingTop: '20px' }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} style={{ padding: '10px', paddingBottom: '0px' }}>
                        <Input
                            className={'admin-client'}
                            onChange={(e) => setCodePostalClient(e?.target.value !== '' ? e?.target.value : '')}
                            autoComplete={codePostalClient}
                            labelText={T.CreateCustomerInputCodePostal}
                            placeHolder=""
                            inputType="text"
                            name="codePostalClient"
                            isOptional
                            showOptionalLabel={true}
                        />
                    </Grid>
                </Grid>
                <h3>{T.CreateCustomerLabelDetails}</h3>
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} sm={12} md={6} style={{ padding: '10px', paddingBottom: '0px' }}>
                        <b>{T.CreateCustomerInputTerritory}</b>
                        <Select
                            styles={styleDropDown}
                            placeholder={T.CreateProjectPlaceholder}
                            value={territoireClient}
                            options={optionsTerritoires}
                            onChange={handleTerritoryChange}
                            getOptionLabel={(x) => x.label}
                            getOptionValue={(x) => x.value}
                            isDisabled={false}
                        />
                        <div style={{ paddingTop: '20px' }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} style={{ padding: '10px', paddingBottom: '0px' }}>
                        <b>{T.CreateCustomerInputSalesRep}</b>
                        <Select
                            // isMulti
                            styles={styleDropDown}
                            placeholder={T.CreateProjectPlaceholder}
                            value={vendeur}
                            options={optionsVendeurs}
                            onChange={handleVendeurChange}
                            getOptionLabel={(x) => x.label}
                            getOptionValue={(x) => x.value}
                            isDisabled={false}
                        />
                        <div style={{ paddingTop: '20px' }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} style={{ padding: '10px', paddingBottom: '0px' }}>
                        <b>{T.CreateCustomerInputGrpIndustrie}</b>
                        <Select
                            styles={styleDropDown}
                            placeholder={T.CreateProjectPlaceholder}
                            value={groupeIndustrie}
                            options={optionsGrpIndustrie}
                            onChange={handleGroupeIndustrieChange}
                            getOptionLabel={(x) => x.label}
                            getOptionValue={(x) => x.value}
                            isDisabled={false}
                        />
                        <div style={{ paddingTop: '20px' }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} style={{ padding: '10px', paddingBottom: '0px' }}>
                        <b>{T.CreateCustomerInputPotentiel}</b>
                        <Select
                            styles={styleDropDown}
                            placeholder={T.CreateProjectPlaceholder}
                            value={potentiel}
                            options={optionsPotentiels}
                            onChange={(potentiel) => setPotentiel(potentiel)}
                            getOptionLabel={(x) => x.label}
                            getOptionValue={(x) => x.value}
                            isDisabled={false}
                        />
                        <div style={{ paddingTop: '20px' }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} style={{ padding: '10px', paddingBottom: '0px' }}>
                        <b>{T.CreateCustomerInputLangue}</b>
                        <Select
                            styles={styleDropDown}
                            placeholder={T.CreateProjectPlaceholder}
                            value={langueCommunication}
                            options={optionsLangues}
                            onChange={(langue) => setLangueCommunication(langue)}
                            getOptionLabel={(x) => x.label}
                            getOptionValue={(x) => x.value}
                            isDisabled={false}
                        />
                        <div style={{ paddingTop: '20px' }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} style={{ padding: '10px', paddingBottom: '0px' }} />
                </Grid>
                <Grid container >
                    <MUIContactsTable mode={'add'} contactList={tableContacts} refreshContacts={(listContacts: any[]) => handleMuiContactsTableCb(listContacts)} />
                </Grid>
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} sm={12} md={12} style={{ padding: '10px', paddingBottom: '0px' }} />
                    <Grid item xs={12} sm={12} md={8} style={{ padding: '10px', paddingBottom: '0px' }} />
                    <Grid item xs={12} sm={12} md={2} style={{ padding: '10px', paddingBottom: '0px' }}>
                        <button
                            className={S.buttonWhite}
                            onClick={onCancel}
                        >
                            {T.GeneralCancel}
                        </button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} style={{ padding: '10px', paddingBottom: '0px' }}>
                        <button
                            className={S.buttonBlue}
                            onClick={onSubmit}
                            disabled={isDisabled}
                        >
                            {T.GeneralAdd}
                        </button>
                    </Grid>
                </Grid>
            </div>
        </LayoutFullWidth>
    );
}
