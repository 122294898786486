import { Position, State } from '@models';
import React from 'react';
import DrawingButton, { DrawingButtonType } from './DrawingButton';
import S from './styles/DrawingButtons.styl';

interface IDrawingButtonsProps {
	onZoomInClicked?: () => void;
	onZoomOutClicked?: () => void;
	onCenterClicked?: () => void;
	state: State;
}

export default function DrawingButtons(props: IDrawingButtonsProps) {
	return (
		<div className={S.drawing_buttons}>
			<DrawingButton
				onClick={() => props.onZoomInClicked && props.onZoomInClicked()}
				state={props.state}
				position={Position.Start}
				type={DrawingButtonType.ZoomIn}
			/>
			<DrawingButton
				onClick={() => props.onZoomOutClicked && props.onZoomOutClicked()}
				state={props.state}
				position={Position.Middle}
				type={DrawingButtonType.ZoomOut}
			/>
			<DrawingButton
				onClick={() => props.onCenterClicked && props.onCenterClicked()}
				state={props.state}
				position={Position.End}
				type={DrawingButtonType.Center}
			/>
		</div>
	);
}
