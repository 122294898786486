import React from 'react';

interface ILargeUncheckedCheckboxProps {
	stroke: string;
}

export default function LargeUncheckedCheckbox(props: ILargeUncheckedCheckboxProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="39" height="38" viewBox="0 0 39 38">
			<rect width="33" height="35" fill="#FFF" fillRule="evenodd" stroke={props.stroke} rx="4" transform="translate(2 1.949)"/>
		</svg>
	);
}
