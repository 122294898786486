import NavLinkButton from '@components/buttons/NavLinkButton';
import Copyright from '@components/labels/Copyright';
import { DetailIcon, ListIcon, NNorbecIcon, NorbecLogoSmall } from '@components/svg';
import { getCurrentLang, useLocale } from '@i18n';
import PeopleIcon from '@material-ui/icons/People';
import classnames from 'classnames';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ERoutes, getPathByRouteName, getPathForLanguageSwitch } from '../../routes';
import S from './styles/NavSidebarDrawer.styl';
interface INavSidebarDrawerProps {
	onToggleSideNavClick: () => void;
	isMenuVisible: boolean;
}

export default function NavSidebarDrawer(props: INavSidebarDrawerProps) {
	const T = useLocale();

	return (
		<>
			<div
				className={classnames(S.navSidebarDrawer, {
					[S.navSidebarDrawerOpen]: props.isMenuVisible,
				})}
			>
				<div className={S.navSidebarDrawerContent}>
					<div className={S.navSidebarDrawerContentFakeDiv} />
					<div className={S.navSidebarDrawerContentMainLinksContainer}>
						<NavLinkButton
							className={S.navSidebarDrawerContentMainLinksContainerItem}
							icon={<ListIcon />}
							title={T.MenuLinkLabelConfiguration}
							urlTo={getPathByRouteName(ERoutes.Home, T.locale)}
						/>
						<NavLinkButton
							className={S.navSidebarDrawerContentMainLinksContainerItem}
							icon={<PeopleIcon />}
							title={T.MenuLinkLabelClients}
							urlTo={getPathByRouteName(ERoutes.Customers, T.locale)}
						/>
						<NavLinkButton
							className={S.navSidebarDrawerContentMainLinksContainerItem}
							icon={<DetailIcon />}
							title={T.MenuLinkLabelFaq}
							urlTo={getPathByRouteName(ERoutes.Faq, T.locale)}
						/>
					</div>

					<div className={S.navSidebarDrawerContentLinksContainer}>
						<div className={S.navSidebarDrawerContentLinksContainerItem}>
							<NavLink to={getPathByRouteName(ERoutes.Terms, T.locale)} className={S.navSidebarDrawerContentLinksContainerItemLink}>
								{T.MenuLinkLabelCondition}
							</NavLink>
						</div>
						<div className={S.navSidebarDrawerContentLinksContainerItem}>
							<a href={getPathForLanguageSwitch()} className={S.navSidebarDrawerContentLinksContainerItemLink}>
								{getCurrentLang() === 'fr' ? T.MenuLinkLabelLangEn : T.MenuLinkLabelLangFr}
							</a>
						</div>
					</div>
				</div>
				<div className={S.navSidebarDrawerFooter}>
					<Link to={'/'}>
						<NorbecLogoSmall />
					</Link>
					<Copyright />
				</div>
			</div>

			{props.isMenuVisible && (
				<div className={S.appOverlay} onClick={props.onToggleSideNavClick} />
			)}
		</>
	);
}
