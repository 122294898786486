import classnames from 'classnames';
import React from 'react';
import S from './styles/LeftSectionLayoutHalfAndHalf.styl';

export enum SectionType {
	AUTH,
	CONTACT,
}

interface ILeftSectionLayoutHalfAndHalfProps {
	children?: React.ReactElement;
	sectionType: SectionType;
}

export default function LeftSectionLayoutHalfAndHalf(props: ILeftSectionLayoutHalfAndHalfProps) {
	return (
		<div
			className={classnames(S.leftSectionContainer, {
				[S.leftSectionContainerAuth]: props.sectionType === SectionType.AUTH,
				[S.leftSectionContainerContact]: props.sectionType === SectionType.CONTACT,
			}) }
		>
			{props.children && (
				props.children
			)}
		</div>
	);
}

LeftSectionLayoutHalfAndHalf.defaultProps = {
	sectionType: SectionType.AUTH,
};
