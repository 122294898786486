import React from 'react';
import S from './styles/BackRoundedLabel.styl';

interface IBackRoundedLabelProps {
	text: string;
}

export default function BackRoundedLabel(props: IBackRoundedLabelProps) {
	return (
		<div className={S.backRoundedLabel}>
			{props.text}
		</div>
	);
}
