import React from 'react';
import GS from '../../styles/global.styl';

interface ITransportPickUpIconProps {
	fill: string;
}

export default function TransportPickUpIcon(props: ITransportPickUpIconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g className="svg-icon-fill-color" fill={props.fill} fillRule="evenodd">
				<path d="M6.5 17C7.88 17 9 18.12 9 19.5S7.88 22 6.5 22 4 20.88 4 19.5 5.12 17 6.5 17zm13 0c1.38 0 2.5 1.12 2.5 2.5S20.88 22 19.5 22 17 20.88 17 19.5s1.12-2.5 2.5-2.5zm-13 1c-.828 0-1.5.672-1.5 1.5S5.672 21 6.5 21 8 20.328 8 19.5 7.328 18 6.5 18zm13 0c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5zm3-16c.806 0 1.427.705 1.494 1.568l.006.154V15.5c0 .276-.224.5-.5.5-.245 0-.45-.177-.492-.41L23 15.5V14H10v3.1c0 .276-.224.5-.5.5-.245 0-.45-.177-.492-.41L9 17.1V8H7v3.5c0 .78-.595 1.42-1.356 1.493L5.5 13H1v1.5h1.5c.276 0 .5.224.5.5 0 .245-.177.45-.41.492l-.09.008H1v2.8c0 .364.2.64.424.691L1.5 19h1c.276 0 .5.224.5.5 0 .245-.177.45-.41.492L2.5 20h-1c-.803 0-1.427-.695-1.494-1.548L0 18.3v-7.2c0-2.176 1.45-3.984 3.318-4.095L3.5 7H9V3.722c0-.88.577-1.634 1.36-1.715L10.5 2h12zM15 19c.276 0 .5.224.5.5 0 .245-.177.45-.41.492L15 20h-4.5c-.276 0-.5-.224-.5-.5 0-.245.177-.45.41-.492L10.5 19H15zm7.5-16h-12c-.229 0-.455.251-.494.611L10 3.722V13h13V3.722c0-.338-.162-.601-.357-.69l-.075-.025L22.5 3zM6 8H3.5c-1.308 0-2.415 1.27-2.495 2.91L1 11.1v.9h4.5c.245 0 .45-.177.492-.41L6 11.5V8zm10.5-1c.513 0 .936.386.993.883L17.5 8v3h1c.276 0 .5.224.5.5 0 .245-.177.45-.41.492L18.5 12h-3c-.276 0-.5-.224-.5-.5 0-.245.177-.45.41-.492L15.5 11h1V8h-1c-.276 0-.5-.224-.5-.5 0-.245.177-.45.41-.492L15.5 7h1zm-.25-2.5l.102.007c.366.05.648.363.648.743 0 .414-.336.75-.75.75s-.75-.336-.75-.75c0-.38.282-.693.648-.743l.102-.007z"/>
			</g>
		</svg>

	);
}

TransportPickUpIcon.defaulProps = {
	fill: GS.darkBlueGray,
};
