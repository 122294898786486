import React from 'react';

interface IExpandIconProps {
	stroke: string;
}

export default function ExpandIcon(props: IExpandIconProps) {
	return (
		<svg width="11px" height="11px" viewBox="0 0 11 11" version="1.1">
			<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
				<g className="svg-stroke-color" id="ico-expand-small" stroke={props.stroke}>
					<g id="expand-5" transform="translate(0.500000, 0.500000)">
						<rect id="Rectangle" x="2.75" y="2.70791667" width="4.5" height="4.5" rx="1"/>
						<g id="Group">
							<line x1="0.208333333" y1="0.207916667" x2="1.79932359" y2="1.79890692" id="Path"/>
							<polyline id="Path" points="0 2 0 0 2 0"/>
						</g>
						<g id="Group-Copy-2" transform="translate(1.000000, 9.000000) scale(1, -1) translate(-1.000000, -9.000000) translate(0.000000, 8.000000)">
							<line x1="0.208333333" y1="0.207916667" x2="1.79932359" y2="1.79890692" id="Path"/>
							<polyline id="Path" points="0 2 0 0 2 0"/>
						</g>
						<g id="Group-Copy" transform="translate(9.000000, 1.000000) scale(-1, 1) translate(-9.000000, -1.000000) translate(8.000000, 0.000000)">
							<line x1="0.208333333" y1="0.207916667" x2="1.79932359" y2="1.79890692" id="Path"/>
							<polyline id="Path" points="0 2 0 0 2 0"/>
						</g>
						<g id="Group-Copy-3" transform="translate(9.000000, 9.000000) scale(-1, -1) translate(-9.000000, -9.000000) translate(8.000000, 8.000000)">
							<line x1="0.208333333" y1="0.207916667" x2="1.79932359" y2="1.79890692" id="Path"/>
							<polyline id="Path" points="0 2 0 0 2 0"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
