import React from 'react';

interface ILargeCheckedCheckboxProps {
	stroke: string;
	fill: string;
	opacity?: number;
}

export default function LargeCheckedCheckbox(props: ILargeCheckedCheckboxProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="39" height="38" viewBox="0 0 39 38">
			<g fill="none" fillRule="evenodd" stroke={props.stroke} opacity={props.opacity || 1}>
				<rect width="33" height="35" x="2" y="2" fill={props.fill} fillOpacity=".248" rx="4"/>
				<path strokeWidth="4" d="M26.9 12.222L16.472 25.556 10.1 20.654"/>
			</g>
		</svg>
	);
}
