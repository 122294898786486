import classnames from 'classnames';
import React, { useState } from 'react';

import S from './styles/Burger.styl';

interface IBurgerProps {
	readonly isOpen?: boolean;
	readonly onClick?: () => void;
}

export default function Burger({ onClick, isOpen = false }: IBurgerProps) {
	const [isHover, setIsHover] = useState<boolean>(false);

	return (
		<button
			onClick={onClick}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
			className={S.burger}
		>
			<div
				className={classnames(S.burgerLines, {
					[S.burgerLinesOpen]: isOpen,
					[S.burgerLinesHover]: isHover,
				})}
			/>
		</button>
	);
}
