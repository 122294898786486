import { AutoComplete, IDropdownOption, ISuggestion, SimpleDropdown } from '@components/inputs';
import { LargeRadioButton } from '@components/svg';
import { useLocale } from '@i18n';
import { ICityViewModel, State } from '@models';
import { useStore } from '@store';
import classnames from 'classnames';
import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { CountryCodes } from '../../routes/size-it/steps/InstallationLocationStep';
import { AnimationSettings } from '../../utils/animation';
import S from './styles/LocationRadio.styl';

interface ILocationRadioProps {
	disabled: boolean;
	country: string;
	countryLabel: string;
	cityStates?: string[];
	cities?: ICityViewModel[];
	onCountrySelected?: () => void;
	onCityStateSelected: (cityState: string) => void;
	onCitySelected?: (city: ICityViewModel) => void;
	onCityFetch?: (city: string) => void;
	onCityClear?: () => void;
	classname?: string;
	state: State;
	isLoading: boolean;
}

export default function LocationRadio(props: ILocationRadioProps) {
	const { state } = useStore();
	const T = useLocale();
	const [city, setCity] = useState<ICityViewModel | undefined>(state.project.installationLocation?.city);

	const {
		onCitySelected = (c: ICityViewModel) => void(0),
		onCityFetch = (c: string) => void(0),
		onCityClear = () => void(0),
		cities = [],
	} = props;

	function getStateOptions(): IDropdownOption[] {
		return props.cityStates?.map((cityState) => {
			return {
				label: T[cityState],
				value: cityState,
			};
		}) || [];
	}

	function handleCityChange(value: ISuggestion) {
		setCity(value);
		onCitySelected(value);
	}

	function handleCityStateChange(e: any) {
		props.onCityStateSelected(e);
		setCity(undefined);
	}

	return (
		<div
			className={classnames(S.locationRadio, props.classname, {
				[S.locationRadioFocused]: props.state === State.Focused,
				[S.locationRadioSelected]: props.state === State.Selected,
				[S.locationRadioLocked]: props.disabled,
			})}
			onClick={props.disabled ? () => void(0) : props.onCountrySelected}
		>
			<div className={S.locationRadioContent}>
				<div
					className={classnames(S.locationRadioContentTitle, {
						[S.locationRadioContentTitleFocused]: props.state === State.Focused,
						[S.locationRadioContentTitleSelected]: props.state === State.Selected,
					})}
				>
					<div>
						{props.countryLabel}
					</div>

					<div className={S.locationRadioButton}>
						{(props.state === State.Enabled || !props.state) &&
							<LargeRadioButton stroke={S.darkBlueGray} fill={S.white} opacity={props.disabled ? '0.5' : '1'}/>
						}
						{props.state === State.Focused &&
							<LargeRadioButton stroke={S.orange} fill={S.orange} opacity={props.disabled ? '0.5' : '1'}/>
						}
						{props.state === State.Selected &&
							<LargeRadioButton stroke={S.blueNorbec} fill={S.blueNorbec} opacity={props.disabled ? '0.5' : '1'}/>
						}
					</div>
				</div>
					{props.country === state.project.installationLocation?.country && (
						<div className={S.locationRadioContentDescription}>
							<CSSTransition
								in={props.cityStates && !!state.project.installationLocation?.country}
								timeout={AnimationSettings.FADE_DELAY}
								classNames={S.locationRadioDropdownContainer}
								appear
								mountOnEnter
								unmountOnExit
							>
								<div className={S.locationRadioDropdownContainer} onClick={(e) => e.stopPropagation()}>
									<SimpleDropdown
										options={getStateOptions()}
										placeholder={T.InstallationLocationSelectLabel}
										label={props.country === CountryCodes.CAN ? T.InstallationLocationProvince : T.InstallationLocationState}
										value={state.project.installationLocation?.cityState}
										onSelect={handleCityStateChange}
										disabled={props.disabled}
									/>
								</div>
							</CSSTransition>

							<CSSTransition
								in={!!state.project.installationLocation?.cityState}
								timeout={AnimationSettings.FADE_DELAY}
								classNames={S.locationRadioDropdownContainer}
								mountOnEnter
								unmountOnExit
							>
								<div className={S.locationRadioDropdownContainer} onClick={(e) => e.stopPropagation()}>
									<AutoComplete
										disabled={props.disabled}
										alert={T.InstallationLocationNoResultAlert}
										alertState={props.state}
										shouldRenderAlert={!!state.project.installationLocation.city && !state.layout.requirements.installPriceAuto}
										label={T.InstallationLocationCity}
										noResultsText={T.InstallationLocationNoResultDropdown}
										value={city}
										onChange={handleCityChange}
										suggestions={cities}
										onFetch={onCityFetch}
										onClear={onCityClear}
										isLoading={props.isLoading}
									/>
								</div>
							</CSSTransition>
						</div>
					)}
			</div>
		</div>
	);
}

LocationRadio.defaultProps = {
	cityState: '',
	city: '',
	disabled: false,
	isLoading: false,
	onCityStateSelected: () => void(0),
};
