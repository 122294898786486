import React from 'react';

interface IUncheckedCheckbox {
	stroke: string;
}

export default function UncheckedCheckbox(props: IUncheckedCheckbox) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21">
			<rect width="18.182" height="18.261" x=".909" y=".913" fill="#FFF" fillRule="evenodd" stroke={props.stroke} rx="4"/>
		</svg>
	);
}
