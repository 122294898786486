type Country = 'CAN' | 'USA';
type StateCode =
	| 'AB'
	| 'BC'
	| 'MB'
	| 'NB'
	| 'NL'
	| 'NT'
	| 'NS'
	| 'NU'
	| 'ON'
	| 'PE'
	| 'QC'
	| 'SK'
	| 'YT'
	| undefined;

export interface ICity {
	id: number;
	name: string;
	state: StateCode;
	country?: Country;
	deliveryAvail?: boolean;
	installAvail?: boolean;
}

export type ICityViewModel = string;

export function fromCitiesDTO(cities: ICity[]): ICityViewModel[] {
	return cities.map((city) => fromCityDTO(city));
}

export function fromCityDTO(city: ICity): ICityViewModel {
	return city.name;
}
