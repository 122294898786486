import React from 'react';

interface IArrowRightIcon {
	stroke: string;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
}

export default function ArrowRightIcon(props: IArrowRightIcon) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
			<g fill="none" fillRule="evenodd" stroke={props.stroke} strokeLinecap="round" strokeLinejoin="round">
				<path d="M6.923 14.423l7.487-6.61M.577 7.788h13.269M6.634.878l7.789 6.923"/>
			</g>
		</svg>
	);
}
