import { IFilterProjects, IProject, IProjectsData } from '@models';
import axiosInstance from './axiosInstance';

const GET_PROJECTS = `/app/project/search`;
const GET_PROJECT = (id: number) => `/app/project/${id}`;
const PUT_PROJECT = `/app/project`;
const POST_PROJECT = `/app/project`;
const EXTEND_PROJECT = (id: number) => `/app/project/extend/${id}`;
const ARCHIVE_PROJECT = (id: number, isArchived: boolean) => `/app/project/archived/${id}/${isArchived}`;

export async function getProjects(body: IFilterProjects | undefined): Promise<IProjectsData> {
	const response = await axiosInstance.get(GET_PROJECTS, { params: body });
	return response.data;
}

export async function getProject(id: number): Promise<IProject> {
	const response = await axiosInstance.get(GET_PROJECT(id));
	return response.data;
}

export async function putProject(body: IProject | any): Promise<IProject> {
	const response = await axiosInstance.put(PUT_PROJECT, body );
	return response.data;
}

export async function postProject(body: IProject | any): Promise<IProject> {
	const response = await axiosInstance.post(POST_PROJECT, body );
	return response.data;
}

export async function postExtendProject(id: number): Promise<any> {
	const response = await axiosInstance.post(EXTEND_PROJECT(id), {});
	return response.data;
}

export async function archiveProject(id: number, isArchived: boolean): Promise<any> {
	const response = await axiosInstance.put(ARCHIVE_PROJECT(id, isArchived));
	return response.data;
}
