import { IFloorMaterialState } from '@models';
import { State } from './state';

export interface IFloorMaterial {
	id: FloorMaterialType;
	name: string;
	isIncluded: boolean;
	description?: string;
	price?: number;
	unit?: string;
}

export enum FloorMaterialType {
	GalvanisedSteel = 1,
	Aluminium = 2,
}

export function fromFloorMaterialsDTO(floorMaterials: IFloorMaterial[]): IFloorMaterialState[] {
	return floorMaterials.map((floorMaterial) => {
		return fromFloorMaterialDTO(floorMaterial);
	});
}

export function fromFloorMaterialDTO(floorMaterial: IFloorMaterial): IFloorMaterialState {
	return {
		id: floorMaterial.id,
		name: floorMaterial.name,
		isIncluded: floorMaterial.isIncluded,
		description: floorMaterial.description,
		price: floorMaterial.price,
		unit: floorMaterial.unit,
		state: State.Enabled,
	};
}

export function toFloorMaterialDTO(floorMaterialState: IFloorMaterialState): IFloorMaterial {
	return {
		id: floorMaterialState.id,
		name: floorMaterialState.name,
		isIncluded: floorMaterialState.isIncluded,
		description: floorMaterialState.description,
		price: floorMaterialState.price,
		unit: floorMaterialState.unit,
	};
}
