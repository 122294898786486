import { II3OptionState } from './store';

export interface II3Option {
	id: number;
	name: string;
	description: string;
	price?: number;
	unit?: string;
	technicalSheetUrl: string;
	imageUrl: string;
}

export function fromI3OptionsDTO(i3Options: II3Option[]): II3OptionState {
	const option = i3Options[0];
	return {
		id: option.id,
		title: option.name,
		price: option.price,
		unit: option.unit,
		description: option.description,
		technicalSheetUrl: option.technicalSheetUrl,
		imageUrl: option.imageUrl,
	};
}

export function toI3OptionDTO(i3OptionState: II3OptionState): II3Option {
	return {
		id: i3OptionState.id,
		name: i3OptionState.title,
		price: i3OptionState.price,
		unit: i3OptionState.unit,
		description: i3OptionState.description,
		technicalSheetUrl: i3OptionState.technicalSheetUrl,
		imageUrl: i3OptionState.imageUrl,
	};
}
