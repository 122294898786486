import { LinkWrapper } from '@components/layout';
import { Chevron, ChevronDirection } from '@components/svg';
import SmallCheckmark from '@components/svg/SmallCheckmark';
import { useLocale } from '@i18n';
import { IDrawerSectionDefinition, Steps, StepState } from '@models';
import { useStore } from '@store';
import BrowserUtils from '@utils/browser';
import { getCurrentStepUrl, isNextStep, shouldSkipNavSection, shouldSkipStep } from '@utils/navigation';
import classnames from 'classnames';
import React, { useState } from 'react';

import AppStyle from '../../routes/size-it/styles/SizeIt.styl';
import S from './styles/TabDrawer.styl';
import Summary from './Summary';

interface ITabDrawerProps {
	readonly isFolded: boolean;
	readonly foldedOnClick: Function;
	readonly drawerExpanded: boolean;
	readonly onClick: () => void;
	readonly drawerTopOffset: number;
	readonly title: string;
	readonly sectionNumber: number;
	readonly routes: IDrawerSectionDefinition[];
	readonly showInPage: boolean;
}

interface IDrawerStepProps {
	readonly title: string;
	readonly step: Steps;
	readonly stepState: StepState;
	readonly url: string;
	readonly projectId: string;
}

interface IDrawerSectionProps {
	readonly title: string;
	readonly children?: React.ReactChild[] | React.ReactChild;
}

function getStepUrl(locale: string, url: string, projectId: string) {
	return `/${locale}${url}`.replace(':id', projectId);
}

function DrawerSection(props: IDrawerSectionProps) {
	return (
		<div className={S.tabDrawerMenuSection}>
			<div className={S.tabDrawerMenuSectionTitle}>
				<div>{props.title.toUpperCase()}</div>
				<hr />
			</div>
			{props.children}
		</div>
	);
}

function DrawerStep({ step, stepState, title, url, projectId }: IDrawerStepProps) {
	const [isHover, setIsHover] = useState<boolean>(false);
	const T = useLocale();

	let color: string;
	let currentCheckmarkClass: string;
	let currentStepNameClass: string;

	switch (stepState) {
		case StepState.Completed:
			color = '#0095c8';
			currentCheckmarkClass = S.tabDrawerMenuStepCheckmarkCompleted;
			currentStepNameClass = S.tabDrawerMenuStepNameCompleted;
			break;

		case StepState.Active:
			color = '#fff';
			currentCheckmarkClass = S.tabDrawerMenuStepCheckmarkActive;
			currentStepNameClass = S.tabDrawerMenuStepNameActive;
			break;

		case StepState.Enabled:
		case StepState.Added:
			color = '#9fbbc4';
			currentCheckmarkClass = S.tabDrawerMenuStepCheckmarkEnabled;
			currentStepNameClass = S.tabDrawerMenuStepNameEnabled;
			break;

		case StepState.Disabled:
		default:
			color = '#e2eaed';
			currentCheckmarkClass = S.tabDrawerMenuStepCheckmarkDisabled;
			currentStepNameClass = S.tabDrawerMenuStepNameDisabled;
	}

	const isEnabledHover = isHover && stepState !== StepState.Disabled;
	return (
		<div
			className={S.tabDrawerMenuStepContainer}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			<div
				className={classnames(S.tabDrawerMenuStepCheckmark, currentCheckmarkClass, {
					[S.tabDrawerMenuStepCheckmarkHover]: isEnabledHover,
				})}
			>
				<SmallCheckmark color={isEnabledHover ? '#fff' : color} />
			</div>
			<div
				className={classnames(S.tabDrawerMenuStepName, currentStepNameClass, {
					[S.tabDrawerMenuStepNameHover]: isEnabledHover,
				})}
			>
				{stepState === StepState.Disabled ? (
					title
				) : (
					<LinkWrapper to={getStepUrl(T.locale, url, projectId)} step={step}>{title}</LinkWrapper>
				)}
			</div>
			{stepState === StepState.Added && (
				<div className={S.tabDrawerMenuStepPill}>
					{T.SeismicKitAddTag}
				</div>
			)}
		</div>
	);
}

export default function TabDrawer(props: ITabDrawerProps) {
	const T = useLocale();
	const { state } = useStore();
	const [isMenuHover, setIsMenuHover] = useState<boolean>(false);

	const showChevron = !props.isFolded && !props.showInPage;
	const isHover = props.isFolded && isMenuHover;

	function expand() {
		if (!props.isFolded && !props.showInPage) {
			setIsMenuHover(false);
			props.onClick();
		}
	}

	function getMouseHandlers(isActive: boolean = true) {
		if (isActive) {
			const events =  {
				onMouseEnter: () => setIsMenuHover(true),
				onMouseLeave: () => setIsMenuHover(false),
				onClick: props.isFolded ? () => props.foldedOnClick() : expand,
			};

			if (BrowserUtils.isMobileDevice()) {
				delete events.onMouseEnter;
				delete events.onMouseLeave;
			}

			return events;
		}
		return {};
	}

	function getStepState(step: Steps) {
		if (step === state.layout.currentStep) {
			return StepState.Active;
		}

		if (step <= state.layout.latestCompletedStep) {
			return StepState.Completed;
		}
		if (isNextStep(state.layout.latestCompletedStep, step)) {
			return StepState.Enabled;
		}

		return StepState.Disabled;
	}

	function shouldShowChevron() {
		if (state.layout.isMobile) {
			if (props.isFolded) {
				return true;
			}

			return !props.showInPage;
		}
		return (!props.isFolded && !props.showInPage);
	}

	return (
		<>
			<div
				className={classnames({
					[AppStyle.tabsFoldedDrawerContainer]: props.isFolded,
				})}
				{...getMouseHandlers(!state.layout.isMobile)}
			>

				<div
					className={classnames(S.tabTitleContainer, {
						[S.tabTitleContainerFolded]: props.isFolded,
						[S.tabTitleContainerExpanded]: props.drawerExpanded,
						[S.tabTitleContainerClickable]: showChevron,
						// [S.tabTitleContainerAnimated]: isHover,
					})}
					{...getMouseHandlers(state.layout.isMobile)}
				>
					<span
						className={classnames(S.tabTitleStepNumber, {
							[S.tabTitleStepNumberFolded]: props.isFolded,
							// [S.tabTitleStepNumberAnimated]: isHover,
						})}
					>
						{props.sectionNumber}
					</span>
					<span
						className={classnames(S.tabTitleLine, {
							[S.tabTitleLineFolded]: props.isFolded,
							// [S.tabTitleLineAnimated]: isHover,
						})}
					/>
					&nbsp;
					<span
						className={classnames(S.tabTitleTitle, {
							[S.tabTitleTitleFolded]: props.isFolded,
							[S.tabTitleTitleAnimated]: isHover,
						})}
					>
						{props.title}
					</span>
					<div className={S.tabTitleSpacer}/>
					<div className={S.tabTitleChevron}>
						{shouldShowChevron() && (
							<Chevron
								direction={props.drawerExpanded ? ChevronDirection.UP : ChevronDirection.DOWN}
								color={state.layout.isMobile && props.isFolded ? '#9fbbc4' : 'white'}
							/>
						)}
					</div>
				</div>

				{state.layout.isMobile && !props.isFolded && !props.showInPage && state.layout.summaryIsShown && (
					<Summary />
				)}

				<div
					className={classnames(S.tabDrawerMenu, {
						[S.tabDrawerMenuExpanded]: props.drawerExpanded,
						[S.tabDrawerMenuPeaking]: !props.isFolded && !props.drawerExpanded && isMenuHover,
						[S.tabDrawerMenuInPage]: props.showInPage,
					})}
					style={{ top: `${props.drawerTopOffset}px` }}
				>
					<div
						className={classnames({
							[S.tabDrawerMenuInPageSectionContainer]: props.showInPage,
						})}
					>
						{!props.isFolded && props.routes.map((section) => !shouldSkipNavSection(section.sectionName, state.layout.requirements) ? (
							<DrawerSection title={section.sectionName} key={section.sectionName}>
								{section.steps.map((step) => !shouldSkipStep(step.step, state.layout.requirements) ? (
									<DrawerStep
										title={step.stepName}
										step={step.step}
										stepState={getStepState(step.step)}
										url={getCurrentStepUrl(step.step, step.url[T.locale], state.project.id)}
										key={step.stepName}
										projectId={state.project.id ? state.project.id?.toString() : ''}
									/>
								) : (
									<React.Fragment key={step.stepName} />
								))}
							</DrawerSection>
						) : (
							<React.Fragment key={section.sectionName} />
						))}
					</div>
				</div>
			</div>
			{props.drawerExpanded && !props.showInPage && (
				<div
					className={S.tabDrawerOverlay}
					style={{
						top: `${state.layout.isMobile ? props.drawerTopOffset : props.drawerTopOffset - 45}px`,
						height: `calc(100% - ${state.layout.isMobile ? props.drawerTopOffset : props.drawerTopOffset - 45}px)`,
					}}
					onClick={expand}
				/>
			)}
		</>
	);
}
