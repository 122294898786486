import PriceModalContainer from '@components/layout/PriceModalContainer';
import {
	CalendarIcon,
	Chevron,
	ChevronDirection,
	InfoClientIcon,
	InfoNorbecIcon,
	InstallationIcon,
	InstallationLocationIcon,
	ModaSpaceLogoSmall,
	SeismicIcon,
	TransportNorbecIcon,
	TransportPickUpIcon,
} from '@components/svg';
import { useLocale } from '@i18n';
import { ETransportType, IStoreState, State, Steps } from '@models';
import { useStore } from '@store';
import { shouldShowDrawing, shouldShowSection } from '@utils/navigation';
import classnames from 'classnames';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import AppStyle from '../../routes/size-it/styles/SizeIt.styl';
import MiniatureDrawing from './MiniatureDrawing';
import S from './styles/Summary.styl';
import SummaryGroupSpecs from './SummaryGroupSpecs';
import SummaryOption from './SummaryOption';
import SummaryRoomSpecs from './SummaryRoomSpecs';
import SummarySection from './SummarySection';

enum ScrollPositions {
	top,
	scrolling,
	bottom,
}

function getTopOffset(state: IStoreState) {
	if (!state.layout.installationFolded) {
		return '210px';
	} else if (!state.layout.optionsFolded) {
		return '185px';
	}
	return '160px';
}

export default function Summary() {
	const T = useLocale();
	const { state, dispatch, actions } = useStore();
	const { currentStep } = state.layout;
	const [scrollPosition, setScrollPosition] = useState(ScrollPositions.top);
	const [isMenuHover, setIsMenuHover] = useState<boolean>(false);
	const [disabled, setIsDisabled] = useState<boolean>(currentStep === Steps.PROJECT_NAME);

	const isFolded = state.layout.summaryFolded;
	const isMobile = state.layout.isMobile;
	const tabNode = document.getElementById('summary-tab');
	const topOffset = getTopOffset(state);

	function expand() {
		if (!disabled) {
			setIsMenuHover(false);
			dispatch(actions.LayoutActions.toggleSummary());
		}
	}

	function handlleHover(isHover: boolean) {
		if (isFolded && !disabled) {
			setIsMenuHover(isHover);
		}
	}

	const handleScroll = () => {
		if (tabNode && tabNode.clientHeight + tabNode.scrollTop >= tabNode.scrollHeight - 20) {
			setScrollPosition(ScrollPositions.bottom);
		} else if (tabNode?.scrollTop && tabNode.scrollTop >= 20) {
			setScrollPosition(ScrollPositions.scrolling);
		} else {
			setScrollPosition(ScrollPositions.top);
		}
	};

	useEffect(() => {
		tabNode?.addEventListener('scroll', handleScroll);
		return () => tabNode?.removeEventListener('scroll', handleScroll);
	});

	useEffect(() => {
		setIsDisabled(state.layout.currentStep === Steps.PROJECT_NAME);
	}, [state.layout.currentStep]);

	function getRoomText() {
		if (state.group.rooms.length > 1) {
			return T.SummaryRooms;
		}
		return T.SummaryRoom;
	}

	function shouldShowQuoteContact() {
		const showQuote = !!state.project.customer.name ||
			!!state.project.contact.email ||
			!!state.project.contact.name ||
			!!state.project.contact.phoneNum ||
			!!state.project.contact.phoneExt;
		return currentStep >= Steps.QUOTE && (showQuote || currentStep === Steps.QUOTE);
	}

	function getQuoteContactFields() {
		return {
			customerName: state.project.customer.name,
			contactName: state.project.contact.name,
			contactPhoneNumber: state.project.contact.phoneNum,
			contactPhoneExtension: state.project.contact.phoneExt,
			contactEmail: state.project.contact.email,
		};
	}

	function getQuoteContactText() {
		const fields = getQuoteContactFields();

		if (currentStep !== Steps.QUOTE &&
			!fields.customerName &&
			!fields.contactName &&
			!fields.contactPhoneNumber &&
			!fields.contactPhoneExtension &&
			!fields.contactEmail) {
			return '';
		}

		return getSummaryMultiLineInitializedValues(fields, Steps.QUOTE);
	}

	function shouldShowQuoteRepresentative() {
		const showQuote =
			state.project.salesrepEmail !== '' ||
			state.project.salesrepName !== '';
		return state.layout?.currentStep >= Steps.QUOTE && (showQuote || state.layout.currentStep === Steps.QUOTE);
	}

	function getQuoteRepresentativeFields() {
		return {
			salesRepName: state.project.salesrepName,
			salesRepEmail: state.project.salesrepEmail,
		};
	}

	function getQuoteRepresentativeText() {
		const fields = getQuoteRepresentativeFields();

		if (currentStep !== Steps.QUOTE &&
			!fields.salesRepName &&
			!fields.salesRepEmail) {
			return '';
		}

		return getSummaryMultiLineInitializedValues(fields, Steps.QUOTE);
	}

	function getSummaryMultiLineInitializedValues(fields: {}, checkStep: Steps) {
		const textArray: string[] = [];

		const fieldValues = Object.values(fields);
		for (const value of fieldValues) {
			if (!!value) {
				textArray.push(String(value));
			} else {
				if (currentStep === checkStep) {
					textArray.push(T.SummarySeismicKitPendingLabel);
				}
			}
		}

		return textArray;
	}

	function shouldShowSeismicKit() {
		return currentStep >= Steps.SEISMIC_KIT && (state.project.hasSeismicKit || currentStep === Steps.SEISMIC_KIT);
	}

	function shouldShowInstallationDate() {
		return currentStep >= Steps.DELIVERY_DATE && (state.project.installationDate || currentStep === Steps.DELIVERY_DATE);
	}

	function shouldShowInstallationLocation() {
		return state.layout.currentStep >= Steps.INSTALLATION_LOCATION &&
		(
			(state.project.installationLocation && state.project.installationLocation.country) ||
			state.layout.currentStep === Steps.INSTALLATION_LOCATION
		);
	}

	function getSeismicKitText() {
		if (currentStep === Steps.SEISMIC_KIT && !state.project.hasSeismicKit) {
			return T.SummarySeismicKitPendingLabel;
		} else if (state.project.hasSeismicKit) {
			return T.SummarySeismicKit;
		}
		return '';
	}

	function shouldShowInstallation() {
		return state.layout.currentStep >= Steps.INSTALLATION && (state.project.installByNorbec || state.layout.currentStep === Steps.INSTALLATION);
	}

	function getInstallationByNorbecText() {
		if (state.layout.currentStep === Steps.INSTALLATION && !state.project.installByNorbec) {
			return T.InstallationInstallationLabelNoSummary;
		} else if (state.project.installByNorbec) {
			return T.InstallationInstallationLabelYesSummary;
		}
		return '';
	}

	function shouldShowTransport() {
		return state.layout.currentStep >= Steps.DELIVERY_CHOICE && (!!state.project.transportType || state.layout.currentStep === Steps.DELIVERY_CHOICE);
	}

	function getTransportText() {
		if (state.project.transportType && state.project.transportType.id === ETransportType.PICK_UP) {
			return T.InstallationInstallationLabelNoSummary;
		} else if (state.project.transportType && state.project.transportType.id === ETransportType.BY_NORBEC) {
			return T.InstallationInstallationLabelYesSummary;
		}
		return '';
	}

	function getInstallationDateText() {
		if (currentStep === Steps.DELIVERY_DATE && !state.project.installationDate) {
			return T.DeliveryDateSummaryPending;
		} else if (state.project.installationDate) {
			return format(state.project.installationDate, 'dd-MM-yyyy');
		}
		return '';
	}

	function getInstallationLocationText() {
		const fields: any = getInstallationLocationFields();
		const checkStep = currentStep === Steps.DELIVERY_PLACE ? Steps.DELIVERY_PLACE : Steps.INSTALLATION_LOCATION;

		if ((currentStep !== Steps.INSTALLATION_LOCATION &&
			currentStep !== Steps.DELIVERY_PLACE) &&
			!fields?.street &&
			!fields?.street2 &&
			!fields?.city &&
			!fields?.cityState &&
			!fields?.country &&
			!fields?.zipCode) {
			return '';
		}

		return getSummaryMultiLineInitializedValues(fields, checkStep);
	}

	function getInstallationLocationFields() {
		const installationLocation = state.project.installationLocation;
		const fields: any = {};
		const showAllFields = currentStep >= Steps.DELIVERY_PLACE || state.layout.latestCompletedStep >= Steps.DELIVERY_PLACE;

		if (showAllFields) {
			fields.street = '';
			if (installationLocation?.street || installationLocation?.street2) {
				fields.street = `${installationLocation?.street || ''} ${installationLocation?.street2 || ''}`;
			}
		}

		fields.city = installationLocation?.city;
		fields.cityState = installationLocation?.cityState ? T[installationLocation?.cityState.replace('-', '')] : '';
		fields.country = installationLocation?.country ? T[installationLocation.country] : '';

		if (showAllFields) {
			fields.zipCode = installationLocation?.zipCode;
		}

		return fields;
	}

	function getIconStrokeColorForStep(step: Steps) {
		if (currentStep === step) {
			if (state.project.state === State.Focused) {
				return S.orange;
			}
			return S.blueNorbec;
		}
		return S.darkBlueGray;
	}

	function shouldShowPriceSection() {
		const requirements = state.layout.requirements;
		if (requirements.shouldSkipCondenserDistanceStep && requirements.shouldSkipComboOptionsStep) {
			return state.layout.latestCompletedStep >= Steps.COMBO_SYSTEM;
		} else if (requirements.shouldSkipComboOptionsStep && !requirements.shouldSkipCondenserDistanceStep) {
			return state.layout.latestCompletedStep >= Steps.CONDENSER_DISTANCE;
		} else {
			return state.layout.latestCompletedStep >= Steps.COMBO_OPTIONS;
		}
	}

	return (
		<div
			className={classnames(S.summaryWrapper, {
				[S.summaryWrapperExpanded]: !state.layout.summaryFolded || state.layout.isMobile,
			})}
		>
			{(!isMobile && !state.layout.isTablet && !isFolded) && shouldShowDrawing(currentStep) &&
				<MiniatureDrawing/>
			}
			<div
				id="summary-tab"
				className={classnames(AppStyle.tabs, {
					[AppStyle.tabsSemiFolded]: !state.layout.summaryFolded,
					[AppStyle.tabsFolded]: state.layout.summaryFolded,
				})}
			>
				<div
					onClick={expand}
					onMouseEnter={() => handlleHover(true)}
					onMouseLeave={() => handlleHover(false)}
					className={classnames(S.summaryTitle, {
						[S.summaryTitleFolded]: isFolded,
						[S.summaryTitleDisabled]: disabled,
						// [S.summaryTitleFading]: scrollPosition === ScrollPositions.scrolling,
					})}
				>
					<h2>{T.SummaryTitle}</h2>
					&nbsp;
					<span className={classnames(S.summaryTitleLine, { [S.summaryTitleFoldedLine]: isFolded })} />
					{(!isFolded || isMobile) && (
						<div className={S.summaryTitleSpacer} />
					)}
					{isMobile ? (
							<Chevron className={S.summaryTitleChevron} direction={isFolded ? ChevronDirection.DOWN : ChevronDirection.UP} color="#9fbbc4" />
						) : (
						isFolded && (
							<Chevron direction={ChevronDirection.UP} color="#9fbbc4" />
						)
					)}
				</div>

				{(isMobile || !isFolded) && (
					<div
						className={classnames(S.summarySectionContainer, {
							[S.summarySectionContainerExpended]: !isFolded,
							[S.summarySectionContainerPeaking]: isMenuHover,
						})}
						style={{ top: topOffset }}
					>
						<SummarySection title={getRoomText()}>
							<div className={S.summaryLogo}>
								<ModaSpaceLogoSmall />
							</div>
						</SummarySection>

						<SummarySection title={T.ConfiguratorProjectGroupTitle}>
							<div>
								{state.project.name}
							</div>
						</SummarySection>

						<SummarySection title={`# ${state.project.ref}`} large={true} />

						<div className={S.summarySectionOptions}>
							{shouldShowInstallationLocation() && (
								<SummaryOption
									id={`${Steps.INSTALLATION_LOCATION}`}
									text={getInstallationLocationText()}
									isMultiLine
									state={
										(state.layout.currentStep === Steps.INSTALLATION_LOCATION || state.layout.currentStep === Steps.DELIVERY_PLACE) ?
											state.project.state === State.Enabled ?
												State.Selected
												: state.project.state
											: State.Enabled
									}
								>
									<InstallationLocationIcon stroke={getIconStrokeColorForStep(Steps.INSTALLATION_LOCATION)}/>
								</SummaryOption>
							)}
						</div>

						<div className={S.summarySectionOptions}>
							{shouldShowSeismicKit() && (
								<SummaryOption
									id={`${Steps.SEISMIC_KIT}`}
									text={getSeismicKitText()}
									state={
										currentStep === Steps.SEISMIC_KIT ?
											state.project.state === State.Enabled ?
											State.Selected
											: state.project.state
											: State.Enabled
										}
								>
									<SeismicIcon stroke={getIconStrokeColorForStep(Steps.SEISMIC_KIT)}/>
								</SummaryOption>
							)}
						</div>

						<div className={S.summarySectionOptions}>
							{shouldShowInstallation() && (
								<SummaryOption
									id={`${Steps.INSTALLATION}`}
									text={getInstallationByNorbecText()}
									state={
										currentStep === Steps.INSTALLATION ?
											state.project.state === State.Enabled ?
												State.Selected
												: state.project.state
											: State.Enabled
									}
								>
									<InstallationIcon stroke={getIconStrokeColorForStep(Steps.INSTALLATION)}/>
								</SummaryOption>
							)}
						</div>

						<div className={S.summarySectionOptions}>
							{shouldShowTransport() && (
								<SummaryOption
									id={`${Steps.DELIVERY_CHOICE}`}
									text={getTransportText()}
									state={
										currentStep === Steps.DELIVERY_CHOICE ?
											state.project.state === State.Enabled ?
												State.Selected
												: state.project.state
											: State.Enabled
									}
								>
									{!!state.project.transportType && state.project.transportType.id === ETransportType.BY_NORBEC ? (
										<TransportNorbecIcon fill={getIconStrokeColorForStep(Steps.DELIVERY_CHOICE)}/>
									) : (
										<TransportPickUpIcon fill={getIconStrokeColorForStep(Steps.DELIVERY_CHOICE)}/>
									)}
								</SummaryOption>
							)}
						</div>

						<div className={S.summarySectionOptions}>
							{shouldShowInstallationDate() && (
								<SummaryOption
									id={`${Steps.DELIVERY_DATE}`}
									text={getInstallationDateText()}
									state={
										currentStep === Steps.DELIVERY_DATE ?
											state.project.state === State.Enabled ?
											State.Selected
											: state.project.state
											: State.Enabled
										}
								>
									<CalendarIcon stroke={getIconStrokeColorForStep(Steps.DELIVERY_DATE)}/>
								</SummaryOption>
							)}
						</div>

						<div className={S.summarySectionOptions}>
							{shouldShowQuoteContact() && (
								<SummaryOption
									id={`${Steps.QUOTE}`}
									text={getQuoteContactText()}
									isMultiLine
									state={
										currentStep === Steps.QUOTE ?
											state.project.state === State.Enabled ?
											State.Selected
											: state.project.state
											: State.Enabled
									}
								>
									<InfoClientIcon stroke={getIconStrokeColorForStep(Steps.QUOTE)}/>
								</SummaryOption>
							)}
						</div>

						<div className={S.summarySectionOptions}>
							{shouldShowQuoteRepresentative() && (
								<SummaryOption
									id={`${Steps.QUOTE}`}
									text={getQuoteRepresentativeText()}
									isMultiLine
									state={
										currentStep === Steps.QUOTE ?
											state.project.state === State.Enabled ?
											State.Selected
											: state.project.state
											: State.Enabled
									}
								>
									<InfoNorbecIcon stroke={getIconStrokeColorForStep(Steps.QUOTE)} fill={getIconStrokeColorForStep(Steps.QUOTE)}/>
								</SummaryOption>
							)}
						</div>

						{state.group.rooms.length > 0 && (
							<SummarySection title={`${state.group.rooms.length} ${getRoomText()}`}>
								<>
									{state.group.rooms.map((room) => (
										<SummaryRoomSpecs room={room} key={room.code} />
									))}
									{shouldShowSection(Steps.COMBO_WIDTH, state) &&
										<SummaryGroupSpecs />
									}
								</>
							</SummarySection>
						)}

						{!isFolded && state.layout.isMobile && (
							<div style={{ top: topOffset }} className={S.summaryOverlay} onClick={expand} />
						)}
					</div>
				)}
			</div>

			{shouldShowPriceSection() && !state.layout.isMobile && !isFolded && (
				<PriceModalContainer />
			)}
		</div>
	);
}
