import Accordion from '@components/layout/Accordion';
import { LayoutFullWidth } from '@components/layout/Layout';
import { useLocale } from '@i18n';
import React from 'react';
import S from './styles/Faq.styl';

interface IFaqElement {
	id: string | number;
	question: string;
	answer: string;
}

export default function Faq() {
	const T = useLocale();

	const getFaqData = () => {
		const faqQuestions = Object.keys(T).filter((key) => {
			return key.indexOf('KeyFaqQ') > -1;
		});

		const faqAnswers = Object.keys(T).filter((key) => {
			return key.indexOf('KeyFaqA') > -1;
		});

		return Object.assign(
			faqQuestions.map((key, index) => (
				{
					id: index,
					question: T[key],
					answer: T[faqAnswers[index]],
				}
			)),
		);
	};

	const FaqElements = () => {
		const faqData = getFaqData();

		return (
			faqData.map((faqItem: IFaqElement) => {
				return (
					<Accordion
						key={faqItem.id}
						id={faqItem.id}
						title={faqItem.question}
						content={faqItem.answer}
					/>
				);
			})
		);
	};

	return (
		<LayoutFullWidth>
			<div className={S.faqContainer}>
				<h1 className={S.faqContainerMainTitle}>
					FAQ
				</h1>

				{FaqElements()}

			</div>
		</LayoutFullWidth>
	);
}
