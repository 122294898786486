import React from 'react';

export default function PinIcon() {
	return (
		<svg width="20px" height="24px" viewBox="0 0 20 24" version="1.1">
			<title>90CB3021-7A24-40DF-B2E9-A506149FFCFF</title>
			<desc>Created with sketchtool.</desc>
			<g id="Maquettes-UI" stroke="none" strokeWidth="1" fill="#ffffff" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
				<g id="UI-kit" transform="translate(-408.000000, -3529.000000)" stroke="#415A62">
					<g id="ico-pin-doorposition" transform="translate(408.000000, 3529.000000)">
						<g id="style-one-pin">
							<path d="M19.0000044,9.756 C19.004292,5.38608549 15.8689015,1.64455496 11.5656114,0.884380247 C7.26232133,0.124205529 3.03481081,2.56508212 1.54167452,6.6719928 C0.0485382288,10.7789035 1.72130242,15.364924 5.508,17.546 C7.196,18.52 7.651,19.595 9.565,22.989 C9.65364066,23.1461518 9.82007313,23.2433585 10.0005,23.2433585 C10.1809269,23.2433585 10.3473593,23.1461518 10.436,22.989 C12.37,19.558 12.808,18.518 14.492,17.546 C17.2809513,15.9436708 19.0003548,12.9724745 19.0000044,9.756 Z" id="Path"/>
							<circle id="Oval" cx="10" cy="9.756" r="5"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
