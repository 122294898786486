import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { LoadingElement } from '@components/layout';
import S from './styles/Button.styl';

interface IButtonProps {
	children: JSX.Element | string | never[];
	onClick?: () => void;
	href?: string;
	icon?: JSX.Element;
	small?: boolean;
	secondary?: boolean;
	disabled?: boolean;
	typeSubmit?: boolean;
	orange?: boolean;
	center?: boolean;
	isLoading?: boolean;
}

export default function ButtonQuote(props: IButtonProps) {
	const { onClick, href, children, icon, small, secondary, disabled, typeSubmit, orange, center, isLoading } = props;
	const classes = classnames(
		S.buttonBase, {
		[S.center]: center,
		[S.small]: small,
		[S.secondary]: secondary,
		[S.orange]: orange,
	});

	const content = (
		<>
			{icon}
			{children &&
				(
					<span>
						{children}
					</span>
				)
			}
		</>
	);

	return (
		<>
			{(onClick || typeSubmit) && !href &&
				(
					<button
						type={typeSubmit ? 'submit' : 'button'}
						className={classes}
						onClick={onClick}
						disabled={disabled}
					>
						{isLoading ? <LoadingElement color={'white'} /> : content}
					</button>
				)
			}
			{href &&
				(
					<Link
						className={classes}
						to={href}
						onClick={onClick}
					>
						{isLoading ? <LoadingElement color={'white'} /> : content}
					</Link>
				)
			}
		</>
	);
}
