import { ILightOptionState, State } from '@models';

export interface ILightOption {
	id: number;
	name: string;
	imageUrl: string;
	price?: number;
	unit?: string;
	isIncluded: boolean;
	description: string;
	technicalSheetUrl: string;
}

export function fromLightOptionsDTO(lightOptions: ILightOption[]): ILightOptionState[] {
	return lightOptions.map((lightOption) => fromLightOptionDTO(lightOption));
}

export function fromLightOptionDTO(lightOption: ILightOption): ILightOptionState {
	return {
		id: lightOption.id,
		name: lightOption.name,
		imageUrl: lightOption.imageUrl,
		price: lightOption.price,
		unit: lightOption.unit,
		isIncluded: lightOption.isIncluded,
		description: lightOption.description,
		technicalSheetUrl: lightOption.technicalSheetUrl,
		state: State.Enabled,
	};
}

export function toLightOptionDTO(lightOptionState: ILightOptionState): ILightOption {
	return {
		id: lightOptionState.id,
		name: lightOptionState.name,
		imageUrl: lightOptionState.imageUrl,
		price: lightOptionState.price,
		unit: lightOptionState.unit,
		isIncluded: lightOptionState.isIncluded,
		description: lightOptionState.description,
		technicalSheetUrl: lightOptionState.technicalSheetUrl,
	};
}
