import Button from '@components/buttons/Button';
import { LayoutHalfAndHalf } from '@components/layout/Layout';
import LeftContentAuthHalfAndHalf from '@components/layout/LeftContentAuthHalfAndHalf';
import LeftSectionLayoutHalfAndHalf from '@components/layout/LeftSectionLayoutHalfAndHalf';
import RightSectionPublicLayoutHalfAndHalf from '@components/layout/RightSectionPublicLayoutHalfAndHalf';
import { useLocale } from '@i18n';
import FormatUtils from '@utils/format';
import React from 'react';
import { ERoutes, getPathByRouteName } from '../index';
import S from './styles/Password.styl';

export default function TokenExpired() {
	const T = useLocale();

	return (
		<LayoutHalfAndHalf>
			<LeftSectionLayoutHalfAndHalf>
				<LeftContentAuthHalfAndHalf/>
			</LeftSectionLayoutHalfAndHalf>
			<RightSectionPublicLayoutHalfAndHalf>
			<div className={S.passwordContent}>
				<h1 className={S.passwordContentTitle}>
					{T.LoginExpiredLinkTitle}
				</h1>
				<div className={S.passwordContentDescription} dangerouslySetInnerHTML={{ __html: FormatUtils.formatLocaleVariable(T, T.LoginExpiredLinkText) }} />
				<Button
					href={getPathByRouteName(ERoutes.Login, T.locale)}
				>
					{T.LoginExpiredLinkButtonText}
				</Button>
			</div>
			</RightSectionPublicLayoutHalfAndHalf>
		</LayoutHalfAndHalf>
	);
}
