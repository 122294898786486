import React from 'react';

interface IInfoClientIcon {
	stroke: string;
}

export default function InfoClientIcon(props: IInfoClientIcon) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" stroke={props.stroke}>
				<g transform="translate(11 11)">
					<circle cx="6.5" cy="6.5" r="6"/>
					<path d="M6.5 9.5V6c0-.276-.224-.5-.5-.5H5M5.75 3c.138 0 .25.112.25.25s-.112.25-.25.25-.25-.112-.25-.25.112-.25.25-.25M5 9.5L8 9.5"/>
				</g>
				<circle cx="5.5" cy="4" r="3.5"/>
				<path d="M10.013 11.848c-1.006-2.11-3.339-3.24-5.618-2.724C2.116 9.64.5 11.666.5 14.004v3.5H3l.5 6h4l.5-6h1.482"/>
			</g>
		</svg>
	);
}
