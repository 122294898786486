import { Chevron, ChevronDirection } from '@components/svg';
import { useLocale } from '@i18n';
import { State } from '@models';
import { AnimationSettings } from '@utils/animation';
import React, { useState } from 'react';
import Select, { components, IndicatorProps, OptionTypeBase, StylesConfig } from 'react-select';
import S from './styles/SimpleDropdown.styl';

export interface IDropdownOption {
	label: React.ReactNode;
	value: string;
}

interface ISimpleDropdownProps {
	options: IDropdownOption[];
	placeholder: string;
	label: string;
	onSelect: (e: any) => void;
	value: string;
	disabled?: boolean;
	scrollOnMenuOpen: boolean;
}

function getInputBordersStyle(hasValue: boolean, isMenuOpen: boolean, isDisabled: boolean) {
	let boxShadowStyle = `inset 0 0 8px 0 ${
		isMenuOpen ? S.orange50 : S.blueGray
	}`;

	if (!hasValue) {
		boxShadowStyle = 'none';

		if (isMenuOpen) {
			boxShadowStyle = `inset 0 0 8px 0 ${S.orange50}`;
		}
	}

	return {
		border: `1px solid ${
			isMenuOpen ? S.orange : S.darkBlueGray
		} !important`,
		borderRadius: '8px',
		boxShadow: boxShadowStyle,
		transition: '500ms',
	};
}

const colourStyles: StylesConfig = {
	option: (styles, { hasValue, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			backgroundColor: isSelected
				? S.blueNorbec
				: isFocused
				? S.orange25
				: S.blueGray50ish,
			color: isSelected
				? S.white
				: S.darkGray,
			cursor: isDisabled ? 'not-allowed' : 'default',

		};
	},
	menu: (styles, { hasValue, isDisabled, isFocused, isSelected }) => ({
		...styles,
		border: `1px solid ${S.darkBlueGray}`,
		borderRadius: '8px',
		backgroundColor: S.blueGray50ish,
		marginTop: 0,
	}),
	menuList: (styles, { hasValue, isDisabled, isFocused, isSelected }) => ({
		...styles,
		borderRadius: '8px',
		padding: 0,
	}),
	control: (styles, { hasValue, menuIsOpen, isDisabled, isFocused }) => ({
		...styles,
		...getInputBordersStyle(hasValue, menuIsOpen, isDisabled),
		color: S.darkGray,
		fontSize: S.fontSizeNormal,
		fontWeight: S.fontWeightRegular as any,
		height: '48px',
		width: '100%',
	}),
	placeholder: (styles) => ({ ...styles }),
	valueContainer: (styles, { data }) => ({ ...styles, padding: '2px 20px' }),
	indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
	dropdownIndicator: (styles) => ({ ...styles, marginRight: '20px' }),
};

function getValueOption(value: string, options: IDropdownOption[]) {
	return options.find((opt) => opt.value === value);
}

const StyledChevron = (props: IndicatorProps<OptionTypeBase>) => {
	return (
	<components.DropdownIndicator {...props}>
		<Chevron
			color={props.selectProps.menuIsOpen ? S.orange : S.darkBlueGray}
			direction={props.selectProps.menuIsOpen ? ChevronDirection.UP : ChevronDirection.DOWN}
		/>
	</components.DropdownIndicator>
	);
};

export default function SimpleDropdown(props: ISimpleDropdownProps) {
	const [state, setState] = useState<State>(State.Enabled);
	const [timer, setTimer] = useState<number>(0);
	const T = useLocale();

	const NoOptionsMessage = (props: any) => {
		return (
			<components.NoOptionsMessage {...props} >
				<span>{T.GeneralDropdownNoOptions}</span>
			</components.NoOptionsMessage>
		);
	};

	function handleChange(e: any) {
		setState(State.Focused);
		props.onSelect(e.value);

		setTimer(window.setTimeout(() => {
			setState(State.Selected);
		}, AnimationSettings.FADE_DELAY));
	}

	function onDropdownMenuOpen() {
		if (props.scrollOnMenuOpen) {
			const container = document.getElementsByClassName('stepContainer-project')[0];

			setTimeout(() => {
				container.scrollBy(0, container.scrollHeight);
			}, 150);
		}
	}

	return (
		<>
			<div className={S.simpleDropdownLabel}>{props.label}</div>
			<Select
				isDisabled={props.disabled}
				components={{ DropdownIndicator: StyledChevron, NoOptionsMessage }}
				placeholder={props.placeholder}
				value={getValueOption(props.value, props.options)}
				onChange={handleChange}
				options={props.options}
				styles={colourStyles}
				isSearchable={false}
				backspaceRemovesValue={false}
				onMenuOpen={() => onDropdownMenuOpen()}
			/>
		</>
	);
}

SimpleDropdown.defaultProps = {
	value: '',
	onSelect: (e: any) => void(0),
	scrollOnMenuOpen: true,
};
