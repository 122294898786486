import React from 'react';

interface IAlertCircleIconProps {
	dark?: boolean;
}

export default function AlertCircleIcon({ dark }: IAlertCircleIconProps) {
	if (dark) {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
				<defs>
					<filter id="y7fmoy5dha" width="101.8%" height="126.7%" x="-.9%" y="-13.3%" filterUnits="objectBoundingBox">
						<feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
						<feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
						<feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.254901961 0 0 0 0 0.352941176 0 0 0 0 0.384313725 0 0 0 0.5 0"/>
						<feMerge>
							<feMergeNode in="shadowMatrixOuter1"/>
							<feMergeNode in="SourceGraphic"/>
						</feMerge>
					</filter>
				</defs>
				<g fill="none" fillRule="evenodd" filter="url(#y7fmoy5dha)" transform="translate(-20 -20)">
					<g transform="translate(20 20)">
						<circle cx="11" cy="11" r="11" fill="#9FBBC4" transform="translate(1 1)"/>
						<g stroke="#FFF" strokeLinecap="round" strokeLinejoin="round">
							<path d="M1 7.005L1 .005M.991 9.005c-.066.001-.129.029-.174.077-.046.048-.07.112-.067.178.005.136.117.245.253.245h0c.066-.001.129-.029.174-.077.045-.048.07-.112.067-.178-.004-.133-.11-.24-.244-.245H.995" transform="translate(11 7)"/>
						</g>
					</g>
				</g>
			</svg>
		);
	}

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fillRule="evenodd" stroke="#9FBBC4" strokeLinecap="round" strokeLinejoin="round">
				<path d="M23 11.811c.015 6.112-4.888 11.1-11 11.189A10.837 10.837 0 0 1 1 12.192C.983 6.078 5.887 1.089 12 1a10.838 10.838 0 0 1 11 10.811zM12 14.005v-7"/>
				<path d="M11.991 16.005a.245.245 0 0 0-.241.255.254.254 0 0 0 .253.245.246.246 0 0 0 .241-.255.253.253 0 0 0-.244-.245h-.005"/>
			</g>
		</svg>
	);
}
