import { getCustomers } from '@api/customers';
import { IDropdownOption } from '@components/inputs';
import { LayoutFullWidth } from '@components/layout/Layout';
import { useLocale } from '@i18n';
import { ToastType, useToastContext } from 'app/hooks/contexts/UseContext';
import { ICustomerListPayload } from 'app/models/customers';
import React, { useState } from 'react';
import CreateProject from './CreateProject';
import MuiTable from './MuiTable';

import S from './styles/Dashboard.styl';

export default function Dashboard() {
    const T = useLocale();

    return (
        <LayoutFullWidth>
            <div className={S.dashboardHeadContainer}>
                <h1>{T.DashboardTitle}</h1>
                <CreateProject />
            </div>
            <MuiTable />
        </LayoutFullWidth>
    );
}
function useEffect(arg0: () => void, arg1: undefined[]) {
    throw new Error('Function not implemented.');
}

function getRoute(selectedCustomer: any): any {
    throw new Error('Function not implemented.');
}

function selectedCustomer(selectedCustomer: any): any {
    throw new Error('Function not implemented.');
}

function setOptions(optionList: IDropdownOption[]) {
    throw new Error('Function not implemented.');
}

function addToast(arg0: { text: string; type: any; }) {
    throw new Error('Function not implemented.');
}
