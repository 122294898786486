import Button from '@components/buttons/Button';
import { useLocale } from '@i18n';
import React from 'react';

import S from './styles/ContactBox.styl';

interface IContactBoxProps {
	title: string;
	name?: string;
	email?: string;
	onClick?: () => void;
	showActionButton?: boolean;
}

export default function ContactBox(props: IContactBoxProps) {
	const T = useLocale();

	return (
		<div className={S.contactBox}>
			<div className={S.contactBoxContent}>
				<div className={S.contactBoxTitle}>
					{props.title}
				</div>

				<div className={S.contactBoxName}>
					{props.name ? props.name : '-'}
				</div>

				<div className={S.contactBoxEmail}>
					{props.email ?
						(
							<a href={`mailto:${props.email}`}>
								{props.email}
							</a>
						)
					:
						'-'
					}
				</div>
			</div>

			{props.showActionButton && (
				<div className={S.contactBoxButtonContainer}>
					<Button
						secondary
						onClick={props.onClick}
					>
						{T.GeneralModify}
					</Button>
				</div>
			)}
		</div>
	);
}
