import { CancelIcon, CheckIcon } from '@components/svg';
import { useLocale } from '@i18n';
import React from 'react';
import S from './styles/ConfirmationAlert.styl';

interface IConfirmationAlertProps {
	title: string;
	keyword?: string;
	onContinueClicked?: () => void;
	onCancelClicked?: () => void;
}

export default function ConfirmationAlert(props: IConfirmationAlertProps) {
	const T = useLocale();

	return (
		<div className={S.confirmationAlert}>
			{props.keyword && (
				<div className={S.confirmationAlertKeyword}>{props.keyword}</div>
			)}
			<div className={S.confirmationAlertTitle}>{props.title}</div>
			<div className={S.confirmationAlertIconContainer}>
				<div className={S.confirmationAlertButton} onClick={props.onContinueClicked}>
					<CheckIcon/>
					<div className={S.confirmationAlertButtonTitle}>{T.GeneralContinue}</div>
				</div>
				<div className={S.confirmationAlertButton} onClick={props.onCancelClicked}>
					<CancelIcon/>
					<div className={S.confirmationAlertButtonTitle}>{T.GeneralCancel}</div>
				</div>
			</div>
		</div>
	);
}
