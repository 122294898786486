import { getCitiesForProvince } from '@api/index';
import { AutoComplete, DatePicker, IDropdownOption, ISuggestion } from '@components/inputs';
import Dropdown from '@components/inputs/Dropdown';
import Input from '@components/inputs/Input';
import InfoLabelTile from '@components/labels/InfoLabelTile';
import { SimpleTable } from '@components/layout/SimpleTable';
import { useLocale } from '@i18n';
import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { ETransportType, FormInputState, ICityViewModel, IContact } from '@models';
import { formatTimestamp } from '@utils/format';
import { addDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ModalIconType, useModalContext } from '../../hooks/contexts/UseContext';
import { ERowType, IRow } from './MuiTabs';
import S from './styles/Project.styl';

interface ITabRowProps {
	row: IRow;
	rowHasChanged?: () => void;
	updateGroupInRow?: (newRows: Array<{ inputName: string, value: string, options?: IDropdownOption[] }>) => void;
	edit?: boolean;
	hiddenByState?: boolean;
	forceUpdateProject?: (force: boolean) => void;
}

const styleDropDown = {
	container: (provided: any, state: any) => ({
		...provided,
		paddingTop: '8px',
	}),
	control: (provided: any, state: any) => ({
		...provided,
		width: '100%',
		minHeight: '48px',
		color: S.darkGray,
		fontSize: S.fontSizeNormal,
		fontWeight: S.fontWeightRegular as any,
		borderRadius: '8px',
		borderColor: S.blueGray50,
		paddingRight: '20px',
	}),
	menu: (provided: any, state: any) => ({
		...provided,
		borderRadius: '8px',
	}),
	menuList: (provided: any, state: any) => ({
		...provided,
		borderRadius: '8px',
		padding: 0,
		backgroundColor: 'white',
	}),
	option: (provided: any, state: any) => ({
		...provided,
		backgroundColor: state.isFocused ? S.blueGray50ish : 'white',
		color: state.isDisabled
			? S.lightGray
			: state.isSelected
				? S.blueNorbec
				: state.isFocused
					? S.darkGray
					: S.darkGray,
		borderBottom: `1px solid ${S.blueGray25}`,
	}),
	indicatorSeparator: (provided: any) => ({
		...provided,
		display: 'none',
	}),
	dropdownIndicator: (provided: any, state: any) => ({
		...provided,
		color: S.darkBlueGray,
		transition: 'all .2s ease',
		transform: state.isFocused ? 'rotate(180deg) scale(1.25)' : 'scale(1.25)',
	}),
};

export default function TabRow({ row, edit, rowHasChanged, updateGroupInRow, hiddenByState, forceUpdateProject }: ITabRowProps) {
	const T = useLocale();
	const { useModal, changeYesActionState } = useModalContext();

	const [inputState, setInputState] = useState({ state: FormInputState.Unselected, value: row.value });
	const [cities, setCities] = useState<ICityViewModel[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const initInputState = () => {
		if (!row.inputProps?.isOptional) {
			if (row.value === '' || row.value === null) {
				row.inputState = FormInputState.Error;
			}
		}
	};

	useEffect(() => {
		if (edit) {
			initInputState();
		}
	}, []);

	useEffect(() => {
		if (row.value !== inputState.value) {
			setInputState({ state: FormInputState.Unselected, value: row.value });
		}
	}, [row.value]);

	function updateRow(value: any, state: FormInputState) {
		if (row.value !== value || row.inputState !== state) {
			setInputState(({ state, value }));
			row.value = value;
			row.inputState = state;
			if (rowHasChanged) {
				rowHasChanged();
			}
		}
	}

	if (hiddenByState === false) {
		return null;
	}

	function handleCitiesFetch(search: string) {
		setIsLoading(true);
		if (row.cityState) {
			getCitiesForProvince(row.cityState, search, false, false)
				.then((results) => {
					setCities(results);
					setIsLoading(false);
				});
		} else {
			setCities([]);
		}
	}

	function getRowClassName(): string | undefined {
		const classNames: string[] = [S.tabRowContainer];
		if (row.fullWidthRowInViewMode && !edit) {
			classNames.push(S.tabRowFullWidth);
		} else if (row.type === ERowType.checkbox) {
			classNames.push(S.tabRowFullWidth);
		} else if (row.type === ERowType.radio) {
			classNames.push(S.tabRowFullWidth);
		}
		return classNames.join(' ');
	}

	function buildRadioOptions() {
		const values = row.options.split(',');
		const labels = row.label.split(',');

		return values.map((value: string, index: number) => {
			return <FormControlLabel key={value} value={`${value}`} control={<Radio />} label={labels[index]} />;
		});
	}

	function handleTransportChanged(event: any) {
		const value = Number(event.target.value);

		if (row.projectOptions.installByNorbec && value === ETransportType.PICK_UP) {
			useModal({
				content: (
					<div>{T.ModalTransportAlertPickUpText}</div>
				),
				title: T.ModalTransportAlertPickUpTitle,
				iconType: ModalIconType.Warning,
				color: S.orange,
				exitLabel: T.GeneralNo,
				yesLabel: T.GeneralYes,
				yesAction: () => {
					updateRow(value, FormInputState.Valid);
					if (!edit && row.canEditInViewMode && forceUpdateProject) {
						forceUpdateProject(true);
					}
					return true;
				},
			});
			changeYesActionState(true);
		} else {
			updateRow(value, inputState.state);
			if (!edit && row.canEditInViewMode && forceUpdateProject) {
				forceUpdateProject(true);
			}
			if (edit && value === ETransportType.BY_NORBEC && row.projectOptions.priceTransport) {
				if (updateGroupInRow && rowHasChanged) {
					updateGroupInRow([
						{
							inputName: 'transportPriceOverride',
							value: row.projectOptions.priceTransport > 0 ? row.projectOptions.priceTransport.toFixed(2) : 0,
						},
					]);
					rowHasChanged();
				}
			}
		}
	}

	function handleInstallationChanged(event: any) {
		const isChecked: boolean = event.target.checked;

		if (isChecked && (row.projectOptions.transportType === ETransportType.PICK_UP)) {
			useModal({
				content: (
					<div>{T.ModalTransportAlertByNorbecText}</div>
				),
				title: T.ModalTransportAlertByNorbecTitle,
				iconType: ModalIconType.Warning,
				color: S.orange,
				exitLabel: T.GeneralNo,
				yesLabel: T.GeneralYes,
				yesAction: () => {
					updateRow(isChecked, inputState.state);
					if (!edit && row.canEditInViewMode && forceUpdateProject) {
						forceUpdateProject(true);
					}
					return true;
				},
			});
			changeYesActionState(true);
		} else {
			updateRow(isChecked, inputState.state);
			if (!edit && row.canEditInViewMode && forceUpdateProject) {
				forceUpdateProject(true);
			}
		}
	}

	function formatCitiesForProvince(results: any[]): IDropdownOption[] {
		const villes: any[] = [{ label: '', value: '' }];
		results?.map((ville) => {
			villes.push({
				label: ville,
				value: ville,
			});
		});
		return villes || [];
	}

	function getVendeurs(options: IDropdownOption[], value: string): any[] {
		const splitted = value.split(';');
		const salesRepCodes: IDropdownOption[] = [];
		splitted.forEach((element) => {
			const item: IDropdownOption | undefined = options.find((vendeur: IDropdownOption) => vendeur.value === element);
			if (item) { salesRepCodes.push(item); }
		});
		return (salesRepCodes);
	}

	return (
		<div className={getRowClassName()}>
			{edit ? (
				<>
					{row.type === ERowType.checkbox && (
						<div className={`${S.tabRowCheckboxContainer} edit-mode`}>
							<Checkbox
								id={row.label}
								checked={inputState.value}
								color="primary"
								onChange={handleInstallationChanged}
							/>
							<label htmlFor={row.label}>{row.label}</label>
						</div>
					)}
					{row.type === ERowType.radio && (
						<div className={`${S.tabRowRadioContainer} edit-mode`}>
							<RadioGroup
								aria-label={row.inputName}
								name={row.inputName}
								value={`${inputState.value}`}
								onChange={handleTransportChanged}
							>
								{buildRadioOptions()}
							</RadioGroup>
						</div>
					)}
					{row.type === ERowType.string && (
						<Input
							{...row.inputProps}
							onChange={(e, newInputState) => { updateRow(e.target.value, newInputState); }}
							labelText={row.label}
							inputType={row.inputProps?.inputType ? row.inputProps?.inputType : 'text'}
							name={row.inputName}
							className="admin-client"
							value={inputState.value}
						/>
					)}
					{row.type === ERowType.number && (
						<Input
							{...row.inputProps}
							onChange={(e, newInputState) => { updateRow(e.target.value, newInputState); }}
							labelText={row.label}
							inputType={row.inputProps?.inputType ? row.inputProps?.inputType : 'number'}
							name={row.inputName}
							className="admin-client"
							value={inputState.value}
						/>
					)}
					{row.type === ERowType.contact && (
						<Dropdown
							options={row.options.map((contact: any, index: number) => ({
								label: contact.name,
								value: index,
							}))}
							label={row.label}
							onSelect={(e) => {
								const selectedOption: IContact = row.options[e.value];
								if (updateGroupInRow && rowHasChanged) {
									updateGroupInRow([
										{
											inputName: 'contact.name',
											value: selectedOption.name,
										},
										{
											inputName: 'contact.phoneNum',
											value: selectedOption.phoneNum || '',
										},
										{
											inputName: 'contact.phoneExt',
											value: '',
										},
										{
											inputName: 'contact.cellPhoneNum',
											value: selectedOption.cellPhoneNum || '',
										},
										{
											inputName: 'contact.email',
											value: selectedOption.email || '',
										},
										{
											inputName: 'contact.faxNum',
											value: selectedOption.faxNum || '',
										},
										{
											inputName: 'contact.epicorCustId',
											value: selectedOption.epicorCustId || '',
										},
										{
											inputName: 'contact.epicorConNum',
											value: selectedOption.epicorConNum || '',
										},
									]);
									rowHasChanged();
								}
							}}
							value={inputState.value}
							placeholder={inputState.value ? inputState.value : T.GeneralDropdownChoosePlaceholder}
							disabled={false}
							isClearable={false}
							isMulti={false}
							isOptional={false}
						/>
					)}
					{row.type === ERowType.language && (
						<Dropdown
							options={[{ label: T.QuoteLanguageSectionCheckboxFrLabel, value: 'frc' }, { label: T.QuoteLanguageSectionCheckboxEnLabel, value: 'enu' }]}
							label={row.label}
							onSelect={(e) => { updateRow(e.value, inputState.state); }}
							value={inputState.value}
							placeholder={inputState.value}
							disabled={false}
							isClearable={false}
							isMulti={false}
							isOptional={false}
						/>
					)}
					{row.type === ERowType.status && (
						<Dropdown
							options={[{ label: T.CustomerStatusActive, value: 'true' }, { label: T.CustomerStatusInactive, value: 'false' }]}
							label={row.label}
							onSelect={(e) => { console.log(inputState.state); updateRow(e.value, inputState.state); }}
							value={inputState.value}
							placeholder={inputState.value}
							disabled={row.inputProps?.disabled}
							isClearable={false}
							isMulti={false}
							isOptional={false}
						/>
					)}
					{row.type === ERowType.country && (
						<>
							<Dropdown
								options={row.options.map((pays: any, index: number) => ({ label: pays.label, value: pays.value }))}
								label={row.label}
								onSelect={(e) => {
									const selectedOption: any = row.options.find((pays: { value: any; }) => pays.value === e.value);
									if (updateGroupInRow && rowHasChanged) {
										const items: any[] = [];
										if (row.value !== selectedOption.value) {
											items.push(
												{
													inputName: 'country',
													value: selectedOption.value,
												},
												{
													inputName: 'state',
													value: '',
													options: selectedOption.provinces || '',
												},
												{
													inputName: 'city',
													value: '',
												},
												{
													inputName: 'zip',
													value: '',
												},
											);
										}
										updateGroupInRow(items);
										rowHasChanged();
									}
								}}
								value={inputState.value}
								placeholder={inputState.value}
								disabled={false}
								isClearable={false}
								isMulti={false}
								isOptional={false}
							/>
							<div style={{ paddingTop: '20px' }} />
						</>
					)}
					{row.type === ERowType.province && (
						<>
							<Dropdown
								options={row.options}
								label={row.label}
								onSelect={(e) => {
									if (e !== null) {
										getCitiesForProvince(e.value).then((results) => {
											if (updateGroupInRow && rowHasChanged) {
												updateGroupInRow([
													{
														inputName: 'state',
														value: e.value,
													},
													{
														inputName: 'city',
														value: '',
														options: formatCitiesForProvince(results),
													},
													{
														inputName: 'zip',
														value: '',
													},
												]);
												rowHasChanged();
											}
										});
									}
								}}
								value={inputState.value}
								placeholder={T[inputState.value]}
								disabled={false}
								isClearable={false}
								isMulti={false}
								isOptional={false}
							/>
							<div style={{ paddingTop: '20px' }} />
						</>
					)}
					{row.type === ERowType.city && (
						<Dropdown
							options={row.options}
							label={row.label}
							// onSelect={(e) => { updateRow(e.value, inputState.state); }}
							onSelect={(e) => {
								if (e !== null) {
									if (updateGroupInRow && rowHasChanged) {
										updateGroupInRow([
											{
												inputName: 'city',
												value: e.value,
											},
											{
												inputName: 'zip',
												value: '',
											},
										]);
										rowHasChanged();
									}
								}
							}}
							value={inputState.value}
							placeholder={T[inputState.value]}
							disabled={false}
							isClearable={false}
							isMulti={false}
							isOptional={false}
						/>
					)}
					{row.type === ERowType.territory && (
						<>
							<Dropdown
								options={row.options}
								label={row.label}
								onSelect={(e) => { updateRow(e.value, inputState.state); }}
								value={inputState.value}
								// placeholder={T[inputState.value]}
								placeholder={row.options[0].options.find((territoire: IDropdownOption) => territoire.value === inputState.value)?.label || row.options[1].options.find((territoire: IDropdownOption) => territoire.value === inputState.value)?.label}
								disabled={row.inputProps?.disabled}
								isClearable={false}
								isMulti={false}
								isOptional={false}
							/>
							<div style={{ paddingTop: '20px' }} />
						</>
					)}
					{row.type === ERowType.vendeur && (
						<>
							<b>{row.label}</b>
							<Select
								// isMulti
								styles={styleDropDown}
								placeholder={T[inputState.value]}
								value={getVendeurs(row.options, inputState.value)}
								options={row.options}
								onChange={(e) => {
									let salesReps: string = '';
									if (Array.isArray(e)) {
										e.forEach((element) => {
											salesReps = salesReps.concat(element.value as string).concat(';');
										});
										salesReps = salesReps.substring(0, salesReps.length - 1);
									} else {
										salesReps = e.value;
									}
									if (updateGroupInRow && rowHasChanged) {
										updateGroupInRow([
											{
												inputName: 'salesrepCode',
												value: salesReps,
											},
										]);
										rowHasChanged();
									}
								}}
								getOptionLabel={(x) => x.label}
								getOptionValue={(x) => x.value}
								isDisabled={row.inputProps?.disabled}
							/>
						</>
					)}
					{row.type === ERowType.groupeIndustrie && (
						<>
							<Dropdown
								options={row.options}
								label={row.label}
								onSelect={(e) => { updateRow(e.value, inputState.state); }}
								value={inputState.value}
								placeholder={T[inputState.value]}
								disabled={row.inputProps?.disabled}
								isClearable={false}
								isMulti={false}
								isOptional={false}
							/>
							<div style={{ paddingTop: '20px' }} />
						</>
					)}
					{row.type === ERowType.potential && (
						<>
							<Dropdown
								options={row.options}
								label={row.label}
								onSelect={(e) => { updateRow(e.value, inputState.state); }}
								value={inputState.value}
								placeholder={T[inputState.value]}
								disabled={row.inputProps?.disabled}
								isClearable={false}
								isMulti={false}
								isOptional={false}
							/>
							<div style={{ paddingTop: '20px' }} />
						</>
					)}
					{row.type === ERowType.data && (
						<>
							<div className={'mvp-autocomplete'} onClick={(e) => e.stopPropagation()}>
								<AutoComplete
									alert={T.ShippingLocationCityNoCalculationText}
									suggestionNotice={T.ShippingLocationCityDropdownWarningText}
									label={T.ShippingLocationCityLabel}
									noResultsText={T.ShippingLocationCityDropdownNotFound}
									value={inputState.value ? `${inputState.value}` : ''}
									onChange={(inputValue: ISuggestion, newInputState) => { updateRow(inputValue, !!newInputState ? newInputState : FormInputState.Valid); }}
									suggestions={cities}
									onFetch={handleCitiesFetch}
									onClear={() => setCities([])}
									isLoading={isLoading}
									autoComplete="new-password"
									isOptional={row.inputProps?.isOptional}
								/>
							</div>
						</>
					)}
					{row.type === ERowType.date && (
						<>
							<DatePicker
								onChange={(d) => { updateRow(Array.isArray(d) ? d[0] : d, inputState.state); }}
								placeholderText={T.GeneralDatePlaceholder}
								minDate={addDays(new Date(), 15)}
								selected={inputState.value ? new Date(formatTimestamp(inputState.value, 'yyyy-MM-dd') + ' 00:00') : null}
								dateFormat={'yyyy-MM-dd'}
								className={'mvp'}
							/>
						</>
					)}
				</>
			) : (
				<>
					{row.type === ERowType.checkbox || row.type === ERowType.radio || row.type === ERowType.table ? (
						<>
							{row.type === ERowType.checkbox && (
								<div className={S.tabRowCheckboxContainer}>
									<Checkbox
										id={row.label}
										checked={inputState.value}
										color="primary"
										onChange={handleInstallationChanged}
									/>
									<label htmlFor={row.label}>{row.label}</label>
								</div>
							)}
							{row.type === ERowType.radio && (
								<div className={S.tabRowRadioContainer}>
									<RadioGroup
										aria-label={row.inputName}
										name={row.inputName}
										value={`${inputState.value}`}
										onChange={handleTransportChanged}
									>
										{buildRadioOptions()}
									</RadioGroup>
								</div>
							)}
							{row.type === ERowType.table && (
								<div className={S.tabRowTableContainer}>
									<SimpleTable
										dataFetcher={row.options}
										dataFetchParam={row.projectOptions.id}
										showTotal
										columns={[
											{ key: 'palette', headerLabel: T.TabTransportInstallationSectionTableHeaderSid, width: '14%' },
											{ key: 'largeur', headerLabel: T.TabTransportInstallationSectionTableHeaderWidth, width: '14%', render: (v: any) => Math.ceil(v) },
											{ key: 'longueur', headerLabel: T.TabTransportInstallationSectionTableHeaderLength, width: '14%', render: (v: any) => Math.ceil(v) },
											{ key: 'hauteur', headerLabel: T.TabTransportInstallationSectionTableHeaderHeight, width: '14%', render: (v: any) => Math.ceil(v) },
											{ key: 'poids', headerLabel: T.TabTransportInstallationSectionTableHeaderWeight, width: '14%', render: (v: any) => Math.ceil(v), sumValue: true, unit: 'lbs' },
											{ key: 'notes', headerLabel: T.TabTransportInstallationSectionTableHeaderNote, width: '30%' },
										]}
									/>
								</div>
							)}
						</>
					)
						:
						<InfoLabelTile dateExpire={row.dateExpire} label={row.label} information={row.render ? row.render(row.value) : row.value} />
					}
				</>
			)}
		</div>
	);
}
