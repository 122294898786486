import { NorbecLogoSmall, SizeItLogoSmall } from '@components/svg/';
import { ILocaleData } from '@i18n';
import React from 'react';

import S from './styles/PrintablePage.styl';

interface IPrintablePageFooterProps {
	pageNumber: number;
	pageTotal: number;
	locale: ILocaleData;
}

export default function PrintablePageFooter(props: IPrintablePageFooterProps) {
	const T = props.locale;

	return (
		<div
			style={{
				backgroundColor: S.darkBlueGrayHex25,
				fontSize: '11px',
			}}
		>
			<div
				style={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-around',
					height: '25px',
				}}
			>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<span style={{ marginRight: '5px' }}>{T.PdfDoneBy}</span>
					<SizeItLogoSmall />
				</div>
				<div>{T.PdfAddress}</div>
				<div dangerouslySetInnerHTML={{ __html: T.PdfContact }} />

				<div
					style={{
						backgroundColor: S.white,
						borderRadius: '4px',
						color: S.darkGray,
						padding: '5px',
					}}
				>
					<b style={{ marginRight: '2px' }}>{T.PdfPage}</b> {props.pageNumber} / {props.pageTotal}
				</div>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<NorbecLogoSmall />
				</div>
			</div>
			<div
				style={{
					backgroundColor: S.blueNorbec,
					height: '5px',
				}}
			/>
		</div>
	);
}
