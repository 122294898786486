export interface IPricingItem {
	extension: number;
	infos: IPricingItemInfos[] | any;
	item: any;
	name: string;
	qtes: number[];
	type: string;
	unitPrice: number;
}

export interface IPricingItemInfos {
	condAmps: string;
	condBtu: number;
	condHp: string;
	condPartNum: string;
	condTechnicalSheetUrl: string;
	condVolts: string;
	coolingType: any;
	evapBtu: number;
	evapPartNum: string;
	evapTechnicalSheetUrl: string;
	kitTechnicalSheetUrl: string;
	neededBtu: number;
	price: number;
	package?: string;
}

export function fromPricingItemsDTO(pricing: IPricingItem[]): IPricingItem[] {
	return pricing.map((pricingItem) => {
		return fromPricingItemDTO(pricingItem);
	});
}

export function fromPricingItemDTO(pricingItem: IPricingItem): IPricingItem {
	return {
		extension: pricingItem.extension,
		infos: pricingItem.infos,
		item: pricingItem.item,
		name: pricingItem.name,
		qtes: pricingItem.qtes,
		type: pricingItem.type,
		unitPrice: pricingItem.unitPrice,
	};
}
