import { IDropdownOption } from '@components/inputs/SimpleDropdown';
import { Chevron, ChevronDirection, MoreIcon } from '@components/svg';
import { useLocale } from '@i18n';
import { floorMaterialStepDescription } from 'app/routes/size-it/steps/styles/FloorMaterialStep.styl';
import React, { CSSProperties, ReactElement, useState } from 'react';
import Select, {
	components,
	IndicatorProps,
	MenuPlacement,
	MenuProps,
	OptionProps,
	StylesConfig,
	ValueContainerProps,
	ValueType,
} from 'react-select';
import { OptionTypeBase } from 'react-select/src/types';
import S from './styles/Dropdown.styl';

export interface IDropdownButtonActionOption extends IDropdownOption {
	icon: ReactElement;
	action: () => void;
}

interface IDropdownButtonActionProps {
	options: IDropdownButtonActionOption[];
	onSelect: (e: any) => void;
	value?: string;
	disabled?: boolean;
	horizontalOpenMenuPosition: string;
	menuWidth: string;
	verticalMenuPlacement: MenuPlacement;
	showDropdownIndicator: boolean;
	buttonContent: string | ReactElement;
	className: string;
}

const colourStyles: StylesConfig = {
	option: (styles, { hasValue, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			backgroundColor: isFocused
				? S.blueGray50ish
				: 'white',
			color: isDisabled
				? S.lightGray
				: isSelected
					? S.blueNorbec
					: isFocused
						? S.darkGray
						: S.darkGray,
			cursor: isDisabled ? 'not-allowed' : 'default',
			borderBottom: `1px solid ${S.blueGray25}`,
		};
	},
	menu: (styles, { hasValue, isDisabled, isFocused, isSelected }) => ({
		...styles,
		borderRadius: '8px',
		backgroundColor: 'white',
		marginTop: '10px',
		border: '0 !important',
	}),
	menuList: (styles, { hasValue, isDisabled, isFocused, isSelected }) => ({
		...styles,
		borderRadius: '8px',
		padding: 0,
		backgroundColor: 'white',
	}),
	control: (styles, { hasValue, menuIsOpen, isDisabled, isFocused }) => ({
		...styles,
		border: `1px solid ${S.blueGray50} !important`,
		borderRadius: '8px',
		boxShadow: 'none !important',
		transition: '500ms',
		cursor: 'pointer !important',
		color: S.darkGray,
		fontSize: S.fontSizeNormal,
		fontWeight: S.fontWeightRegular as any,
		height: '48px',
	}),
	valueContainer: (styles, { data }) => ({ ...styles, padding: '2px 20px' }),
};

const StyledChevron = (props: IndicatorProps<OptionTypeBase>) => {
	return (
		<components.DropdownIndicator {...props}>
			<Chevron
				color={props.selectProps.menuIsOpen ? S.blueNorbec : S.darkBlueGray}
				direction={props.selectProps.menuIsOpen ? ChevronDirection.UP : ChevronDirection.DOWN}
			/>
		</components.DropdownIndicator>
	);
};

const Option = (props: OptionProps<any>) => {
	return (
		<components.Option {...props} >
			<div className={S.dropdownButtonActionOptionContainer}>
				<div className={S.dropdownButtonActionOptionContainerIcon}>
					{props.data.icon}
				</div>
				<span className={S.dropdownButtonActionOptionContainerText}>
					{props.label}
				</span>
			</div>
		</components.Option>
	);
};

export default function DropdownButtonAction(props: IDropdownButtonActionProps) {
	const T = useLocale();
	const [selectedOption, setSelectedOption] = useState<ValueType<IDropdownButtonActionOption>>([]);

	const Menu = (propsMenu: MenuProps<OptionTypeBase>) => {
		return (
			<div style={getMenuStyles(propsMenu)}>
				<components.Menu {...propsMenu}>{propsMenu.children}</components.Menu>
			</div>
		);
	};

	const ValueContainer = ({ children, ...valueContainerProps }: ValueContainerProps<any>) => {
		const { hasValue } = valueContainerProps;

		return (
			<components.ValueContainer {...valueContainerProps}>
				<div className={S.dropdownButtonInput}>
					{props.buttonContent}
					{!hasValue && (
						children
					)}
				</div>
			</components.ValueContainer>
		);
	};

	const getMenuStyles = (propsMenu: any): CSSProperties => {
		const menuStyle: CSSProperties = {
			position: 'absolute',
			minWidth: props.menuWidth,
			left: props.horizontalOpenMenuPosition === 'left' ? '0' : 'unset',
			right: props.horizontalOpenMenuPosition === 'right' ? '0' : 'unset',
		};

		if (props.verticalMenuPlacement === 'top' || propsMenu.placement === 'top') {
			menuStyle.bottom = '100%';
		}

		if (props.verticalMenuPlacement === 'bottom' || propsMenu.placement === 'bottom') {
			menuStyle.top = '100%';
		}

		return menuStyle;
	};

	const handleDropdownChange = (option: ValueType<IDropdownButtonActionOption>) => {
		props.onSelect(option);
		setSelectedOption([]);
	};

	return (
		<>
			<Select
				components={{
					DropdownIndicator: props.showDropdownIndicator ? StyledChevron : null,
					IndicatorSeparator: null,
					Menu,
					Option,
					ValueContainer,
				}}
				onChange={
					(option) => handleDropdownChange(option as ValueType<IDropdownButtonActionOption>)
				}
				placeholder={null}
				options={props.options}
				value={selectedOption as ValueType<IDropdownButtonActionOption>}
				styles={colourStyles}
				isClearable={false}
				isMulti={false}
				isSearchable={false}
				className={`dropdown dropdown-button-action dropdown-button-icon ${props.className}`}
				classNamePrefix={'custom'}
				closeMenuOnSelect={true}
				isDisabled={props.disabled}
				controlShouldRenderValue={false}
				hideSelectedOptions={false}
				menuPlacement={props.verticalMenuPlacement}
				blurInputOnSelect
			/>
		</>
	);
}

DropdownButtonAction.defaultProps = {
	disabled: false,
	horizontalOpenMenuPosition: 'left',
	menuWidth: '258px',
	verticalMenuPlacement: 'auto',
	showDropdownIndicator: false,
	buttonContent: <MoreIcon />,
	className: '',
};
