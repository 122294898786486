import { IStoreState } from '@models';
import { IRoom, toRoomDTO } from './room';

export interface IGroup {
    id?: number;
	rooms: IRoom[];
	appProjectId: number|undefined;
}

export function toGroupDTO(state: IStoreState): IGroup {
	return {
		appProjectId: state.project.id,
		rooms: state.group.rooms.map((room) => toRoomDTO(room)),
	};
}
