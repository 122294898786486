import AlertPrice from '@components/labels/AlertPrice';
import PriceContentSection from '@components/labels/PriceContentSection';
import { Loading } from '@components/layout/index';
import { CloseIcon } from '@components/svg';
import { useLocale } from '@i18n';
import { ECurrencyCode, IPricingItem, State } from '@models';
import { useStore } from '@store/index';
import FormatUtils from '@utils/format';
import { installShouldBeShown, transportShouldBeShown } from '@utils/messages';
import {
	getInstallationPrice,
	getPrice,
	getPriceSections,
	getTotalPrice,
	getTransportPrice,
	IPriceSections,
} from '@utils/pricing';
import classnames from 'classnames';
import React, { useState } from 'react';
import GS from '../../styles/global.styl';
import S from './styles/PriceModalContent.styl';

interface IPriceContentSection {
	key: string;
	mainText?: string;
	price?: string;
	subText?: string;
	linkUrl?: string;
	linkText?: string;
	textColor: string;
	backgroundColor: string;
	fontSize?: string;
	sectionType?: string;
	alertException?: string;
}

interface IPriceModalProps {
	onClick?: () => void;
	priceFetch: IPricingItem[];
	isLoading: boolean;
}

export default function PriceModalContent(props: IPriceModalProps) {
	const T = useLocale();
	const { actions, state, dispatch } = useStore();
	const [timeout, setTimeout] = useState<number>(0);
	const [buttonState, setButtonState] = useState<State>(State.Enabled);
	const prices = props.priceFetch;
	const priceSections: IPriceSections = getPriceSections(prices);
	const showInstallAlert = !installShouldBeShown(priceSections.installation.extension, state.project.installValidated, state.project.installByNorbec, state.project.installPriceOverride);
	const showTransportAlert = !transportShouldBeShown(priceSections.transport.extension, state.project.transportType, state.project.transportValidated, state.project.transportPriceOverride);

	function getConfigurationProps() {
		const configurationProps: IPriceContentSection = {
			key: T.PriceConfigurationLabelBloc1,
			backgroundColor: GS.blueGray50ish,
			textColor: GS.darkGray,
			mainText: T.PriceConfigurationLabelBloc1,
		};

		configurationProps.price = getPrice(priceSections.configuration);
		return configurationProps;
	}

	function getCoolingSystemProps() {
		const coolingSystemProps: IPriceContentSection = {
			key: T.PriceRefrigerationLabelBloc2,
			backgroundColor: GS.blueGray50ish,
			textColor: GS.darkGray,
			mainText: T.PriceRefrigerationLabelBloc2,
		};

		coolingSystemProps.price = getPrice(priceSections.coolingSystem);
		return coolingSystemProps;
	}

	function getInstallationProps() {
		const installationProps: IPriceContentSection = {
			key: T.PriceInstallationLabelBloc3,
			backgroundColor: GS.blueGray50ish,
			textColor: GS.darkGray,
			mainText: T.PriceInstallationLabelBloc3,
		};

		if (showInstallAlert) {
			installationProps.price = '-';
			installationProps.subText = T.PriceAlertNoBloc3CalculationFromCity;
			installationProps.sectionType = 'textSection';
		} else {
			installationProps.price = FormatUtils.formatDecimalPrice(
				getInstallationPrice(priceSections?.installation?.extension, state.project.installValidated, state.project.installByNorbec, state.project.installPriceOverride),
			);
		}

		return installationProps;
	}

	function getSavingProps() {
		const hasSaving = priceSections.bundleRebate && priceSections.bundleRebate.extension > 0;

		const savingProps: IPriceContentSection = {
			key: T.PriceSavingsLabel,
			backgroundColor: GS.blueNorbec10,
			textColor: GS.blueNorbec,
			sectionType: 'priceSection',
		};

		if (hasSaving) {
			savingProps.fontSize = GS.fontSizeXsmall;
			savingProps.mainText = T.PriceSavingsLabel;
			savingProps.linkUrl = state.settings.serviceOfferInfoSheetUrl;
			savingProps.linkText = T.PriceSavingsDetailsLabel;
			savingProps.price = `-${getPrice(priceSections.bundleRebate)}`;

		} else if (!hasSaving && showInstallAlert) {
			savingProps.backgroundColor = GS.white;
			savingProps.textColor = GS.darkGray;
			savingProps.mainText = T.PriceSavingsLabel;
			savingProps.fontSize = GS.fontSizeXsmall;
			savingProps.price = '-';
			savingProps.linkUrl = state.settings.serviceOfferInfoSheetUrl;
			savingProps.linkText = T.PriceNoSavingCalculationBtnLabel;
			savingProps.subText = T.PriceAlertNoSavingCalculationFromCity;

		} else {
			savingProps.backgroundColor = GS.white;
			savingProps.textColor = GS.darkGray;
			savingProps.subText = T.PriceNoSavingsText;
			savingProps.sectionType = 'textSection';
			savingProps.linkUrl = state.settings.serviceOfferInfoSheetUrl;
			savingProps.linkText = T.PriceNoSavingsBtnLabel;
		}

		return savingProps;
	}

	function getPremiumOptionsProps() {
		const premiumOptionsProps: IPriceContentSection = {
			key: T.PricePremiumOptionsLabelBloc4,
			backgroundColor: GS.blueGray50ish,
			textColor: GS.darkGray,
			mainText: T.PricePremiumOptionsLabelBloc4,
		};

		premiumOptionsProps.price = getPrice(priceSections.options);

		return premiumOptionsProps;
	}

	function getTransportProps() {
		const shippingProps: IPriceContentSection = {
			key: T.PriceShippingLabelBloc5,
			backgroundColor: GS.blueGray50ish,
			textColor: GS.darkGray,
			mainText: T.PriceShippingLabelBloc5,
		};

		if (showTransportAlert) {
			shippingProps.price = '-';
			shippingProps.subText = T.PriceAlertNoBloc5CalculationFromCity;
			shippingProps.sectionType = 'textSection';
		} else {
			shippingProps.price = FormatUtils.formatDecimalPrice(
				getTransportPrice(priceSections.transport.extension, state.project.transportType, state.project.transportValidated, state.project.transportPriceOverride),
			);
		}

		return shippingProps;
	}

	function getTotalPriceProps() {
		const totalPriceProps: IPriceContentSection = {
			key: T.PriceTotalPriceLabel,
			backgroundColor: GS.darkBlueGray,
			textColor: GS.white,
			mainText: T.PriceTotalPriceLabel,
			sectionType: 'totalSection',
		};

		if (showInstallAlert && showTransportAlert) {
			totalPriceProps.alertException = T.PriceAlertNoInstallationAndShippingCalculation;
		} else if (!showInstallAlert && showTransportAlert) {
			totalPriceProps.alertException = T.PriceAlertNoShippingCalculation;
		} else if (!showTransportAlert && showInstallAlert) {
			totalPriceProps.alertException = T.PriceAlertNoInstallationCalculation;
		}

		totalPriceProps.price = getTotalPrice(priceSections, state.project.transportType, state.project.installPriceOverride, state.project.installValidated, state.project.installByNorbec, state.project.transportPriceOverride, state.project.transportValidated);
		totalPriceProps.subText = state.project.currencyCode === ECurrencyCode.USD ? T.PriceUsdTaxesLabel : T.PriceCadTaxesLabel;

		return totalPriceProps;
	}

	function handleClick() {
		if (props.onClick) {

			setButtonState(State.Focused);

			if (timeout) {
				window.clearTimeout(timeout);
			}

			setTimeout(window.setTimeout(() => {
				if (props.onClick) {
					props.onClick();
				}
				setButtonState(State.Enabled);
			}, 50));
		}
	}

	function handleClickClose() {
		dispatch(actions.LayoutActions.setPriceDetailsIsShown(false));
	}

	return (
		<>
			<div className={S.priceModal}>
				{!state.layout.isMobile && (
					<div className={S.priceModalCloseButtonContainer}>
						<div
							className={S.priceModalCloseButtonContainerTab}
							onClick={handleClick}
							onMouseOver={() => setButtonState(State.Focused)}
							onMouseLeave={() => setButtonState(State.Enabled)}
						>
							<CloseIcon stroke={buttonState === State.Enabled ? GS.darkBlueGray : GS.orange} />
							<span className={S.priceModalCloseButtonContainerTabText}>
								{T.GeneralClose}
							</span>
						</div>
					</div>
				)}

				{props.isLoading && (
					<div className={S.priceModalLoaderContainer}>
						<Loading />
					</div>
				)}

				{!props.isLoading && prices && (
					<>
						<AlertPrice showAlert={showInstallAlert || showTransportAlert} />

						<div
							className={classnames(S.priceModalContent, {
								[S.priceModalContentNoAlert]: !showInstallAlert && !showTransportAlert,
							})}
						>
							<div className={S.priceModalContentItems}>
								<PriceContentSection
									{...getConfigurationProps()}
								/>
								<PriceContentSection
									{...getCoolingSystemProps()}
								/>
								<PriceContentSection
									{...getInstallationProps()}
								/>
								<PriceContentSection
									{...getSavingProps()}
								/>
								<PriceContentSection
									{...getPremiumOptionsProps()}
								/>
								<PriceContentSection
									{...getTransportProps()}
								/>
							</div>
							<PriceContentSection
								{...getTotalPriceProps()}
							/>
						</div>
					</>
				)}
			</div>

			{state.layout.isMobile && (
				<div className={S.priceModalCloseButtonContainerMobile}>
					<div className={S.priceModalCloseButtonContainerMobileButton} onClick={handleClickClose}>
						<CloseIcon stroke={S.blueNorbec} />
						<div className={S.priceModalCloseButtonContainerMobileButtonClose}>
							{T.GeneralClose}
						</div>
					</div>
				</div>
			)}
		</>
	);
}
