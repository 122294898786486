import { ICoolingSystemOptionState, State } from '@models';

export interface ICoolingSystemOption {
	id: number;
	name: string;
	price: number;
	unit: string;
	description: string;
	technicalSheetUrl: string;
	dependsOn?: number;
	imageUrl: string;
	lifeCycle?: number;
}

export function fromCoolingSystemOptionsDTO(
	coolingSystemOptions: ICoolingSystemOption[],
): ICoolingSystemOptionState[] {
	return coolingSystemOptions.map((coolingSystemOption) => {
		return {
			id: coolingSystemOption.id,
			name: coolingSystemOption.name,
			price: coolingSystemOption.price,
			unit: coolingSystemOption.unit,
			description: coolingSystemOption.description,
			technicalSheetUrl: coolingSystemOption.technicalSheetUrl,
			dependsOn: coolingSystemOption.dependsOn,
			imageUrl: coolingSystemOption.imageUrl,
			state: State.Enabled,
			lifeCycle: coolingSystemOption.lifeCycle,
		};
	});
}

export function toCoolingSystemOptionsDTO(
	coolingSystemOptions: ICoolingSystemOptionState[],
): ICoolingSystemOption[] {
	return coolingSystemOptions.map((coolingSystemOption) => {
		return {
			id: coolingSystemOption.id,
			name: coolingSystemOption.name,
			price: coolingSystemOption.price,
			unit: coolingSystemOption.unit,
			description: coolingSystemOption.description,
			technicalSheetUrl: coolingSystemOption.technicalSheetUrl,
			dependsOn: coolingSystemOption.dependsOn,
			imageUrl: coolingSystemOption.imageUrl,
			state: State.Enabled,
		};
	});
}
