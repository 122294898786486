import { TagLabel } from '@components/labels';
import { useLocale } from '@i18n';
import React from 'react';
import S from './styles/NotFound.styl';

export default function NotFound() {
	const T = useLocale();

	return (
		<div className={S.notFoundPage}>
			<TagLabel title={T.NotFound404}/>
			<h1 className={S.notFoundPageTitle}>{T.NotFoundOops}</h1>
			<h2 className={S.notFoundPageHeader}>{T.NotFoundTitle}</h2>
			<p className={S.notFoundPageDescription}>{T.NotFoundText}</p>
		</div>
	);
}
