import Header, { AppTheme } from '@components/layout/Header';
import NavSidebarDrawer from '@components/layout/NavSidebarDrawer';
import React, { useEffect, useState } from 'react';

export default function Navigation({ theme }: {theme: AppTheme}) {
	const [isNavSidebarVisible, setNavSidebarVisible] = useState<boolean>(false);

	useEffect(() => {
		if (isNavSidebarVisible) {
			document.getElementById('body')!.style.overflow = 'hidden';
		} else {
			document.getElementById('body')!.style.overflow = 'unset';
		}
		return () => {
			document.getElementById('body')!.style.overflow = 'unset';
		};
	}, [isNavSidebarVisible]);

	function handleToggleSidebarNavClick() {
		setNavSidebarVisible(!isNavSidebarVisible);
	}

	return (
		<>
			<Header theme={theme} isMenuVisible={isNavSidebarVisible} onClick={handleToggleSidebarNavClick}/>
			<NavSidebarDrawer isMenuVisible={isNavSidebarVisible} onToggleSideNavClick={handleToggleSidebarNavClick} />
		</>
	);
}

Navigation.defaultProps = {
	theme: AppTheme.MVP,
};
