import Button from '@components/buttons/Button';
import { DetailIcon, EditIcon, MoreIcon } from '@components/svg';
import { useLocale } from '@i18n';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ICustomer } from '@models';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useToastContext } from '../../hooks/contexts/UseContext';
import { ERoutes, getPathByRouteName } from '../../routes';

import S from './styles/ActionsMenu.styl';

interface IActionsMenuCustomers {
    customer: ICustomer;
    viewDetails?: boolean;
    firstActionBtn?: boolean;
}

interface IAction {
    icon: JSX.Element;
    label: string;
    action: () => void;
}

function ActionsMenuCustomers({ customer, viewDetails, firstActionBtn }: IActionsMenuCustomers) {

    const detailsAction = () => {
        history.push(
            getPathByRouteName(ERoutes.PreviewCustomer, T.locale, [
                { slug: ':id', value: `${customer.id}` },
            ]),
        );
    };

    // const modifyAction = () => {
    //     history.push(
    //         getPathByRouteName(ERoutes.EditCustomer, T.locale, [
    //             { slug: ':id', value: `${customer.id}` },
    //         ]),
    //     );
    // };

    const generateActions = (customer: ICustomer) => {
        const actions: IAction[] = [];

        const details = {
            label: T.ActionMenuDetails,
            icon: <DetailIcon color={S.darkBlueGray} />,
            action: () => detailsAction(),
        };

        actions.push(details);

        return actions;
    };

    const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
    const open = Boolean(anchorEl);
    const history = useHistory();
    const T = useLocale();
    const addToast = useToastContext();
    const [actions, setActions] = useState<IAction[]>(generateActions(customer));

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        localStorage.setItem('customerSelected', JSON.stringify(customer));
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (action: any) => {
        setAnchorEl(null);
        if (action.action) {
            action.action();
        }
    };

    useEffect(() => {
        setActions(generateActions(customer));
    }, [customer]);

    return (
        <>
            {(actions.length > 1 || viewDetails) && (
                <div className={S.actionsMenuContainer}>
                    <IconButton
                        aria-label="actions"
                        aria-controls="action-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreIcon />
                    </IconButton>
                    <Menu
                        id="action-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        transformOrigin={{
                            vertical: -60,
                            horizontal: 0,
                        }}
                        PaperProps={{
                            style: {
                                maxHeight: 48 * 4.5,
                                width: '30ch',
                            },
                        }}
                    >
                        {actions
                            .slice(firstActionBtn ? 1 : 0)
                            .map((action, index) => (
                                <MenuItem
                                    key={index}
                                    onClick={() => handleClose(action)}
                                >
                                    {action.icon}
                                    {action.label}
                                </MenuItem>
                            ))}
                    </Menu>
                </div>
            )}
            {firstActionBtn && actions[0] && (
                <Button onClick={actions[0].action} icon={actions[0].icon}>
                    {actions[0].label}
                </Button>
            )}
        </>
    );
}

export default ActionsMenuCustomers;
