// Regex
export const REGEX_NUMBER = '^\\d+(\\.\\d+)*$';
export const REGEX_EMAIL = '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+(?!.*?test)[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])';
export const REGEX_PHONE = '^\\(?([0-9]{3})\\)?[-]?([0-9]{3})[-]?([0-9]{4})$';
export const REGEX_ZIP_CODE_USA = '^(\\d{5}(?:\\-\\d{4})?)$';
export const REGEX_ZIP_CODE_CAN = '^[A-Za-z]\\d[A-Za-z][ ]?\\d[A-Za-z]\\d$';
export const ELLIPSIS = '...';
export const MAX_CHAR_COUNT_ELLIPSIS = 50;
export const DEFAULT_MAX_INPUT_CHAR_COUNT = 50;
export const STREET2_MAX_INPUT_CHAR_COUNT = 15;
