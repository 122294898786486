import React from 'react';

interface IRefreshIcon {
	stroke: string;
}

export default function RefreshIcon(props: IRefreshIcon) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
			<g stroke={props.stroke} fill="none">
				<path d="M18.477 3.5C16.17.785 12.414-.203 9.07 1.025 5.725 2.254 3.502 5.437 3.5 9v2.5" transform="rotate(90 10.5 11.5)"/>
				<path d="M6.5 7.5L3.5 11.5.5 7.5M5.523 14.505c2.308 2.716 6.065 3.703 9.41 2.474C18.276 15.749 20.5 12.564 20.5 9V6.5" transform="rotate(90 10.5 11.5)"/>
				<path d="M17.5 10.5L20.5 6.5 23.5 10.5" transform="rotate(90 10.5 11.5)"/>
			</g>
		</svg>

	);
}
