import React from 'react';

interface ICloseButtonProps {
	stroke: string;
}

export default function CloseIcon(props: ICloseButtonProps) {
	return (
		<svg width="11px" height="11px" viewBox="0 0 11 11" version="1.1">
			<g id="Maquettes-UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
				<g  className="svg-stroke-color" id="Norbec-Desktop-1280up-popup-dessin" transform="translate(-1003.000000, -170.000000)" stroke={props.stroke}>
					<g id="Pupup" transform="translate(198.000000, 145.000000)">
						<g id="BTN-ANNULER-Copy-2" transform="translate(805.000000, 23.000000)">
							<g id="BTN-ANNULER">
								<g id="ico-close-small" transform="translate(0.000000, 2.000000)">
									<g id="close" transform="translate(0.500000, 0.500000)">
										<line x1="0.208333333" y1="0.207916667" x2="9.79166667" y2="9.79125" id="Path"/>
										<line x1="9.79166667" y1="0.207916667" x2="0.208333333" y2="9.79125" id="Path"/>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
