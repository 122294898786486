import React from 'react';

interface IMuiTabPanelProps {
	value: number;
	index: number;
	children?: React.ReactNode;
}

export default function MuiTabPanel(props: IMuiTabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			className="tabPanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	);
}
