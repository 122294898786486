import LargeRadioButton from '@components/svg/LargeRadioButton';
import { State } from '@models';
import classnames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';

import S from './styles/SimpleLargeRadio.styl';

interface ISimpleLargeRadio {
	state?: State;
	title?: string;
	children?: ReactNode;
	centerRadio?: boolean;
	isLocked?: boolean;
	onRadioClicked?: (checked: boolean) => void;
	wrapTitle?: boolean;
	classname?: string;
}

export default function SimpleLargeRadio(props: ISimpleLargeRadio = {
	centerRadio: true,
	state: State.Enabled,
	isLocked: false,
}) {
	const [simpleLargeRadioState, setSimpleLargeRadioState] = useState(props.state);

	useEffect(() => {
		setSimpleLargeRadioState(props.state);
	}, [props.state]);

	function onClick() {
		if (!props.isLocked && props.onRadioClicked) {
			props.onRadioClicked(simpleLargeRadioState === State.Enabled);
		}
	}

	return (
		<div
			className={classnames(S.simpleLargeRadio, props.classname, {
				[S.simpleLargeRadioFocused]: simpleLargeRadioState === State.Focused,
				[S.simpleLargeRadioSelected]: simpleLargeRadioState === State.Selected,
				[S.simpleLargeRadioWithSmallDesc]: !!props.children,
				[S.simpleLargeRadioWithLongDesc]: !props.centerRadio,
				[S.simpleLargeRadioLocked]: !!props.isLocked,
			})}
			onClick={onClick}
		>
			<div className={S.simpleLargeRadioContent}>
				<div
					className={classnames(S.simpleLargeRadioContentTitle, {
						[S.simpleLargeRadioContentTitleFocused]: simpleLargeRadioState === State.Focused,
						[S.simpleLargeRadioContentTitleSelected]: simpleLargeRadioState === State.Selected,
						[S.simpleLargeRadioContentTitleLocked]: !!props.isLocked,
						[S.simpleLargeRadioContentTitleWrapped]: props.wrapTitle,
					})}
				>
					{props.title}
				</div>
				<div className={S.simpleLargeRadioContentDescription}>
					{props.children}
				</div>
			</div>
			<div
				className={classnames(S.simpleLargeRadioButton, {
					[S.simpleLargeRadioButtonWithLongDesc]: !props.centerRadio,
				})}
			>
				{(simpleLargeRadioState === State.Enabled || !simpleLargeRadioState) &&
					<LargeRadioButton stroke={'#9FBBC4'} fill={'#FFF'} opacity={props.isLocked ? '0.5' : '1'}/>
				}
				{simpleLargeRadioState === State.Focused &&
					<LargeRadioButton stroke={'#F49800'} fill={'#F49800'} opacity={props.isLocked ? '0.5' : '1'}/>
				}
				{simpleLargeRadioState === State.Selected &&
					<LargeRadioButton stroke={'#0095C8'} fill={'#0095C8'} opacity={props.isLocked ? '0.5' : '1'}/>
				}
			</div>
		</div>
	);
}
