import { getSessionToken } from '@api/token';

export const headers = new Headers({
	'Content-Type': 'application/json',
});

export function authenticatedHeaders(lang = location.pathname.substr(1, 2)) {
	return new Headers({
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${getSessionToken()}`,
		'Accept-Language': lang,
	});
}
