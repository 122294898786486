import React from 'react';

interface ILockedIconProps {
	stroke: string;
}

export default function LockedIcon(props: ILockedIconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25">
			<g fill="none" stroke={props.stroke} transform="translate(3 1)">
				<circle cx="9" cy="15" r="1"/>
				<path d="M9 16L9 19"/>
				<rect width="17" height="14" x=".5" y="9.5" rx="1"/>
				<path d="M3.5 6C3.5 2.962 5.962.5 9 .5s5.5 2.462 5.5 5.5v3.5h-11V6z"/>
			</g>
		</svg>
	);
}
