import classnames from 'classnames';
import React from 'react';
import Loading from './Loading';

import S from './styles/Layout.styl';

export interface ILayout {
	className?: string;
	children: any;
	dataNeeded?: boolean;
}

function Layout({ children, dataNeeded }: ILayout) {
	if (!dataNeeded) {
		return children;
	}
	return <Loading />;
}

export function LayoutFullWidth({ children, className, dataNeeded }: ILayout) {

	return (
		<Layout dataNeeded={dataNeeded}>
			<div
				className={classnames(
					S.layout,
					S.layoutFullWidth,
					className,
				)}
			>
				{children}
			</div>
		</Layout>
	);
}

export function LayoutHalfAndHalf({ children, className, dataNeeded }: ILayout) {
	return (
		<Layout dataNeeded={dataNeeded}>
			<div
				className={classnames(
					S.layout,
					S.layoutHalfAndHalf,
					className,
				)}
			>
				<div className={classnames(S.half, S.halfFirst)}>
					{children[0]}
				</div>
				<div className={classnames(S.half, S.halfSecond)}>
					{children[1]}
				</div>
			</div>
		</Layout>
	);
}
