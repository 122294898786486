import React from 'react';

interface ICenterIconProps {
	stroke: string;
	opacity?: string;
}

export default function CenterIcon(props: ICenterIconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" opacity={props.opacity || '1'}>
			<g stroke={props.stroke} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
				<circle cx="9.133" cy="9.133" r="6.133"/>
				<path d="M1 9h4M13 9h4M9 13v4M9 1v4"/>
			</g>
		</svg>
	);
}
