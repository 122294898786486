import { IRoomRequirementsState } from '@models';

export interface IRoomRequirements {
	availableWallsForDoor: boolean;
	pro3Available: boolean;
}

export function fromRoomRequirementsDTO(roomRequirements: IRoomRequirements): IRoomRequirementsState {
	return {
		availableWallsForDoor: roomRequirements.availableWallsForDoor,
		pro3Available: roomRequirements.pro3Available,
	};
}

export function toRoomRequirementsDTO(roomTypeState: IRoomRequirementsState): IRoomRequirements {
	return {
		availableWallsForDoor: roomTypeState.availableWallsForDoor,
		pro3Available: roomTypeState.pro3Available,
	};
}
