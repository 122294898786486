import { getCurrentLang } from '@i18n';
import {
	Bounds,
	ETransportType,
	IDrawerSectionDefinition,
	IDrawerStepDefinition,
	IProject,
	IProjectRequirements,
	IProjectRequirementsState,
	IRoutes,
	IStoreState,
	ITransportType,
	IUrl,
	Steps,
} from '@models';
import smoothscroll from 'smoothscroll-polyfill';
import { routeByStep } from '../routes/routes-definitions';

smoothscroll.polyfill();

export enum Widths {
	FOLDED,
	NORMAL,
	FULL,
	HIDE_SUMMARY,
}

export const enum Tabs {
	installation = 'installation',
	configuration = 'configuration',
	options = 'options',
}

export function getNextStep(currentStep: Steps, lastCompletedStep: Steps, requirements: IProjectRequirementsState | IProjectRequirements, transportType: ITransportType | null) {
	const names = getStepsNames();
	let nextStepIndex = names.indexOf(Steps[currentStep]) + 1;
	let nextStep = Steps[names[nextStepIndex] as keyof typeof Steps];
	if (shouldSkipStep(nextStep, requirements as IProjectRequirementsState)) {
		nextStepIndex = names.indexOf(Steps[currentStep]) + 2;
		nextStep = Steps[names[nextStepIndex] as keyof typeof Steps];
	}
	if (nextStep === Steps.COMBO_OPTIONS) {
		if (requirements.shouldSkipCondenserDistanceStep) {
			nextStepIndex++;
		}
	}
	if (nextStep === Steps.COMBO_FLOOR_FINISH) {
		if (!requirements.hasFloor) {
			nextStepIndex += 2;
		}
	}
	if (nextStep === Steps.DELIVERY_CHOICE) {
		if (requirements.shouldSkipTransport) {
			nextStepIndex++;
		}
	}
	if (nextStep === Steps.DELIVERY_PLACE) {
		if (transportType && transportType?.id === ETransportType.PICK_UP) {
			nextStepIndex++;
		}
	}

	return Steps[names[nextStepIndex] as keyof typeof Steps];
}

export function shouldShowSection(step: Steps, state: IStoreState) {
	return state.layout.latestCompletedStep >= step || state.layout.currentStep >= step;
}

export function getStepsNames() {
	return Object.keys(Steps).filter((v) => typeof Steps[v as any] === 'number');
}

export function getCurrentTab(step: Steps): Tabs {
	return step < Bounds.optionsLowerBound ? Tabs.configuration : step > Bounds.optionsUpperBound ? Tabs.installation : Tabs.options;
}

export function getCurrentStepUrl(step: Steps, routes: string[] | string, projectId: number | undefined) {
	if (step === Steps.PROJECT_NAME) {
		return projectId ? routes[1] : routes[0];
	}
	return routes as string;
}

export function shouldSkipStep(step: Steps, requirements: IProjectRequirementsState) {
	return (requirements.shouldSkipCondenserDistanceStep && step === Steps.CONDENSER_DISTANCE) ||
	(requirements.shouldSkipComboOptionsStep && step === Steps.COMBO_OPTIONS);
}

export function shouldSkipNavSection(sectionName: string, requirements: IProjectRequirementsState) {
	return !requirements.hasFloor && (sectionName === 'Plancher' || sectionName === 'Floor');
}

export function getNext(step: Steps): Steps {
	const names = getStepsNames();
	const nextStepIndex = names.indexOf(Steps[step]) + 1;
	return Steps[names[nextStepIndex] as keyof typeof Steps];
}

export function isNextStep(currentStep: Steps, nextStep: Steps) {
	const names = getStepsNames();
	const currentStepNextIndex = names.indexOf(Steps[currentStep]) + 1;
	const nextStepIndex = names.indexOf(Steps[nextStep]);
	return currentStepNextIndex === nextStepIndex;
}

export function getStepFromUrl(url: string, routes: IRoutes) {
	let found: IDrawerStepDefinition | undefined;
	Object.keys(routes).forEach((tab) => {
		routes[tab].forEach((section) => {
			section.steps.forEach((route) => {
				Object.keys(route.url).forEach((locale) => {
					if (typeof route.url[locale] === 'string') {
						if (route.url[locale] === url) {
							found = route;
						}
					} else if ((route.url[locale] as string[]).find((r: string) => r === url)) {
						found = route;
					}
				});
			});
		});
	});
	return found;
}

export function getAbsoluteRoute(step: Steps, routes: IRoutes, lang: string, projectId: number | undefined) {
	let found: IDrawerStepDefinition | undefined;
	routes[getCurrentTab(step)].forEach((route) => {
		if (!found) {
			found = route.steps.find((_step) => _step.step === step);
		}
	});
	if (found && found.url) {
		return `/${lang}${getCurrentStepUrl(step, found.url[lang], projectId)}`;
	}
	return '';
}

function getRoutes(prefix: string, key: string | string[]): string[] {
	const urls: string[] = [];
	if (typeof key === 'string') {
		urls.push(`${prefix}${key}`);
	} else {
		key.forEach((url) => {
			urls.push(`${prefix}${url}`);
		});
	}
	return urls;
}

export function mapRoutes(step: Steps, routes: IRoutes, prefix: string) {

	let found: IDrawerStepDefinition | undefined;
	routes[getCurrentTab(step)].forEach((route) => {
		if (!found) {
			found = route.steps.find((_step) => _step.step === step);
		}
	});
	if (found && found.url) {
		const enUrls = getRoutes(prefix, found.url.en);
		const frUrls = getRoutes(prefix, found.url.fr);
		return enUrls.concat(frUrls);
	}
	return [];
}

export function shouldShowDrawing(currentStep: Steps): boolean {
	return currentStep >= Steps.COMBO_HEIGHT &&
		currentStep !== Steps.DOORS_WALL &&
        currentStep !== Steps.DOORS_RAMPE &&
		currentStep !== Steps.DOORS_WIDTH &&
		currentStep !== Steps.DOORS_POSITION &&
		currentStep !== Steps.DOORS_OPENING_DIRECTION;
}

export class DrawerContentBuilder {
	private drawerMap: IDrawerSectionDefinition[];
	private currentSection: string | undefined;

	constructor() {
		this.drawerMap = [];
	}

	public addSection(sectionName: string) {
		this.drawerMap.push({
			sectionName,
			steps: [],
		});

		this.currentSection = sectionName;
		return this;
	}

	public endSection() {
		this.currentSection = undefined;
		return this;
	}

	public addStep(stepName: string, step: Steps, url: IUrl) {
		if (!this.currentSection) {
			throw new Error('Can\'t add step without first working on a section or after closing a section');
		}

		this.drawerMap
			.find((section) => section.sectionName === this.currentSection)?.steps
			.push({
				stepName,
				step,
				url,
			});
		return this;
	}

	public build(): IDrawerSectionDefinition[] {
		return this.drawerMap;
	}
}

export function goToSummary(targetLink: string) {
	const targetNode = document.getElementById(targetLink);
	const parentNode = document.getElementById('summary-tab');
	if (parentNode && targetNode) {
		const parentOffset = parentNode?.offsetTop || 0;
		const targetOffset = targetNode?.offsetTop || 0;
		parentNode.scroll({ top: targetOffset - parentOffset - 70, behavior: 'smooth' });
	}
}

export function getSummaryRoomTargetLink(roomCode: string, stepId: number) {
	return `room-${roomCode}-step-${stepId}`;
}

/**
 * Use for action edit config or continue config from admin.
 * Return the url from forceStep if set else from project.completionStep.
 * @param project
 * @param forceStep
 */
export function getUrlFromStep(project: IProject, forceStep?: number): string {
	const { completionState, requirements, id } = project;
	const lang = getCurrentLang();
	let path = '';

	if (forceStep && forceStep > 0) {
		path = routeByStep[forceStep][lang];
	} else {
		const nextStep = completionState === Steps.QUOTE ? Steps.QUOTE : getNextStep(completionState, completionState, requirements, project.transportType);

		if (completionState === Steps.PROJECT_NAME) {
			path = id ? routeByStep[completionState][lang][1] : routeByStep[completionState][lang][0];
		} else if (typeof routeByStep[nextStep][lang] === 'string') {
			path = routeByStep[nextStep][lang];
		}
	}

	return `/${lang}${path.replace(':id', `${id}` || '')}`;
}
