import {useState, useCallback, useLayoutEffect} from "react";
import ResizeObserver from "resize-observer-polyfill";
import {debounce} from "lodash";

function getSize(el) {
	if (!el) {
		return {
			width: 0,
			height: 0,
		};
	}

	return {
		width: el.offsetWidth,
		height: el.offsetHeight,
	};
}

export default function useComponentSize(ref) {
	let _useState = useState(getSize(ref ? ref.current : {}));
	let ComponentSize = _useState[0];
	let setComponentSize = _useState[1];

	let handleResize = useCallback(debounce(function handleResize() {
		if (ref.current) {
				setComponentSize(getSize(ref.current));
			}
		}, 10), [ref],
	);

	useLayoutEffect(
		function() {
			if (!ref.current) {
				return;
			}

			handleResize();

			if (typeof ResizeObserver === "function") {
				let resizeObserver = new ResizeObserver(function() {
					handleResize();
				});
				resizeObserver.observe(ref.current);

				return function() {
					resizeObserver.disconnect();
					resizeObserver = null;
				};
			} else {
				window.addEventListener("resize", handleResize);

				return function() {
					window.removeEventListener("resize", handleResize);
				};
			}
		},
		[ref.current],
	);

	return ComponentSize;
}
