export enum FormInputState {
	Selected,
	Unselected,
	Error,
	Valid,
}

export interface IRequiredField {
	name: string;
	isValid: boolean;
}
