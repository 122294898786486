import classnames from 'classnames';
import React from 'react';

import S from './styles/FloorPill.styl';

interface IFloorPillProps {
	readonly hollow?: boolean;
	readonly isActive?: boolean;
	readonly isSelected?: boolean;
	readonly text: string;
}

export default function FloorPill({ hollow, text, isActive, isSelected }: IFloorPillProps) {
	return (
		<div
			className={classnames(S.floorPill, {
				[S.floorPillHollow]: hollow,
				[S.floorPillActive]: isActive,
				[S.floorPillSelected]: isSelected,
			})}
		>
			{text}
		</div>
	);
}
