import React from 'react';

interface ICheckedCheckbox {
	stroke: string;
	fillOpacity: string;
	fill: string;
}

export default function CheckedCheckbox(props: ICheckedCheckbox) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21">
			<g fill="none" fillRule="evenodd" stroke={props.stroke}>
				<rect width="18.182" height="18.261" x=".909" y=".913" fill={props.fill} fillOpacity={props.fillOpacity} rx="4"/>
				<path strokeWidth="2" d="M14.9 6.222l-5.462 7.334L6.1 10.86"/>
			</g>
		</svg>
	);
}
