import React from 'react';

export default function CheckIcon() {
	return (
		<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
			<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="ico-check-copy-2">
					<g id="check-circle-1" transform="translate(1.000000, 1.000000)">
						<circle id="Oval" fill="#9FBBC4" cx="11" cy="11" r="11"/>
						<polyline id="Path" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" points="16.5 6.5 10.0833333 15.5 5.5 11.7105263"/>
					</g>
				</g>
			</g>
		</svg>
	);
}
