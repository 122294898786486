import { Direction } from '@components/drawing/Door';
import { Graphics } from '@inlet/react-pixi';
import { IDoorState, IRoomState, RoomSettings, State } from '@models';
import { renderDimension } from '@utils/drawing/dimensions';
import { LineDirection } from '@utils/drawing/lines';
import FormatUtils from '@utils/format';
import RoomUtils from '@utils/room';
import React from 'react';

interface IRoomDoorPositionDimensionProps {
	room: IRoomState;
	door: IDoorState;
	biggerDimension?: boolean;
}

export default function RoomDoorPositionDimension(props: IRoomDoorPositionDimensionProps) {
	const roomDepth = RoomUtils.toPixels(props.room.depth);
	const roomWidth = RoomUtils.toPixels(props.room.width);
	const borderLength = RoomUtils.toPixels(props.room.borderLength);

	return (
		<Graphics
			draw={(graphics) => {
				graphics.clear();
				graphics.removeChildren();

				let roomX = 0;
				let roomY = 0;
				let firstDimensionPosition;
				let secondDimensionPosition;
				let shortHook = false;

				switch (props.door.direction) {
					case Direction.RIGHT:
						graphics.position.x = props.room.x + roomWidth + borderLength;
						graphics.position.y = props.room.y + roomDepth + borderLength;
						graphics.rotation =  1.5 * Math.PI;
						roomY = -15;
						firstDimensionPosition = LineDirection.VerticalDownRight;
						secondDimensionPosition = LineDirection.VerticalTopRight;
						shortHook = (props.room.depth / 2) < 30;
						if (props.biggerDimension) {
							graphics.position.x -= 4;
						}
						break;

					case Direction.DOWN:
						graphics.position.x = props.room.x + borderLength;
						graphics.position.y = props.room.y + borderLength + roomDepth - RoomSettings.ROOM_DIMENSION_HEIGHT;
						roomY = -5;
						firstDimensionPosition = LineDirection.DownLeft;
						secondDimensionPosition = LineDirection.DownRight;
						shortHook = (props.room.width / 2) < 30;
						if (props.biggerDimension) {
							graphics.position.y -= 4;
						}
						break;

					case Direction.LEFT:
						graphics.position.x = props.room.x + borderLength + RoomSettings.ROOM_DIMENSION_HEIGHT;
						graphics.position.y = props.room.y + roomDepth + borderLength;
						graphics.rotation = 1.5 * Math.PI;
						roomY = -5;
						firstDimensionPosition = LineDirection.VerticalDownLeft;
						secondDimensionPosition = LineDirection.VerticalTopLeft;
						shortHook = (props.room.depth / 2) < 30;
						if (props.biggerDimension) {
							graphics.position.x -= 2;
						}
						break;

					case Direction.TOP:
						graphics.position.x = props.room.x + borderLength;
						graphics.position.y = props.room.y + borderLength;
						roomY = 5;
						firstDimensionPosition = LineDirection.TopLeft;
						secondDimensionPosition = LineDirection.TopRight;
						shortHook = (props.room.width / 2) < 30;
						if (props.biggerDimension) {
							graphics.position.y -= 2;
						}
						break;
				}

				renderDimension(
					graphics,
					roomX,
					roomY,
					props.door.position,
					true,
					props.door.state,
					FormatUtils.formatInches,
					firstDimensionPosition,
					shortHook,
					props.biggerDimension,
				);

				roomX += RoomUtils.toPixels(props.door.position);

				renderDimension(
					graphics,
					roomX,
					roomY,
					props.door.width,
					false,
					State.Enabled,
					(v: number) => `${v}"`,
					undefined,
					false,
					props.biggerDimension,
				);

				roomX += RoomUtils.toPixels(props.door.width);

				const width = (props.door.direction === Direction.RIGHT || props.door.direction === Direction.LEFT) ?
					props.room.depth - props.door.width - props.door.position :
					props.room.width - props.door.width - props.door.position;

				renderDimension(
					graphics,
					roomX,
					roomY,
					width,
					true,
					props.door.state,
					FormatUtils.formatInches,
					secondDimensionPosition,
					shortHook,
					props.biggerDimension,
				);
			}}
		/>
	);
}
