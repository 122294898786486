import React from 'react';

interface ISmallCheckmarkProps {
	readonly color: string;
}

export default function SmallCheckmark({ color = '#fff' }: ISmallCheckmarkProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="6" height="5" viewBox="0 0 6 5">
			<path fill="none" fillRule="evenodd" stroke={color} d="M5 .5L1.897 4.25 0 2.871"/>
		</svg>
	);
}
