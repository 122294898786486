export default class BrowserUtils {
	public static isMobileDevice(): boolean {
		return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
	}

	public static isChrome(): boolean {
		return navigator.userAgent.indexOf('Chrome') > -1;
	}

	public static isSafari(): boolean {
		return navigator.userAgent.indexOf('Safari') > -1;
	}

	public static isFirefox(): boolean {
		return navigator.userAgent.indexOf('Firefox') > -1;
	}

	public static isIosFirefox(): boolean {
		return navigator.userAgent.indexOf('FxiOS') > -1;
	}

	public static isOpera(): boolean {
		return navigator.userAgent.indexOf('OPR') > -1;
	}

	public static isEdge(): boolean {
		return navigator.userAgent.indexOf('Edge') > -1;
	}
}
