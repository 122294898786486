import React from 'react';

export default function MoreIcon({ color }: {color?: string}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24">
			<g fill={color ? color : '#9FBBC4'} fillRule="evenodd">
				<path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" transform="translate(-1062 -5631) translate(60 5609) translate(296.953 15) translate(698.64) translate(7 7)"/>
			</g>
		</svg>
	);
}
