import React from 'react';

interface IArrowDownIcon {
	stroke: string;
}

export default function ArrowDownIcon(props: IArrowDownIcon) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" style={{ stroke: props.stroke }}>
			<g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
				<path d="M1 12l11.458 12.978M12.5 1v23M24.478 11.499L12.478 25"/>
			</g>
		</svg>
	);
}
