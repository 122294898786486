import { Graphics } from 'pixi.js';

export default class ShapesUtils {
	public static drawCenteredRoundedRectangle(
		graphics: Graphics,
		parentWidth: number,
		parentHeight: number,
		parentX: number,
		parentY: number,
		rectWidth: number,
		rectHeight: number,
		color: number,
		alpha: number,
	) {
		const x = parentX + (parentWidth / 2) - (rectWidth / 2);
		const y = parentY + (parentHeight / 2) - (rectHeight / 2);
		ShapesUtils.drawRoundedRectangle(
			graphics,
			x,
			y,
			rectWidth,
			rectHeight,
			color,
			alpha,
		);
	}

	public static drawRoundedRectangle(
		graphics: Graphics,
		x: number,
		y: number,
		rectWidth: number,
		rectHeight: number,
		color: number,
		alpha: number,
	) {
		graphics.beginFill(color, alpha);
		graphics.lineStyle(0);
		graphics.drawRoundedRect(x, y, rectWidth, rectHeight, rectHeight / 2);
		graphics.endFill();
	}
}
