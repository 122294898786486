import { IDropdownOption } from '@components/inputs';
import { useLocale } from '@i18n';
import { FormInputState } from '@models';
import classnames from 'classnames';
import React, { useCallback, useState } from 'react';
import Button from '../../components/buttons/Button';
import { ERowType, IGroup, IRow } from './MuiTabs';
import S from './styles/Project.styl';
import TabRow from './TabRow';

interface ITabGroupProps {
	group: IGroup;
	onSubmit: (group: IGroup) => Promise<boolean>;
	onCancel: (group: IGroup) => void;
	hasChanged: (group: IGroup) => boolean;
	onValidate: (fieldName: string) => void;
}

export default function TabGroup({ group, onSubmit, onCancel, hasChanged, onValidate }: ITabGroupProps) {
	const T = useLocale();
	const [isEditing, setIsEditing] = useState(false);
	const [canSubmit, setCanSubmit] = useState(false);
	const [toggleContentVisible, setToggleContentVisible] = useState(false);
	const [, updateState] = useState<any>();
	const forceUpdate = useCallback(() => updateState({}), []);

	const rowHasChanged = () => {
		if (hasChanged(group) && verifyInputState()) {
			if (!canSubmit) {
				setCanSubmit(true);
			}
		} else {
			if (canSubmit) {
				setCanSubmit(false);
			}
		}
		forceUpdate();
	};

	const forceUpdateProject = (forceUpdateProject: boolean) => {
		if (forceUpdateProject) {
			group.rows = group.rows.filter((row) => {
				return !!row.canEditInViewMode;
			});
			onSubmitClicked().then();
		}
	};

	const updateGroupInRow = (newRows: Array<{ inputName: string, value: string, options?: IDropdownOption[] }>) => {
		group.rows.forEach((row) => {
			const matchRow = newRows.filter((newRow) => newRow.inputName === row.inputName);
			if (matchRow.length > 0) {
				row.value = matchRow[0].value;
				if (matchRow[0].options !== undefined) { row.options = matchRow[0].options; }

				if (!row.inputProps?.isOptional) {
					row.inputState = row.value ? FormInputState.Valid : FormInputState.Unselected;
				}
			}
		});
		forceUpdate();
	};

	const verifyInputState = () => {
		let valid = true;
		group.rows.map((row) => {
			if (row.inputState === FormInputState.Error) { valid = false; }
		});

		return valid;
	};

	const getHiddenState = (row: IRow) => {
		if (row.hiddenBy) {
			const conditionMatch = group.rows.filter((filteredRow) => filteredRow.inputName === row.hiddenBy)[0];
			if (conditionMatch) {
				if (conditionMatch.type === ERowType.checkbox) {
					return conditionMatch.value;
				} else if (conditionMatch.type === ERowType.radio) {
					return isEditing ? conditionMatch.value !== conditionMatch.hiddenByCondition : true;
				}
			}
		}
		return undefined;
	};

	const getTabRows = () => {
		return group.rows.map((row, index) => (
			!row.hidden && <TabRow key={index} hiddenByState={getHiddenState(row)} row={row} edit={isEditing} rowHasChanged={rowHasChanged} updateGroupInRow={updateGroupInRow} forceUpdateProject={(force: boolean) => forceUpdateProject(force)} />
		));
	};

	const getTabContent = () => {
		return group.tabContentToggle ?
			(
				<div
					className={classnames(S.tabGroupToggleContent, {
						[S.tabGroupToggleContentVisible]: toggleContentVisible,
					})}
				>
					{getTabRows()}
				</div>
			) :
			getTabRows();
	};

	const onSubmitClicked = async () => {
		await onSubmit(group)
			.then((response) => {
				if (response) {
					setIsEditing(false);
					setCanSubmit(false);
				}
			});
	};

	const onCancelClicked = () => {
		onCancel(group);
		setIsEditing(false);
		setCanSubmit(false);
	};

	const onToggleClick = () => {
		setToggleContentVisible(!toggleContentVisible);
	};

	return (
		<div className={S.tabGroupContainer}>
			<div className={S.tabGroupHeader}>
				<h3>{group.label}</h3>
				{group.isEditable && !isEditing && (
					<div className={S.tabGroupHeaderFormActions}>
						{!group.hideEditButton && (
							<Button secondary small onClick={() => setIsEditing(true)} disabled={group.disabledEditButton}>{group.addText ? T.GeneralAdd : T.GeneralModify}</Button>
						)}
						{group.validateField && <Button orange onClick={() => onValidate(group.validateField!)}>{T.GeneralValidate}</Button>}
					</div>
				)}
				{isEditing && (
					<div className={S.tabGroupHeaderFormActions}>
						<Button secondary small onClick={onCancelClicked}>{T.GeneralCancel}</Button>
						<Button disabled={!canSubmit} small onClick={onSubmitClicked}>{T.GeneralSave}</Button>
					</div>
				)}

				{group.tabContentToggle && (
					<div className={S.tabGroupHeaderFormActions}>
						<Button
							secondary={!toggleContentVisible}
							small
							onClick={onToggleClick}
						>
							{toggleContentVisible ? T.TabTransportInstallationSectionPalletCloseButtonText : T.TabTransportInstallationSectionPalletViewButtonText}
						</Button>
					</div>
				)}
			</div>

			{isEditing && (
				<form className={`${S.tabGroupContainerForm} half-width-row`}>
					{getTabRows()}
				</form>
			)}

			{!isEditing && (
				<>
					{getTabContent()}
				</>
			)}
		</div>
	);
}
