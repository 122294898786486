import React from 'react';

export default function ListIcon({ color }: {color?: string}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill={color ? color : '#FFF'} fillRule="evenodd">
				<path d="M4 14h4v-4H4v4zm0 5h4v-4H4v4zM4 9h4V5H4v4zm5 5h12v-4H9v4zm0 5h12v-4H9v4zM9 5v4h12V5H9z" transform="translate(-984 -4420) translate(984 4420)"/>
			</g>
		</svg>
	);
}
