import Assets from '@assets/index';
import { Loading } from '@components/layout';
import Navigation from '@components/layout/Navigation';
import { Chevron } from '@components/svg';
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from '@i18n';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import React, { Suspense } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { AuthContextProvider } from './hooks/contexts/AuthContext';
import combineContext from './hooks/contexts/CombineContext';
import { useAuthContext } from './hooks/contexts/UseContext';
import { routes } from './routes';
import NotFound from './routes/NotFound';
import ProtectedRoute from './routes/ProtectedRoute';

import S from './index.styl';

function App() {
	const { isAuthenticated } = useAuthContext();
	if (Assets) {
		// do nothing. Hack to tell webpack to bundle em
	}

	return (
		<Suspense fallback={<Loading />}>
			<BrowserRouter>
				<Switch>
					<Route exact path="/">
						<Redirect to={`/${DEFAULT_LOCALE.locale}`} />
					</Route>

					{routes.map((route) => SUPPORTED_LOCALES.map((locale) => {
							const localizedPath = route.path[locale].map((path: string) => `/${locale}${path}`);
							const CombinedProvider = combineContext([...route.provider]);
							return (
								<Route exact={route.exact} path={localizedPath} key={`${route.name}-${locale}`}>
									<CombinedProvider>
										<ProtectedRoute needAuth={route.needAuth} isAuthenticated={isAuthenticated}>
											<>
												{route.header && isAuthenticated && <Navigation />}
												<route.component />
											</>
										</ProtectedRoute>
									</CombinedProvider>
								</Route>
							);
						}),
					)}

					<Route>
						<NotFound />
					</Route>
				</Switch>
			</BrowserRouter>
		</Suspense>
	);
}

document.addEventListener('DOMContentLoaded', () => {
	const root = document.getElementById('root');
	if (root && root instanceof Element) {
		ReactDOM.render(
			(
				<ThemeProvider
					theme={createMuiTheme({
						palette: { primary: { main: S.blueNorbec },
						secondary: { main: S.darkBlue } },
						typography: { fontFamily: 'Assistant, \'Assistant\'' },
						props: {
							MuiSelect: {
								IconComponent: () => <Chevron color={S.darkBlueGray} />,
							},
						},
						overrides: {
							MuiIconButton: {
								root: {
									'&:hover': {
										'& .material-icons': {
											color: S.darkBlue,
										},
										backgroundColor: 'unset',
									},
								},
							},
							MuiTooltip: {
								popper: {
									'& div': {
										fontSize: '1rem',
									},
								},
							},
							MuiListItem: {
								button: {
									'&:hover': {
										backgroundColor: S.blueGray50ish,
									},
								},
							},
						},
					})}
				>
					<AuthContextProvider>
						<App />
					</AuthContextProvider>
				</ThemeProvider>
			),
			root,
		);
	}
});
