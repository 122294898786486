import AlertCircleIcon from '@components/svg/AlertCircleIcon';
import classnames from 'classnames';
import React from 'react';
import S from './styles/AlertContent.styl';

interface ICheckboxAlertProps {
	alertTitle: string;
	alertDesc: string;
	alertInsight: string;
	showAlert: boolean;
}

export default function AlertContent(props: ICheckboxAlertProps) {

	return (
		<div
			className={classnames(S.alertContainer, {
				[S.alertContainerVisible]: props.showAlert,
			})}
		>
			<div className={S.alertContainerLine}/>
			<div className={S.alertContainerContent}>
				<div className={S.alertContainerContentInsight}>
					<AlertCircleIcon/>
					<div className={S.alertContainerContentInsightText}>{props.alertInsight}</div>
				</div>
				<h1>{props.alertTitle}</h1>
				<div className={S.alertContainerContentDescription}>{props.alertDesc}</div>
			</div>
		</div>
	);
}

AlertContent.defaultProps = {
	alertTitle: '',
	alertDesc: '',
	alertInsight: '',
};
