import React from 'react';

export default function ContactCheckedIcon({ color }: {color?: string}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fillRule="evenodd">
				<g fill={color ? color : '#FFF'}>
					<g>
						<g>
							<g>
								<path d="M20.6 12.5l1.4 1.41-6.53 6.59L12 17l1.4-1.41 2.07 2.08 5.13-5.17zM11 14c.32 0 .61.02 1 .06L9 17l3 3H3v-2c0-2.66 5.33-4 8-4zm0-10c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4z" transform="translate(-628 -380) translate(440 330) translate(178 40) translate(10 10)"/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
