import Button from '@components/buttons/Button';
import Input, { ErrorPosition } from '@components/inputs/Input';
import { Loading } from '@components/layout';
import { LayoutHalfAndHalf } from '@components/layout/Layout';
import LeftContentAuthHalfAndHalf from '@components/layout/LeftContentAuthHalfAndHalf';
import LeftSectionLayoutHalfAndHalf from '@components/layout/LeftSectionLayoutHalfAndHalf';
import RightSectionPublicLayoutHalfAndHalf from '@components/layout/RightSectionPublicLayoutHalfAndHalf';
import { useLocale } from '@i18n';
import { FormInputState } from '@models';
import { REGEX_EMAIL } from '@utils/constants';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getRequestResetPassword } from '../../api/auth';
import { ERoutes, getPathByRouteName } from '../index';
import S from './styles/Password.styl';

interface IInput {
	email: string;
}

interface IInputState {
	email: FormInputState;
}

export default function ForgotPassword() {
	const T = useLocale();
	const history = useHistory();
	const [formValid, setFormValid] = useState<boolean>(false);
	const [emailSent, setEmailSent] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [input, setInput] = useState<IInput>({
		email: '',
	});

	const [inputState, setInputState] = useState<IInputState>({
		email: FormInputState.Unselected,
	});

	useEffect(() => {
		setFormValid(inputState.email === FormInputState.Valid);
	}, [inputState]);

	const doRequestResetPassword = (e?: any) => {
		e?.preventDefault();
		setIsLoading(true);
		getRequestResetPassword(
			input.email,
		)
		.then(() => {
			setEmailSent(true);
			setIsLoading(false);
		})
		.catch(() => {
			// Show confirmation message even if email not exist
			setEmailSent(true);
			setIsLoading(false);
		});
	};

	const handleInputChange = (event: any, currentInputState: FormInputState) => {
		const { name, value } = event.target;
		setInput({ ...input, [name]: value });
		setInputState({ ...inputState, [name]: currentInputState });
	};

	const handleBackToLoginClick = () => {
		history.replace(getPathByRouteName(ERoutes.Login, T.locale));
	};

	if (isLoading) {
		return (<Loading/>);
	}

	return (
		<LayoutHalfAndHalf>
			<LeftSectionLayoutHalfAndHalf>
				<LeftContentAuthHalfAndHalf />
			</LeftSectionLayoutHalfAndHalf>

			<RightSectionPublicLayoutHalfAndHalf>
				<div className={S.passwordContent}>

					{!emailSent && (
						<>
							<h1 className={S.passwordContentTitle}>
								{T.LoginForgotPasswordTitleMain}
							</h1>

							<p className={S.passwordContentDescription}>
								{T.LoginForgotPasswordText}
							</p>

							<form onSubmit={doRequestResetPassword}>
								<Input
									className={'admin-client'}
									onChange={(
										e, inputState) => handleInputChange(e, inputState)
									}
									labelText={T.LoginConnectionEmailLabel}
									placeHolder={T.LoginConnectionEmailPlaceholder}
									inputType={'email'}
									name="email"
									errorMessage={T.LoginConnectionEmailError}
									validationRegex={REGEX_EMAIL}
									errorMessagePosition={ErrorPosition.BOTTOM}
								/>

								<Button
									typeSubmit
									disabled={!formValid}
									onClick={doRequestResetPassword}
								>
									{T.LoginForgotPasswordButtonText}
								</Button>
							</form>

							<div className={S.passwordContentBackToLoginContainer}>
								<span>{T.LoginForgotPasswordBackToLoginPageText}</span>
								<Link className="mvp-link" to={getPathByRouteName(ERoutes.Login, T.locale)}>
									<span>{T.LoginForgotPasswordBackToLoginPageLinkText}</span>
								</Link>
							</div>
						</>
					)}

					{emailSent && (
						<>
							<h1 className={S.passwordContentTitle}>
								{T.LoginForgotPasswordEmailSentTitle}
							</h1>

							<p className={S.passwordContentDescription}>
								{T.LoginForgotPasswordEmailSentText}
							</p>

							<Button onClick={() => handleBackToLoginClick()}>
								{`${T.LoginForgotPasswordBackToLoginPageText}${T.LoginForgotPasswordBackToLoginPageLinkText}`}
							</Button>

							<div className={S.passwordContentBackToLoginContainer}>
								<p className={S.passwordContentBackToLoginContainerNotice}>
									{T.LoginForgotPasswordSpamCheckText}
								</p>
							</div>
						</>
					)}

				</div>
			</RightSectionPublicLayoutHalfAndHalf>
		</LayoutHalfAndHalf>
	);
}
