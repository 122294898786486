import { getCurrentLang } from '@i18n';
import { ETransportType, IPricingItem, ITransportType } from '@models';
import { installShouldBeShown, transportShouldBeShown } from '@utils/messages';
import FormatUtils from './format';

export enum Sections {
	configuration = 'configuration',
	coolingSystem = 'coolingSystem',
	installation = 'installation',
	bundleRebate = 'bundleRebate',
	options = 'options',
	doorOptions = 'door options',
	pedal = 'pedal',
	curtain = 'curtain',
	window = 'window',
	kickplate = 'kickplate',
	i3 = 'i3',
	dataLogging = 'data logging',
	coolingOptions = 'cooling options',
	lda = 'LDA',
	emplus = 'EM Plus',
	floorOptions = 'floor options',
	nextgrip = 'nextgrip',
	light = 'light',
	motionDetector = 'motion detector',
	transport = 'transport',
}

export interface IPriceSections {
	configuration: IPricingItem;
	coolingSystem: IPricingItem;
	installation: IPricingItem;
	options: IPricingItem;
	transport: IPricingItem;
	bundleRebate: IPricingItem;
	doorOptions: IPricingItem;
	pedal: IPricingItem;
	curtain: IPricingItem;
	window: IPricingItem;
	kickplate: IPricingItem;
	i3: IPricingItem;
	dataLogging: IPricingItem;
	coolingOptions: IPricingItem;
	lda: IPricingItem;
	emplus: IPricingItem;
	floorOptions: IPricingItem;
	nextgrip: IPricingItem;
	light: IPricingItem;
	motionDetector: IPricingItem;
}

export function getPriceSectionByName(
	prices: IPricingItem[],
	priceName: string,
): IPricingItem {
	return (
		prices.find((price: IPricingItem) => price.name === priceName) ||
		getDefaultPriceSection(priceName)
	);
}

export function getDefaultPriceSection(priceName: string) {
	return {
		extension: 0,
		item: [],
		infos: [],
		name: priceName,
		qtes: [],
		type: '',
		unitPrice: 0,
	};
}

export function getPrice(
	priceSection: IPricingItem | undefined,
	lang?: string,
	unit: string = '$',
	thousandsSep: boolean = true,
): string {
	const currentLang = !!lang ? lang : getCurrentLang();
	return FormatUtils.formatDecimalPrice(
		priceSection?.extension || 0,
		currentLang,
		unit,
		thousandsSep,
	);
}

export function getPriceExtension(
	priceSection: number,
	lang?: string,
	unit: string = '$',
	thousandsSep: boolean = true,
): string {
	const currentLang = !!lang ? lang : getCurrentLang();
	return FormatUtils.formatDecimalPrice(
		priceSection || 0,
		currentLang,
		unit,
		thousandsSep,
	);
}

export function getUnitPrice(
	priceSection: IPricingItem | undefined,
	lang?: string,
	unit: string = '$',
	thousandsSep: boolean = true,
): string {
	const currentLang = !!lang ? lang : getCurrentLang();
	return FormatUtils.formatDecimalPrice(
		priceSection?.unitPrice || 0,
		currentLang,
		unit,
		thousandsSep,
	);
}

export function getTotalPrice(
	priceSections: any,
	transportType: ITransportType | null,
	installPriceOverride?: string,
	installValidated?: boolean,
	installByNorbec?: boolean,
	transportPriceOverride?: string,
	transportValidated?: boolean,
	lang?: string,
	unit: string = '$',
	thousandsSep: boolean = true,
): string {
	const currentLang = !!lang ? lang : getCurrentLang();
	let totalPrice: number = 0;
	totalPrice += priceSections?.configuration?.extension || 0;
	totalPrice += priceSections?.coolingSystem?.extension || 0;
	totalPrice += getInstallationPrice(
		priceSections?.installation?.extension,
		installValidated,
		installByNorbec,
		installPriceOverride,
	);
	totalPrice += priceSections?.options?.extension || 0;
	totalPrice += getTransportPrice(
		priceSections?.transport?.extension,
		transportType,
		transportValidated,
		transportPriceOverride,
	);
	totalPrice -= priceSections?.bundleRebate?.extension || 0;

	return FormatUtils.formatDecimalPrice(
		totalPrice,
		currentLang,
		unit,
		thousandsSep,
	);
}

export function getPriceSections(
	prices: IPricingItem[] | undefined,
): IPriceSections {
	const getFunction = prices
		? (sectionName: Sections) => getPriceSectionByName(prices, sectionName)
		: getDefaultPriceSection;

	return {
		configuration: getFunction(Sections.configuration),
		coolingSystem: getFunction(Sections.coolingSystem),
		installation: getFunction(Sections.installation),
		options: getFunction(Sections.options),
		transport: getFunction(Sections.transport),
		bundleRebate: getFunction(Sections.bundleRebate),
		doorOptions: getFunction(Sections.doorOptions),
		pedal: getFunction(Sections.pedal),
		curtain: getFunction(Sections.curtain),
		window: getFunction(Sections.window),
		kickplate: getFunction(Sections.kickplate),
		i3: getFunction(Sections.i3),
		dataLogging: getFunction(Sections.dataLogging),
		coolingOptions: getFunction(Sections.coolingOptions),
		lda: getFunction(Sections.lda),
		emplus: getFunction(Sections.emplus),
		floorOptions: getFunction(Sections.floorOptions),
		nextgrip: getFunction(Sections.nextgrip),
		light: getFunction(Sections.light),
		motionDetector: getFunction(Sections.motionDetector),
	};
}

// export const getInstallationPrice = (
// 	extension: number | null,
// 	installValidated?: boolean,
// 	installByNorbec?: boolean,
// 	installPriceOverride?: string) => {
// 	if (installShouldBeShown(extension, installValidated, installByNorbec, installPriceOverride )) {
// 		if (installPriceOverride !== null) {
// 			return Number(installPriceOverride);
// 		}
//
// 		if (extension) {
// 			return extension;
// 		}
// 	}
//
// 	return 0;
// };

export const getInstallationPrice = (
	extension: number | null,
	installValidated?: boolean,
	installByNorbec?: boolean,
	installPriceOverride?: string,
) => {
	if (!installByNorbec) {
		return 0;
	}

	if (installPriceOverride !== null && !(installValidated === false)) {
		return Number(installPriceOverride);
	} else if (extension && extension > 0) {
		return extension;
	}

	return 0;
};

export const getTransportPrice = (
	extension: number | null,
	transportType: ITransportType | null,
	transportValidated?: boolean,
	transportPriceOverride?: string,
) => {
	if (transportType && transportType?.id === ETransportType.PICK_UP) {
		return 0;
	}

	if (transportType && transportType?.id === ETransportType.BY_NORBEC) {
		if (
			transportPriceOverride !== null &&
			!(transportValidated === false)
		) {
			return Number(transportPriceOverride);
		} else if (extension && extension > 0) {
			return extension;
		}
	}

	return 0;
};
