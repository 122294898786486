import { Steps } from '@models';
import { useStore } from '@store';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface ILinkWrapperProperties {
	to: string;
	step: Steps;
	children: ReactNode;
}

export default function LinkWrapper(props: ILinkWrapperProperties) {
	const { dispatch, actions } = useStore();

	function onLinkClicked(e: React.MouseEvent) {
		if (props.step) {
			dispatch(actions.LayoutActions.setCurrentStep(props.step));
		}
	}

	return (
		<Link to={props.to} onClick={onLinkClicked}>
			{props.children}
		</Link>
	);
}
