import React from 'react';

interface IInstallationIconProps {
	stroke: string;
}

export default function InstallationIcon(props: IInstallationIconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25">
			<g fill="none" stroke={props.stroke}>
				<path d="M2.55 19.048c-.312.33-.78.466-1.22.352-.44-.114-.785-.457-.899-.898-.113-.44.021-.908.351-1.22l5.476-5.477 1.768 1.768-5.476 5.475zM5.376 10.921L8.909 14.456M16.592 3.249L7.143 12.688M18.154 3.465c-.145.194-.366.315-.608.332-.24.017-.478-.071-.648-.242l-.677-.676c-.175-.174-.263-.417-.241-.663.021-.246.15-.47.353-.61L18.18.323c.166-.115.39-.095.532.048l.75.75c.147.147.163.38.038.546l-1.346 1.798zM9.655 7.82L6.788 4.954c.517-1.144.348-2.482-.438-3.46C5.563.514 4.294.06 3.065.319c-.15.031-.271.142-.315.289-.045.147-.004.307.105.416l1.19 1.186c.078.078.122.184.122.294v1.08c0 .23-.187.416-.417.416H2.672c-.11 0-.216-.044-.294-.122L1.19 2.688C1.081 2.58.922 2.54.776 2.584c-.147.043-.258.164-.29.313-.258 1.23.196 2.499 1.175 3.285.979.786 2.317.956 3.46.439l2.867 2.866M12.012 10.178l2.866 2.865c1.032-.466 2.23-.376 3.181.239.95.614 1.525 1.67 1.524 2.801 0 .23-.023.46-.07.685-.031.15-.142.27-.289.314-.146.044-.305.004-.413-.105l-1.19-1.188c-.077-.078-.183-.122-.293-.122H16.25c-.23 0-.417.186-.417.416v1.078c0 .11.044.217.122.295l1.19 1.188c.107.108.148.267.104.414-.044.146-.164.257-.314.289-.225.046-.455.07-.685.07-1.132 0-2.186-.575-2.8-1.525-.615-.951-.704-2.149-.238-3.18l-2.867-2.866" transform="translate(2 3)"/>
			</g>
		</svg>
	);
}
