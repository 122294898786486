import { useLocale } from '@i18n';
import React, { useEffect, useState } from 'react';

import S from './styles/Loading.styl';

export function LoadingSpinner() {
	return (
		<div className={S.loadingSpinner}>
			<div/>
			<div/>
			<div/>
			<div/>
			<div/>
			<div/>
			<div/>
			<div/>
		</div>
	);
}

interface ILoadingProps {
	delay?: number;
}

let timer: number;

export default function Loading(props: ILoadingProps) {
	const T = useLocale();
	const [isDelayed, SetIsDelayed] = useState<boolean>(!!props.delay);

	useEffect(() => {
		if (isDelayed) {
			timer = window.setTimeout(() => {
				SetIsDelayed(false);
			}, props.delay);
		}

		return () => clearTimeout(timer);
	}, []);

	return (
		<div className={S.loadingContainer}>
			<div className={S.loading}>
				{!isDelayed && (
					<>
						<LoadingSpinner />
						<div className={S.loadingText}>
							{T.GeneralLoading}
						</div>
					</>
				)}
			</div>
		</div>
	);
}
