import DrawingButtons from '@components/buttons/DrawingButtons';
import Checkbox from '@components/inputs/Checkbox';
import { useLocale } from '@i18n';
import { State } from '@models';
import classnames from 'classnames';
import React from 'react';
import S from './styles/GroupSettingsSection.styl';

interface IGroupSettingsProps {
	onAllRoomsCheckChanged?: (checked: boolean) => void;
	checkboxState?: State;
	drawingButtonsState: State;
	showAllRoomsCheckbox?: boolean;
	showDrawingButtons?: boolean;
	buttonsDisabled?: boolean;
	onZoomInClicked?: () => void;
	onZoomOutClicked?: () => void;
	onCenterClicked?: () => void;
}

export default function GroupSettingsSection(props: IGroupSettingsProps) {
	const T = useLocale();

	return (
		<div className={S.groupSettingsSection}>
			<div
				className={classnames(S.groupSettingsSectionButtons, {
					[S.groupSettingsSectionButtonsDisabled]: props.buttonsDisabled,
				})}
			>
				{props.showDrawingButtons &&
					(
						<DrawingButtons
							state={props.drawingButtonsState}
							onCenterClicked={() => props.onCenterClicked && props.onCenterClicked()}
							onZoomInClicked={() => props.onZoomInClicked && props.onZoomInClicked()}
							onZoomOutClicked={() => props.onZoomOutClicked && props.onZoomOutClicked()}
						/>
					)
				}
				{ props.showAllRoomsCheckbox &&
					(
						<Checkbox
							state={props.checkboxState || State.Enabled}
							title={T.GeneralAllRooms}
							onChange={props.onAllRoomsCheckChanged}
							isMultiInput={false}
						/>
					)
				}
			</div>
		</div>
	);
}
