import { deleteLogout, postLoginWithToken } from '@api/auth';
import { ILoginData, IUserData } from '@api/interface';
import { getSessionToken, removeSessionToken, setSessionToken } from '@api/token';
import { Loading } from '@components/layout';
import { getCurrentLang } from '@i18n';
import jwt from 'jwt-decode';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { ERoutes, getPathByRouteName } from '../../routes';

interface IAuthContext {
	isAuthenticated: boolean;
	authenticate: (data: ILoginData) => void;
	logout: (history: any) => void;
	userHasPermissions: (permission: EPermissions) => boolean;
	user?: IUserData;
}

export enum EPermissions {
	// PROJECT LIST
	viewTableClientId = 'viewTableClientId',
	viewTableClientName = 'viewTableClientName',
	viewTableCreatedBy = 'viewTableCreatedBy',
	viewTableQuotedDate = 'viewTableQuotedDate',

	// PROJECT DETAILS
	viewDiscountTab = 'viewDiscountTab',
	editDiscountTab = 'editDiscountTab',
	viewMarkupTab = 'viewMarkupTab',
	editMarkupTab = 'editMarkupTab',
	editTransportOrInstallationTab = 'editTransportOrInstallationTab',
	editProjectManager = 'editProjectManager',

	// ACTIONS
	viewContactSizeIt = 'viewContactSizeIt',
	editReopenExpiredProject = 'editReopenExpiredProject',
	editExtendProject = 'editExtendProject',

	// USERS
	userManage = 'user.manage',
}

interface IDecodedToken {
	nameid: string;
	unique_name: string;
	nbf: number;
	exp: number;
	iat: number;
}

const AuthContext = createContext<IAuthContext>({
	isAuthenticated: false,
	authenticate: () => undefined,
	logout: () => undefined,
	userHasPermissions: () => false,
	user: undefined,
});

export default AuthContext;

function verifyToken() {
	const token = getSessionToken();
	if (token) {
		try {
			const decodedToken = jwt(token) as IDecodedToken;
			const currentTime = new Date().getTime() / 1000;
			if (decodedToken.exp > currentTime) {
				return true;
			} else {
				console.warn('TOKEN EXPIRED');
			}
		} catch (error) {
			console.warn('BAD TOKEN');
		}
	}
	return false;
}

export function AuthContextProvider({ children }: { children: React.ReactNode }) {
	const [user, setUser] = useState<undefined | IUserData>(undefined);
	const [isAuthenticated, setIsAuthenticated] = useState(!!getSessionToken());

	useEffect(() => {
		if (verifyToken()) {
			if (!user) {
				postLoginWithToken().then((res) => {
					if (res?.data?.token && res?.data?.user) {
						setSessionToken(res.data.token);
						setUser({ ...res.data.user, permissions: res.data.permissions });
					}
				})
					.catch(() => {
						removeSessionToken();
						setIsAuthenticated(false);
					});
			}
		} else {
			removeSessionToken();
			setIsAuthenticated(false);
		}
	}, [!!getSessionToken()]);

	const logout = useCallback(
		(history) => {
			const currentLang = getCurrentLang();

			deleteLogout().then(() => {
				removeSessionToken();
				setIsAuthenticated(false);
				setUser(undefined);
				history.push(getPathByRouteName(ERoutes.Login, currentLang));
			});
		},
		[setIsAuthenticated, setUser],
	);

	const authenticate = useCallback(
		(data) => {
			setSessionToken(data.token);
			setIsAuthenticated(true);
		},
		[setIsAuthenticated, setUser],
	);

	const userHasPermissions = useCallback(
		(permission: EPermissions) => user?.permissions?.indexOf(permission) !== -1,
		[user],
	);

	return (
		<AuthContext.Provider value={{ isAuthenticated, authenticate, logout, user, userHasPermissions }} >
			{
				(isAuthenticated && !user) ?
					<Loading />
					:
					children
			}
		</AuthContext.Provider>
	);
}
