import React from 'react';

export default function EmailIcon({ color }: {color?: string}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill={color ? color : '#ffffff'} fillRule="evenodd">
				<path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" transform="translate(-1064 -102) translate(0 70) translate(1045 20) translate(19 12)"/>
			</g>
		</svg>
	);
}
