import { ILocaleData } from '@i18n';
import { MapLike } from '@utils/types';

import en from './en';
import fr from './fr';

export { en, fr };

export enum ELocales {
	fr = 'fr',
	en = 'en',
}

export const LOCALES: MapLike<ILocaleData> = { en, fr };
export const DEFAULT_LOCALE = fr;
export const SUPPORTED_LOCALES: ELocales[]  = [ELocales.fr, ELocales.en];
