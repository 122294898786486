import { Burger } from '@components/buttons';
import DropdownButtonAction, { IDropdownButtonActionOption } from '@components/inputs/DropdownButtonAction';
import BackRoundedLabel from '@components/labels/BackRoundedLabel';
import {
	AccountIcon,
	InfoIcon,
	LogoutIcon,
} from '@components/svg';
import SizeItLogoBig from '@components/svg/SizeItLogoBig';
import { useLocale } from '@i18n';
import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ModalIconType, useAuthContext, useModalContext } from '../../hooks/contexts/UseContext';
import { ERoutes, getPathByRouteName } from '../../routes';
import S from './styles/Header.styl';

export enum AppTheme {
	VT,
	MVP,
}

interface IHeaderProps {
	onClick: () => void;
	isMenuVisible: boolean;
	theme: AppTheme;
}

export default function Header(props: IHeaderProps) {
	const T = useLocale();
	const { useModal } = useModalContext();
	const { logout, user } = useAuthContext();
	const history = useHistory();

	const actionOptions: IDropdownButtonActionOption[] = [
		{ value: 'linkToAccount', label: T.MenuLinkUserMyAccount, icon: (<AccountIcon />), action: () => history.push(getPathByRouteName(ERoutes.MyAccount, T.locale)) },
		{ value: 'logout', label: T.MenuLinkUserLogout, icon: (<LogoutIcon />), action: () => logout(history) },
	];

	const helpModalContent = (
		<div className={S.helpModalContent}>
			<p className={S.helpModalContentText}>
				{T.ModalHelpDescription}
			</p>
			<a
				className={S.helpModalContentLinkButton}
				href={`tel:${T.VariablePhoneNumberSizeIt}`}
			>
				<BackRoundedLabel text={T.VariablePhoneNumberSizeIt}/>
			</a>
			<p className={S.helpModalContentText}>
				{T.ModalHelpOrBy}
			</p>
			<a
				className={S.helpModalContentLinkButton}
				href={`mailto:${T.VariableEmailSizeIt}`}
			>
				<BackRoundedLabel text={T.VariableEmailSizeIt}/>
			</a>
		</div>
	);

	const handleOnSelect = (selectedOption: IDropdownButtonActionOption) => {
		selectedOption.action();
	};

	return (
		<div
			className={classnames(S.appHeader, {
				[S.appHeaderMvp]: props.theme === AppTheme.MVP,
				[S.appHeaderVt]: props.theme === AppTheme.VT,
			})}
		>
			<div className={S.appHeaderContainer}>
				<div className={S.appHeaderMenuBurger}>
					<Burger
						isOpen={props.isMenuVisible}
						onClick={props.onClick}
					/>
				</div>
				<Link className={S.appHeaderContainerLogo} to={'/'}>
					<SizeItLogoBig lang={T.locale}/>
				</Link>
				<div className={S.appHeaderSectionRight}>
					<button
						className={S.appHeaderSectionRightInfo}
						onClick={() => useModal({
							content: helpModalContent,
							title: T.ModalHelpTitle,
							iconType: ModalIconType.Info,
							color: S.blueNorbec,
							exitLabel: T.GeneralClose,
						})}
					>
						<span className={S.appHeaderSectionRightInfoLabel}>
							{T.HeaderHelpButtonLabel}
						</span>
						<span className={S.appHeaderSectionRightInfoIcon}>
							<InfoIcon color={S.darkBlueGray}/>
						</span>
					</button>

					<div className={S.appHeaderSectionRightUser}>
						<DropdownButtonAction
							value={''}
							options={actionOptions}
							onSelect={(selectedOption) => handleOnSelect(selectedOption)}
							horizontalOpenMenuPosition="right"
							menuWidth="295px"
							showDropdownIndicator
							buttonContent={user?.name}
							className={'user-header-button'}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
