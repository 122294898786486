import React from 'react';

export interface IInstallationLocationProps {
	stroke: string;
}

export default function InstallationLocationIcon(props: IInstallationLocationProps) {
	return (
		<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
			<g id="Maquettes-UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
				<g id="UI-kit" transform="translate(-69.000000, -3900.000000)" stroke={props.stroke}>
					<g id="ico-pin-location-/-rested" transform="translate(69.000000, 3900.000000)">
						<g id="pin-location-1">
							<g id="style-one-pin" transform="translate(3.500000, 0.000000)">
								<path d="M16.8000039,8.57834559 C16.803795,4.73592688 14.0314498,1.44603945 10.2264354,0.777626014 C6.42142097,0.109212583 2.68341166,2.25544905 1.36316484,5.86661132 C0.0429180128,9.47777359 1.52199372,13.5102119 4.87023158,15.4280086 C6.36277895,16.2844363 6.76509474,17.2296722 8.45747368,20.2139798 C8.53585069,20.3521617 8.68301203,20.4376345 8.84254737,20.4376345 C9.00208271,20.4376345 9.14924405,20.3521617 9.22762105,20.2139798 C10.9376842,17.1971385 11.3249684,16.2826777 12.8139789,15.4280086 C15.2799991,14.0190978 16.8003137,11.4065569 16.8000039,8.57834559 Z" id="Path"/>
								<ellipse id="Oval" cx="8.84210526" cy="8.57834559" rx="4.42105263" ry="4.39644608"/>
							</g>
							<path d="M17,19.7 C20.848,20.024 23.5,20.709 23.5,21.5 C23.5,22.605 18.352,23.5 12,23.5 C5.648,23.5 0.5,22.605 0.5,21.5 C0.5,20.71 3.135,20.027 6.958,19.7" id="Path"/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
