import { State } from '@models';
import classnames from 'classnames';
import React from 'react';
import S from './styles/SummaryElementLabel.styl';

interface ISummaryElementLabel {
	id?: string;
	title?: string;
	value: string;
	valueState: State;
	titleState?: State;
	isMulti?: boolean;
}

export default function SummaryElementLabel(props: ISummaryElementLabel) {
	return (
		<div
			id={props.id}
			className={classnames(S.summaryElement, {
				[S.summaryElementMulti]: props.isMulti,
				[S.summaryElementMultiWithTitle]: !!props.title && props.title.length > 0,
			})}
		>
			<div
				className={classnames(S.summaryElementTitle, {
					[S.summaryElementTitleFocused]: (props.titleState || props.valueState) === State.Focused,
					[S.summaryElementTitleSelected]: (props.titleState || props.valueState) === State.Selected,
				})}
			>
				{props.title}
			</div>
			<div
				className={classnames(S.summaryElementValue, {
					[S.summaryElementValueFocused]: props.valueState === State.Focused,
					[S.summaryElementValueSelected]: props.valueState === State.Selected,
					[S.summaryElementValueMulti]: props.isMulti,
				})}
			>
				{props.value}
			</div>
		</div>
	);
}
