import { useLocale } from '@i18n';
import { FormInputState } from '@models';
import { REGEX_NUMBER } from '@utils/constants';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import S from './styles/Input.styl';

export enum ErrorPosition {
	TOP,
	BOTTOM,
}

export interface IInputPropsBase {
	inputAppend?: string | React.ReactElement;
	inputAppendClick?: (event?: React.MouseEvent) => void;
	inputAppendElementId?: string;
	errorMessage?: string;
	isOptional?: boolean;
	showOptionalLabel?: boolean;
	placeHolder?: string;
	value?: string | null;
	inputSize?: string;
	validationRegex?: string | string[];
	maxLength?: number;
	disabled?: boolean;
	className?: string;
	labelLinkHref?: string;
	labelLinkText?: string;
	autoComplete?: string;
	max?: number;
	min?: number;
	timeoutMs?: number;
	toFixed?: number;
	valid?: boolean;
	inputRef?: React.MutableRefObject<any>;
	showCharCounter?: boolean;
}
export interface IInputProps extends IInputPropsBase {
	onChange: (event: ChangeEvent<HTMLInputElement>, inputState: FormInputState) => void;
	labelText: string;
	name: string;
	inputType: string;
	errorMessagePosition: ErrorPosition;
}

export default function Input(props: IInputProps) {
	const inputRef = props.inputRef ? props.inputRef : useRef<HTMLInputElement>(null);
	const T = useLocale();
	const [inputContent, setInputContent] = useState(props.value);
	const [inputState, setInputState] = useState(FormInputState.Unselected);
	const [inputHasFocus, setInputHasFocus] = useState<boolean>(false);
	const [timeout, setTimeout] = useState<number>(0);
	const [charCount, setCharCount] = useState(props.value?.length || 0);

	useEffect(() => {
		setInputContent(props.value);
	}, [props.value]);

	function onInputBlur(event: any) {
		setInputHasFocus(false);
		onInputChange(event, 0);
	}

	function isRegexValid(value: string) {
		let isValid = true;
		if (props.validationRegex) {
			if (Array.isArray(props.validationRegex)) {
				isValid = props.validationRegex.some((regex) => {
					const regexRule = new RegExp(regex);
					return regexRule.test(value);
				});
			} else {
				const regexRule = new RegExp(props.validationRegex);
				return regexRule.test(String(value).toLowerCase());
			}
		}
		return isValid;
	}

	function onInputChange(event: React.ChangeEvent<HTMLInputElement>, timeoutMs: number = 0) {
		event.persist();

		let { value } = event.target;
		let setNewNumberValue = true;
		if (props.inputType === 'number') {
			const numberValue = Number(value);

			if (props.max && (numberValue > props.max)) {
				setNewNumberValue = false;
			}
			if (props.min && (numberValue < props.min)) {
				setNewNumberValue = false;
			}
			if ((value !== '') && !(new RegExp(REGEX_NUMBER).test(value))) {
				setNewNumberValue = false;
			}
			if (props.toFixed && value.indexOf('.') !== -1) {
				value = `${Math.floor(numberValue * Math.pow(10, props.toFixed)) / Math.pow(10, props.toFixed)}`;
			}
		}

		if (setNewNumberValue) {
			setInputContent(value);
		}

		if (props.showCharCounter) {
			setCharCount(value.length);
		}

		if (timeout) {
			window.clearTimeout(timeout);
		}

		let newInputState = inputState;

		setTimeout(window.setTimeout(() => {
			if (!props.isOptional && isEmpty(value) || (props.valid === false)) {
				newInputState = FormInputState.Error;
			} else if (props.validationRegex) {
				if (isRegexValid(value)) {
					newInputState = FormInputState.Valid;
				}
				else {
					if (props.isOptional && !value) {
						newInputState = FormInputState.Valid;
					}
					else {
						newInputState = FormInputState.Error;
					}
				}
			}
			else {
				newInputState = FormInputState.Valid;
			}

			setInputState(newInputState);
			props.onChange(event, newInputState);
		}, timeoutMs));
	}

	function onInputClicked(event: any) {
		inputState === FormInputState.Error ?
			setInputState(FormInputState.Error) :
			setInputState(FormInputState.Selected);
		inputRef.current?.focus();
	}

	function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	}

	function showErrorBottomSection() {
		return (props.errorMessage && props.errorMessagePosition === ErrorPosition.BOTTOM && inputState === FormInputState.Error);
	}

	function showCounterSection() {
		return (props.showCharCounter && props.maxLength);
	}

	return (
		<div
			className={classnames(S.formGroup, {
				[S.adminClient]: props.className === 'admin-client',
			})}
		>
			<label
				className={classnames(S.formGroupLabel , {
					[S.formGroupLabelError]: inputState === FormInputState.Error,
					[S.formGroupLabelErrorMessageBottom]: props.errorMessagePosition === ErrorPosition.BOTTOM,
				} )}
			>
				<span
					className={classnames('' , {
						[S.formGroupLabelTextError]: inputState === FormInputState.Error,
					} )}
				>
					{props.labelText}
				</span>

				{props.errorMessage && props.errorMessagePosition === ErrorPosition.TOP && inputState === FormInputState.Error && (
					<span
						className={classnames('', {
							[S.formGroupLabelMessageError]: inputState === FormInputState.Error,
						} )}
					>
						{props.errorMessage}
					</span>
				)}

				{props.isOptional && props.showOptionalLabel && (
					<span
						className={classnames(S.formGroupLabelOptional, {
							[S.formGroupLabelOptionalSelected]: inputState === FormInputState.Selected,
							[S.formGroupLabelOptionalError]: inputState === FormInputState.Error,
						} )}
					>
						{T.GeneralOptionalField}
					</span>
				)}

				{props.labelLinkHref && props.labelLinkText && (
					<span className={S.formGroupLabelLink}>
						<Link to={props.labelLinkHref} >
							{props.labelLinkText}
						</Link>
					</span>
				)}

			</label>

			<div
				className={classnames('', {
					[S.formGroupFormControlGroup]: props.inputAppend,
				} )}
			>
				<input
					ref={inputRef}
					className={classnames(S.formGroupFormControl, {
						[S.formGroupFormControlSelected]: inputHasFocus,
						[S.formGroupFormControlError]: inputState === FormInputState.Error,
						[S.formGroupFormControlUnselectedFilled]: !inputHasFocus && inputContent !== '' && inputState !== FormInputState.Error,
						[S.formGroupFormControlUnselectedUnfilled]: !inputHasFocus && !inputContent && inputState !== FormInputState.Error,
						[S.formGroupFormControlDisabled]: props.disabled,
						[S.formGroupFormControlGroupInput]: props.inputAppend,
					} )}
					max={props.max}
					min={props.min}
					type={props.inputType}
					name={props.name}
					placeholder={props.placeHolder}
					value={inputContent || ''}
					maxLength={props.maxLength}
					onFocus={() => setInputHasFocus(true)}
					onBlur={(event) => onInputBlur(event)}
					onClick={(event) => onInputClicked(event)}
					onChange={(event) => onInputChange(event, props.timeoutMs)}
					onKeyDown={(event) => onKeyDown(event)}
					disabled={props.disabled}
					autoComplete={props.autoComplete}
				/>

				{props.inputAppend && (
					<div className={S.formGroupFormControlGroupInputAppend}>
						<div
							className={classnames(S.formGroupFormControlGroupInputAppendContent, {
								[S.formGroupFormControlGroupInputAppendContentSelected]: inputState === FormInputState.Selected,
								[S.formGroupFormControlGroupInputAppendContentError]: inputState === FormInputState.Error,
								[S.formGroupFormControlGroupInputAppendEvent]: !!props.inputAppendClick,
							} )}
							onClick={(event) => props.inputAppendClick && props.inputAppendClick(event)}
							id={props.inputAppendElementId ? props.inputAppendElementId : ''}
						>
							{props.inputAppend}
						</div>
					</div>
				)}

				<div className={S.formGroupInputBottomElements}>
					{showErrorBottomSection() && (
						<div
							className={classnames(S.formGroupLabelMessageErrorBottom, {
								[S.formGroupLabelMessageError]: inputState === FormInputState.Error,
								[S.formGroupLabelMessageErrorBottomCounterShown]: showErrorBottomSection() && showCounterSection(),
							} )}
						>
							{props.errorMessage}
						</div>
					)}

					{showCounterSection() && (
						<div className={S.formGroupInputBottomElementsCounterContainer}>
							<div className={S.formGroupInputBottomElementsCounterContainerCount}>
								{charCount} / {props.maxLength}
							</div>
						</div>
					)}
				</div>

			</div>
		</div>
	);
}

Input.defaultProps = {
	isOptional: false,
	showOptionalLabel: true,
	className: 'configurator',
	disabled: false,
	labelLinkHref: '',
	labelLinkText: '',
	errorMessagePosition: ErrorPosition.TOP,
};
