import { IMotionDetectorOptionState, State } from '@models';

export interface IMotionDetectorOption {
	id: number;
	name: string;
	description: string;
	price?: number;
	unit?: string;
	technicalSheetUrl: string;
	imageUrl: string;
}

export function fromMotionDetectorOptionDTO(motionDetectors: IMotionDetectorOption[]): IMotionDetectorOptionState {
	const option = motionDetectors[0];
	return {
		id: option.id,
		title: option.name,
		price: option.price,
		unit: option.unit,
		technicalSheetUrl: option.technicalSheetUrl,
		imageUrl: option.imageUrl,
		description: option.description,
		state: State.Enabled,
	};
}

export function toMotionDetectorOptionDTO(motionDetectorState: IMotionDetectorOptionState): IMotionDetectorOption {
	return {
		id: motionDetectorState.id,
		name: motionDetectorState.title,
		price: motionDetectorState.price,
		unit: motionDetectorState.unit,
		technicalSheetUrl: motionDetectorState.technicalSheetUrl,
		imageUrl: motionDetectorState.imageUrl,
		description: motionDetectorState.description,
	};
}
