import { getCurrentLang } from '@i18n';
import classnames from 'classnames';
import React from 'react';
import { getPathForLanguageSwitch } from '../../routes';
import S from './styles/LangSwitcher.styl';

export default function LangSwitcher() {
	const lang = getCurrentLang();

	return (
		<div className={S.langSwitcherContainer}>
			<a
				className={classnames(S.langSwitcherContainerLink, {
					[S.langSwitcherContainerLinkActive]: lang === 'en',
				})}
				href={lang === 'en' ? '' : getPathForLanguageSwitch()}
			>
				EN
			</a>
			<span className={S.langSwitcherContainerSeparator}>|</span>
			<a
				className={classnames(S.langSwitcherContainerLink, {
					[S.langSwitcherContainerLinkActive]: lang === 'fr',
				})}
				href={lang === 'fr' ? '' : getPathForLanguageSwitch()}
			>
				FR
			</a>
		</div>
	);
}
