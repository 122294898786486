import React from 'react';

import GS from '../../styles/global.styl';

interface IUncheckedCheckboxMvp {
	fill: string;
}

export default function UncheckedCheckboxMvp(props: IUncheckedCheckboxMvp) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g className="svg-uncheck-checkbox-mvp" fill={props.fill} fillRule="evenodd">
				<path d="M19 5v14H5V5h14zm0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" transform="translate(-761 -706) translate(761 226) translate(0 480)"/>
			</g>
		</svg>
	);
}

UncheckedCheckboxMvp.defaultProps = {
	fill: GS.darkBlueGray,
};
