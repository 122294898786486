import { useContext } from 'react';
import AuthContext from './AuthContext';
import ModalContext from './ModalContext';
import ToastContext from './ToastContext';

export enum ToastType {
	success,
	warning,
	error,
}

export enum ModalIconType {
	CheckMark,
	Mail,
	Info,
	Warning,
	Timer,
	Power,
	Plus,
	Contact,
	Trash,
	Cart,
	CheckInCircle,
}

export const useToastContext = () => useContext(ToastContext);

export const useAuthContext = () => useContext(AuthContext);

export const useModalContext = () => useContext(ModalContext);
