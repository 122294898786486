import { State } from '@models';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import CheckedCheckbox from '../svg/CheckedCheckbox';
import UncheckedCheckbox from '../svg/UncheckedCheckbox';
import S from './styles/Checkbox.styl';

interface ICheckboxProps {
    title: string;
    state: State;
    onChange?: (checked: boolean) => void;
    isMultiInput: boolean;
    labelRight?: boolean;
    disabled?: boolean;
}

export default function Checkbox(props: ICheckboxProps) {
    const [checkboxState, setCheckboxState] = useState(props.state);

    useEffect(() => {
        setCheckboxState(props.state);
    }, [props.state]);

    function onClick() {
        if (props.disabled) {
            return;
        }
        let nextState = State.Enabled;

        if (checkboxState === State.Enabled) {
            nextState = State.Selected;
        }

        if (props.onChange) {
            nextState === State.Enabled ?
                props.onChange(false) :
                props.onChange(true);
        }
    }

    return (
        <div
            className={classnames('', {
                [S.checkboxWrapper]: props.isMultiInput,
            })}
            onClick={onClick}
            style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}
        >
            <div className={S.checkboxContainer}>
                <div
                    style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}
                >
                    {!props.labelRight && (
                        <div
                            className={classnames(S.checkboxContainerTitle, {
                                [S.checkboxContainerTitleLeft]: !props.labelRight,
                            })}
                        >
                            {props.title}
                        </div>
                    )}

                    {checkboxState === State.Selected && (
                        <CheckedCheckbox
                            stroke={S.blueNorbec}
                            fill={S.blueNorbec}
                            fillOpacity={'0.2'}
                        />
                    )}
                    {checkboxState === State.Focused && (
                        <CheckedCheckbox
                            stroke={S.orange}
                            fill={S.orange}
                            fillOpacity={'0.2'}
                        />
                    )}
                    {checkboxState === State.Enabled && (
                        <UncheckedCheckbox
                            stroke={S.darkBlueGray}
                        />
                    )}

                    {props.labelRight && (
                        <div
                            className={classnames(S.checkboxContainerTitle, {
                                [S.checkboxContainerTitleRight]: props.labelRight,
                            })}
                        >
                            {props.title}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

Checkbox.defaultProps = {
    isMultiInput: true,
    labelRight: true,
};
