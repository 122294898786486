import { Steps } from '@models';
import { forEach } from 'lodash';

const definition: { [key: string]: any } = {
	configuration: {
		project_name: {
			en: ['/project', '/project/:id/name'],
			fr: ['/projet', '/projet/:id/nom'],
		},
		installation_place: {
			en: '/project/:id/location',
			fr: '/projet/:id/lieu',
		},
		seismic_kit: {
			en: '/project/:id/seismic-kit',
			fr: '/projet/:id/kit-sysmique',
		},
		number_of_rooms: {
			en: '/project/:id/rooms',
			fr: '/projet/:id/chambres',
		},
		room_types: {
			en: '/project/:id/rooms/type',
			fr: '/projet/:id/chambres/type',
		},
		combo_width: {
			en: '/project/:id/rooms/width',
			fr: '/projet/:id/chambres/largeur',
		},
		combo_depth: {
			en: '/project/:id/rooms/depth',
			fr: '/projet/:id/chambres/profondeur',
		},
		combo_height: {
			en: '/project/:id/rooms/height',
			fr: '/projet/:id/chambres/hauteur',
		},
		combo_system: {
			en: '/project/:id/combo/system',
			fr: '/projet/:id/combo/systeme',
		},
		condenser_distance: {
			en: '/project/:id/combo/condenser',
			fr: '/projet/:id/combo/condensateur',
		},
		combo_options: {
			en: '/project/:id/combo/options',
			fr: '/projet/:id/combo/options',
		},
	},
	options: {
		doors_wall: {
			en: '/project/:id/doors/wall-selection',
			fr: '/projet/:id/portes/choix-de-mur',
		},
        doors_rampe: {
			en: '/project/:id/doors/rampe',
			fr: '/projet/:id/portes/rampe',
		},
		doors_width: {
			en: '/project/:id/doors/opening-width',
			fr: '/projet/:id/portes/largeur-ouverture',
		},
		doors_position: {
			en: '/project/:id/doors/wall-placement',
			fr: '/projet/:id/portes/positionnement',
		},
		doors_opening_direction: {
			en: '/project/:id/doors/opening-direction',
			fr: '/projet/:id/portes/direction-ouverture',
		},
		doors_options: {
			en: '/project/:id/doors/options',
			fr: '/projet/:id/portes/options',
		},
		combo_i3: {
			en: '/project/:id/doors/i3',
			fr: '/projet/:id/portes/i3',
		},
		combo_floor_finish: {
			en: '/project/:id/combo/floor/finish',
			fr: '/projet/:id/combo/plancher/finis',
		},
		combo_floor_type: {
			en: '/project/:id/combo/floor/type',
			fr: '/projet/:id/combo/plancher/type',
		},
		room_light_models: {
			en: '/project/:id/rooms/lights',
			fr: '/projet/:id/chambres/lumieres',
		},
		room_motion_detector: {
			en: '/project/:id/rooms/motion-detector',
			fr: '/projet/:id/chambres/detecteur-mouvement',
		},
	},
	installation: {
		installation: {
			en: '/project/:id/installation',
			fr: '/projet/:id/installation',
		},
		delivery_choice: {
			en: '/project/:id/delivery/choice',
			fr: '/projet/:id/transport/choix',
		},
		delivery_date: {
			en: '/project/:id/delivery/date',
			fr: '/projet/:id/livraison/date',
		},
		delivery_contact: {
			en: '/project/:id/delivery/contact',
			fr: '/projet/:id/livraison/contact',
		},
		delivery_place: {
			en: '/project/:id/delivery/location',
			fr: '/projet/:id/livraison/lieu',
		},
		quote: {
			en: '/project/:id/quote',
			fr: '/projet/:id/soumission',
		},
	},
};

export function getAllValidRoutes(path?: string, swap?: boolean): string[] {
	const routes: string[] = [];
	forEach(definition, (tab) => {
		forEach(tab, (step) => {
			(swap ? Object.values(step).reverse() : Object.values(step)).forEach((route: any) => {
				if (typeof route === 'string') {
					routes.push(`${path ? path : ''}${route}`);
				} else {
					forEach(route, (forkedRoute) => {
						routes.push(`${path ? path : ''}${forkedRoute}`);
					});
				}
			});
		});
	});
	return routes;
}

// Use to redirect from admin to configurator
export function getRoutesByStep() {
	const { configuration, options, installation } = definition;

	const stepRoutes: { [key: number]: any }  = [];
	stepRoutes[Steps.PROJECT_NAME] = configuration.project_name;
	stepRoutes[Steps.INSTALLATION_LOCATION] = configuration.installation_place;
	stepRoutes[Steps.SEISMIC_KIT] = configuration.seismic_kit;
	stepRoutes[Steps.NUMBER_OF_ROOMS] = configuration.number_of_rooms;
	stepRoutes[Steps.ROOM_TYPES] = configuration.room_types;
	stepRoutes[Steps.COMBO_WIDTH] = configuration.combo_width;
	stepRoutes[Steps.COMBO_DEPTH] = configuration.combo_depth;
	stepRoutes[Steps.COMBO_HEIGHT] = configuration.combo_height;
	stepRoutes[Steps.COMBO_SYSTEM] = configuration.combo_system;
	stepRoutes[Steps.CONDENSER_DISTANCE] = configuration.condenser_distance;
	stepRoutes[Steps.COMBO_OPTIONS] = configuration.combo_options;
	stepRoutes[Steps.DOORS_WALL] = options.doors_wall;
	stepRoutes[Steps.DOORS_RAMPE] = options.doors_rampe;
	stepRoutes[Steps.DOORS_WIDTH] = options.doors_width;
	stepRoutes[Steps.DOORS_POSITION] = options.doors_position;
	stepRoutes[Steps.DOORS_OPENING_DIRECTION] = options.doors_opening_direction;
	stepRoutes[Steps.DOORS_OPTIONS] = options.doors_options;
	stepRoutes[Steps.COMBO_I3] = options.combo_i3;
	stepRoutes[Steps.COMBO_FLOOR_FINISH] = options.combo_floor_finish;
	stepRoutes[Steps.COMBO_FLOOR_TYPE] = options.combo_floor_type;
	stepRoutes[Steps.ROOM_LIGHT_MODELS] = options.room_light_models;
	stepRoutes[Steps.ROOM_MOTION_DETECTOR] = options.room_motion_detector;
	stepRoutes[Steps.INSTALLATION] = installation.installation;
	stepRoutes[Steps.DELIVERY_CHOICE] = installation.delivery_choice;
	stepRoutes[Steps.DELIVERY_DATE] = installation.delivery_date;
	stepRoutes[Steps.DELIVERY_CONTACT] = installation.delivery_contact;
	stepRoutes[Steps.DELIVERY_PLACE] = installation.delivery_place;
	stepRoutes[Steps.QUOTE] = installation.quote;

	return stepRoutes;
}

export const routeByStep = getRoutesByStep();
export default definition;
