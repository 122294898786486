import React from 'react';

export default function DuplicateIcon({ color }: { color?: string }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke={color ? color : '#FFF'} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
            <g className="svg-fill-color" fill={color ? color : '#FFF'} fillRule="evenodd">
                <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
            </g>
        </svg>
    );
}
