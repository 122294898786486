import React from 'react';

export enum ChevronDirection {
	LEFT,
	RIGHT,
	UP,
	DOWN,
}

interface IChevronProps {
	readonly direction?: ChevronDirection;
	readonly color?: string;
	readonly className?: string;
	readonly transitionDuration?: string;
}

export default function Chevron({ direction, color = 'black', className, transitionDuration }: IChevronProps) {
	let deg;

	switch (direction) {
		case ChevronDirection.DOWN:
			deg = 0;
			break;
		case ChevronDirection.UP:
			deg = -180;
			break;
		case ChevronDirection.LEFT:
			deg = 90;
			break;
		case ChevronDirection.RIGHT:
			deg = -90;
			break;
	}

	return (
		<div style={{ alignItems: 'center', display: 'flex', transition: `all ${transitionDuration} ease-out`, transform: `rotate(${deg}deg)` }} className={className}>
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" viewBox="0 0 16 9">
				<path className="svg-fill-color" fill={color} fillRule="nonzero" d="M15.526 2.396L8.372 8.443h-.475L.743 2.396 1.956.34l6.152 5.045L14.278.34z" />
			</svg>
		</div>
	);
}

Chevron.defaultProps = {
	transitionDuration: '0.25s',
};
