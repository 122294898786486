import FloorPill from '@components/labels/FloorPill';
import PillLabel from '@components/labels/PillLabel';
import SummaryElementLabel from '@components/labels/SummaryElementLabel';
import { useLocale } from '@i18n';
import { CoolingSystemType, IRoomState, State, Steps } from '@models';
import { useStore } from '@store';
import FormatUtils from '@utils/format';
import { getSummaryRoomTargetLink, shouldShowSection } from '@utils/navigation';
import RoomUtils from '@utils/room';
import classnames from 'classnames';
import { capitalize } from 'lodash';
import React from 'react';
import S from './styles/SummaryRoomSpecs.styl';

interface IRoomSpecsProps {
    room: IRoomState;
}

const stepsFollowingRoomState = [
    Steps.ROOM_TYPES,
    Steps.COMBO_WIDTH,
    Steps.CONDENSER_DISTANCE,
    Steps.DOORS_WALL,
    Steps.DOORS_RAMPE,
    Steps.DOORS_WIDTH,
    Steps.DOORS_POSITION,
    Steps.DOORS_OPENING_DIRECTION,
];

export default function SummaryRoomSpecs(props: IRoomSpecsProps) {
    const T = useLocale();
    const { state } = useStore();

    function getRoomState() {
        if (state.layout.currentStep === Steps.COMBO_HEIGHT ||
            state.layout.currentStep === Steps.COMBO_DEPTH) {
            // state on rooms disabled with combo height step
            return State.Enabled;
        }
        return props.room.state;
    }

    function getSummaryElementState(step: Steps) {
        if (state.layout.currentStep === step) {
            if (stepsFollowingRoomState.includes(state.layout.currentStep)) {
                return props.room.state;
            }
            return props.room.state === State.Focused ? State.Focused : State.Selected;
        }
        return State.Enabled;
    }

    function getSectionElementState(minStep: Steps, maxStep: Steps) {
        if (state.layout.currentStep >= minStep && state.layout.currentStep <= maxStep) {
            if (stepsFollowingRoomState.includes(state.layout.currentStep)) {
                return props.room.state;
            }
            return props.room.state === State.Focused ? State.Focused : State.Selected;
        }
        return State.Enabled;
    }

    function getRoomInteriorWidth(): string {
        return props.room.hasDefaultWidth ?
            T.InteriorWidthPendingLabel :
            FormatUtils.formatInches(props.room.width);
    }

    function getRoomCoolingSytem(): string {
        if (props.room.hasSelectedNoCoolingSystem) {
            return T.CoolingSystemNoneLabel;
        }
        return props.room.coolingSystem ?
            T[props.room.coolingSystem.name] :
            T.CoolingSystemPendingLabel;
    }

    function getRoomCondenserDistance(): string {
        if ((props.room.coolingSystem && props.room.coolingSystem.id === CoolingSystemType.Pro3) || props.room.hasSelectedNoCoolingSystem) {
            return '';
        }
        if (props.room.hasDefaultCondenserDistance) {
            return T.CondenserDistancePendingLabel;
        }
        const distance = FormatUtils.formatInches(props.room.condenserDistance);
        return `${distance} ${T.SummaryCondenserDistance}`;
    }

    function getRoomFloorMaterial(): string {
        return T[props.room.floorMaterial?.name || ''];
    }

    function getCoolingSystemOptions() {
        if (props.room.coolingSystemOptions.length === 0) {
            return (
                <SummaryElementLabel
                    title=""
                    value="..."
                    valueState={getSummaryElementState(Steps.COMBO_OPTIONS)}
                    isMulti={true}
                />
            );
        }
        return props.room.coolingSystemOptions.map((option) => {
            if (shouldShowCoolingSystemOptions()) {
                return (
                    <SummaryElementLabel
                        key={option.id}
                        title=""
                        value={T[option.name]}
                        valueState={getSummaryElementState(Steps.COMBO_OPTIONS)}
                        isMulti={true}
                    />
                );
            }
        });
    }

    function getRoomFloorTexture(): string {
        return T[props.room.floorTexture?.name || ''];
    }

    function getDoorPosition(): string {
        if (!props.room.door || props.room.door?.hasDefaultPosition) {
            return T.DoorsPositioningPendingLabel;
        }
        return RoomUtils.isDoorPositionCentered(props.room.door, props.room, state.settings) ?
            T.DoorsPositioningCenterLabel :
            `${FormatUtils.formatInches(props.room.door.position)} ${T.DoorsPositioningSummary}`;
    }

    function getDoorI3Option() {
        if (!props.room.door?.i3Option) {
            return T.DoorsI3OptionPendingLabel;
        }
        return T[props.room.door.i3Option.title];
    }

    function shouldShowCondenserDistance() {
        return !props.room.hasDefaultCondenserDistance || (props.room.hasDefaultCondenserDistance && state.layout.currentStep === Steps.CONDENSER_DISTANCE);
    }

    function shouldShowCoolingSystemOptions() {
        if (!props.room.hasSelectedNoCoolingSystem) {
            if (props.room.coolingSystem?.id === CoolingSystemType.Pro3) {
                return false;
            }
        }
        else {
            return false;
        }
        return true;
    }

    function shouldShowI3Option() {
        return props.room.door?.i3Option || (!props.room.door?.i3Option && state.layout.currentStep === Steps.COMBO_I3);
    }

    function shouldShowMotionDetectorOption() {
        return props.room.motionDetector || (!props.room.motionDetector && state.layout.currentStep === Steps.ROOM_MOTION_DETECTOR);
    }

    function roomDoorHasNoOptions() {
        return props.room.door &&
            !props.room.door.options.curtain &&
            !props.room.door.options.kickplate &&
            !props.room.door.options.pedal &&
            !props.room.door.options.window;
    }

    return (
        <div id={props.room.code} className={S.roomSpecs}>
            <PillLabel
                title={props.room.code}
                state={getRoomState()}
            />
            {shouldShowSection(Steps.ROOM_TYPES, state) && props.room.type && (
                <div
                    id={getSummaryRoomTargetLink(props.room.code, Steps.ROOM_TYPES)}
                    className={S.roomSpecsRoomType}
                >
                    <div className={S.roomSpecsRoomTypeLabelRow}>
                        <div
                            className={classnames({
                                [S.roomSpecsRoomTypeDescriptionActive]: getSummaryElementState(Steps.ROOM_TYPES) === State.Focused,
                                [S.roomSpecsRoomTypeDescriptionSelected]: getSummaryElementState(Steps.ROOM_TYPES) === State.Selected,
                            })}
                        >
                            {T[props.room.type.name]}
                        </div>

                        <FloorPill
                            text={props.room.type.hasFloor ? T.RoomTypeWithFloorLabel : T.RoomTypeWithoutFloorLabel}
                            hollow={!props.room.type.hasFloor}
                            isSelected={getSummaryElementState(Steps.ROOM_TYPES) === State.Selected}
                            isActive={getSummaryElementState(Steps.ROOM_TYPES) === State.Focused}
                        />
                    </div>
                    <div
                        className={classnames(S.roomSpecsRoomTypeDescription, {
                            [S.roomSpecsRoomTypeDescriptionActive]: getSummaryElementState(Steps.ROOM_TYPES) === State.Focused,
                            [S.roomSpecsRoomTypeDescriptionSelected]: getSummaryElementState(Steps.ROOM_TYPES) === State.Selected,
                        })}
                    >
                        {T[props.room.type.description]}
                    </div>
                </div>
            )}
            {shouldShowSection(Steps.ROOM_TYPES, state) && !props.room.type && (
                <div
                    id={getSummaryRoomTargetLink(props.room.code, Steps.ROOM_TYPES)}
                    className={classnames(S.roomSpecsRoomType, {
                        [S.roomSpecsRoomTypeActive]: getSummaryElementState(Steps.ROOM_TYPES) === State.Focused,
                        [S.roomSpecsRoomTypeSelected]: getSummaryElementState(Steps.ROOM_TYPES) === State.Selected,
                    })}
                >
                    ...
                </div>
            )}
            {shouldShowSection(Steps.COMBO_WIDTH, state) && (
                <SummaryElementLabel
                    id={getSummaryRoomTargetLink(props.room.code, Steps.COMBO_WIDTH)}
                    title={T.SummaryInteriorWidth}
                    value={getRoomInteriorWidth()}
                    valueState={getSummaryElementState(Steps.COMBO_WIDTH)}
                />
            )}
            {shouldShowSection(Steps.COMBO_SYSTEM, state) && (
                <SummaryElementLabel
                    id={getSummaryRoomTargetLink(props.room.code, Steps.COMBO_SYSTEM)}
                    title={T.SummaryCoolingSystem}
                    value={getRoomCoolingSytem()}
                    valueState={getSummaryElementState(Steps.COMBO_SYSTEM)}
                    titleState={getSectionElementState(Steps.COMBO_SYSTEM, Steps.COMBO_OPTIONS)}
                    isMulti={true}
                />
            )}
            {shouldShowSection(Steps.CONDENSER_DISTANCE, state) && !state.layout.requirements.shouldSkipCondenserDistanceStep && shouldShowCondenserDistance() && (
                <SummaryElementLabel
                    title=""
                    value={getRoomCondenserDistance()}
                    valueState={getSummaryElementState(Steps.CONDENSER_DISTANCE)}
                    isMulti={true}
                />
            )}
            {shouldShowSection(Steps.COMBO_OPTIONS, state)
                && props.room.coolingSystemOptions
                && !(props.room.coolingSystemOptions.length === 0
                    && state.layout.currentStep !== Steps.COMBO_OPTIONS) && (
                    getCoolingSystemOptions()
                )
            }
            {shouldShowSection(Steps.DOORS_WALL, state) && (
                <SummaryElementLabel
                    id={getSummaryRoomTargetLink(props.room.code, Steps.DOORS_WALL)}
                    title={`${T.GeneralDoor} ${props.room.code}`}
                    value={props.room.door ? T[`DoorsWallSelectionRoom${props.room.code}Wall${props.room.door?.roomWall}`] : T.DoorsWidthDefaultLabel}
                    titleState={getSectionElementState(Steps.DOORS_WALL, Steps.COMBO_I3)}
                    valueState={getSummaryElementState(Steps.DOORS_WALL)}
                    isMulti={true}
                />
            )}
            {shouldShowSection(Steps.DOORS_RAMPE, state) && (
                <SummaryElementLabel
                    title=""
                    value={props.room.door && !props.room.door.hasDefaultWidth ? `${props.room.door.width}"` : T.DoorsWidthDefaultLabel}
                    valueState={getSummaryElementState(Steps.DOORS_RAMPE)}
                    isMulti={true}
                />
            )}
            {shouldShowSection(Steps.DOORS_WIDTH, state) && (
                <SummaryElementLabel
                    title=""
                    value={props.room.door && !props.room.door.hasDefaultWidth ? `${props.room.door.width}"` : T.DoorsWidthDefaultLabel}
                    valueState={getSummaryElementState(Steps.DOORS_WIDTH)}
                    isMulti={true}
                />
            )}
            {shouldShowSection(Steps.DOORS_POSITION, state) && (
                <SummaryElementLabel
                    title=""
                    value={getDoorPosition()}
                    valueState={getSummaryElementState(Steps.DOORS_POSITION)}
                    isMulti={true}
                />
            )}
            {shouldShowSection(Steps.DOORS_OPENING_DIRECTION, state) && (
                <SummaryElementLabel
                    title=""
                    value={props.room.door?.hasDefaultOpeningDirection ? T.DoorsOpeningDirectionDefaultLabel : T[`DoorsOpeningDirection${capitalize(props.room.door?.openingDirection)}Hinge`]}
                    valueState={getSummaryElementState(Steps.DOORS_OPENING_DIRECTION)}
                    isMulti={true}
                />
            )}
            {shouldShowSection(Steps.DOORS_OPTIONS, state) && (
                <>
                    {roomDoorHasNoOptions() && state.layout.currentStep === Steps.DOORS_OPTIONS && (
                        <SummaryElementLabel
                            title=""
                            value="..."
                            valueState={getSummaryElementState(Steps.DOORS_OPTIONS)}
                            isMulti={true}
                        />
                    )}
                    {props.room.door && props.room.door.options.pedal && (
                        <SummaryElementLabel
                            title=""
                            value={T.DoorsOptionsPedal}
                            valueState={getSummaryElementState(Steps.DOORS_OPTIONS)}
                            isMulti={true}
                        />
                    )}
                    {props.room.door && props.room.door.options.curtain && (
                        <SummaryElementLabel
                            title=""
                            value={T.DoorsOptionsCurtain}
                            valueState={getSummaryElementState(Steps.DOORS_OPTIONS)}
                            isMulti={true}
                        />
                    )}
                    {props.room.door && props.room.door.options.window && (
                        <SummaryElementLabel
                            title=""
                            value={T.DoorsOptionsWindow}
                            valueState={getSummaryElementState(Steps.DOORS_OPTIONS)}
                            isMulti={true}
                        />
                    )}
                    {props.room.door && props.room.door.options.kickplate && (
                        <SummaryElementLabel
                            title=""
                            value={T.DoorsOptionsKickplate}
                            valueState={getSummaryElementState(Steps.DOORS_OPTIONS)}
                            isMulti={true}
                        />
                    )}
                </>
            )}
            {shouldShowSection(Steps.COMBO_I3, state) && shouldShowI3Option() && (
                <SummaryElementLabel
                    title=""
                    value={getDoorI3Option()}
                    valueState={getSummaryElementState(Steps.COMBO_I3)}
                    isMulti={true}
                />
            )}
            {shouldShowSection(Steps.COMBO_FLOOR_FINISH, state) && !!props.room.floorMaterial && (
                <SummaryElementLabel
                    id={getSummaryRoomTargetLink(props.room.code, Steps.COMBO_FLOOR_FINISH)}
                    title={T.FloorMaterialSummary}
                    titleState={getSectionElementState(Steps.COMBO_FLOOR_FINISH, Steps.COMBO_FLOOR_TYPE)}
                    value={getRoomFloorMaterial()}
                    valueState={getSummaryElementState(Steps.COMBO_FLOOR_FINISH)}
                    isMulti={true}
                />
            )}
            {shouldShowSection(Steps.COMBO_FLOOR_TYPE, state) && !!props.room.floorTexture && (
                <SummaryElementLabel
                    value={getRoomFloorTexture()}
                    valueState={getSummaryElementState(Steps.COMBO_FLOOR_TYPE)}
                    isMulti={true}
                />
            )}
            {shouldShowSection(Steps.ROOM_LIGHT_MODELS, state) && (
                <SummaryElementLabel
                    id={getSummaryRoomTargetLink(props.room.code, Steps.ROOM_LIGHT_MODELS)}
                    title={T.LightsOptionsSummary}
                    value={T[props.room.lightOption?.name || '...']}
                    titleState={getSectionElementState(Steps.ROOM_LIGHT_MODELS, Steps.ROOM_MOTION_DETECTOR)}
                    valueState={getSummaryElementState(Steps.ROOM_LIGHT_MODELS)}
                    isMulti={true}
                />
            )}
            {shouldShowSection(Steps.ROOM_MOTION_DETECTOR, state) && shouldShowMotionDetectorOption() && (
                <SummaryElementLabel
                    title=""
                    value={props.room.motionDetector ? T.MotionDetectorSummary : T.MotionDetectorPendingLabel}
                    valueState={getSummaryElementState(Steps.ROOM_MOTION_DETECTOR)}
                    isMulti={true}
                />
            )}
        </div>
    );
}
