import { CloseIcon, ExpandIcon } from '@components/svg';
import { useLocale } from '@i18n';
import { useStore } from '@store/index';
import classnames from 'classnames';
import React from 'react';
import S from './styles/DrawingHeader.styl';

interface IDrawingHeaderProps {
	onClick?: () => void;
	closeDrawing?: boolean;
	showDrawing?: boolean;
	titleStyle?: string;
	onFullScreenClick?: () => void;
}

export default function DrawingHeader(props: IDrawingHeaderProps) {
	const T = useLocale();
	const { state, actions, dispatch } = useStore();

	function handleClick() {
		if (props.onClick) {
			props.onClick();
		}
	}

	return (
		<div className={S.drawingHeader}>
			<h2 className={classnames(props.titleStyle, S.drawingHeaderTitle)}>{T.SummaryDrawingTitle}</h2>
			&nbsp;
			<span className={S.drawingHeaderLine} />
			<div className={S.drawingHeaderSpacer} />
			<div className={S.drawingHeaderActionContainer}>
				{props.showDrawing &&
					(
						<div
							className={S.drawingHeaderActionContainerButton}
							onClick={handleClick}
						>
							<ExpandIcon stroke={S.darkBlueGray}/>
							<div className={S.drawingHeaderActionContainerButtonText}>
								{T.GeneralView}
							</div>
						</div>
					)
				}
				{props.closeDrawing &&
					(
						<>
							<div
								className={classnames(S.drawingHeaderActionContainerButton, S.drawingHeaderActionContainerButtonExpend)}
								onClick={() => {
									dispatch(actions.LayoutActions.setIsDrawingModalFullScreen(!state.layout.isDrawingModalFullScreen));
									if (props.onFullScreenClick) {
										props.onFullScreenClick();
									}
								}}
							>
								<ExpandIcon stroke={S.darkBlueGray}/>
								<div className={S.drawingHeaderActionContainerButtonText}>
									{state.layout.isDrawingModalFullScreen ? T.QuitFullScreenMode : T.ViewFullScreenMode}
								</div>
							</div>

							<div
								className={S.drawingHeaderActionContainerButton}
								onClick={() => {
									dispatch(actions.LayoutActions.setIsDrawingModalFullScreen(false));
									handleClick();
								}}
							>
								<CloseIcon stroke={S.darkBlueGray}/>
								<div className={S.drawingHeaderActionContainerButtonText}>
									{T.GeneralClose}
								</div>
							</div>
						</>
					)
				}
			</div>
		</div>
	);
}
