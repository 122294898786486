import axiosInstance from './axiosInstance';
import { IChangePasswordBody, ILoginBody, ILoginData, IResetPasswordBody } from './interface';

const POST_LOGIN = `/auth/login`;
const POST_VALIDATE_TOKEN = `/auth/validateToken`;
const POST_LOGIN_WITH_TOKEN = `/auth/loginWithToken`;
const POST_CHANGE_PASSWORD = `/auth/changePassword`;
const GET_REQUEST_RESET_PASSWORD = `/auth/requestResetPassword`;
const DELETE_LOGOUT = `/auth/logout`;
const POST_RESET_PASSWORD = `/auth/resetPassword`;

export async function postLogin(body: ILoginBody): Promise<{ data: ILoginData }> {
	return await axiosInstance.post(POST_LOGIN, body);
}

export async function postValidateToken(token: string): Promise<any> {
	return await axiosInstance.post(POST_VALIDATE_TOKEN, null, { params: { token } });
}

export async function postLoginWithToken(): Promise<{ data: ILoginData }> {
	return await axiosInstance.post(POST_LOGIN_WITH_TOKEN, null);
}

export async function postChangePassword(body: IChangePasswordBody): Promise<any> {
	return await axiosInstance.post(POST_CHANGE_PASSWORD, body);
}

export async function getRequestResetPassword(email: string): Promise<any> {
	return await axiosInstance.get(GET_REQUEST_RESET_PASSWORD, { params: { email } });
}

export async function postResetPassword(body: IResetPasswordBody, token: string): Promise<any> {
	return await axiosInstance.post(POST_RESET_PASSWORD, body, { headers: { 'Authorization': `Bearer ${token}` } });
}

export async function deleteLogout(): Promise<any> {
	return await axiosInstance.delete(DELETE_LOGOUT);
}
