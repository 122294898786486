import React from 'react';

import GS from '../../styles/global.styl';

interface ICheckedCheckboxMvp {
	fill: string;
}

export default function CheckedCheckboxMvp(props: ICheckedCheckboxMvp) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g className="svg-check-checkbox-mvp" fill={props.fill} fillRule="evenodd">
				<path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" transform="translate(-761 -674) translate(761 226) translate(0 448)"/>
			</g>
		</svg>
	);
}

CheckedCheckboxMvp.defaultProps = {
	fill: GS.darkBlueGray,
};
