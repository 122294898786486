import React from 'react';

export default function PlusIcon({ color }: {color?: string}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fillRule="evenodd">
				<g fill={color ? color : '#FFF'}>
					<g>
						<g>
							<g>
								<path d="M19 13L13 13 13 19 11 19 11 13 5 13 5 11 11 11 11 5 13 5 13 11 19 11z" transform="translate(-628 -374) translate(440 324) translate(178 40) translate(10 10)"/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
