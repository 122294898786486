import AddRoomButton, { IAddRoomButtonProps } from '@components/drawing/AddRoomButton';
import Door, { Direction, DirectionsMap } from '@components/drawing/Door';
import Room from '@components/drawing/Room';
import RoomDimension, { IRoomDimensionAligment } from '@components/drawing/RoomDimension';
import SmallRoom from '@components/drawing/SmallRoom';
import { GroupSettingsSection } from '@components/layout';
import ConfirmationAlert from '@components/layout/ConfirmationAlert';
import { useLocale } from '@i18n';
import { Container, Stage } from '@inlet/react-pixi';
import { IPoint, IRoomState, IStoreState, Position, RoomSettings, State, Steps, WallsDTOMapping } from '@models';
import { useStore } from '@store';
import { goToSummary } from '@utils/navigation';
import RoomUtils from '@utils/room';
import classnames from 'classnames';
import { debounce, first, last } from 'lodash';
import { InteractionEvent, Point } from 'pixi.js';
import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';

import useComponentSize from '../../hooks/useComponentSize';
import DrawingDimension from './DrawingDimension';
import RoomDoorDimension from './RoomDoorDimension';
import RoomDoorPositionDimension from './RoomDoorPositionDimension';
import S from './styles/Group.styl';

interface IGroupProps {
    showLargeGroup?: boolean;
    showSmallGroup?: boolean;
    showWidthDimensions?: boolean;
    showDepthDimension?: boolean;
    showTotalDimensions?: boolean;
    showAddRoomButtons?: boolean;
    showDoors?: boolean;
    showDoorsSelection?: boolean;
    showDoorsWidth?: boolean;
    showDoorsDimensions?: boolean;
    showDoorsPositionDimensions?: boolean;
    showDoorsOpeningDirection?: boolean;
    roomsStateDisabled?: boolean;
    hideShadow?: boolean;
    showDrawingButtons?: boolean;
    showCheckAllRoomsButton?: boolean;
    centered?: boolean;
    disableDrag?: boolean;
    wallNumberTexts?: string[];
    groupStyle?: CSSProperties;
    checkAllRoomsButtonState?: State;
    preserveDrawingBuffer?: boolean;
    onDoorClick?: (room: IRoomState, wall: number) => void;
    onRoomClicked?: (room: IRoomState) => void;
    shouldShowWarning?: () => boolean;
    onSelectAllRooms?: () => void;
    onDeselectAllRooms?: () => void;
    onWarningShown?: () => void;
    onWarningHidden?: () => void;
    angle?: number;
    forPrinting?: boolean;
    biggerDimension?: boolean;
}

export const RoomWalls = [1, 2, 3, 4];

function getSelectedRoom(state: IStoreState) {
    return state.group.rooms.find((room) => room.state === State.Selected || room.state === State.Focused);
}

function getOptions(deviceResolution: number, forPrinting?: boolean, preserveBuffer: boolean = false) {
    return {
        antialias: true,
        transparent: forPrinting ? false : true,
        autoDensity: true,
        backgroundColor: forPrinting ? 0xFFFFFF : undefined,
        // resolution: deviceResolution,
        resolution: 2,
        preserveDrawingBuffer: preserveBuffer,
    };
}

export default function Group(props: IGroupProps) {
    const T = useLocale();
    const { state, actions, dispatch } = useStore();
    const canvasDefaultDimensions = { width: 800, height: 300 };
    const [addRoomButtons, setAddRoomButtons] = useState<IAddRoomButtonProps[]>([]);
    const [rendered, setRendered] = useState<boolean>(false);
    const [draggingEnabled, setDraggingEnabled] = useState<boolean>(false);
    const [draggingStarted, setDraggingStarted] = useState<boolean>(false);
    const [position, setPosition] = useState<IPoint>({ x: 0, y: 0 });
    const [deviceResolution, setDeviceResolution] = useState<number>(window.devicePixelRatio);
    const [previousPosition, setPreviousPosition] = useState<IPoint>({ x: 0, y: 0 });
    const [scale, setScale] = useState<number>(1);
    const [canvasCurrentDimensions, setCanvasCurrentDimensions] = useState<{ width: number, height: number }>(canvasDefaultDimensions);
    const [pivot, setPivot] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
    const { setRoomNumber } = actions.GroupActions;
    const containerRef = useRef(null);
    const containerSize = useComponentSize(containerRef);
    const [warningIsVisible, setWarningIsVisible] = useState<boolean>(false);

    useEffect(() => {
        setRendered(true);
        window.matchMedia('screen and (min-resolution: 2dppx)')
            .addListener((_) => {
                setDeviceResolution(window.devicePixelRatio);
            });
    }, []);

    useEffect(() => {
        updateAddRoomButtons();
    }, [state.group.rooms]);

    useEffect(() => {
        if (
            Math.abs(containerSize.width - canvasCurrentDimensions.width) > 10 ||
            Math.abs(containerSize.height - canvasCurrentDimensions.height) > 10
        ) {
            updateCanvasSize();
        }
    }, [containerSize]);

    useEffect(() => {
        updateDrawingPosition();
    }, [canvasCurrentDimensions]);

    function zoomIn() {
        if (scale < RoomSettings.MAX_ZOOM) {
            setScale(scale + RoomSettings.ZOOM_SCALE);
        }
    }

    function zoomOut() {
        if (scale > RoomSettings.MIN_ZOOM) {
            setScale(scale - RoomSettings.ZOOM_SCALE);
        }
    }

    function center() {
        const drawingWidth = getDrawingTotalWidth();
        const drawingHeight = getDrawingTotalHeight();
        const canvasWidth = canvasCurrentDimensions.width;
        const canvasHeight = canvasCurrentDimensions.height;
        updateDrawingScale();
        setPivot({
            x: drawingWidth / 2,
            y: drawingHeight / 2,
        });
        setPosition({
            x: canvasWidth / 2,
            y: canvasHeight / 2,
        });
    }

    function updateDrawingScale() {
        const drawingWidth = getDrawingTotalWidth();
        const drawingHeight = getDrawingTotalHeight();
        let canvasW = canvasCurrentDimensions.width;
        let canvasH = canvasCurrentDimensions.height;

        // The rotated canvas falsifies the scale calculation, so we invert the width and height if the rotation is vertical
        if (props.angle && ((props.angle / 90) % 2 !== 0)) {
            canvasW = canvasCurrentDimensions.height;
            canvasH = canvasCurrentDimensions.width;
        }

        setScale(Math.min(Math.min(
            canvasW / (drawingWidth + (RoomSettings.ROOM_PADDING * 4)),
            canvasH / (drawingHeight + (RoomSettings.ROOM_PADDING * 4)),
        ), RoomSettings.MAX_ZOOM));
    }

    function alignStart() {
        const drawingHeight = getDrawingTotalHeight();
        const canvasHeight = containerSize.height;
        const doorsHeight = doorsAreShown() ? RoomSettings.ROOM_DOOR_HEIGHT : 0;
        updateDrawingScale();
        setPivot({ x: 0, y: drawingHeight / 2 });
        setPosition({ x: RoomSettings.ROOM_PADDING + doorsHeight, y: (canvasHeight + doorsHeight) / 2 });
    }

    function updateAddRoomButtons() {
        if (state.group.rooms.length < state.settings.maxRoomNumber && props.showAddRoomButtons) {
            generateAddRoomButtons(state.group.rooms.length, getRoomsTotalWidth());
        } else {
            setAddRoomButtons([]);
        }
    }

    function updateCanvasSize() {
        if (containerSize) {
            setCanvasCurrentDimensions({
                width: containerSize.width,
                height: containerSize.height,
            });
        }
    }

    function updateDrawingPosition() {
        props.centered ? center() : alignStart();
    }

    function startDragging(event: InteractionEvent) {
        if (props.disableDrag) {
            return;
        }
        setDraggingEnabled(true);
        const pos = event.data.global;
        setPreviousPosition({
            x: pos.x,
            y: pos.y,
        });
    }

    function stopDragging() {
        setDraggingStarted(false);
        setDraggingEnabled(false);

        setPosition({
            x: position.x,
            y: position.y,
        });
    }

    const drag = debounce((event: InteractionEvent) => {
        if (!draggingEnabled) {
            return;
        }

        const pos = event.data.global;

        const dx = pos.x - previousPosition.x;
        const dy = pos.y - previousPosition.y;

        if (Math.abs(dx) > 10 || Math.abs(dy) > 10) {
            setDraggingStarted(true);

            setPosition({
                x: position.x += dx,
                y: position.y += dy,
            });

            setPreviousPosition({
                x: pos.x,
                y: pos.y,
            });
        }
    }, 5);

    function generateAddRoomButtons(numberOfRooms: number, roomsLength: number) {
        const times = state.settings.maxRoomNumber - numberOfRooms;
        const emptyRooms: IAddRoomButtonProps[] = [];
        let x = roomsLength;
        const y = 0;
        const totalWidth = state.settings.defaultWidth * RoomSettings.PIXEL_PER_INCH + state.settings.commonWallWidth * RoomSettings.PIXEL_PER_INCH * 2;
        const totalDepth = state.settings.defaultDepth * RoomSettings.PIXEL_PER_INCH + state.settings.commonWallWidth * RoomSettings.PIXEL_PER_INCH * 2;
        const addRoomWidth = totalWidth - RoomSettings.EMPTY_ROOM_BORDER_LENGTH;
        const addRoomHeight = totalDepth - RoomSettings.EMPTY_ROOM_BORDER_LENGTH;
        for (let index = 0; index < times; index++) {
            emptyRooms.push({
                width: addRoomWidth,
                height: addRoomHeight,
                x,
                y,
                buttonState: (index === 0) ? State.Enabled : State.Disabled,
            } as IAddRoomButtonProps);
            x += addRoomWidth;
        }
        setAddRoomButtons(emptyRooms);
    }

    function getRoomsTotalWidth() {
        const commonWallWidth = state.settings.commonWallWidth * RoomSettings.PIXEL_PER_INCH;
        let length = (state.group.rooms.length > 0) ? commonWallWidth : 0;
        for (const room of state.group.rooms) {
            length += (room.width * RoomSettings.PIXEL_PER_INCH + commonWallWidth);
        }
        return length;
    }

    function getRoomsTotalWidthInInch() {
        let length = (state.group.rooms.length > 0) ? state.settings.commonWallWidth : 0;
        for (const room of state.group.rooms) {
            length += (room.width + state.settings.commonWallWidth);
        }
        return length;
    }

    function getAddRoomButtonsTotalWidth() {
        let length = 0;
        for (const addRoomButton of addRoomButtons) {
            length += addRoomButton.width;
        }
        return length;
    }

    function getDrawingTotalWidth() {
        let length = getRoomsTotalWidth() + getAddRoomButtonsTotalWidth();

        if (doorsAreShownForDirection(Direction.LEFT)) {
            length += RoomSettings.ROOM_DOOR_HEIGHT;
        }

        if (doorsAreShownForDirection(Direction.RIGHT)) {
            length += RoomSettings.ROOM_DOOR_HEIGHT + RoomSettings.ROOM_DEPTH_DIMENSION_PADDING * 2 + RoomSettings.ROOM_DIMENSION_HEIGHT;
        }

        if (props.showDepthDimension) {
            length += RoomSettings.ROOM_DEPTH_DIMENSION_PADDING + RoomSettings.ROOM_DIMENSION_HEIGHT;
        }

        if (props.showTotalDimensions) {
            length += RoomSettings.ROOM_DEPTH_DIMENSION_PADDING + RoomSettings.ROOM_DIMENSION_HEIGHT;
        }

        return length;
    }

    function doorsAreShownForDirection(direction: Direction) {
        let isDoorOnDimensionSide = props.showDoorsSelection;

        switch (direction) {
            case Direction.LEFT:
                if (first(state.group.rooms)?.door?.direction === direction) {
                    isDoorOnDimensionSide = true;
                }
                break;
            case Direction.TOP:
            case Direction.DOWN:
                state.group.rooms.forEach((room) => {
                    if (room.door?.direction === direction) {
                        isDoorOnDimensionSide = true;
                    }
                });
                break;
            case Direction.RIGHT:
                if (last(state.group.rooms)?.door?.direction === direction) {
                    isDoorOnDimensionSide = true;
                }
                break;
        }

        return isDoorOnDimensionSide;
    }

    function doorsAreShown() {
        return props.showDoors || props.showDoorsOpeningDirection || props.showDoorsSelection;
    }

    function getDrawingTotalHeight() {
        const rooms = getRooms();
        if (rooms.length > 0) {
            const roomsHeight = RoomUtils.toPixels((rooms[0].depth + (2 * rooms[0].borderLength)));
            let doorsheight = 0;
            let dimensionsHeight = props.showWidthDimensions ? RoomSettings.ROOM_WIDTH_DIMENSION_PADDING + RoomSettings.ROOM_DIMENSION_HEIGHT : 0;

            if (props.showTotalDimensions) {
                dimensionsHeight += (
                    props.biggerDimension ?
                        RoomSettings.ROOM_WIDTH_DIMENSION_PADDING + RoomSettings.ROOM_DIMENSION_HEIGHT :
                        RoomSettings.ROOM_WIDTH_DIMENSION_PADDING + RoomSettings.ROOM_DIMENSION_HEIGHT_BIGGER
                );
            }

            if (doorsAreShownForDirection(Direction.DOWN)) {
                doorsheight += RoomSettings.ROOM_DOOR_HEIGHT;
            }

            if (doorsAreShownForDirection(Direction.TOP)) {
                doorsheight += RoomSettings.ROOM_DOOR_HEIGHT;
            }

            return roomsHeight + doorsheight + dimensionsHeight;
        }
        return state.settings.defaultHeight * RoomSettings.PIXEL_PER_INCH + 2 * state.settings.commonWallWidth * RoomSettings.PIXEL_PER_INCH;
    }

    function setRoomPosition(room: IRoomState, index: number) {
        if ((index === 0 && state.group.rooms.length === 1)) {
            room.position = Position.Single;
        } else if (index === 0 && state.group.rooms.length > 1) {
            room.position = Position.Start;
        } else if (index === state.group.rooms.length - 1) {
            room.position = Position.End;
        } else {
            room.position = Position.Middle;
        }
    }

    function setRoomXY(room: IRoomState, index: number) {
        if (room.position === Position.Start || room.position === Position.Single) {
            room.x = 0;
            room.y = 0;
        } else {
            room.x = (state.group.rooms[index - 1].x + state.group.rooms[index - 1].width * RoomSettings.PIXEL_PER_INCH + room.borderLength * RoomSettings.PIXEL_PER_INCH);
            room.y = 0;
        }
    }

    function getRooms(): IRoomState[] {
        return state.group.rooms.map((room, index) => {
            room.borderLength = state.settings.commonWallWidth;
            setRoomPosition(room, index);
            setRoomXY(room, index);
            return room;
        });
    }

    function handleRoomClicked(room: IRoomState) {
        if (props.onRoomClicked && !draggingStarted) {
            goToSummary(room.code);
            props.onRoomClicked(room);
        }
    }

    function getLargeRooms(): ReactNode {
        return getRooms().map((room) => {
            const shouldShowWallNumberTexts = props.showDoorsSelection && room.state !== State.Enabled && room.state !== State.Ready;
            return (
                <Room
                    key={room.code}
                    room={room}
                    onClick={() => handleRoomClicked(room)}
                    stateEnabled={!props.roomsStateDisabled}
                    wallNumberTexts={shouldShowWallNumberTexts ? props.wallNumberTexts : []}
                />
            );
        });
    }

    function getSmallRooms(): ReactNode {
        return getRooms().map((room) => {
            return (
                <SmallRoom
                    key={room.code}
                    room={room}
                    onClick={() => handleRoomClicked(room)}
                    stateEnabled={!props.roomsStateDisabled}
                />
            );
        });
    }

    function getAddRoomButtons() {
        return addRoomButtons.map((addRoomButton, index) => {
            return (
                <AddRoomButton
                    key={index}
                    width={addRoomButton.width}
                    height={addRoomButton.height}
                    x={addRoomButton.x}
                    y={addRoomButton.y}
                    buttonState={addRoomButton.buttonState}
                    onClick={() => dispatch(setRoomNumber(state.group.roomNumber + 1))}
                />
            );
        });
    }

    function getRoomWidthDimensionState(room: IRoomState) {
        if (state.layout.currentStep === Steps.COMBO_WIDTH) {
            return room.state;
        }
        return State.Enabled;
    }

    function getRoomDepthDimensionState(room: IRoomState) {
        if (state.layout.currentStep === Steps.COMBO_DEPTH) {
            return room.state;
        }
        return State.Enabled;
    }

    function getTotalDimensions() {
        const rooms = getRooms();
        const roomDepth = RoomUtils.toPixels((rooms[0].depth + (2 * rooms[0].borderLength)));
        let horizontalDimensionY = rooms[0].y + roomDepth + RoomSettings.ROOM_WIDTH_DIMENSION_PADDING * 4;
        let verticalDimensionY = getDrawingTotalWidth();

        if (doorsAreShownForDirection(Direction.DOWN)) {
            horizontalDimensionY += RoomSettings.ROOM_DOOR_HEIGHT;
        }

        if (doorsAreShownForDirection(Direction.LEFT)) {
            verticalDimensionY -= RoomSettings.ROOM_DOOR_HEIGHT;
        }

        if (doorsAreShownForDirection(Direction.RIGHT)) {
            verticalDimensionY -= RoomSettings.ROOM_DOOR_HEIGHT;
        }

        return (
            <>
                <DrawingDimension
                    x={0}
                    y={horizontalDimensionY}
                    alignment={IRoomDimensionAligment.HORIZONTAL}
                    lengthInch={getRoomsTotalWidthInInch()}
                    biggerDimension={props.biggerDimension}
                />
                <DrawingDimension
                    x={-roomDepth}
                    y={verticalDimensionY}
                    alignment={IRoomDimensionAligment.VERTICAL}
                    lengthInch={(rooms[0].depth + state.settings.commonWallWidth * 2)}
                    biggerDimension={props.biggerDimension}
                />
            </>
        );
    }

    function getRoomWidthDimensions() {
        return getRooms().map((room) => {
            const roomDepth = RoomUtils.toPixels((room.depth + (2 * room.borderLength)));
            const hasExtraCommonWall = room.position === Position.Single || room.position === Position.End;
            let y = room.y + roomDepth + RoomSettings.ROOM_WIDTH_DIMENSION_PADDING;

            if (doorsAreShownForDirection(Direction.DOWN)) {
                y += RoomSettings.ROOM_DOOR_HEIGHT;
            }

            return (
                <RoomDimension
                    key={room.code}
                    hasExtraCommonWall={hasExtraCommonWall}
                    commonWallLengthInch={room.borderLength}
                    isDirty={!room.hasDefaultWidth}
                    x={room.x}
                    y={y}
                    lengthInch={room.width}
                    state={getRoomWidthDimensionState(room)}
                    alignment={IRoomDimensionAligment.HORIZONTAL}
                    biggerDimension={props.biggerDimension}
                />
            );
        });
    }

    function getRoomDepthDimension() {
        const room = state.group.rooms[0];
        let x = getRoomsTotalWidth() + RoomSettings.ROOM_DEPTH_DIMENSION_PADDING;
        const y = room.y + (room.depth + (2 * room.borderLength)) * RoomSettings.PIXEL_PER_INCH;

        if (doorsAreShownForDirection(Direction.RIGHT)) {
            x += RoomSettings.ROOM_DOOR_HEIGHT;
        }

        return (
            <RoomDimension
                hasExtraCommonWall={true}
                commonWallLengthInch={room.borderLength}
                isDirty={!room.hasDefaultDepth}
                x={x}
                y={y}
                lengthInch={room.depth}
                state={getRoomDepthDimensionState(room)}
                alignment={IRoomDimensionAligment.VERTICAL}
                biggerDimension={props.biggerDimension}
            />
        );
    }

    function getDoors() {
        return state.group.rooms.map((room) => {
            if (room && room.door) {
                return (
                    <Door
                        key={`${room.code}_${JSON.stringify(room.door)}}`}
                        room={room}
                        wall={WallsDTOMapping[room.code].findIndex((v) => v === room.door?.roomWall) + 1}
                        supportedWidths={state.settings.doors.doorWidths}
                        position={room.door.position}
                        settings={state.settings}
                        showDoorsRampe={room.door.hasRamp}
                    />
                );
            }
        });
    }

    function getDoorsOpeningDirection() {
        return state.group.rooms.map((room) => {
            if (room && room.door) {
                return (
                    <React.Fragment key={`${room.code}_${JSON.stringify(room.door)}}`}>
                        <Door
                            room={room}
                            wall={WallsDTOMapping[room.code].findIndex((v) => v === room.door?.roomWall) + 1}
                            supportedWidths={state.settings.doors.doorWidths}
                            position={room.door.position}
                            settings={state.settings}
                            showDoorsRampe={room.door.hasRamp}
                        />
                    </React.Fragment>
                );
            }
        });
    }

    function getDoorsDimensions() {
        return state.group.rooms.map((room) => {
            if (room && room.door) {
                return (
                    <RoomDoorDimension
                        key={room.code}
                        room={room}
                        door={room.door}
                        isDefault={room.door.hasDefaultWidth}
                    />
                );
            }
        });
    }

    function getDoorsPositionDimensions() {
        return state.group.rooms.map((room) => {
            if (room && room.door) {
                return (
                    <RoomDoorPositionDimension
                        key={room.code}
                        room={room}
                        door={room.door}
                        biggerDimension={props.biggerDimension}
                    />
                );
            }
        });
    }

    function getDoorsSelection() {
        const selectedRoom = getSelectedRoom(state);
        const walls = RoomWalls.filter((wall) => {
            const map = DirectionsMap.get(`${selectedRoom?.code}${wall}`);
            if (map) {
                let doorWallAvailable = true;
                if (selectedRoom) {
                    doorWallAvailable = RoomUtils.isDoorWallAvailableFromRoomType(state.group.rooms, selectedRoom, map.roomWall, map.direction);
                }

                return doorWallAvailable;
            }
        });

        if (selectedRoom) {
            return walls.map((wall) => {
                return (
                    <Door
                        key={`${selectedRoom.code}_${wall}_${selectedRoom.door?.state}`}
                        room={selectedRoom}
                        wall={wall}
                        supportedWidths={state.settings.doors.doorWidths}
                        onClick={props.onDoorClick}
                        settings={state.settings}
                        showDoorsRampe={selectedRoom.hasRamp}
                    />
                );
            });
        }
    }

    function onSelectAllRoomsCheckboxChanged(checked: boolean) {
        if (checked) {
            if (props.shouldShowWarning && props.shouldShowWarning()) {
                if (props.onWarningShown) {
                    props.onWarningShown();
                }
                setWarningIsVisible(true);
            } else {
                if (props.onSelectAllRooms) {
                    props.onSelectAllRooms();
                }
            }
        } else {
            if (props.onDeselectAllRooms) {
                props.onDeselectAllRooms();
            }
        }
    }

    return (
        <div
            className={classnames(S.groupContainer, {
                [S.groupContainerWrapped]: props.showSmallGroup,
            })}
        >
            {(props.showCheckAllRoomsButton || props.showDrawingButtons) &&
                (
                    <div
                        className={classnames({
                            [S.groupContainerSettingsWrapped]: props.showSmallGroup,
                        })}
                    >
                        <GroupSettingsSection
                            drawingButtonsState={State.Enabled}
                            checkboxState={props.checkAllRoomsButtonState}
                            showDrawingButtons={
                                props.showDrawingButtons &&
                                props.showLargeGroup
                            }
                            onAllRoomsCheckChanged={onSelectAllRoomsCheckboxChanged}
                            showAllRoomsCheckbox={props.showCheckAllRoomsButton}
                            buttonsDisabled={warningIsVisible}
                            onZoomInClicked={zoomIn}
                            onZoomOutClicked={zoomOut}
                            onCenterClicked={center}
                        />
                    </div>
                )
            }
            <div
                className={classnames(S.groupContainerWarning, {
                    [S.groupContainerWarningActive]: warningIsVisible,
                    [S.groupContainerWarningWrapped]: props.showSmallGroup,
                })}
            >
                {warningIsVisible && (
                    <ConfirmationAlert
                        keyword={T.GeneralOverwriteAlertKeyword}
                        title={T.GeneralOverwriteAlertDescription}
                        onContinueClicked={() => {
                            setWarningIsVisible(false);
                            if (props.onWarningHidden) {
                                props.onWarningHidden();
                            }
                            if (props.onSelectAllRooms) {
                                if (state.group.rooms && state.group.rooms.length > 0) {
                                    goToSummary(state.group.rooms[0].code);
                                }
                                props.onSelectAllRooms();
                            }
                        }}
                        onCancelClicked={() => {
                            setWarningIsVisible(false);
                            if (props.onWarningHidden) {
                                props.onWarningHidden();
                            }
                        }}
                    />
                )}
            </div>
            <div
                className={classnames(S.groupContainerDrawingContainer, {
                    [S.groupContainerDrawingDisabled]: warningIsVisible,
                })}
            >
                {props.showLargeGroup && (
                    <div
                        style={props.groupStyle}
                        className={S.groupContainerDrawingLarge}
                        ref={containerRef}
                    >
                        {rendered && (
                            <Stage
                                width={canvasCurrentDimensions.width}
                                height={canvasCurrentDimensions.height}
                                options={getOptions(deviceResolution, props.forPrinting, props.preserveDrawingBuffer)}
                            >
                                <Container
                                    angle={props.angle ? props.angle : 0}
                                    interactive={!(props.disableDrag && !props.onRoomClicked)}
                                    touchend={stopDragging}
                                    touchmove={drag}
                                    touchstart={startDragging}
                                    mouseout={stopDragging}
                                    mousemove={drag}
                                    mouseup={stopDragging}
                                    mousedown={startDragging}
                                    position={position as Point}
                                    scale={scale}
                                    pivot={pivot as Point}
                                    cursor={(props.disableDrag && !props.onRoomClicked) ? 'default' : 'pointer'}
                                >
                                    {getLargeRooms()}
                                    {props.showAddRoomButtons && getAddRoomButtons()}
                                    {props.showWidthDimensions && getRoomWidthDimensions()}
                                    {props.showDepthDimension && getRoomDepthDimension()}
                                    {props.showTotalDimensions && getTotalDimensions()}
                                    {props.showDoorsSelection && getDoorsSelection()}
                                    {props.showDoors && getDoors()}
                                    {props.showDoorsDimensions && getDoorsDimensions()}
                                    {props.showDoorsPositionDimensions && getDoorsPositionDimensions()}
                                    {props.showDoorsOpeningDirection && getDoorsOpeningDirection()}
                                </Container>
                            </Stage>
                        )}
                        {!props.hideShadow && <div className={S.groupContainerDrawingLargeShadow} />}
                    </div>
                )}
                {(props.showSmallGroup) && (
                    <div
                        className={classnames(S.groupContainerDrawingSmall, {
                            [S.groupContainerDrawingDisabled]: warningIsVisible,
                        })}
                    >
                        {getSmallRooms()}
                    </div>
                )}
            </div>
        </div>
    );
}
