import { ModaSpaceLogoSmall } from '@components/svg';
import { ILocaleData } from '@i18n';
import { format } from 'date-fns';
import { toInteger } from 'lodash';
import React from 'react';

import S from './styles/PrintablePage.styl';

interface IPrintablePageHeaderProps {
	quoteNumber: string;
	quoteDate: Date;
	locale: ILocaleData;
}

const SpanStyle = {
	backgroundColor: S.darkBlueGrayHex25,
	borderRadius: '4px',
	color: S.blueNorbec,
	fontWeight: toInteger(S.fontWeightBold),
	marginLeft: '5px',
	padding: '2px 5px',
};

export default function PrintablePageHeader(props: IPrintablePageHeaderProps) {
	const T = props.locale;

	return (
		<div
			style={{
				height: '55px',
				backgroundColor: S.blueNorbec,
			}}
		>
			<div
				style={{
					backgroundColor: S.blueNorbec,
					height: '5px',
				}}
			/>

			<div
				style={{
					alignItems: 'center',
					backgroundColor: S.white,
					display: 'flex',
					flexDirection: 'row',
					height: '50px',
					padding: '0 10px',
				}}
			>
				<ModaSpaceLogoSmall />
				<span style={{ color: S.darkBlueGray, marginLeft: '10px', fontWeight: toInteger(S.fontWeightExtraBold), fontSize: '12px', width: '36px' }}>{T.PdfByNorbec}</span>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: 'auto' }}>
					{ENV.name !== 'production' ? <div style={{ fontSize: '11px', color: 'red', opacity: '0.5' }}><b>{T.PdfTestEnvironment}</b></div> : ''}
					<div style={{ marginLeft: '20px' }}><b>{T.PdfRefNumber}</b> <span style={SpanStyle}>{props.quoteNumber}</span></div>
					<div style={{ marginLeft: '20px' }}><b>{T.PdfQuoteDate}</b> <span style={SpanStyle}>{format(props.quoteDate, T.PdfDateFormat)}</span><span style={SpanStyle}>{format(props.quoteDate, T.PdfTimeFormat)}</span>
					</div>
				</div>
			</div>
		</div>
	);
}
