import { useLocale } from '@i18n';
import { Graphics } from '@inlet/react-pixi';
import { RoomSettings, State } from '@models';
import LinesUtils from '@utils/drawing/lines';
import ShapesUtils from '@utils/drawing/shapes';
import TextUtils from '@utils/drawing/text';
import FormatUtils from '@utils/format';
import RoomUtils from '@utils/room';
import { Graphics as PIXIGraphics } from 'pixi.js';
import React from 'react';
import { IRoomDimensionAligment } from './RoomDimension';

const ArrowLength = 7;
const lineHeight = 2;
const color = 0x9fbbc4;
const textColor = 0xFFFFFF;

interface IDrawingDimension {
	lengthInch: number;
	x: number;
	y: number;
	alignment: IRoomDimensionAligment;
	biggerDimension?: boolean;
}

export default function DrawingDimension(props: IDrawingDimension) {
	const length = props.lengthInch * RoomSettings.PIXEL_PER_INCH;

	return (
		<Graphics
			draw={(graphics) => {
				graphics.clear();
				graphics.removeChildren();

				const propsY = props.biggerDimension ? props.y + 15 : props.y;

				graphics.lineStyle(lineHeight, color, 1, 0.5);
				graphics.moveTo(props.x, propsY)
					.lineTo(props.x + length, propsY);

				// Left Arrow
				graphics.moveTo(props.x + 1, propsY)
					.lineTo(props.x + ArrowLength, propsY + ArrowLength);
				graphics.moveTo(props.x + 1, propsY)
					.lineTo(props.x + ArrowLength, propsY - ArrowLength);

				// Right Arrow
				graphics.moveTo(props.x + length - 1, propsY)
					.lineTo(props.x + length - ArrowLength, propsY + ArrowLength);
				graphics.moveTo(props.x + length - 1, propsY)
					.lineTo(props.x + length - ArrowLength, propsY - ArrowLength);

				// Text
				ShapesUtils.drawCenteredRoundedRectangle(
					graphics,
					length,
					lineHeight,
					props.x,
					propsY,
					props.biggerDimension ? 45 : 30,
					props.biggerDimension ? 18 : 10,
					color,
					1,
				);
				graphics.addChild(
					TextUtils.createCenteredText(
					FormatUtils.formatInches(props.lengthInch),
					'Assistant',
					props.biggerDimension ? 18 : 12,
					textColor,
					props.x,
					propsY,
					length,
					lineHeight,
					'normal',
					1,
					1,
				));

				if (props.alignment === IRoomDimensionAligment.VERTICAL) {
					graphics.rotation = 1.5 * Math.PI;
				}
			}}
		/>
	);
}
