class Logger {
	private debug: Boolean = false;

	public init() {
		this.debug = !!ENV.debug;
	}

	public log(msg: string) {
		if (this.debug) {
			console.log(msg);
		}
	}
}

const logger = new Logger();
logger.init();

export function log(msg: string) {
	logger.log(msg);
}
