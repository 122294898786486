import BgImageAuthLeft from './bg-image-auth-left.jpg';
import BgImageContactLeft from './bg-image-contact-left.jpg';
import CloseIconInline from './close-icon-inline.svg';
import Door32Ready from './door-32-choice.svg';
import Door32Focused from './door-32-focus.svg';
import Door32Enabled from './door-32-rested.svg';
import Door32Selected from './door-32-selected.svg';
import Door36Ready from './door-36-choice.svg';
import Door36Focused from './door-36-focus.svg';
import Door36Enabled from './door-36-rested.svg';
import Door36Selected from './door-36-selected.svg';
import FloorTile from './plancher-tile.svg';
import Tile from './tile-gage.png';

export default {
	BgImageAuthLeft,
	BgImageContactLeft,
	CloseIconInline,
	Tile,
	Door32Enabled,
	Door36Enabled,
	Door32Focused,
	Door36Focused,
	Door32Ready,
	Door36Ready,
	Door32Selected,
	Door36Selected,
	FloorTile,
};
