import { useStore } from '@store/index';
import classnames from 'classnames';
import React, { CSSProperties, ReactNode } from 'react';
import S from './styles/Modal.styl';

interface IModalProps {
	children: ReactNode;
	modalStyle?: CSSProperties;
	contentContainerStyle?: CSSProperties;
	mobileContentFullPage?: boolean;
}

export default function Modal(props: IModalProps) {
	const { state } = useStore();

	return (
		<div
			className={S.modalVt}
			style={props.modalStyle}
		>
			<div
				className={classnames(S.modalVtCard, {
					[S.modalVtCardFullPage]: state && state.layout.isDrawingModalFullScreen,
					[S.modalVtCardFullPageMobile]: props.mobileContentFullPage,
				})}
				style={props.contentContainerStyle}
			>
				{props.children}
			</div>
		</div>
	);
}
