import HyperLink from '@components/buttons/HyperLink';
import AlertCircleIcon from '@components/svg/AlertCircleIcon';
import LargeCheckedCheckbox from '@components/svg/LargeCheckedCheckbox';
import LargeUncheckedCheckbox from '@components/svg/LargeUncheckedCheckbox';
import { State } from '@models';
import classnames from 'classnames';
import React from 'react';
import S from './styles/CheckboxAlert.styl';

interface ICheckboxAlertProps {
	state: State;
	title: string;
	alertTitle: string;
	alertDesc: string;
	alertInsight: string;
	onClick?: (checked: boolean) => void;
	linkUrl?: string;
	linkLabel?: string;
}

export default function CheckboxAlert(props: ICheckboxAlertProps) {

	function onClick() {
		if (props.onClick) {
			props.onClick(props.state === State.Enabled);
		}
	}

	return (
		<div className={S.checkboxAlertContainer} onClick={onClick}>
			<div className={S.checkboxAlertContainerCheckbox}>
				{props.state === State.Selected && (
					<LargeCheckedCheckbox
						stroke={S.blueNorbec}
						fill={S.blueNorbec}
					/>
				)}
				{props.state === State.Focused && (
					<LargeCheckedCheckbox
						stroke={S.orange}
						fill={S.orange}
					/>
				)}
				{props.state === State.Enabled && (
					<LargeUncheckedCheckbox
						stroke={S.darkBlueGray}
					/>
				)}
				<h2
					className={classnames(S.checkboxAlertContainerCheckboxTitle, {
						[S.checkboxAlertContainerCheckboxTitleSelected]: props.state === State.Selected,
						[S.checkboxAlertContainerCheckboxTitleFocused]: props.state === State.Focused,
					})}
				>
					{props.title}
				</h2>
			</div>
			<div
				className={classnames(S.checkboxAlertContainerAlert, {
					[S.checkboxAlertContainerAlertVisible]: props.state === State.Selected,
				})}
			>
				<div className={S.checkboxAlertContainerAlertLine}/>
				<div className={S.checkboxAlertContainerAlertContent}>
					<div className={S.checkboxAlertContainerAlertContentInsight}>
						<AlertCircleIcon/>
						<div className={S.checkboxAlertContainerAlertContentInsightText}>{props.alertInsight}</div>
					</div>
					<h1>{props.alertTitle}</h1>
					<div className={S.checkboxAlertContainerAlertContentDescription}>{props.alertDesc}</div>

					{props.linkUrl && props.linkLabel && (
						<div className={S.checkboxAlertContainerAlertContentLinkWrapper}>
							<HyperLink title={props.linkLabel} link={props.linkUrl} />
						</div>
					)}

				</div>
			</div>
		</div>
	);
}
