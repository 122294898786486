import ArrowDownIcon from '@components/svg/ArrowDownIcon';
import { useLocale } from '@i18n';
import { State } from '@models';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import { AnimationSettings } from '../../utils/animation';
import S from './styles/NextButton.styl';

interface INextButtonProps {
	onClick?: () => void;
	enabled: boolean;
	isNextSection?: boolean;
	label: string;
}

let timeout: number;

export default function NextButton(props: INextButtonProps) {
	const T = useLocale();
	const [state, setState] = useState<State>(getState());

	useEffect(() => {
		return () => {
			window.clearTimeout(timeout);
		};
	}, []);

	useEffect(() => {
		setState(getState());
	}, [props.enabled]);

	function getState(): State {
		if (props.enabled) {
			return State.Enabled;
		}
		return State.Disabled;
	}

	function blur() {
		if (state !== State.Disabled) {
			setState(State.Enabled);
		}
	}

	function focus() {
		if (state !== State.Disabled) {
			setState(State.Focused);
		}
	}

	function getArrowColor(): string {
		return state === State.Focused ? '#f49800' : '#0095C8';
	}

	function onClick() {
		if (props.onClick && state !== State.Disabled) {
			props.onClick();

			focus();

			if (timeout) {
				window.clearTimeout(timeout);
			}

			timeout = window.setTimeout(() => {
				blur();
			}, AnimationSettings.FADE_DELAY);
		}
	}

	return (
		<div
			onMouseOver={focus}
			onMouseLeave={blur}
			onClick={(_) => onClick()}
			className={classnames(S.next_button, {
				[S.nextButtonDisabled]: state === State.Disabled,
				[S.nextButtonNextSection]: props.isNextSection,
			})}
		>
			<ArrowDownIcon stroke={getArrowColor()}/>
			<div
				className={classnames(S.nextButtonTitle, {
					[S.nextButtonTitleDisabled]: state === State.Disabled,
					[S.nextButtonTitleFocused]: state === State.Focused,
				})}
			>
				{props.label}
			</div>
		</div>
	);
}
