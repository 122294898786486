import Button from '@components/buttons/Button';
import CreatePassword, { INewPasswordForm } from '@components/createPassword/CreatePassword';
import { Loading } from '@components/layout';
import { LayoutFullWidth } from '@components/layout/Layout';
import { useLocale } from '@i18n';
import { formatTimestamp } from '@utils/format';
import React, { useState } from 'react';
import { postChangePassword, putNewsletter } from '../../api';
import { ToastType, useAuthContext, useToastContext } from '../../hooks/contexts/UseContext';
import { ERowType } from '../project/MuiTabs';
import TabRow from '../project/TabRow';
import S from './styles/MyAccount.styl';

export default function MyAccount() {
	const T = useLocale();
	const { user } = useAuthContext();
	const addToast = useToastContext();
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [newPasswordIsValid, setNewPasswordIsValid] = useState<boolean>(false);
	const [newPasswordForm, setNewPasswordForm] = useState<INewPasswordForm>({
		password: '',
		confirmPassword: '',
		oldPassword: '',
	});

	if (!user) {
		return <Loading />;
	}

	const [sucribedToNewsletter, setSucribedToNewsletter] = useState<boolean>(!!user.newsletter);

	const onSubmitClicked = () => {
		setIsLoading(true);
		postChangePassword({ password: newPasswordForm.password, oldPassword: newPasswordForm.oldPassword })
		.then(() => {
			setIsEditing(false);
			addToast({
				text: T.MyAccountPasswordChangeSuccess,
				type: ToastType.success,
			});
		})
		.catch(() => {
			addToast({
				text: T.GeneralFormSubmitMessageError,
				type: ToastType.error,
			});
		});
		setIsLoading(false);
	};

	const suscribeNewsletter = (value: boolean) => {
		putNewsletter({ email: user.email, value })
		.then(() => {
			addToast({
				text: value ? T.AlertNewsletterSubscribed : T.AlertNewsletterUnsubscribed,
				type: ToastType.success,
			});
			setSucribedToNewsletter(value);
		})
		.catch(() => {
			addToast({
				text: T.GeneralFormSubmitMessageError,
				type: ToastType.error,
			});
		});
	};

	return (
		<LayoutFullWidth>
			<div className={S.myAccountContainer}>
				<h1>{T.MyAccountTitle}</h1>

				<div className={S.tabGroupContainer}>
					<div className={S.tabGroupHeader}>
						<h3>{T.MyAccountPersonalInfo}</h3>
					</div>
					<TabRow
						row={{
							label: T.MyAccountUsername,
							value: user.name,
							type: ERowType.string,
							inputName: 'username',
						}}
					/>
					<TabRow
						row={{
							label: T.MyAccountEmail,
							value: user.email,
							type: ERowType.string,
							inputName: 'email',
						}}
					/>
					<TabRow
						row={{
							label: T.MyAccountLanguage,
							value: user.language,
							type: ERowType.string,
							inputName: 'langId',
							render: (value: string) => value === 'FR' ? T.ProjectDetailsTabClientFrench : value === 'EN' ? T.ProjectDetailsTabClientEnglish : undefined,
						}}
					/>
				</div>

				<div className={S.tabGroupContainer}>
					<div className={S.tabGroupHeader}>
						<h3>{T.MyAccountPassword}</h3>
						{isEditing ?
							(
								<div className={S.tabGroupHeaderFormActions}>
									<Button secondary small onClick={() => setIsEditing(false)}>{T.GeneralCancel}</Button>
									<Button disabled={!newPasswordIsValid || isLoading} small onClick={onSubmitClicked}>{T.GeneralSave}</Button>
								</div>
							)
						:
							(
								<div className={S.tabGroupHeaderFormActions}>
									<Button secondary small onClick={() => setIsEditing(true)}>{T.GeneralModify}</Button>
								</div>
							)
						}
					</div>
					{isEditing ?
						<CreatePassword handleFormSubmit={() => undefined} setFormCallback={setNewPasswordForm} setIsValidCallback={setNewPasswordIsValid} />
					:
						(
							<>
								<TabRow
									row={{
										label: T.MyAccountLastUpdate,
										value: user.dateLastModifiedPassword,
										type: ERowType.date,
										inputName: 'lastUpdate',
										render: (value: string) => value ? formatTimestamp(value, 'yyyy/MM/dd') : '',
									}}
									edit={isEditing}
								/>
							</>
						)
					}
				</div>

				<div className={S.tabGroupContainer}>
					<div className={S.tabGroupHeader}>
						<h3>{T.MyAccountNewsletter}</h3>
						{sucribedToNewsletter ?
							(
								<div className={S.tabGroupHeaderFormActions}>
									<p>{T.MyAccountNewsletterSuscribed}</p>
									<Button secondary small onClick={() => suscribeNewsletter(false)}>{T.MyAccountNewsletterUnsuscribe}</Button>
								</div>
							)
						:
							(
								<div className={S.tabGroupHeaderFormActions}>
									<Button small onClick={() => suscribeNewsletter(true)}>{T.MyAccountNewsletterSuscribe}</Button>
								</div>
							)
						}
					</div>
					<p className={S.tabGroupText}>{T.MyAccountNewsletterText}</p>
				</div>
			</div>
		</LayoutFullWidth>
	);
}
