import Toast, { IToast } from '@components/toast/Toast';
import React, { createContext, useCallback, useEffect, useState  } from 'react';
import {
	CSSTransition,
	TransitionGroup,
} from 'react-transition-group';

const ToastContext = createContext<(toast: IToast) => void>((toast: IToast) => undefined);

export default ToastContext;

export function ToastContextProvider({ children }: {children: any}) {
	const [toasts, setToasts] = useState<IToast[]>([]);
	const [currentToast, setCurrentToast] = useState<IToast | undefined>(undefined);

	useEffect(() => {
		if (toasts.length > 0 && !currentToast) {
			setCurrentToast(toasts[0]);
			setToasts((toasts) => toasts.slice(1));
		}
	}, [toasts, currentToast]);

	const addToast = useCallback(
		(toast: IToast) => {
			setToasts((currentToasts) => [...currentToasts, toast]);
		},
		[setToasts],
	);

	const removeToast = useCallback(
		() => {
			setCurrentToast(undefined);
		},
		[setCurrentToast],
	);

	return (
		<ToastContext.Provider value={addToast}>
			{children}
			<TransitionGroup>
				{currentToast && (
					<CSSTransition classNames={'toast'} timeout={600}>
						<Toast toast={currentToast} removeToast={removeToast} />
					</CSSTransition>
				)}
			</TransitionGroup>
		</ToastContext.Provider>
	);
}
