import {
	CheckInCircleIcon,
	ErrorIcon,
	WarningIcon,
	XIcon,
} from '@components/svg';
import React, { useEffect } from 'react';
import { ToastType } from '../../hooks/contexts/UseContext';

import S from './styles/Toast.styl';

export interface IToast {
	text: string;
	type: ToastType;
}

interface IToastProps {
	toast: IToast;
	removeToast: () => void;
}

export default function Toast(props: IToastProps) {
	const {	toast, removeToast } = props;
	const {	type, text } = toast;
	let typeClass;
	let icon: any;

	useEffect(() => {
		const toastTimer = setTimeout(() => removeToast(), 3000);
		return () => clearTimeout(toastTimer);
	});

	switch (type) {
		case ToastType.success:
			typeClass = S.success;
			icon = <CheckInCircleIcon />;
			break;
		case ToastType.warning:
			typeClass = S.warning;
			icon = <WarningIcon />;
			break;
		case ToastType.error:
			typeClass = S.error;
			icon = <ErrorIcon />;
			break;
	}

	return (
		<div className={`${S.toast} ${typeClass}`}>
			{icon}
			<p aria-live="assertive">{text}</p>
			<button onClick={removeToast}>
				<XIcon  />
			</button>
		</div>
	);
}
