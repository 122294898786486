import React from 'react';

interface ILargeRadioButton {
	stroke: string;
	fill: string;
	opacity?: string;
}

export default function LargeRadioButton(props: ILargeRadioButton) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="47" height="39" viewBox="0 0 47 39" opacity={props.opacity || '1'}>
			<g fill="none" fillRule="evenodd">
				<circle cx="27" cy="19" r="18.5" fill="#FFF" stroke={props.stroke} strokeOpacity=".498"/>
				<circle cx="27" cy="19" r="12" fill={props.fill}/>
			</g>
		</svg>
	);
}
