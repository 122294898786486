import { ILocaleData, useLocale } from '@i18n';
import FormatUtils from '@utils/format';
import { log } from '@utils/logger';
import { DrawerContentBuilder } from '@utils/navigation';
import { clone } from 'lodash';
import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { IActions, IActionWithoutPayload, IActionWithPayload, IStoreState, Steps, toGroupDTO, toProjectDTO } from '@models';
import RoutesDefinition from '../routes/routes-definitions';
import { Actions } from './actions';
import { DEFAULT_STATE } from './default';
import { GroupReducer, LayoutReducer, ProjectReducer, SettingsReducer } from './reducers';
import DataReducer from './reducers/data';
import UserReducer from './reducers/user';

export { DEFAULT_STATE, IStoreState };

interface IProviderProps {
    readonly children: React.ReactNode;
}

function useSingleReducer(state: IStoreState, action: IActionWithPayload<any> & IActionWithoutPayload) {
    log(`Dispatched action: ${action.type}`);
    state.layout = LayoutReducer(state.layout, action);
    state.project = ProjectReducer(state.project, action);
    state.group = GroupReducer(state.group, action);
    state.settings = SettingsReducer(state.settings, action);
    state.user = UserReducer(state.user, action);
    state.data = DataReducer(state.data, action);
    state.hash = FormatUtils.toHash(JSON.stringify(toGroupDTO(state)) + JSON.stringify(toProjectDTO(state)));
    return { ...state };
}

const StoreContext = createContext(clone(DEFAULT_STATE));

const StoreProvider = (props: IProviderProps) => {
    const T = useLocale();
    const { children } = props;
    const [state, dispatch] = useReducer(useSingleReducer, clone(DEFAULT_STATE));
    const actions = Actions;

    const { configuration, options, installation } = RoutesDefinition;

    function initRoutes() {
        dispatch(actions.LayoutActions.setRoutes({
            configuration: new DrawerContentBuilder()
                .addSection(T.ConfiguratorProjectGroupTitle)
                .addStep(T.ProjectNameTitle, Steps.PROJECT_NAME, configuration.project_name)
                .addStep(T.InstallationLocationTitle, Steps.INSTALLATION_LOCATION, configuration.installation_place)
                .addStep(T.SeismicKitAlertTitle, Steps.SEISMIC_KIT, configuration.seismic_kit)
                .endSection()
                .addSection(T.ConfiguratorRoomsSelectionTitle)
                .addStep(T.RoomNumberTitle, Steps.NUMBER_OF_ROOMS, configuration.number_of_rooms)
                .addStep(T.RoomTypeTitle, Steps.ROOM_TYPES, configuration.room_types)
                .endSection()
                .addSection(T.ConfiguratorDimensionsGroupTitle)
                .addStep(T.InteriorWidthComboTitle, Steps.COMBO_WIDTH, configuration.combo_width)
                .addStep(T.InteriorDepthComboTitle, Steps.COMBO_DEPTH, configuration.combo_depth)
                .addStep(T.ExteriorHeightComboTitle, Steps.COMBO_HEIGHT, configuration.combo_height)
                .endSection()
                .addSection(T.ConfiguratorCoolingSystemGroup)
                .addStep(T.CoolingSystemComboTitle, Steps.COMBO_SYSTEM, configuration.combo_system)
                .addStep(T.CondenserDistanceComboTitle, Steps.CONDENSER_DISTANCE, configuration.condenser_distance)
                .addStep(T.CoolingSystemOptionComboTitle, Steps.COMBO_OPTIONS, configuration.combo_options)
                .build(),
            options: new DrawerContentBuilder()
                .addSection(T.DoorsWallSelectionLabel)
                .addStep(T.DoorsWallSelectionEachDoorTitle, Steps.DOORS_WALL, options.doors_wall)
                .addStep(T.DoorsRampeEachDoorTitle, Steps.DOORS_RAMPE, options.doors_rampe)
                .addStep(T.DoorsWidthEachDoorTitle, Steps.DOORS_WIDTH, options.doors_width)
                .addStep(T.DoorsPositioningEachDoorTitle, Steps.DOORS_POSITION, options.doors_position)
                .addStep(T.DoorsOpeningDirectionEachDoorTitle, Steps.DOORS_OPENING_DIRECTION, options.doors_opening_direction)
                .addStep(T.DoorsOptionsEachDoorTitle, Steps.DOORS_OPTIONS, options.doors_options)
                .addStep(T.DoorsI3OptionComboTitle, Steps.COMBO_I3, options.combo_i3)
                .endSection()
                .addSection(T.FloorGroup)
                .addStep(T.FloorMaterialComboTitle, Steps.COMBO_FLOOR_FINISH, options.combo_floor_finish)
                .addStep(T.FloorTextureComboTitle, Steps.COMBO_FLOOR_TYPE, options.combo_floor_type)
                .endSection()
                .addSection(T.LightsOptionsGroup)
                .addStep(T.LightsOptionsComboTitle, Steps.ROOM_LIGHT_MODELS, options.room_light_models)
                .addStep(T.MotionDetectorOptionComboTitle, Steps.ROOM_MOTION_DETECTOR, options.room_motion_detector)
                .build(),
            installation: new DrawerContentBuilder()
                .addSection(T.InstallationInstallationTagLabel)
                .addStep(T.InstallationInstallationTitle, Steps.INSTALLATION, installation.installation)
                .addStep(T.DeliveryChoiceTitle, Steps.DELIVERY_CHOICE, installation.delivery_choice)
                .addStep(T.DeliveryDateTitle, Steps.DELIVERY_DATE, installation.delivery_date)
                .addStep(T.ShippingContactTagLabel, Steps.DELIVERY_CONTACT, installation.delivery_contact)
                .addStep(T.ShippingLocationTitle, Steps.DELIVERY_PLACE, installation.delivery_place)
                .endSection()
                .addSection(T.QuoteTagLabel)
                .addStep(T.QuoteMainTitle, Steps.QUOTE, installation.quote)
                .build(),
        }));
    }

    useEffect(() => {
        if (!state.layout.routes) {
            initRoutes();
        }
    }, [state.layout.routes]);

    return (
        <StoreContext.Provider value={{ state, dispatch, actions } as any}>
            {children}
        </StoreContext.Provider>
    );
};

export { StoreContext, StoreProvider };
export function useStore(): ({ state: IStoreState, dispatch: React.Dispatch<IActionWithPayload<any> | IActionWithoutPayload>, actions: IActions }) {
    return useContext(StoreContext) as any;
}
