import { Direction } from '@components/drawing/Door';
import {
	CoolingSystemType,
	ERoomIndex,
	ERoomTypeKind,
	IDoorState,
	IRoomState,
	ISettingsState,
	RoomSettings,
	State,
} from '@models';

export default class RoomUtils {
	public static toPixels(inch: number): number {
		return inch * RoomSettings.PIXEL_PER_INCH;
	}

	public static toInches(pixel: number): number {
		return pixel / RoomSettings.PIXEL_PER_INCH;
	}

	public static getRoomsTag(rooms: IRoomState[], lockedRooms: string[] = []) {
		return rooms
			.filter((room) => {
				return !lockedRooms.includes(room.code);
			})
			.map((room) => room.code)
			.join('-');
	}

	public static getRoomBackgroundColor(state: State) {
		switch (state) {
			case State.Selected:
				return 0x0095c8;

			case State.Focused:
				return 0xf49800;

			case State.Done:
			case State.Disabled:
			case State.Enabled:
			case State.Ready:
				return 0xffffff;
		}
	}

	public static getRoomBackgroundAlpha(state: State) {
		switch (state) {
			case State.Focused:
			case State.Selected:
				return 0.1;

			case State.Done:
			case State.Ready:
			case State.Disabled:
			case State.Enabled:
				return 1;
		}
	}

	public static getAddButtonBackgroundColor(state: State) {
		switch (state) {
			case State.Ready:
			case State.Focused:
			case State.Selected:
				return 0xf49800;

			case State.Done:
			case State.Enabled:
				return 0xebf1f3;

			case State.Disabled:
				return 0xf5f8f9;
		}
	}

	public static getAddButtonPlusColor(state: State) {
		switch (state) {
			case State.Done:
			case State.Ready:
			case State.Focused:
			case State.Selected:
				return 0xffffff;

			case State.Enabled:
				return 0x415a62;

			case State.Disabled:
				return 0xEBF1F3;
		}
	}

	public static getCodeBlurColor() {
		return 0xf49800;
	}

	public static getCodeColor(state: State) {
		switch (state) {
			case State.Done:
			case State.Selected:
				return 0x0095c8;

			case State.Focused:
				return 0xf49800;

			case State.Disabled:
				return 0xd7e3e7;

			case State.Ready:
				return 0x9fbbc4;

			case State.Enabled:
				return 0xd7e3e7;
		}
	}

	public static getWallNumberColor(state: State) {
		switch (state) {
			case State.Ready:
			case State.Enabled:
				return 0x9fbbc4;

			case State.Selected:
			case State.Done:
				return 0x0095c8;

			case State.Focused:
				return 0xf49800;

			case State.Disabled:
				return 0xd7e3e7;
		}
	}

	public static getCodeAlpha(state: State) {
		switch (state) {
			case State.Done:
			case State.Disabled:
				return 0.3;
			case State.Selected:
			case State.Focused:
			case State.Enabled:
			case State.Ready:
				return 1;
		}
	}

	public static getCodeFontWeight(state: State) {
		if (state === State.Focused || state === State.Selected) {
			return 'bold';
		}
		return 'normal';
	}

	public static getBackgroundDimensionColor(state: State) {
		switch (state) {
			case State.Selected:
				return 0x0095c8;

			case State.Ready:
			case State.Enabled:
			case State.Done:
				return 0xebf1f3;

			case State.Focused:
				return 0xf49800;

			case State.Disabled:
				return 0;
		}
	}

	public static getBackgroundDoorDimensionColor(state: State) {
		switch (state) {
			case State.Disabled:
			case State.Ready:
			case State.Enabled:
			case State.Done:
				return 0xebf1f3;
			case State.Selected:
				return 0x0095c8;
			case State.Focused:
				return 0xf49800;
		}
	}

	public static getDimensionTextColor(state: State) {
		switch (state) {
			case State.Selected:
				return 0x0095c8;

			case State.Ready:
			case State.Enabled:
			case State.Done:
				return 0x415a62;

			case State.Focused:
				return 0xf49800;

			case State.Disabled:
				return 0xd7e3e7;
		}
	}

	public static getDimensionDoorTextColor(state: State) {
		switch (state) {
			case State.Focused:
			case State.Selected:
				return 0xffffff;

			case State.Done:
			case State.Ready:
			case State.Disabled:
			case State.Enabled:
				return 0x415a62;
		}
	}

	public static getMinPosition(doorWidth: number, settings: ISettingsState): number {
		const doorFrame = (settings.doors.doorFrameWidth - doorWidth) / 2;
		return settings.doors.positionMin + doorFrame;
	}

	public static getCenteredDoorPosition(doorWidth: number, wallWidth: number, settings: ISettingsState): number {
		const center = wallWidth / 2 - doorWidth / 2;
		const firstIncrement = RoomUtils.getMinPosition(doorWidth, settings) + settings.doors.panelWidth;
		const rest = (center - firstIncrement) % settings.doors.positionIncrement;
		const nearestIncrementToCenter = center - rest;
		return nearestIncrementToCenter;
	}

	public static doorPositionStartsInPanel(doorWidth: number, position: number, settings: ISettingsState): boolean {
		return (position > RoomUtils.getMinPosition(doorWidth, settings)
			&& position < (RoomUtils.getMinPosition(doorWidth, settings) + settings.doors.panelWidth));
	}

	public static doorPositionFinishesInPanel(position: number, wallWidth: number, doorWidth: number, settings: ISettingsState): boolean {
		return (position + doorWidth > wallWidth - (RoomUtils.getMinPosition(doorWidth, settings) + settings.doors.panelWidth)
			&& position + doorWidth < wallWidth - RoomUtils.getMinPosition(doorWidth, settings));
	}

	public static doorPositionStartsBeforeMin(doorWidth: number, position: number, settings: ISettingsState): boolean {
		return position < RoomUtils.getMinPosition(doorWidth, settings);
	}

	public static doorPositionFinishesAfterMax(position: number, wallWidth: number, doorWidth: number, settings: ISettingsState): boolean {
		return position + doorWidth > wallWidth - RoomUtils.getMinPosition(doorWidth, settings);
	}

	public static doorPositionIsInvalid(wallWidth: number, position: number, doorWidth: number, settings: ISettingsState): boolean {
		return position < 0 ||
			RoomUtils.doorPositionStartsInPanel(doorWidth, position, settings) ||
			RoomUtils.doorPositionFinishesInPanel(position, wallWidth, doorWidth, settings) ||
			RoomUtils.doorPositionStartsBeforeMin(doorWidth, position, settings) ||
			RoomUtils.doorPositionFinishesAfterMax(position, wallWidth, doorWidth, settings);
	}

	public static getDefaultDoorPosition(doorWidth: number, direction: Direction, room: IRoomState, settings: ISettingsState): number {
		switch (direction) {
			case Direction.DOWN:
			case Direction.TOP:
				const centeredTopDownPosition = RoomUtils.getCenteredDoorPosition(doorWidth, room.width, settings);
				if (RoomUtils.doorPositionIsInvalid(room.width, centeredTopDownPosition, doorWidth, settings)) {
					return RoomUtils.getMinPosition(doorWidth, settings);
				}
				return centeredTopDownPosition;
			case Direction.LEFT:
			case Direction.RIGHT:
				const centeredLeftRightPosition = RoomUtils.getCenteredDoorPosition(doorWidth, room.depth, settings);
				if (RoomUtils.doorPositionIsInvalid(room.depth, centeredLeftRightPosition, doorWidth, settings)) {
					return RoomUtils.getMinPosition(doorWidth, settings);
				}
				return centeredLeftRightPosition;
		}
	}

	public static getRoomDoorPosition(door: IDoorState, room: IRoomState, settings: ISettingsState): number {
		if (door.hasDefaultPosition) {
			return RoomUtils.getDefaultDoorPosition(door.width, door.direction, room, settings);
		}

		switch (door.direction) {
			case Direction.DOWN:
			case Direction.TOP:
				if (RoomUtils.doorPositionIsInvalid(room.width, door.position, door.width, settings)) {
					return room.width - door.width - RoomUtils.getMinPosition(door.width, settings);
				}
				break;
			case Direction.LEFT:
			case Direction.RIGHT:
				if (RoomUtils.doorPositionIsInvalid(room.depth, door.position, door.width, settings)) {
					return room.depth - door.width - RoomUtils.getMinPosition(door.width, settings);
				}
		}

		return door.position;
	}

	public static isDoorPositionCentered(door: IDoorState, room: IRoomState, settings: ISettingsState): boolean {
		switch (door.direction) {
			case Direction.DOWN:
			case Direction.TOP:
				return door.position === RoomUtils.getCenteredDoorPosition(door.width, room.width, settings);
			case Direction.LEFT:
			case Direction.RIGHT:
				return door.position === RoomUtils.getCenteredDoorPosition(door.width, room.depth, settings);
		}
	}

	public static isDoorWallAvailableFromRoomType(rooms: IRoomState[], currentRoom: IRoomState, roomWall: number, direction: Direction) {
		let doorWallAvailable = this.isDoorWallAvailable(rooms, currentRoom, roomWall);
		if (rooms && rooms.length > 1) {
			if (doorWallAvailable && currentRoom.type?.typeKind === ERoomTypeKind.COOLER && this.roomsHasFreezerType(rooms)) {
				const currentRoomIndex = ERoomIndex[currentRoom.code];
				if (currentRoomIndex > 0 && direction === Direction.LEFT) {
					doorWallAvailable = rooms[currentRoomIndex - 1].type?.typeKind !== ERoomTypeKind.FREEZER;
				}
				if ((currentRoomIndex + 1) < rooms.length && direction === Direction.RIGHT) {
					doorWallAvailable = rooms[currentRoomIndex + 1].type?.typeKind !== ERoomTypeKind.FREEZER;
				}
			}
		}

		return doorWallAvailable;
	}

	public static isDoorWallAvailable(rooms: IRoomState[], currentRoom: IRoomState, roomWall: number) {
		return !rooms.find((r) =>
			r.door
			&& r.door.roomWall === roomWall
			&& r.code !== currentRoom?.code,
		);
	}

	public static roomsHasCoolerType(rooms: IRoomState[]) {
		return rooms && rooms.some((room) => {
			return room.type?.typeKind === ERoomTypeKind.COOLER;
		});
	}

	public static roomsHasFreezerType(rooms: IRoomState[]) {
		return rooms && rooms.some((room) => {
			return room.type?.typeKind === ERoomTypeKind.FREEZER;
		});
	}

	public static getRoomsTotalWidthInInch(rooms: IRoomState[], settings: ISettingsState) {
		let length = (rooms.length > 0) ? settings.commonWallWidth : 0;
		for (const room of rooms) {
			length += (room.width + settings.commonWallWidth);
		}
		return length;
	}

	public static allRoomsHaveACoolingSystem(rooms: IRoomState[]): boolean {
		for (const room of rooms) {
			if (!(room.coolingSystem !== undefined || room.hasSelectedNoCoolingSystem)) {
				return false;
			}
		}
		return true;
	}

	public static allCoolingSystemCondenserDistanceCompleted(rooms: IRoomState[]): boolean {
		for (const room of rooms) {
			if (room.coolingSystem && (room.coolingSystem.id === CoolingSystemType.ConventionalExterior || room.coolingSystem.id === CoolingSystemType.ConventionalInterior)) {
				if (room.hasDefaultCondenserDistance) {
					return false;
				}
			}
		}
		return true;
	}

	public static resetRoomCoolingSystem(room: IRoomState): IRoomState {
		return {
			...room,
			coolingSystem: undefined,
			hasSelectedNoCoolingSystem: false,
			hasDefaultCondenserDistance: true,
			condenserDistance: 0,
			coolingSystemOptions: [],
		};
	}
}
