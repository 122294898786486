import { IInstallationOriginState } from '@models';

export interface IInstallationOrigin {
	name: string;
	state: string;
	region: string;
	kmNoChg: number;
	kmMax: number;
	kmToInstall: number;
}

export function fromInstallationOriginDTO(installationOrigin: IInstallationOrigin): IInstallationOriginState {
	return {
		originCity: installationOrigin.name,
		originState: installationOrigin.state,
		originRegion: installationOrigin.region,
		maxKmDistanceNoCharge: installationOrigin.kmNoChg,
		maxKmDistanceInstallationCityFromOrigin: installationOrigin.kmMax,
		distanceKmFromInstallationCityToOrigin: installationOrigin.kmToInstall,
	};
}
