import { Widths } from '@utils/navigation';

export enum Steps {
	PROJECT_NAME = 1000,
	INSTALLATION_LOCATION = 1050,
	SEISMIC_KIT = 1100,
	NUMBER_OF_ROOMS = 1150,
	ROOM_TYPES = 1200,
	COMBO_WIDTH = 1250,
	COMBO_DEPTH = 1300,
	COMBO_HEIGHT = 1350,
	COMBO_SYSTEM = 1400,
	CONDENSER_DISTANCE = 1450,
	COMBO_OPTIONS = 1500,

	DOORS_WALL = 2000,
    DOORS_RAMPE = 2050,
	DOORS_WIDTH = 2100,
	DOORS_POSITION = 2150,
	DOORS_OPENING_DIRECTION = 2200,
	DOORS_OPTIONS = 2250,
	COMBO_I3 = 2300,
	COMBO_FLOOR_FINISH = 2350,
	COMBO_FLOOR_TYPE = 2400,
	ROOM_LIGHT_MODELS = 2450,
	ROOM_MOTION_DETECTOR = 2500,

	INSTALLATION = 3000,
	DELIVERY_CHOICE = 3025,
	DELIVERY_DATE = 3050,
	DELIVERY_CONTACT = 3075,
	DELIVERY_PLACE = 3100,
	QUOTE = 3200,
}

export const Bounds = {
	configurationLowerBound: 1000,
	configurationUpperBound: 1999,
	optionsLowerBound: 2000,
	optionsUpperBound: 2999,
	installationLowerBound: 3000,
	installationUpperBound: 3999,
};

export interface IUrl {
	[key: string]: string | string[];
	en: string | string[];
	fr: string | string[];
}

export interface IBaseTabProps {
	readonly routePrefix: string;
	readonly isFolded: boolean;
	readonly navigationStepsLowerBound: Steps;
	readonly navigationStepsUpperBound: Steps;
	readonly tabWidth: Widths;
	readonly tabDrawer: React.ReactElement;
}

export interface IDrawerStepDefinition {
	stepName: string;
	step: Steps;
	url: IUrl;
}

export interface IDrawerSectionDefinition {
	[key: string]: any;
	sectionName: string;
	steps: IDrawerStepDefinition[];
}
