import CenterIcon from '@components/svg/CenterIcon';
import ZoomInIcon from '@components/svg/ZoomInIcon';
import ZoomOutIcon from '@components/svg/ZoomOutIcon';
import { Position, State } from '@models';
import classnames from 'classnames';
import React, { useState } from 'react';
import { AnimationSettings } from '../../utils/animation';
import S from './styles/DrawingButton.styl';

export enum DrawingButtonType {
	ZoomIn,
	ZoomOut,
	Center,
}

interface IDrawingButton {
	onClick?: () => void;
	state: State;
	position: Position;
	type: DrawingButtonType;
}

export default function DrawingButton(props: IDrawingButton) {
	const [buttonState, setButtonState] = useState(props.state);
	const [timeout, setTimeout] = useState<number>();

	function onClick() {
		if (props.onClick && buttonState !== State.Disabled) {
			props.onClick();

			setButtonState(State.Focused);

			clearTimeout(timeout);

			setTimeout(window.setTimeout(() => {
				setButtonState(State.Enabled);
			}, AnimationSettings.FADE_DELAY));
		}
	}

	function getStrokeColor(): string {
		return buttonState === State.Focused ? '#f49800' : '#9FBBC4';
	}

	function getOpacity(): string {
		return buttonState === State.Disabled ? '0.5' : '1';
	}

	return (
		<div
			onMouseOver={() => buttonState !== State.Disabled && setButtonState(State.Focused)}
			onMouseLeave={() => buttonState !== State.Disabled && setButtonState(State.Enabled)}
			onMouseDown={onClick}
			onTouchEnd={onClick}
			className={classnames(
			S.drawingButtonContainer, {
				[S.drawingButtonContainerSingle]: props.position === Position.Single,
				[S.drawingButtonContainerStart]: props.position === Position.Start,
				[S.drawingButtonContainerEnd]: props.position === Position.End,
				[S.drawingButtonContainerMiddle]: props.position === Position.Middle,
				[S.drawingButtonContainerFocused]: buttonState === State.Focused,
				[S.drawingButtonContainerDisabled]: buttonState === State.Disabled,
			})}
		>
			{ props.type === DrawingButtonType.ZoomIn &&
				<ZoomInIcon stroke={getStrokeColor()} opacity={getOpacity()}/>
			}
			{ props.type === DrawingButtonType.ZoomOut &&
				<ZoomOutIcon stroke={getStrokeColor()} opacity={getOpacity()}/>
			}
			{ props.type === DrawingButtonType.Center &&
				<CenterIcon stroke={getStrokeColor()} opacity={getOpacity()}/>
			}
		</div>
	);
}
