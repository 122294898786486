import { ICustomer } from '@models';
import { ICustomerGroupListPayload, ICustomerListPayload, ICustomerTerritorieListPayload } from 'app/models/customers';
import axiosInstance from './axiosInstance';

const GET_CUSTOMERS = `/erp/customers`;
const GET_CUSTOMERS_TERRITORIES_CAN = `/erp/territories/Canada`;
const GET_CUSTOMERS_TERRITORIES_USA = `/erp/territories/USA`;
const GET_CUSTOMERS_GROUP = `/erp/custgrups`;

const POST_CUSTOMER = `/erp/customer`;
const PUT_CUSTOMER = `/erp/customer`;

export async function getCustomers(): Promise<{ data: ICustomerListPayload[] | any }> {
	return await axiosInstance.get(GET_CUSTOMERS);
}

export async function getAllCustomers(): Promise<{	data: ICustomerListPayload[] | any}> {
	return await axiosInstance.get(GET_CUSTOMERS, {params: { activeFilter: 2 },
	});
}

export async function getCustomersTerritoriesCAN(): Promise<{ data: ICustomerTerritorieListPayload[] | any }> {
	return await axiosInstance.get(GET_CUSTOMERS_TERRITORIES_CAN);
}

export async function getCustomersTerritoriesUSA(): Promise<{ data: ICustomerTerritorieListPayload[] | any }> {
	return await axiosInstance.get(GET_CUSTOMERS_TERRITORIES_USA);
}

export async function getCustomersGroups(): Promise<{ data: ICustomerGroupListPayload[] | any }> {
	return await axiosInstance.get(GET_CUSTOMERS_GROUP);
}

export async function PostCustomer(body: ICustomer): Promise<ICustomer> {
	const response = await axiosInstance.post(POST_CUSTOMER, body );
	return response.data;
}

export async function PutCustomer(body: ICustomer): Promise<ICustomer> {
	const response = await axiosInstance.put(PUT_CUSTOMER, body );
	return response.data;
}
