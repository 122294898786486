import { IActionWithoutPayload, IActionWithPayload, IDataState } from '@models';
import { DataActionsNames } from '@store/actions';

function DataReducer(state: IDataState, action: IActionWithPayload<any> & IActionWithoutPayload) {
	switch (action.type) {
		case DataActionsNames.SET_CUSTOMERS:
			return {
			...state,
			customers: action.payload,
            optionsCustomers: state.optionsCustomers,
		};
        case DataActionsNames.SET_OPTIONS_CUSTOMERS:
			return {
			...state,
            customers: state.customers,
			optionsCustomers: action.payload,
		};
	    default:
			return state;
	}
}

export default DataReducer;
