import { useLocale } from '@i18n';
import { Paper } from '@material-ui/core';
import { IContact } from '@models';
import { REGEX_EMAIL, REGEX_PHONE } from '@utils/constants';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import Button from '../../components/buttons/Button';
import S from '../project/styles/Project.styl';

export enum Mode {
    add = 'add',
    edit = 'edit',
}

export default function MUIContactsTable({ mode, contactList, refreshContacts }: any) {

    const T = useLocale();
    const [isEditing, setIsEditing] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [addMode, setAddMode] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [displayActionsButton, setDisplayActionsButton] = useState(false);
    const [contacts, setContacts] = useState<any[]>([]);

    const columns: any[] = [
        { title: T.CreateCustomerContactName, field: 'name', width: '30%', validate: (rowData: IContact) => rowData.name === undefined || rowData.name === '' ? T.CreateCustomerInputRequired : true },
        { title: T.CreateCustomerContactFunction, field: 'func', width: '30%' },
        { title: T.CreateCustomerContactEmail, field: 'email', width: '25%', validate: (rowData: IContact) => rowData.email === undefined || rowData.email === '' || !new RegExp(REGEX_EMAIL).test(rowData.email) ? T.CreateCustomerInputRequired : true },
        { title: T.CreateCustomerContactPhoneNum, field: 'phoneNum', width: '10%' },
        // { title: T.CreateCustomerContactPhoneNum, field: 'phoneNum', width: '75%', validate: (rowData: IContact) => rowData.phoneNum === '' || rowData.phoneNum === undefined ? true : !new RegExp(REGEX_PHONE).test(rowData.phoneNum) ? 'Champ non valide' : true },
    ];

    const columnsWithBilling = [...columns, { title: 'Billing', field: 'billing', type: 'boolean', width: '5%', editable: 'never' }];

    const onCancelClicked = () => {
        setContacts(contactList);
        setIsEditing(false);
        setCanSubmit(false);
        setDisplayActionsButton(false);
    };

    const onSubmitClicked = () => {
        refreshContacts(contacts);
        setIsEditing(false);
        setCanSubmit(false);
        setDisplayActionsButton(false);

    };

    const onModifyClicked = () => {
        setIsEditing(true);
        setDisplayActionsButton(true);

    };

    function findWithAttr(array: string | any[], attr: string | number, value: any) {
        for (let i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    useEffect(() => {
        setContacts(contactList);
    }, [contactList]);

    useEffect(() => {
        mode === Mode.add ? setDisplayActionsButton(true) : setEditMode(true);
    }, [mode]);

    useEffect(() => {
        if (mode === Mode.add && contacts.length !== 0) { refreshContacts(contacts); }
    }, [contacts]);

    function isValidData(data: any): boolean {
        const name = data.name === undefined || data.name === '' ? false : true;
        const email = data.email === undefined || data.email === '' || !new RegExp(REGEX_EMAIL).test(data.email) ? false : true;
        // const phone = data.phoneNum !== undefined && !new RegExp(REGEX_PHONE).test(data.phoneNum) ? false : true; console.log('phone : ', phone);
        return name && email;
    }

    return (
        <>
            <div className={S.tabGroupHeader}>
                <h3>{T.CreateCustomerLabelContacts}</h3>
                {editMode && !isEditing && (
                    <div className={S.tabGroupHeaderFormActions}>
                        <Button secondary small onClick={onModifyClicked}>{T.GeneralModify}</Button>
                    </div>
                )}
                {isEditing && (
                    <div className={S.tabGroupHeaderFormActions}>
                        <Button secondary small onClick={onCancelClicked}>{T.GeneralCancel}</Button>
                        <Button disabled={!canSubmit} small onClick={onSubmitClicked}>{T.GeneralSave}</Button>
                    </div>
                )}
            </div>

            <MaterialTable
                components={{
                    Container: (props) => <Paper {...props} elevation={0} />,
                }}
                title={''}
                data={contacts.filter((contact) => contact.active === true)}
                // data={contacts}
                columns={mode === Mode.add ? columns : columnsWithBilling}
                options={{
                    headerStyle: { color: 'black', fontWeight: 'bold' },
                    search: true,
                    filtering: false,
                    sorting: true,
                    selection: false,
                    searchFieldAlignment: 'left',
                    pageSizeOptions: [5, 10, 20, 100],
                    actionsColumnIndex: -1,
                    addRowPosition: 'first',
                }}
                style={{ width: '100%' }}
                localization={{
                    body: {
                        editRow: { deleteText: T.CreateCustomerContactsDeleteRow, cancelTooltip: T.CreateCustomerContactsMuiTableActionCancel, saveTooltip: T.CreateCustomerContactsMuiTableActionSave },
                        emptyDataSourceMessage: T.CreateCustomerContactsEmpty,
                        addTooltip: T.CreateCustomerContactsMuiTableActionAdd,
                        editTooltip: T.CreateCustomerContactsMuiTableActionEdit,
                        deleteTooltip: T.CreateCustomerContactsMuiTableActionDelete,
                    },
                    toolbar: {
                        searchPlaceholder: T.CreateCustomerContactsSearch,
                    },
                    pagination: {
                        labelRowsSelect: T.CreateCustomerContactslabelRowsSelect,
                    },
                }}
                editable={displayActionsButton ? {
                    onRowAdd: (newData) => new Promise<void>((resolve, reject) => {
                        if (isValidData(newData)) {
                            newData.active = true;
                            setTimeout(() => {
                                setContacts([...contacts, newData]);
                                resolve();
                                setCanSubmit(true);
                            }, 500);
                        }
                        else {
                            reject();
                        }
                    }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise<void>((resolve, reject) => {
                            if (isValidData(newData)) {
                                setTimeout(() => {
                                    const dataUpdate = [...contacts];
                                    const index = findWithAttr(dataUpdate, 'epicorConNum', oldData?.epicorConNum);
                                    dataUpdate[index] = newData;
                                    setContacts([...dataUpdate]);
                                    resolve();
                                    setCanSubmit(true);
                                }, 500);
                            }
                            else {
                                reject();
                            }
                        }),
                    onRowDelete: (oldData) =>
                        new Promise<void>((resolve, reject) => {
                            setTimeout(() => {
                                const deleteContact: any = {
                                    custNum: oldData.epicorCustId,
                                    conNum: oldData.epicorConNum,
                                    name: oldData.name,
                                    email: oldData.email,
                                    phoneNum: oldData.phoneNum,
                                    func: '',
                                    shipToNum: oldData.epicorShipToNum,
                                    active: false,
                                };
                                const dataDelete = [...contacts];
                                const index = findWithAttr(dataDelete, 'epicorConNum', oldData?.epicorConNum);
                                dataDelete[index] = deleteContact;
                                setContacts([...dataDelete]);
                                resolve();
                                setCanSubmit(true);
                            }, 500);
                        }),
                } : {}}
            />
        </>
    );
}
