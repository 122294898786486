import { Direction } from '@components/drawing/Door';
import { Graphics } from '@inlet/react-pixi';
import { IDoorState, IRoomState, RoomSettings } from '@models';
import { renderDimension } from '@utils/drawing/dimensions';
import RoomUtils from '@utils/room';
import React from 'react';

interface IRoomDoorDimensionProps {
	room: IRoomState;
	door: IDoorState;
	isDefault: boolean;
}

export default function RoomDoorDimension(props: IRoomDoorDimensionProps) {
	const roomDepth = RoomUtils.toPixels(props.room.depth);
	const roomWidth = RoomUtils.toPixels(props.room.width);
	const borderLength = RoomUtils.toPixels(props.room.borderLength);

	const formatter = props.isDefault ? (v: number) => `${v}"` : (v: number) => `${v}"`;

	return (
		<Graphics
			draw={(graphics) => {
				graphics.clear();
				graphics.removeChildren();

				let roomY = 0;

				switch (props.door.direction) {
					case Direction.RIGHT: {
						graphics.position.x = props.room.x + roomWidth + borderLength;
						graphics.position.y = props.room.y + roomDepth + borderLength;
						graphics.rotation =  1.5 * Math.PI;
						roomY = -15;
						break;
					}
					case Direction.DOWN: {
						graphics.position.x = props.room.x + borderLength;
						graphics.position.y = props.room.y + borderLength + roomDepth - RoomSettings.ROOM_DIMENSION_HEIGHT;
						roomY = -5;
						break;
					}
					case Direction.LEFT: {
						graphics.position.x = props.room.x + borderLength + RoomSettings.ROOM_DIMENSION_HEIGHT;
						graphics.position.y = props.room.y + roomDepth + borderLength;
						graphics.rotation = 1.5 * Math.PI;
						roomY = -5;
						break;
					}
					case Direction.TOP: {
						graphics.position.x = props.room.x + borderLength;
						graphics.position.y = props.room.y + borderLength;
						roomY = 5;
						break;
					}
				}

				renderDimension(
					graphics,
					RoomUtils.toPixels(props.door.position),
					roomY,
					props.door.width,
					true,
					props.door.state,
					formatter,
				);
			}}
		/>
	);
}
