import { State } from '@models';
import { useStore } from '@store/index';
import classnames from 'classnames';
import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';

import S from './styles/ButtonLegacy.styl';

interface IButtonProps {
	onClick?: () => void;
	enabled: boolean;
	text: string;
	containerBackgroundColor?: string;
	urlTo?: string;
	buttonIcon?: string | ReactElement;
}

export default function ButtonLegacy(props: IButtonProps) {
	const { state } = useStore();
	const [timeout, setTimeout] = useState<number>();
	const [buttonState, setButtonState] = useState<State>(State.Enabled);

	function blur() {
		if (buttonState !== State.Disabled) {
			setButtonState(State.Enabled);
		}
	}

	function focus() {
		if (buttonState !== State.Disabled) {
			setButtonState(State.Focused);
		}
	}

	function handleClick() {
		if (props.onClick) {

			setButtonState(State.Focused);

			if (timeout) {
				window.clearTimeout(timeout);
			}

			setTimeout(window.setTimeout(() => {
				if (props.onClick) {
					props.onClick();
				}
				setButtonState(State.Enabled);
			}, 50));
		}
	}

	function buttonContent() {
		return (
			<div
				className={classnames(S.buttonButton, {
					[S.buttonButtonDisabled]: buttonState === State.Disabled,
					[S.buttonButtonFocused]: buttonState === State.Focused,
					[S.buttonButtonPriceClose]: state?.layout?.priceDetailsVisible,
				})}
				onMouseOver={focus}
				onMouseLeave={blur}
				onClick={handleClick}
			>
				<div
					className={classnames(S.buttonTitle, {
						[S.buttonTitleDisabled]: buttonState === State.Disabled,
					})}
				>
					{props.buttonIcon && (
						<div className={S.buttonIcon}>
							{props.buttonIcon}
						</div>
					)}

					<div>
						{props.text}
					</div>
				</div>
			</div>
		);
	}

	return (
		<div
			className={S.buttonContainer}
			style={{ backgroundColor: props.containerBackgroundColor || 'inherit' }}
		>

			{props.urlTo && (
				<Link
					to={props.urlTo}
					onClick={props.onClick}
				>
					{buttonContent()}
				</Link>
			)}

			{!props.urlTo && (
				buttonContent()
			)}

		</div>
	);
}
