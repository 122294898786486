import React from 'react';

export default function SortIcon({ color, className, ref }: {color?: string, className?: string, ref?: any}) {
	return (
		<svg ref={ref} className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fillRule="evenodd">
				<polygon fill={color ? color : '#9FBBC4'} points="7 10 12 5 17 10"/>
				<polygon fill={color ? color : '#9FBBC4'} points="7 19 12 14 17 19" transform="matrix(1 0 0 -1 0 33)"/>
			</g>
		</svg>
	);
}
