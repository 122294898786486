import { Graphics } from '@inlet/react-pixi';
import { RoomSettings, State } from '@models';
import LinesUtils from '@utils/drawing/lines';
import ShapesUtils from '@utils/drawing/shapes';
import TextUtils from '@utils/drawing/text';
import RoomUtils from '@utils/room';
import { Graphics as PIXIGraphics, Rectangle } from 'pixi.js';
import React, { useState } from 'react';

export interface IAddRoomButtonProps {
	x: number;
	y: number;
	width: number;
	height: number;
	buttonState: State;
	onClick: Function;
}

export default function AddRoomButton(props: IAddRoomButtonProps) {
	const [buttonState, setState] = useState<State>(props.buttonState);
	const dashedLineGap = 6;
	const dashedLineDash = 1;
	const dashedLineColor = 0x3a3835;
	const addRoomButtonWidth = 56;
	const addRoomButtonHeight = 40;

	function focus() {
		if (buttonState === State.Enabled) {
			setState(State.Focused);
		}
	}

	function blur() {
		if (buttonState === State.Focused) {
			setState(State.Enabled);
		}
	}

	function handleClick() {
		props.onClick();
	}

	function renderDashedBorder(graphics: PIXIGraphics, x: number, y: number, width: number, height: number) {
		// DRAW RECTANGLE
		graphics.clear();

		// DRAW INNER STROKE
		LinesUtils.drawFullDashedBorder(
			graphics,
			RoomSettings.EMPTY_ROOM_BORDER_LENGTH,
			x,
			y,
			width,
			height,
			dashedLineGap,
			dashedLineDash,
			dashedLineColor,
		);
	}

	function renderAddButton(
		graphics: PIXIGraphics,
		x: number,
		y: number,
		parentWidth: number,
		parentHeight: number,
		buttonWidth: number,
		buttonHeight: number,
		state: State,
	) {
		graphics.clear();
		graphics.removeChildren();
		ShapesUtils.drawCenteredRoundedRectangle(
			graphics,
			parentWidth,
			parentHeight,
			x,
			y,
			buttonWidth,
			buttonHeight,
			RoomUtils.getAddButtonBackgroundColor(state),
			1,
		);
		graphics.addChild(TextUtils.createCenteredText(
			'+',
			'Assistant',
			18,
			RoomUtils.getAddButtonPlusColor(state),
			x,
			y,
			parentWidth,
			parentHeight,
		));
	}

	return (
		<>
			<Graphics
				hitArea={new Rectangle(
					props.x,
					props.y,
					props.width,
					props.height,
				)}
				draw={(graphics) => {
					renderDashedBorder(
						graphics,
						props.x,
						props.y,
						props.width,
						props.height,
					);
				}}
			/>
			<Graphics
				mouseout={blur}
				mouseover={focus}
				mousedown={handleClick}
				touchstart={focus}
				touchend={() => {
					handleClick();
					blur();
				}}
				interactive={props.buttonState === State.Enabled}
				draw={(graphics) => {
					renderAddButton(
						graphics,
						props.x,
						props.y,
						props.width,
						props.height,
						addRoomButtonWidth,
						addRoomButtonHeight,
						buttonState,
					);
				}}
			/>
		</>
	);
}
