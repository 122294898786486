import { ICoolingSystemState } from '@models';
import { State } from './state';

export interface ICoolingSystem {
	id: CoolingSystemType;
	name: string;
	shortName: string;
	description: string;
	technicalSheetUrl: string;
	imageUrl: string;
	price: number;
}

export enum CoolingSystemType {
	ConventionalInterior = 1,
	ConventionalExterior = 2,
	Pro3 = 3,
}

export function fromCoolingSystemsDTO(coolingSystems: ICoolingSystem[]): ICoolingSystemState[] {
	return coolingSystems.map((coolingSystem) => {
		return fromCoolingSystemDTO(coolingSystem);
	});
}

export function fromCoolingSystemDTO(coolingSystem: ICoolingSystem): ICoolingSystemState {
	return  {
		id: coolingSystem.id,
		name: coolingSystem.name,
		shortName: coolingSystem.shortName,
		price: coolingSystem.price,
		description: coolingSystem.description,
		technicalSheet: coolingSystem.technicalSheetUrl,
		imageUrl: coolingSystem.imageUrl,
		state: State.Enabled,
	};
}

export function toCoolingSystemDTO(coolingSystemState: ICoolingSystemState): ICoolingSystem {
	return {
		id: coolingSystemState.id,
		name: coolingSystemState.name,
		shortName: coolingSystemState.shortName,
		description: coolingSystemState.description,
		technicalSheetUrl: coolingSystemState.technicalSheet,
		imageUrl: coolingSystemState.imageUrl,
		price: coolingSystemState.price,
	};
}
