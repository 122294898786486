import Modal, { IModal } from '@components/modals/Modal';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import {
	CSSTransition,
	TransitionGroup,
} from 'react-transition-group';

interface IModalContext {
	useModal: (modal: IModal) => void;
	changeYesActionState: (yesActionState: boolean) => void;
}

const ModalContext = createContext<IModalContext>({ useModal: (modal: IModal) => undefined, changeYesActionState: (yesActionState: boolean) => undefined });

export default ModalContext;

export function ModalContextProvider({ children }: { children: any }) {
	const [currentModal, setCurrentModal] = useState<IModal | undefined>(undefined);
	const [yesActionState, setYesActionState] = useState<boolean | undefined>(undefined);

	useEffect(() => {
		if (currentModal) {
			document.getElementById('body')!.classList.add('locked');
		}
		return () => {
			if (currentModal) {
				document.getElementById('body')!.classList.remove('locked');
			}
		};
	});

	const useModal = useCallback(
		(modal: IModal) => {
			setCurrentModal(modal);
		},
		[setCurrentModal],
	);

	const removeModal = useCallback(
		() => {
			setCurrentModal(undefined);
		},
		[setCurrentModal],
	);

	const changeYesActionState = useCallback(
		(state: boolean) => {
			if (state !== yesActionState) {
				setYesActionState(state);
			}
		},
		[setYesActionState],
	);

	return (
		<ModalContext.Provider value={{ useModal, changeYesActionState }}>
			{children}
			<TransitionGroup>
				{currentModal && (
					<CSSTransition
						classNames={'modal'}
						timeout={600}
					>
						<Modal modal={{ ...currentModal, yesActionState }} removeModal={removeModal} />
					</CSSTransition>
				)}
			</TransitionGroup>
		</ModalContext.Provider>
	);
}
