import { getProjects } from '@api/project';
import ActionsMenu from '@components/buttons/ActionsMenu';
import TagLabel from '@components/labels/TagLabel';
import { CloseIcon } from '@components/svg';
import SearchIcon from '@components/svg/SearchIcon';
import SortIcon from '@components/svg/SortIcon';
import { useLocale } from '@i18n';
import { IProject, OrderState, TableOrderState } from '@models';
import { formatTimestamp } from '@utils/format';
import { enCA, frCA } from 'date-fns/locale';
import MaterialTable from 'material-table';
import React, { forwardRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ERoutes, getPathByRouteName } from '..';
import { EPermissions } from '../../hooks/contexts/AuthContext';
import {
	ToastType,
	useAuthContext,
	useToastContext,
} from '../../hooks/contexts/UseContext';

import Loading from '@components/layout/Loading';
import S from './styles/Dashboard.styl';

interface IColumns {
	title: string;
	field: string;
	type?: string;
	render?: (projectRow: IProject) => JSX.Element | string | undefined;
	export?: boolean;
	lookup?: any;
	sorting?: boolean;
	filtering?: boolean;
}

export default function MuiTable() {
	const tableRef: any = React.createRef();

	const T = useLocale();
	const { userHasPermissions } = useAuthContext();
	const addToast = useToastContext();
	const history = useHistory();

	const [loading, setLoading] = useState<boolean>(false);

	const generateColumnsByPermissions = () => {
		const columns: IColumns[] = [{ title: T.TableColName, field: 'name', render: (projectRow: IProject) => <b>{projectRow.name}</b> }];
		if (userHasPermissions(EPermissions.viewTableClientId)) { columns.push({ title: T.TableColEpicorCustId, field: 'customer.epicorCustId' }); }
		if (userHasPermissions(EPermissions.viewTableClientName)) { columns.push({ title: T.TableColEpicorName, field: 'customer.name' }); }
		if (userHasPermissions(EPermissions.viewTableCreatedBy)) { columns.push({ title: T.TableColUserName, field: 'userName' }); }
		columns.push({ title: T.TableColRefNumber, field: 'refNumber' });
		columns.push({ title: T.TableColInternalRefNumber, field: 'epicorQuoteNum' });
		columns.push({ title: T.TableColLastUpdate, field: 'dateLastUpdate', type: 'date', render: (projectRow: IProject) => projectRow.dateLastUpdate && formatTimestamp(projectRow.dateLastUpdate) });
		if (userHasPermissions(EPermissions.viewTableQuotedDate)) {
			columns.push({ title: T.TableColQuotedDate, field: 'dateQuoted', type: 'date', render: (projectRow: IProject) => projectRow.dateQuoted && formatTimestamp(projectRow.dateQuoted) });
		}
		columns.push({ title: T.TableColStatus, field: 'status', lookup: TableOrderState(T), render: (projectRow: IProject) => (<TagLabel state={projectRow.status} archived={projectRow.archived} />) });
		columns.push({
			title: '', export: false, field: 'actions', sorting: false, filtering: false, render: (projectRow: IProject) =>
				(<div className={S.actionMenuContainer}><ActionsMenu project={projectRow} viewDetails fetchProject={() => tableRef.current.onQueryChange()} refreshLoading={(e) => setLoading(e)} /></div>),
		});
		return columns;
	};

	if (loading) {
		return <Loading />;
	}

	const mapFilterName = (filter?: string) => {
		switch (filter) {
			case 'customer.name':
				return 'customerName';
			case 'customer.epicorCustId':
				return 'customerEpicorCustId';
		}
		return filter;
	};

	const onRowClicked = (
		e: React.MouseEvent<Element, MouseEvent> | undefined,
		row: IProject | undefined,
	) => {
		if (e) {
			if (row && (e.target as Element).tagName === 'TD') {
				history.push(
					getPathByRouteName(
						row.status === OrderState.Expired
							? ERoutes.Quote
							: ERoutes.Project,
						T.locale,
						[{ slug: ':id', value: `${row.id}` }],
					),
				);
			}
		}
	};

	return (
		<MaterialTable
			title={''}
			tableRef={tableRef}
			columns={generateColumnsByPermissions() as any}
			data={(query) => {
				return new Promise((resolve, reject) => {
					getProjects({
						projectName: query.filters.find((e) => e.column.field === 'name')?.value,
						epicorQuoteNum: query.filters.find((e) => e.column.field === 'epicorQuoteNum')?.value,
						refNumber: query.filters.find((e) => e.column.field === 'refNumber')?.value,
						customerEpicorCustId: query.filters.find((e) => e.column.field === 'customer.epicorCustId')?.value,
						customerName: query.filters.find((e) => e.column.field === 'customer.name')?.value,
						userName: query.filters.find((e) => e.column.field === 'userName')?.value,
						status: query.filters.find((e) => e.column.field === 'status')?.value.length === 1 && query.filters.find((e) => e.column.field === 'status')?.value[0] === '5' ? '' : query.filters.find((e) => e.column.field === 'status')?.value.join(),
						archived: query.filters.find((e) => e.column.field === 'status')?.value.length === 1 && query.filters.find((e) => e.column.field === 'status')?.value[0] === '5' ? 2 : 0,
						dateQuoted: query.filters.find((e) => e.column.field === 'dateQuoted') ? new Date(query.filters.find((e) => e.column.field === 'dateQuoted')?.value).toISOString().slice(0, 10) : undefined,
						dateLastUpdate: query.filters.find((e) => e.column.field === 'dateLastUpdate') ? new Date(query.filters.find((e) => e.column.field === 'dateLastUpdate')?.value).toISOString().slice(0, 10) : undefined,
						search: query.search,
						sortField: mapFilterName(query.orderBy?.field),
						sortDirection: query.orderDirection,
						pageSize: query.pageSize,
						pageNo: query.page + 1,
					})
						.then((response) => {
							resolve({
								data: response.projects,
								page: query.page,
								totalCount: response.countTotal,
							});
						})
						.catch((error) => {
							addToast({
								text: T.GeneralFormSubmitMessageError,
								type: ToastType.error,
							});
							reject();
						});
				});
			}}
			options={{
				exportButton: true,
				filtering: true,
				searchFieldAlignment: 'left',
				headerStyle: { width: '10px', maxWidth: '10px' },
				actionsCellStyle: { width: '10px', maxWidth: '10px' },
				pageSizeOptions: [5, 10, 20, 100, 250],
			}}
			onRowClick={onRowClicked}
			actions={[
				{
					icon: 'refresh',
					tooltip: T.TableToolbarRefreshTooltip,
					isFreeAction: true,
					onClick: () =>
						tableRef.current && tableRef.current.onQueryChange(),
				},
			]}
			icons={{
				Filter: forwardRef((props, ref) => (
					<SearchIcon fill={S.darkBlueGray} />
				)),
				ResetSearch: forwardRef((props, ref) => (
					<CloseIcon stroke={S.darkBlueGray} />
				)),
				SortArrow: forwardRef((props: any, ref) => (
					<SortIcon ref={ref} className={props.className} />
				)),
			}}
			localization={{
				error: T.TableError,
				body: {
					dateTimePickerLocalization: T.locale === 'en' ? enCA : frCA,
					emptyDataSourceMessage: (
						<span className={S.emptyData}>
							{T.TableBodyEmptyDataSourceMessage}
						</span>
					),
					filterRow: {
						filterPlaceHolder:
							T.TableBodyFilterRowFilterPlaceHolder,
						filterTooltip: T.TableBodyFilterRowFilterTooltip,
					},
				},
				header: {
					actions: T.TableHeaderActions,
				},
				pagination: {
					firstTooltip: T.TablePaginationFirstTooltip,
					firstAriaLabel: T.TablePaginationFirstTooltip,
					previousTooltip: T.TablePaginationPreviousTooltip,
					previousAriaLabel: T.TablePaginationPreviousTooltip,
					nextTooltip: T.TablePaginationNextTooltip,
					nextAriaLabel: T.TablePaginationNextTooltip,
					labelDisplayedRows: T.TablePaginationLabelDisplayedRows,
					labelRowsPerPage: T.TablePaginationLabelRowsPerPage,
					lastTooltip: T.TablePaginationLastTooltip,
					lastAriaLabel: T.TablePaginationLastTooltip,
					labelRowsSelect: T.TablePaginationLabelRowsSelect,
				},
				toolbar: {
					addRemoveColumns: T.TableToolbarAddRemoveColumns,
					nRowsSelected: T.TableToolbarNRowsSelected,
					showColumnsTitle: T.TableToolbarShowColumnsTitle,
					showColumnsAriaLabel: T.TableToolbarShowColumnsTitle,
					exportTitle: T.TableToolbarExportTitle,
					exportAriaLabel: T.TableToolbarExportTitle,
					exportCSVName: T.TableToolbarExportCSVName,
					exportPDFName: T.TableToolbarExportPDFName,
					searchTooltip: T.TableToolbarSearchTooltip,
					searchPlaceholder: T.TableToolbarSearchPlaceholder,
					searchAriaLabel: T.TableToolbarSearchPlaceholder,
					clearSearchAriaLabel: T.TableToolbarClearSearchAriaLabel,
				},
			}}
			components={{
				OverlayLoading: (props) => (
					<div className="MuiTable-loading-overlay">
						<Loading />
					</div>
				),
			}}
		/>
	);
}
