import { Direction } from '@components/drawing/Door';
import { IActionWithoutPayload, IActionWithPayload, ISettingsState } from '@models';
import { SettingsActionsNames } from '../actions';

function SettingsReducer(state: ISettingsState, action: IActionWithPayload<any> & IActionWithoutPayload) {
	switch (action.type) {
		case SettingsActionsNames.SET_ROOM_SETTINGS:
			return {
				orderDelay: action.payload.orderDelay,
				minRoomNumber: action.payload.roomCntMin,
				maxRoomNumber: action.payload.roomCntMax,
				commonWallWidth: action.payload.roomWallThickness,
				defaultHeight: action.payload.roomDefaultHeight,
				defaultWidth: action.payload.roomDefaultWidth,
				defaultDepth: action.payload.roomDefaultDepth,
				widthIncrement: action.payload.roomWallIncr,
				minWidth: action.payload.roomWidthMin,
				maxWidth: action.payload.roomWidthMax,
				depthIncrement: action.payload.roomWallIncr,
				minDepth: action.payload.roomDepthMin,
				maxDepth: action.payload.roomDepthMax,
				heights: action.payload.roomHeights,
				serviceOfferInfoSheetUrl: action.payload.serviceOfferInfoSheetUrl,
				transportTypesTechnicalSheetUrl: action.payload.transportTypesTechnicalSheetUrl,
				condenser: {
					defaultDistance: action.payload.condensingDefaultDistance,
					minDistance: action.payload.condensingDistanceMin,
					maxDistance: action.payload.condensingDistanceMax,
					incrementDistance: action.payload.condensingDistanceIncr,
					condenserDistanceImageUrl: action.payload.coolingDistanceImage,
				},
				doors: {
					maxPerWall: action.payload.doorCntMax,
					defaultWidth: action.payload.doorDefaultWidth,
					defaultWall: action.payload.doorDefaultWall,
					defaultOpeningDirection: action.payload.doorDefaultOpeningDirection === 1 ? Direction.RIGHT : Direction.LEFT,
					doorWidths: action.payload.doorWidths,
					positionIncrement: action.payload.doorPosIncr,
					positionMin: action.payload.doorGapAtWallEnd,
					panelWidth: action.payload.wallMinPanelWidth,
					doorFrameWidth: action.payload.doorPanelWidth,
				},
				seismicKit: {
					name: action.payload.seismicKitName,
					description: action.payload.seismicKitDescription,
					technicalSheetUrl: action.payload.seismicKitTechnicalSheetUrl,
				},
			};
		default:
			return state;
	}
}

export default SettingsReducer;
