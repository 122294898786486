import BackButtonMvp from '@components/buttons/BackButtonMvp';
import { LayoutFullWidth, LayoutHalfAndHalf } from '@components/layout/Layout';
import LeftContentAuthHalfAndHalf from '@components/layout/LeftContentAuthHalfAndHalf';
import LeftSectionLayoutHalfAndHalf from '@components/layout/LeftSectionLayoutHalfAndHalf';
import RightSectionPublicLayoutHalfAndHalf from '@components/layout/RightSectionPublicLayoutHalfAndHalf';
import { useLocale } from '@i18n';
import classnames from 'classnames';
import React from 'react';
import { useAuthContext } from '../../hooks/contexts/UseContext';
import S from './styles/Terms.styl';

export default function Terms() {
	const T = useLocale();
	const { isAuthenticated } = useAuthContext();

	const termsContent = () => {
		return (
			<div
				className={classnames(S.termsContainer, {
					[S.termsContainerIsAuthenticated]: isAuthenticated,
				})}
			>
				<h1 className={S.termsContainerTitle}>
					{T.TermsMvpTitle}
				</h1>
				<div className={S.termsContainerTextContent} dangerouslySetInnerHTML={{ __html: T.TermsMvpContent }} />
			</div>
		);
	};

	return (
		<>
			{isAuthenticated && (
				<LayoutFullWidth>
					{termsContent()}
				</LayoutFullWidth>
			)}

			{!isAuthenticated && (
				<LayoutHalfAndHalf>
					<LeftSectionLayoutHalfAndHalf>
						<LeftContentAuthHalfAndHalf />
					</LeftSectionLayoutHalfAndHalf>
					<RightSectionPublicLayoutHalfAndHalf
						className={'terms-content'}
					>
						<div className={S.termsWrapper}>
							<div className={S.termsWrapperBackButtonContainer}>
								<BackButtonMvp />
							</div>
							{termsContent()}
						</div>
					</RightSectionPublicLayoutHalfAndHalf>
				</LayoutHalfAndHalf>
			)}
		</>
	);
}
