import { Bounds, IActionWithoutPayload, IActionWithPayload, ILayoutState, Steps } from '@models';
import { DEFAULT_STATE } from '@store/default';
import { LayoutActionsNames, ProjectActionsNames } from '../actions';

function closeAllDrawers() {
	return {
		configurationDrawerVisible: false,
		installationDrawerVisible: false,
		optionsDrawerVisible: false,
	};
}

function foldAllTabs() {
	return {
		configurationFolded: true,
		optionsFolded: true,
		installationFolded: true,
	};
}

function getNewStateFromStep(currentStep: Steps, state: ILayoutState): ILayoutState {
	const newState = {
		...state,
		...closeAllDrawers(),
		configurationFolded: true,
		optionsFolded: true,
		installationFolded: true,
		summaryIsShown: true,
		currentStep,
	};

	if (state.currentStep === Steps.PROJECT_NAME
		&& state.summaryFolded
		&& !state.isMobile
		&& currentStep > Steps.PROJECT_NAME
	) {
		newState.summaryFolded = false;
	}

	if (currentStep >= Bounds.installationLowerBound) {
		newState.installationFolded = false;
	} else if (currentStep < Bounds.optionsLowerBound) {
		newState.configurationFolded = false;
	} else {
		newState.optionsFolded = false;
	}

	return newState;
}

function LayoutReducer(state: ILayoutState, action: IActionWithPayload<any> & IActionWithoutPayload) {
	switch (action.type) {
		case LayoutActionsNames.SET_CURRENT_STEP:
			return getNewStateFromStep(
				action.payload,
				state,
			);

		case LayoutActionsNames.TOGGLE_SUMMARY:
			return {
				...state,
				summaryFolded: !state.summaryFolded,
			};

		case LayoutActionsNames.TOGGLE_MENU:
			return {
				...state,
				menuVisible: !state.menuVisible,
			};

		case LayoutActionsNames.TOGGLE_CONFIGURATION:
			return {
				...state,
				...closeAllDrawers(),
				...foldAllTabs(),
				configurationFolded: false,
			};

		case LayoutActionsNames.TOGGLE_CONFIGURATION_DRAWER:
			return {
				...state,
				...closeAllDrawers(),
				configurationDrawerVisible: !state.configurationDrawerVisible,
			};

		case LayoutActionsNames.TOGGLE_OPTIONS:
			return {
				...state,
				...closeAllDrawers(),
				...foldAllTabs(),
				optionsFolded: false,
			};

		case LayoutActionsNames.TOGGLE_OPTIONS_DRAWER:
			return {
				...state,
				...closeAllDrawers(),
				optionsDrawerVisible: !state.optionsDrawerVisible,
			};

		case LayoutActionsNames.TOGGLE_INSTALLATION:
			return {
				...state,
				...closeAllDrawers(),
				...foldAllTabs(),
				installationFolded: false,
			};

		case LayoutActionsNames.TOGGLE_INSTALLATION_DRAWER:
			return {
				...state,
				...closeAllDrawers(),
				installationDrawerVisible: !state.installationDrawerVisible,
			};

		case LayoutActionsNames.SET_MOBILE:
			return {
				...state,
				isMobile: action.payload,
			};

		case LayoutActionsNames.SET_TABLET:
			return {
				...state,
				isTablet: action.payload,
			};

		case LayoutActionsNames.SET_DESKTOP:
			return {
				...state,
				isDesktop: action.payload,
			};

		case LayoutActionsNames.SET_LOADING:
			return {
				...state,
				isLoading: action.payload,
				shouldAnimate: !action.payload,
			};

		case LayoutActionsNames.SET_MODAL_IS_SHOWN:
			return {
				...state,
				modalIsShown: action.payload,
			};

		case LayoutActionsNames.SET_PRICE_DETAILS_IS_SHOWN:
			return {
				...state,
				priceDetailsVisible: action.payload,
			};

		case LayoutActionsNames.SET_ERROR_MODAL_IS_SHOWN:
			return {
				...state,
				errorModalIsShown: action.payload,
			};

		case LayoutActionsNames.SET_IS_INSTALLATION_PLACE_FORM_VALID:
			return {
				...state,
				isInstallationPlaceFormValid: action.payload,
			};

		case LayoutActionsNames.SET_IS_QUOTE_FORM_VALID:
			return {
				...state,
				isQuoteFormValid: action.payload,
			};

		case LayoutActionsNames.SET_ROUTES:
			return {
				...state,
				routes: action.payload,
			};

		case LayoutActionsNames.SET_IS_DRAWING_MODAL_FULL_SCREEN:
			return {
				...state,
				isDrawingModalFullScreen: action.payload,
			};

		case ProjectActionsNames.RESTORE_PROJECT:
			return {
				...state,
				latestCompletedStep: action.payload.completionState,
			};

		case ProjectActionsNames.RESET_PROJECT:
			return DEFAULT_STATE.layout;

		case ProjectActionsNames.RESTORE_PROJECT_REQUIREMENTS:
			return {
				...state,
				requirements: {
					shouldSkipCondenserDistanceStep: action.payload.shouldSkipCondenserDistanceStep,
					shouldSkipComboOptionsStep: action.payload.shouldSkipIntellirefStep,
					shouldSkipFloorMaterial: action.payload.shouldSkipFloorMaterial,
					shouldSkipTransport: action.payload.shouldSkipTransport,
					optionI3Mandatory: action.payload.optionI3Mandatory,
					seismicKitMandatory: action.payload.seismicKitMandatory,
					mandatoryCoolingSystemOption: action.payload.mandatoryCoolingSystemOption,
					transportPriceAuto: action.payload.transportPriceAuto,
					installPriceAuto: action.payload.installPriceAuto,
					hasFloor: action.payload.hasFloor,
					roomsCoolingSystemCompleted: action.payload.roomsCoolingSystemCompleted,
				},
			};

		default:
			return state;
	}
}

export default LayoutReducer;
