import { IStoreState, Roles, State, Steps } from '@models';

export const DEFAULT_STATE: IStoreState = {
	layout: {
		isMobile: window.innerWidth <= 1023,
		isTablet: window.innerWidth > 1023 && window.innerWidth <= 1279,
		isDesktop: window.innerWidth > 1279,
		summaryFolded: true,
		summaryIsShown: true,
		configurationFolded: false,
		configurationDrawerVisible: false,
		optionsFolded: true,
		optionsDrawerVisible: false,
		installationFolded: true,
		installationDrawerVisible: false,
		priceDetailsVisible: false,
		menuVisible: false,
		shouldAnimate: false,
		isLoading: true,
		modalIsShown: false,
		errorModalIsShown: false,
		isInstallationPlaceFormValid: false,
		isQuoteFormValid: false,
		isDrawingModalFullScreen: false,
		currentStep: Steps.PROJECT_NAME,
		latestCompletedStep: 0,
		routes: undefined,
		requirements: {
			hasFloor: false,
			installPriceAuto: false,
			transportPriceAuto: false,
			shouldSkipCondenserDistanceStep: false,
			shouldSkipComboOptionsStep: false,
			shouldSkipFloorMaterial: false,
			shouldSkipTransport: false,
			optionI3Mandatory: false,
			seismicKitMandatory: false,
			mandatoryCoolingSystemOption: undefined,
			roomsCoolingSystemCompleted: [[null, null, null, null]],
		},
	},
	project: {
		id: undefined,
		hasSeismicKit: false,
		installationDate: null,
		markupPc: null,
		currencyCode: '',
		langId: 'fr',
		state: State.Enabled,
		status: 0,
		name: '',
		ref: '',
		salesrepName: '',
		salesrepEmail: '',
		epicorQuoteNum: 0,
		epicorQuoterId: '',
		quoterEmail: '',
		quoterName: '',
		installValidated: undefined,
		installPriceOverride: undefined,
		installByNorbec: true,
		transportValidated: undefined,
		transportPriceOverride: undefined,
		transportType: null,
		contact: {
			cellPhoneNum: '',
			email: '',
			epicorConNum: '',
			epicorCustId: '',
			faxNum: '',
			id: 0,
			name: '',
			phoneExt: '',
			phoneNum: '',
		},
		customer: {
			address: '', // TODO: no more use in FE, wait to know if will be remove from BE, if so remove this line
			city: '',
			country: '',
			email: '',
			epicorCustId: '',
			faxNum: '',
			id: 0,
			markup: 0,
			name: '',
			phoneNum: '',
			salesrepCode: '',
			state: '',
			street: '',
			street2: '',
			terms: '',
			zipCode: '',
		},
	},
	group: {
		rooms: [],
		roomNumber: 0,
	},
	settings: {
		orderDelay: 15,
		minRoomNumber: 1,
		maxRoomNumber: 4,
		commonWallWidth: 4,
		defaultHeight: 120,
		defaultWidth: 120,
		defaultDepth: 120,
		widthIncrement: 4,
		minWidth: 60,
		maxWidth: 352,
		depthIncrement: 4,
		minDepth: 64,
		maxDepth: 160,
		heights: [
			90,
			102,
		],
		condenser: {
			defaultDistance: 20,
			minDistance: 15,
			maxDistance: 900,
			incrementDistance: 1,
			condenserDistanceImageUrl: '',
		},
		doors: {
			maxPerWall: 1,
			defaultWidth: 36,
			defaultWall: 1,
			defaultOpeningDirection: 'RIGHT',
			panelWidth: 8,
			doorWidths: [32, 36],
			positionIncrement: 2,
			positionMin: 1,
			doorFrameWidth: 46,
		},
		seismicKit: {
			name: '',
			description: '',
			technicalSheetUrl: '',
		},
		transportTypesTechnicalSheetUrl: undefined,
		serviceOfferInfoSheetUrl: undefined,
	},
	user: {
		name: '',
		role: Roles.client,
	},
    data: {
        customers: [],
        optionsCustomers: [],
    },
};
