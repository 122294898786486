import React from 'react';

interface IZoomOutIconProps {
	stroke: string;
	opacity?: string;
}

export default function ZoomOutIcon(props: IZoomOutIconProps) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" opacity={props.opacity || '1'}>
			<g stroke={props.stroke} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" transform="translate(1 1)">
				<circle cx="6.133" cy="6.133" r="6.133"/>
				<path d="M4.133 6.133h4M10.47 10.47l4.863 4.863"/>
			</g>
		</svg>
	);
}
