import axios from 'axios';
import { getSessionToken, removeSessionToken } from './token';

const axiosInstance = axios.create({
	baseURL: `${ENV.apiURL}/api/v1`,
	headers: {
		'Content-Type': 'application/json',
		'Accept-Language': location.pathname.substr(1, 2),
	},
});

axiosInstance.interceptors.request.use((config) => {
	const token = getSessionToken();
	if (!config.headers.Authorization) {
		config.headers.Authorization =  token ? `Bearer ${token}` : undefined;
	}
	return config;
});

axiosInstance.interceptors.response.use((response) => {
		return response;
	},
	async (error) => {
		if (error.response) {
			switch (error.response.status) {
				case 401:
					removeSessionToken();
					window.location.replace('/');
				case 403:
					// window.location.replace('');
			}

		}
		throw error.response;
});

export default axiosInstance;
