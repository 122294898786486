import CheckedCheckboxMvp from '@components/svg/CheckedCheckboxMvp';
import UncheckedCheckboxMvp from '@components/svg/UncheckedCheckboxMvp';
import { State } from '@models';
import React, { useEffect, useState } from 'react';

import S from './styles/Checkbox.styl';

interface ICheckboxMvpProps {
	title: string;
	state: State;
	onChange?: (isChecked: boolean) => void;
	linkUrl?: string;
	linkLabel?: string;
	linkTarget: string;
}

export default function CheckboxMvp(props: ICheckboxMvpProps) {
	const [checkboxMvpState, setCheckboxMvpState] = useState(props.state);

	useEffect(() => {
		setCheckboxMvpState(props.state);
	}, [props.state]);

	function onClick() {
		let nextState = State.Enabled;

		if (checkboxMvpState === State.Enabled) {
			nextState = State.Selected;
		}

		if (props.onChange) {
			nextState === State.Enabled ?
				props.onChange(false) :
				props.onChange(true);
		}
	}

	function checkBoxMvpLabel() {
		return (
			<div className={S.checkboxContainerTitle}>
				<span className={S.checkboxContainerTitleRight}>
					{props.title}
				</span>
				{props.linkLabel && props.linkUrl && (

					<a
						className="mvp-link"
						href={props.linkUrl}
						target={props.linkTarget}
						onClick={(e) => e.stopPropagation()}
					>
						{props.linkLabel}
					</a>
				)}
			</div>
		);
	}

	return (
		<div className={S.checkboxWrapperMvp} onClick={onClick}>
			<label className={S.checkboxContainer}>
				{checkboxMvpState === State.Selected && (
					<CheckedCheckboxMvp
						fill={S.blueNorbec}
					/>
				)}
				{checkboxMvpState === State.Enabled && (
					<UncheckedCheckboxMvp
						fill={S.darkBlueGray}
					/>
				)}
				{checkBoxMvpLabel()}
			</label>
		</div>
	);
}

CheckboxMvp.defaultProps = {
	linkTarget: '_blank',
};
