import {
	IActionWithoutPayload,
	IActionWithPayload,
	IProjectState,
	State,
} from '@models';
import { DEFAULT_STATE } from '@store/default';
import { ProjectActionsNames } from '../actions';

function ProjectReducer(state: IProjectState, action: IActionWithPayload<any> & IActionWithoutPayload) {
	switch (action.type) {
		case ProjectActionsNames.SET_PROJECT_STATE:
			return {
				...state,
				state: action.payload,
			};
		case ProjectActionsNames.SET_PROJECT_NAME:
			return {
				...state,
				name: action.payload,
			};

		case ProjectActionsNames.SET_PROJECT_REF_NUMBER:
			return {
				...state,
				ref: action.payload,
			};

		case ProjectActionsNames.SET_PROJECT_CONTACT:
			return {
				...state,
				contact: {
					...state.contact,
					cellPhoneNum: action.payload.cellPhoneNum,
					email: action.payload.email,
					epicorConNum: action.payload.epicorConNum,
					epicorCustId: action.payload.epicorCustId,
					faxNum: action.payload.faxNum,
					id: action.payload.id,
					name: action.payload.name,
					phoneExt: action.payload.phoneExt,
					phoneNum: action.payload.phoneNum,
				},
			};

		case ProjectActionsNames.SET_PROJECT_MARKUP:
			return {
				...state,
				markupPc: action.payload,
			};

		case ProjectActionsNames.SET_PROJECT_LANG:
			return {
				...state,
				langId: action.payload,
			};

		case ProjectActionsNames.SET_PROJECT_EPICOR_QUOTE_NUM:
			return {
				...state,
				epicorQuoteNum: action.payload,
			};

		case ProjectActionsNames.SET_PROJECT_QUOTER:
			return {
				...state,
				epicorQuoterId: action.payload.id,
				quoterEmail: action.payload.email,
				quoterName: action.payload.name,
			};

		case ProjectActionsNames.SET_HAS_SEISMIC_KIT:
			return {
				...state,
				hasSeismicKit: action.payload,
			};

		case ProjectActionsNames.SET_IS_INSTALLATION_BY_NORBEC:
			return {
				...state,
				installByNorbec: action.payload,
			};

		case ProjectActionsNames.SET_TRANSPORT_PRICE_OVERRIDE:
			return {
				...state,
				transportPriceOverride: action.payload,
			};

		case ProjectActionsNames.SET_TRANSPORT_PRICE_VALIDATED:
			return {
				...state,
				transportValidated: action.payload,
			};

		case ProjectActionsNames.SET_INSTALL_PRICE_OVERRIDE:
			return {
				...state,
				installPriceOverride: action.payload,
			};

		case ProjectActionsNames.SET_INSTALL_PRICE_VALIDATED:
			return {
				...state,
				installValidated: action.payload,
			};

		case ProjectActionsNames.SET_TRANSPORT_TYPE:
			return {
				...state,
				transportType: {
					id: action.payload,
				},
			};

		case ProjectActionsNames.SET_INSTALLATION_DATE:
			return {
				...state,
				installationDate: action.payload,
			};

		case ProjectActionsNames.SET_INSTALLATION_LOCATION:
			return {
				...state,
				installationLocation: action.payload,
			};

		case ProjectActionsNames.RESTORE_PROJECT:
			const { shippingAddress } = action.payload;

			return {
				...state,
				name: action.payload.name,
				ref: action.payload.refNumber,
				hasSeismicKit: action.payload.needSeismicKit,
				installationDate: action.payload.requestDate ? new Date(action.payload.requestDate) : null,
				installValidated: action.payload.installValidated,
				installPriceOverride: action.payload.installPriceOverride,
				installByNorbec: action.payload.installByNorbec,
				transportValidated: action.payload.transportValidated,
				transportPriceOverride: action.payload.transportPriceOverride,
				transportType: action.payload.transportType,
				id: action.payload.id,
				state: State.Enabled,
				status: action.payload.status,
				currencyCode: action.payload.currencyCode,
				langId: action.payload.langId,
				installationLocation: {
					street: shippingAddress.street,
					street2: shippingAddress.street2,
					city: shippingAddress.city,
					cityState: shippingAddress.state,
					country: shippingAddress.country,
					zipCode: shippingAddress.zipCode,
					contact: shippingAddress.contact,
					email: shippingAddress.email,
					phoneNum: shippingAddress.phoneNum,
					phoneExt: shippingAddress.phoneExt,
				},
				markupPc: String(action.payload.markupPc),
				customer: action.payload.customer,
				contact: action.payload.contact,
				salesrepEmail: action.payload.salesrepEmail,
				salesrepName: action.payload.salesrepName,
				epicorQuoterId: action.payload.epicorQuoterId,
				epicorQuoteNum: action.payload.epicorQuoteNum,
				quoterEmail: action.payload.quoterEmail,
				quoterName: action.payload.quoterName,
				dateExpiry: action.payload.dateExpiry ? new Date(action.payload.dateExpiry) : null,
			};

		case ProjectActionsNames.RESET_PROJECT:
			return DEFAULT_STATE.project;

		default:
			return state;
	}
}

export default ProjectReducer;
