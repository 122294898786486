import { IActionWithoutPayload, IActionWithPayload, IUserState } from '@models';
import { UserActionsNames } from '@store/actions';

function UserReducer(
	state: IUserState,
	action: IActionWithPayload<any> & IActionWithoutPayload,
) {
	if (action.type === UserActionsNames.SET_USER) {
		return {
			name: action.payload.name,
			token: action.payload.token,
			role: action.payload.role,
		};
	} else {
		return state;
	}
}

export default UserReducer;
