import {
	fromCoolingSystemDTO,
	fromCoolingSystemOptionsDTO,
	fromDoorDTO,
	fromFloorMaterialDTO,
	fromFloorTextureDTO,
	fromLightOptionDTO,
	fromMotionDetectorOptionDTO,
	fromRoomRequirementsDTO,
	fromRoomTypeDTO,
	IActionWithoutPayload,
	IActionWithPayload,
	IGroupState,
	IProject,
	IRoomState,
	State,
	TCode,
} from '@models';
import { DEFAULT_STATE } from '@store/default';
import { GroupActionsNames, ProjectActionsNames } from '../actions';

function getUpdatedGroup(state: IGroupState, payload: IProject): IGroupState {
	if (!payload.groups || (payload.groups && payload.groups.length === 0)) {
		return {
			roomNumber: 0,
			rooms: [],
		};
	}
	const group = payload.groups[0];
	const rooms: IRoomState[] = group.rooms.map((room) => {
		return {
			id: room.id,
			x: 0,
			y: 0,
			width: room.width,
			height: room.height,
			depth: room.depth,
			code: room.name as TCode,
			hasSelectedNoCoolingSystem: room.hasSelectedNoCoolingSystem,
			hasDefaultWidth: room.hasDefaultWidth,
			hasDefaultDepth: room.hasDefaultDepth,
			hasDefaultHeight: room.hasDefaultHeight,
			hasDefaultCondenserDistance: room.hasDefaultCondenserDistance,
			borderLength: room.dividerWallSize,
			condenserDistance: room.coolingDistance,
			state: State.Enabled,
			type: room.roomType ? fromRoomTypeDTO(room.roomType) : undefined,
			requirements: room.requirements ? fromRoomRequirementsDTO(room.requirements) : undefined,
			coolingSystem: room.coolingType ? fromCoolingSystemDTO(room.coolingType) : undefined,
			floorMaterial: room.floorMaterial ? fromFloorMaterialDTO(room.floorMaterial) : undefined,
			door: room.doors.length > 0 ? fromDoorDTO(room.name as TCode, room.doors[0]) : undefined,
			coolingSystemOptions: fromCoolingSystemOptionsDTO(room.intellirefStacked),
			floorTexture: room.floorTexture ? fromFloorTextureDTO(room.floorTexture) : undefined,
			lightOption: room.lightType ? fromLightOptionDTO(room.lightType) : undefined,
			motionDetector: room.motionDetector ? fromMotionDetectorOptionDTO([room.motionDetector]) : undefined,
		};
	})
	.sort((roomA, roomB) => {
		return roomA.code.localeCompare(roomB.code);
	});
	return {
		roomNumber: rooms.length,
		rooms,
	};
}

function GroupReducer(state: IGroupState, action: IActionWithPayload<any> & IActionWithoutPayload) {
	switch (action.type) {
		case GroupActionsNames.SET_ROOMS:
			return {
				...state,
				rooms: action.payload,
			};
		case GroupActionsNames.SET_ROOM_NUMBER:
			return {
				...state,
				roomNumber: action.payload,
			};

		case GroupActionsNames.SELECT_FOCUSED_ROOMS:
			return {
				...state,
				rooms: state.rooms.map((room) => {
					if (room.state === State.Focused) {
						room.state = State.Selected;
					}
					return room;
				}),
			};

		case GroupActionsNames.FOCUS_ROOM:
			return {
				...state,
				rooms: state.rooms.map((room) => {
					if (room.code === action.payload) {
						room.state = State.Focused;
					}
					return room;
				}),
			};

		case GroupActionsNames.FOCUS_ROOMS:
			return {
				...state,
				rooms: state.rooms.map((room) => {
					room.state = State.Focused;
					return room;
				}),
			};

		case GroupActionsNames.SELECT_ROOM:
			return {
				...state,
				rooms: state.rooms.map((room) => {
					if (room.code === action.payload) {
						room.state = State.Selected;
					}
					return room;
				}),
			};

		case GroupActionsNames.SELECT_ROOMS:
			return {
				...state,
				rooms: state.rooms.map((room) => {
					room.state = State.Selected;
					return room;
				}),
			};

		case GroupActionsNames.SELECT_ROOMS_WITH_FLOOR:
			return {
				...state,
				rooms: state.rooms.map((room) => {
					if (room.type?.hasFloor) {
						room.state = State.Selected;
					} else {
						room.state = State.Disabled;
					}
					return room;
				}),
			};

		case GroupActionsNames.FOCUS_ROOMS_WITH_FLOOR:
			return {
				...state,
				rooms: state.rooms.map((room) => {
					if (room.type?.hasFloor) {
						room.state = State.Focused;
					}
					return room;
				}),
			};

		case GroupActionsNames.DISABLE_ROOMS:
			return {
				...state,
				rooms: state.rooms.map((room) => {
					room.state = State.Disabled;
					return room;
				}),
			};

		case GroupActionsNames.ENABLE_ROOMS:
			return {
				...state,
				rooms: state.rooms.map((room) => {
					room.state = State.Enabled;
					return room;
				}),
			};

		case GroupActionsNames.SET_ROOMS_TO_DONE:
			return {
				...state,
				rooms: state.rooms.map((room) => {
					room.state = State.Done;
					return room;
				}),
			};

		case GroupActionsNames.SET_ROOM_TYPE:
			return {
				...state,
				rooms: state.rooms.map((room) => {
					if (room.code === action.payload.code) {
						room.type = action.payload.type;
					}
					return room;
				}),
			};

		case GroupActionsNames.RESET_ROOMS_STATE:
			return {
				...state,
				rooms: state.rooms.map((room) => {
					room.state = State.Enabled;
					return room;
				}),
			};

		case ProjectActionsNames.RESTORE_PROJECT:
			return getUpdatedGroup(state, action.payload);

		case ProjectActionsNames.RESET_PROJECT:
			return DEFAULT_STATE.group;

		default:
			return state;
	}
}

export default GroupReducer;
