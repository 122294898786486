import axiosInstance from './axiosInstance';
import { IPutNewsletter } from './interface';

const GET_PERMISSIONS = (email: string) => `/user/permissions/${email}`;
const PUT_NEWSLETTER = `/user/newsletter`;

export async function getPermissions(email: string): Promise<any> {
	return await axiosInstance.get(GET_PERMISSIONS(email));
}

export async function putNewsletter(body: IPutNewsletter): Promise<any> {
	return await axiosInstance.put(PUT_NEWSLETTER, body);
}
