import { ETransportType, ITransportType } from '@models';

export const installShouldBeShown = (extension: number | null, installValidated?: boolean, installByNorbec?: boolean, installPriceOverride?: string) => {
	if (!installByNorbec) {
		return true;
	}

	if (installValidated === false) {
		return false;
	}

	if (extension || installPriceOverride !== null) {
		return true;
	}

	return false;
};

export const transportShouldBeShown = (extension: number | null, transportType: ITransportType | null, transportValidated?: boolean, transportPriceOverride?: string) => {
	if (transportType && transportType?.id === ETransportType.PICK_UP) {
		return true;
	}

	if (transportValidated === false) {
		return false;
	}

	if (extension || transportPriceOverride !== null) {
		return true;
	}

	return false;
};
