import { getCurrentLang, ILocaleData } from '@i18n';
import { ELLIPSIS, MAX_CHAR_COUNT_ELLIPSIS } from '@utils/constants';
import { format } from 'date-fns';

export default class FormatUtils {
	public static formatInches(value: number): string {
		const inches = Math.floor(value % 12);
		const feets = Math.floor(value / 12);
		return `${feets !== 0 ? `${feets}'` : ''}${inches !== 0 ? `${inches}"` : ''}`;
	}

	public static formatPrice(value: number): string {
		const lang = getCurrentLang();
		if (value.toString().indexOf('.') !== -1) {
			return this.formatDecimalPrice(value, lang);
		}
		return lang === 'fr' ? `${value} $` : `$${value}`;
	}

	public static formatDecimalPrice(
		value: number,
		lang?: string,
		unit: string = '$',
		thousandsSep: boolean = true,
	): string {
		const currentLang = !!lang ? lang : getCurrentLang();
		const newPrice = value.toFixed(2);
		const partsNumber = newPrice.toString().split('.');
		if (thousandsSep) {
			partsNumber[0] = partsNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
		}
		if (currentLang === 'en') {
			return `${unit}${partsNumber.join('.')}`;
		}
		return `${partsNumber.join(',')} ${unit}`;
	}

	public static formatLocaleVariable(locale: ILocaleData, text: string): string {
		const keys = text.match(/[^[\]]+(?=])/g);
		if (keys) {
			for ( const key of keys) {
				text = text.split(`[${key}]`).join(locale[key]);
			}
		}
		return text;
	}

	// From: https://stackoverflow.com/a/34842797/3517808
	public static toHash(str: string) {
		return str.split('').reduce((prevHash: any, currVal: any) => (
			// tslint:disable-next-line: no-bitwise
			((prevHash << 5) - prevHash) + currVal.charCodeAt(0)
		) | 0, 0);
	}

	public static truncateString(str: string, breakWord: boolean = false, max?: number) {
		const maxLength = (!!max ? max : MAX_CHAR_COUNT_ELLIPSIS) - ELLIPSIS.length;
		if (str.length <= maxLength) {
			return str;
		}
		const newString = str.substring(0, maxLength);
		return `${(breakWord ? newString : newString.substring(0, newString.lastIndexOf(' ')))}${ELLIPSIS}`;
	}
}

export const formatTimestamp = (date: string, dateFormat: string = 'yyyy-MM-dd, HH:mm') => date ? format(Date.parse(date), dateFormat) : '';
