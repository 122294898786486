import classnames from 'classnames';
import React from 'react';

import S from './styles/PriceContentSection.styl';

interface IPriceContentSectionProps {
	key: string;
	mainText?: string;
	price?: string;
	subText?: string;
	linkUrl?: string;
	linkText?: string;
	textColor: string;
	backgroundColor: string;
	fontSize?: string;
	sectionType?: string;
	alertException?: string;
}

export default function PriceContentSection(props: IPriceContentSectionProps) {

	const sectionStyle = {
		backgroundColor: props.backgroundColor,
		color: props.textColor,
	};

	const mainTextStyle = {
		fontSize: props.fontSize,
	};

	let hyperLink = null;
	if (props.linkText && props.linkUrl) {
		hyperLink = (
			<a
				className={S.priceContentSectionLink}
				href={props.linkUrl}
				target="_blank"
			>
				{props.linkText}
			</a>
		);
	}

	let content;
	if (props.sectionType === 'textSection') {
		content = (
			<div>
				<div
					className={S.priceContentSectionMainText}
					style={mainTextStyle}
				>
					{props.mainText}
				</div>
				<p>
					{props.subText && (
						<span className={S.priceContentSectionSubtext}>
							{props.subText}
						</span>
					)}

					{hyperLink && (
						<span className={S.priceContentSectionLinkContainer}>
							&nbsp;
							{hyperLink}
						</span>
					)}
				</p>
			</div>
		);
	} else {
		content = (
			<>
				<div className={S.priceContentSectionLeft}>
					<div
						className={S.priceContentSectionMainText}
						style={mainTextStyle}
					>
						{props.mainText}
					</div>

					{props.subText && (
						<div className={S.priceContentSectionSubtext}>
							{props.subText}
						</div>
					)}

					{hyperLink && (
						<div className={S.priceContentSectionLinkContainer}>
							{hyperLink}
						</div>
					)}

				</div>
				<div className={S.priceContentSectionRight}>
					{props.price}

					{props.alertException && (
						<span>*</span>
					)}
				</div>

				{props.alertException && (

					<div className={S.priceContentSectionAlertException}>
						<div className={S.priceContentSectionAlertExceptionLine} />
						<div className={S.priceContentSectionAlertExceptionContainer}>
							<span className={S.priceContentSectionAlertExceptionContainerSpan}>
								*
							</span>
							<span>
								{props.alertException}
							</span>
						</div>
					</div>
				)}
			</>
		);
	}

	return (
		<div
			className={classnames(S.priceContentSection, {
				[S.priceContentSectionTextSection]: props.sectionType === 'textSection',
				[S.priceContentSectionPriceSection]: props.sectionType === 'priceSection',
				[S.priceContentSectionTotalSection]: props.sectionType === 'totalSection',
			})}
			style={sectionStyle}
		>
			{content}
		</div>
	);
}

PriceContentSection.defaultProps = {
	sectionType: 'priceSection',
	fontSize: '15px',
};
