import HyperLink from '@components/buttons/HyperLink';
import PricePerUnitLabel from '@components/labels/PricePerUnitLabel';
import SmallTagLabel from '@components/labels/SmallTagLabel';
import LargeRadioButton from '@components/svg/LargeRadioButton';
import { useLocale } from '@i18n';
import { State } from '@models';
import classnames from 'classnames';
import React from 'react';

import S from './styles/ComplexLargeRadio.styl';

interface IComplexLargeRadio {
	imageUrl: string;
	title: string;
	price?: string;
	units?: string;
	description: string;
	isIncluded?: boolean;
	technicalSheetUrl?: string;
	technicalSheetLabel?: string;
	onClick?: (checked: boolean) => void;
	state: State;
}

export default function ComplexLargeRadio(props: IComplexLargeRadio) {
	const T = useLocale();

	function onClick() {
		if (props.onClick) {
			props.onClick(props.state === State.Enabled);
		}
	}

	return (
		<div
			className={classnames(S.complexLargeRadioContainer, {
				[S.complexLargeRadioContainerFocused]: props.state === State.Focused,
				[S.complexLargeRadioContainerSelected]: props.state === State.Selected,
			})}
			onClick={onClick}
		>
			{props.imageUrl && (
				<div className={S.complexLargeRadioImageContainer} style={{ backgroundImage: `url(${props.imageUrl})` }} />
			)}
			<div className={S.complexLargeRadioContent}>
				<div className={S.complexLargeRadioContentHeader}>
					<div className={S.complexLargeRadioContentHeaderText}>
						<h2
							className={classnames(S.complexLargeRadioContentHeaderTextTitle, {
								[S.complexLargeRadioContentHeaderTextTitleFocused]: props.state === State.Focused,
								[S.complexLargeRadioContentHeaderTextTitleSelected]: props.state === State.Selected,
							})}
						>
							{props.title}
						</h2>
						{props.isIncluded ? (
							<SmallTagLabel text={T.GeneralIncluded}/>
						) : (
							<div style={{ height: '18px' }} />
						)}
						{!props.isIncluded && props.units && props.price &&
							(
								<PricePerUnitLabel
									price={props.price}
									units={props.units}
									state={props.state}
								/>
							)
						}
					</div>
					{props.state === State.Enabled &&
						<LargeRadioButton stroke={'#9FBBC4'} fill={'#FFF'}/>
					}
					{props.state === State.Focused &&
						<LargeRadioButton stroke={'#F49800'} fill={'#F49800'}/>
					}
					{props.state === State.Selected &&
						<LargeRadioButton stroke={'#0095C8'} fill={'#0095C8'}/>
					}
				</div>

				<p>{props.description}</p>

				{props.technicalSheetUrl && props.technicalSheetLabel && (
					<div className={S.complexLargeRadioTechnicalSheet}>
						<HyperLink title={props.technicalSheetLabel} link={props.technicalSheetUrl}/>
					</div>
				)}
			</div>
		</div>
	);
}
