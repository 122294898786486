import React from 'react';

export default function CancelIcon({ color }: {color?: string}) {
	return (
		<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
			<desc>Created with sketchtool.</desc>
			<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g id="ico-check-copy-3">
					<circle id="Oval" fill={color ? color : '#D20000'} cx="12" cy="12" r="11"/>
					<g id="close" transform="translate(7.000000, 7.000000)" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round">
						<line x1="0.208333333" y1="0.207916667" x2="9.79166667" y2="9.79125" id="Path"/>
						<line x1="9.79166667" y1="0.207916667" x2="0.208333333" y2="9.79125" id="Path"/>
					</g>
				</g>
			</g>
		</svg>
	);
}
