import LoadingElement from '@components/layout/LoadingElement';
import { useLocale } from '@i18n';
import { TableFooter } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import React, { ReactText, useEffect, useState } from 'react';
import S from './styles/SimpleTable.styl';

export interface ITableColumn {
	key: string;
	headerLabel: string;
	render?: (t: any) => ReactText;
	sumValue?: boolean;
	unit?: string;
	cellSize?: 'small' | 'medium';
	width?: string;
}

interface ISimpleTableProps {
	dataFetcher: (...args: any) => Promise<any>;
	dataFetchParam?: any;
	columns: ITableColumn[];
	showTotal?: boolean;
	cellAlign: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
});

export function SimpleTable(props: ISimpleTableProps) {
	const classes = useStyles();
	const T = useLocale();
	const [data, setData] = useState<any[]>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		fetchOptions(props.dataFetchParam).then();
	}, []);

	async function fetchOptions(param?: any) {
		if (props.dataFetcher) {
			setIsLoading(true);
			return props.dataFetcher(param ? param : null)
				.then((response) => {
					setData(response ? response : []);
					setIsLoading(false);
				}).catch(() => {
					setData([]);
					setIsLoading(false);
				});
		}
		return Promise.resolve();
	}

	const sumProperty = (arr: any[], type: any, renderFunction?: (t: any) => ReactText) => {
		return arr.reduce((total, obj) => {
			if (typeof obj[type] === 'string') {
				return total + (renderFunction ? renderFunction(Number(obj[type])) : Number(obj[type]));
			}
			return total + (renderFunction ? renderFunction(obj[type]) : obj[type]);
		}, 0);
	};

	const buildHeaderCells = () => {
		return props.columns.map((column, index) => {
			return (
				<TableCell
					key={`header${column.key}`}
					align={props.cellAlign}
					size={column.cellSize ? column.cellSize : 'medium'}
					style={column.width ? { width: column.width } : undefined}
				>
					{column.headerLabel}
				</TableCell>
			);
		});
	};

	const buildRows = () => {
		if (data) {
			return data.map((column, index) => {
				return (
					<TableRow key={`row${index}`}>
						{buildRowCells(index)}
					</TableRow>
				);
			});
		}
	};

	const buildRowCells = (dataIndex: number) => {
		if (data) {
			return props.columns.map((column, columnIndex) => {
				const renderValue = column.render ? column.render(data[dataIndex][column.key]) : data[dataIndex][column.key];
				return (
					<TableCell
						key={`row${dataIndex}cell${columnIndex}`}
						align={props.cellAlign}
					>
						{renderValue}
					</TableCell>
				);
			});
		}
	};

	const buildFooterCells = () => {
		if (props.showTotal && data) {
			return props.columns.map((column, index) => {
				if (index === 0 && !column.sumValue) {
					return (
						<TableCell
							key={`footerCell${index}`}
							align={props.cellAlign}
						>
							{T.PdfOptionsTotal}
						</TableCell>
					);
				} else {
					if (column.sumValue) {
						const renderValue = column.render ? column.render(sumProperty(data, column.key, column.render)) : sumProperty(data, column.key);
						return (
							<TableCell
								key={`footerCell${index}`}
								align={props.cellAlign}
							>
								{`${renderValue} ${column.unit ? column.unit : ''} `}
							</TableCell>
						);
					} else {
						return <TableCell key={`footerCell${index}`} align={props.cellAlign} />;
					}
				}
			});
		}
	};

	return (
		<div
			className={classNames(S.simpleTableContainer, {
				[S.simpleTableContainerHasFooter]: props.showTotal,
			})}
		>
			{ isLoading && (
				<LoadingElement />
			)}

			{ !isLoading && data && data.length === 0 && (
				<div>
					{T.TabTransportInstallationSectionTableNoData}
				</div>
			)}

			{ !isLoading && data && data.length > 0 && (
				<TableContainer component={Paper}>
					<Table className={classes.table} size="small" aria-label="information table">
						<TableHead>
							<TableRow>
								{buildHeaderCells()}
							</TableRow>
						</TableHead>
						<TableBody>
							{buildRows()}
						</TableBody>

						{!!props.showTotal && (
							<TableFooter>
								<TableRow>
									{buildFooterCells()}
								</TableRow>
							</TableFooter>
						)}
					</Table>
				</TableContainer>
			)}
		</div>
	);
}

SimpleTable.defaultProps = {
	cellAlign: 'left',
};
