import { CalendarIcon } from '@components/svg';
import { getCurrentLang } from '@i18n';
import classnames from 'classnames';
import datePickerLocaleFr from 'date-fns/locale/fr-CA';
import React, { useRef, useState } from 'react';
import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';

import GS from '../../styles/global.styl';
import S from './styles/DatePicker.styl';
registerLocale('datePickerLocaleFr', datePickerLocaleFr);

// tslint:disable-next-line: no-var-requires
const { CalendarContainer } = require('react-datepicker'); // Import this way because CalendarContainer type definition is incorrect

enum InputState {
	Selected,
	Unselected,
}

interface IDatePickerProps extends ReactDatePickerProps {
	className: string;
}

function getCalendarIconColor(inputState: InputState, date?: Date | null): string {
	if (inputState === InputState.Selected) {
		if (!date) {
			return GS.orange;
		}

		return GS.blueNorbec;
	}

	return GS.darkBlueGray;
}

export default function DatePicker(props: IDatePickerProps) {
	const [inputState, setInputState] = useState(InputState.Unselected);
	const inputRef = useRef(null);
	const { selected, onChange } = props;
	const containerRef = useRef<HTMLDivElement>(null);

	function getDayClassName(d: Date) {
		return classnames(S.calendarDay, {
			[S.calendarDaySelected]: d.getDate() === selected?.getDate() && d.getMonth() === selected?.getMonth() && d.getFullYear() === selected.getFullYear(),
		});
	}

	const StyledInput = ({ value, onClick, placeholder }: any) => (
		<div className={S.calendarInputContainer}>
			<input
				type="text"
				className={classnames(S.calendarInput, {
					[S.calendarInputSelected]: inputState === InputState.Selected && !selected,
					[S.calendarInputSelectedFilled]: inputState === InputState.Selected && selected,
					[S.calendarInputUnselectedFilled]: inputState === InputState.Unselected && selected,
					[S.calendarInputUnselectedUnfilled]: inputState === InputState.Unselected && !selected,
				})}
				onClick={onClick}
				onChange={() => void(0)}
				value={value}
				placeholder={placeholder}
				ref={inputRef}
			/>
			<CalendarIcon
				className={classnames(S.calendarInputIcon, {
					[S.calendarInputIconGlow]: !selected && inputState === InputState.Unselected,
				})}
				stroke={getCalendarIconColor(inputState, selected)}
			/>
			</div>
	);

	const Container = ({ className, children }: any) => {
		return (
			<div style={{ width: (inputRef as any).current.offsetWidth }}>
				<CalendarContainer className={className}>
					{children}
				</CalendarContainer>
			</div>
		);
	};

	const popper = document.getElementsByClassName('react-datepicker-popper');

	return (
		<div className={`${S.calendarInputWrapper} ${props.className}`} ref={containerRef}>
			<ReactDatePicker
				selected={selected}
				onChange={onChange}
				customInput={<StyledInput />}
				dayClassName={(d) => getDayClassName(d)}
				calendarClassName={S.calendar}
				onCalendarOpen={() => setInputState(InputState.Selected)}
				onCalendarClose={() => setInputState(InputState.Unselected)}
				placeholderText={props.placeholderText}
				calendarContainer={Container}
				minDate={props.minDate}
				dateFormat={props.dateFormat}
				withPortal={false}
				shouldCloseOnSelect={true}
				locale={getCurrentLang() === 'fr' ? datePickerLocaleFr : ''}
				popperModifiers={{
					...props.popperModifiers,
					flip: {
						behavior: ['bottom'],
					},
					preventOverflow: {
						enabled: false,
					},
					hide: {
						enabled: false,
					},
				}}
			/>
			<div
				className={classnames(S.calendarInputWrapperFakeDiv, {
					[S.calendarInputWrapperFakeDivOpen]: inputState === InputState.Selected,
				})}
			/>
		</div>
	);
}

DatePicker.defaultProps = {
	className: 'size-it',
};
