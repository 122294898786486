import { State } from '@models';
import classname from 'classnames';
import React, { ReactNode } from 'react';
import S from './styles/SummaryOption.styl';

interface ISummaryOptionProps {
	id: string;
	state: State;
	text: string | string[];
	children?: ReactNode;
	isMultiLine?: boolean;
}

export default function SummaryOption(props: ISummaryOptionProps) {
	let displayText = '';
	if (typeof props.text === 'string') {
		displayText = props.text;
	} else {
		displayText = props.text ? props.text.join('<br>') : '';
	}

	return (
		<div
			id={props.id}
			className={classname(S.summaryOption, {
				[S.summaryOptionMultiline]: props.isMultiLine,
			})}
		>
			{props.children}
			<div
				className={classname(S.summaryOptionText, {
					[S.summaryOptionTextFocused]: props.state === State.Focused,
					[S.summaryOptionTextSelected]: props.state === State.Selected,
				})}
				dangerouslySetInnerHTML={{ __html: displayText }}
			/>
		</div>
	);
}
