import Copyright from '@components/labels/Copyright';
import LangSwitcher from '@components/labels/LangSwitcher';
import React, { ReactElement } from 'react';
import S from './styles/RightSectionPublicLayoutHalfAndHalf.styl';

interface IRightSectionPublicLayoutHalfAndHalf {
	children: ReactElement;
	className?: string;
}

export default function RightSectionPublicLayoutHalfAndHalf(props: IRightSectionPublicLayoutHalfAndHalf) {
	return (
		<div className={S.rightSectionContainer}>
			<div className={S.rightSectionContainerLangSwitcher}>
				<LangSwitcher />
			</div>

			<div className={`${S.rightSectionContainerContent} ${props.className ? props.className : ''}`}>
				{props.children}
			</div>

			<div className={S.rightSectionContainerCopyright}>
				<Copyright fontSize={'12px'} textUppercase />
			</div>
		</div>
	);
}
