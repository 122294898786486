import { getCustomers } from '@api/customers';
import { IDropdownOption } from '@components/inputs';
import Dropdown from '@components/inputs/Dropdown';
import { PlusIcon } from '@components/svg';
import { useLocale } from '@i18n';
import { useStore } from '@store';
import BrowserUtils from '@utils/browser';
import { ICustomerListPayload } from 'app/models/customers';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/buttons/Button';
import {
    ModalIconType,
    ToastType,
    useModalContext,
    useToastContext,
} from '../../hooks/contexts/UseContext';
import S from './styles/CreateProject.styl';

const CreateProject = () => {
    const history = useHistory();
    const T = useLocale();

    const { state, actions, dispatch } = useStore();

    const addToast = useToastContext();
    const { useModal, changeYesActionState } = useModalContext();

    const [options, setOptions] = useState<IDropdownOption[]>([]);
    const [customers, setCustomers] = useState<ICustomerListPayload[] | undefined>(undefined);
    const [selectedCustomer, setSelectedCustomer] = useState<string>('');
    const [modalSelected, setModalSelected] = useState<boolean>(false);

    useEffect(() => {
        fetchCustomers();
    }, []);

    useEffect(() => {
        if (selectedCustomer && modalSelected) {
            history.push(getRoute(selectedCustomer));
        }
    }, [modalSelected]);

    useEffect(() => {
        if (options !== undefined && options.length > 0) {
            dispatch(actions.DataActions.setOptionsCustomers(options));
        }
    }, [options]);

    const fetchCustomers = () => {
        getCustomers()
            .then((response) => {
                setCustomers(response.data);
                const optionList: IDropdownOption[] = [];
                response.data.map((item: any) => (
                    optionList.push(
                        {
                            label: `${item.epicorCustId} - ${item.name}`,
                            value: item.epicorCustId,
                        },
                    )));
                setOptions(optionList);
            })
            .catch((error) => {
                addToast({
                    text: T.GeneralFormSubmitMessageError,
                    type: ToastType.error,
                });
            });
    };

    function getRoute(customerParameter: string): string {
        if (T.locale === 'en') {
            return `/en/project?epicorCustId=${customerParameter}`;
        }
        else {
            return `/fr/projet?epicorCustId=${customerParameter}`;
        }
    }

    function onMenuOpen() {
        const modalContent = document.querySelector('.modalContent') as HTMLElement;
        if (modalContent) {
            modalContent.style.overflow = BrowserUtils.isMobileDevice() ? 'auto' : 'visible';
        }
    }

    function onMenuClose() {
        const modalContent = document.querySelector('.modalContent') as HTMLElement;
        if (modalContent) {
            modalContent.style.overflow = 'auto';
        }
    }

    const onSelect = (event: IDropdownOption) => {
        setSelectedCustomer(event?.value !== '' ? event?.value : '');
        changeYesActionState(!!event?.value);
    };

    const onCreate = () => {
        if (customers === undefined || customers.length === 0) {
            addToast({
                text: T.CreateProjectNoClientFound,
                type: ToastType.error,
            });
        }
        else if (customers.length === 1) {
            history.push(getRoute(customers[0].epicorCustId));
        }
        else {
            useModal({
                content: (
                    <div>
                        <div className={S.createProjectDescription}>{T.CreateProjectModalDescription}</div>
                        <Dropdown
                            options={options}
                            placeholder={T.CreateProjectPlaceholder}
                            label={T.CreateProjectLabel}
                            onSelect={(e) => onSelect(e)}
                            value={''}
                            disabled={false}
                            isClearable={true}
                            isSearchable={true}
                            isMulti={false}
                            isOptional={false}
                            onMenuClose={onMenuClose}
                            onMenuOpen={onMenuOpen}
                        />
                    </div>
                ),
                title: T.CreateProjectModalTitle,
                iconType: ModalIconType.Plus,
                color: S.blueNorbec,
                exitLabel: T.GeneralCancel,
                yesLabel: T.GeneralCreate,
                yesAction: () => { setModalSelected(true); return true; },
            });
            changeYesActionState(false);
        }
    };

    return (
        <>
            <Button icon={<PlusIcon />} onClick={() => onCreate()} disabled={!customers}>{T.GeneralCreate}</Button>
        </>
    );
};

export default CreateProject;
