import LangSwitcher from '@components/labels/LangSwitcher';
import { NorbecLogoSmall, SizeItLogoSmall } from '@components/svg';
import { useLocale } from '@i18n';
import React from 'react';
import S from './styles/LeftContentAuthHalfAndHalf.styl';

interface ILeftContentAuthHalfAndHalffProps {
	children?: React.ReactElement;
}

export default function LeftContentAuthHalfAndHalf(props: ILeftContentAuthHalfAndHalffProps) {
	const T = useLocale();

	return (
		<div className={S.leftAuthContainer}>
			<div className={S.leftAuthContainerHeader}>
				<div className={S.leftAuthContainerHeaderLang}>
					<LangSwitcher />
				</div>
			</div>

			<div className={S.leftAuthContainerContent}>
				<SizeItLogoSmall color="white" />
				<p className={S.leftAuthContainerContentText}>
					{T.OnboardingHalfHalfTitle}
				</p>
			</div>

			<div className={S.leftAuthContainerFooterLogo}>
				<NorbecLogoSmall color={'white'}/>
			</div>
		</div>
	);
}
