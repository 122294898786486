import { Graphics, TilingSprite } from '@inlet/react-pixi';
import { IRoomState, Position, RoomSettings, State } from '@models';
import LinesUtils from '@utils/drawing/lines';
import TextUtils from '@utils/drawing/text';
import RoomUtils from '@utils/room';
import { Graphics as PIXIGraphics, ObservablePoint, Rectangle, Texture } from 'pixi.js';
import React from 'react';
import { DirectionsMap } from './Door';

interface IRoomProps {
	room: IRoomState;
	onClick?: () => void;
	stateEnabled: boolean;
	wallNumberTexts?: string[];
}

function getWallNumberTextsPosition(index: number, room: IRoomState): { x: number, y: number } {
	const textOffset = 15;
	switch (index) {
		default:
		case 0:
			return {
				x: room.x + RoomUtils.toPixels(((room.width + room.borderLength * 2) / 2)),
				y: room.y + RoomUtils.toPixels(room.borderLength + room.depth) - textOffset,
			};
		case 1:
			return {
				x: room.x + RoomUtils.toPixels(room.width + room.borderLength) - textOffset,
				y: room.y + RoomUtils.toPixels(((room.depth + room.borderLength * 2) / 2)),
			};
		case 2:
			return {
				x: room.x + RoomUtils.toPixels(((room.width + room.borderLength * 2) / 2)),
				y: room.y + RoomUtils.toPixels(room.borderLength) + textOffset,
			};
		case 3:
			return {
				x: room.x + RoomUtils.toPixels(room.borderLength) + textOffset,
				y: room.y + RoomUtils.toPixels(((room.depth + room.borderLength * 2) / 2)),
			};
	}
}

export default function Room(props: IRoomProps) {
	const { wallNumberTexts = [] } = props;
	const borderInnerColor = 0xffffff;
	const borderOuterColor = 0x415a62;
	const roomWidth = props.room.width * RoomSettings.PIXEL_PER_INCH;
	const roomDepth = props.room.depth * RoomSettings.PIXEL_PER_INCH;
	const roomBorderLength = props.room.borderLength * RoomSettings.PIXEL_PER_INCH;
	const innerBorderLength = roomBorderLength * 0.20;
	const outerBorderLength = roomBorderLength * 0.60;

	function handleClick() {
		if (props.onClick) {
			props.onClick();
		}
	}

	function renderBaseRoom(graphics: PIXIGraphics, state: State) {
		// DRAW RECTANGLE
		graphics.clear();
		graphics.removeChildren();

		graphics.beginFill(RoomUtils.getRoomBackgroundColor(state), RoomUtils.getRoomBackgroundAlpha(state));
		graphics.drawRect(
			props.room.x + roomBorderLength,
			props.room.y + roomBorderLength,
			roomWidth,
			roomDepth,
		);
		graphics.endFill();

		if (state === State.Ready) {
			const blurredText = TextUtils.createCenteredBlurredText(
				props.room.code,
				'Assistant',
				62,
				RoomUtils.getCodeBlurColor(),
				props.room.x,
				props.room.y,
				roomWidth + roomBorderLength * 2,
				roomDepth + roomBorderLength * 2,
				'normal',
				1,
				5,
			);
			graphics.addChild(blurredText);
		}

		graphics.addChild(TextUtils.createCenteredText(
			props.room.code,
			'Assistant',
			60,
			RoomUtils.getCodeColor(state),
			props.room.x,
			props.room.y,
			roomWidth + roomBorderLength * 2,
			roomDepth + roomBorderLength * 2,
			RoomUtils.getCodeFontWeight(state),
			RoomUtils.getCodeAlpha(state),
		));

		if (wallNumberTexts.length > 0 && (state === State.Focused || state === State.Selected)) {
			wallNumberTexts.forEach((text, index) => {
				const pos = getWallNumberTextsPosition(index, props.room);
				const map = DirectionsMap.get(`${props.room.code}${index + 1}`);
				const color = RoomUtils.getWallNumberColor((props.room.door?.roomWall === map?.roomWall && props.room.door?.state) || State.Ready);

				graphics.addChild(TextUtils.createCenteredText(
					text,
					'Assistant',
					12,
					color,
					pos.x,
					pos.y,
					0,
					0,
					RoomUtils.getCodeFontWeight(state),
					RoomUtils.getCodeAlpha(state),
					-90 * (index) % 180,
				));
			});
		}

		// DRAW INNER STROKE
		LinesUtils.drawFullBorder(
			props.room.x + roomBorderLength,
			props.room.y + roomBorderLength,
			roomWidth,
			roomDepth,
			graphics,
			innerBorderLength,
			borderOuterColor,
		);

		// DRAW STOKE FILLING
		const unfilledBorderLength = roomBorderLength - innerBorderLength;
		const filledBorderLength = innerBorderLength;
		LinesUtils.drawFullBorder(
			props.room.x + unfilledBorderLength,
			props.room.y + unfilledBorderLength,
			roomWidth + 2 * filledBorderLength,
			roomDepth + 2 * filledBorderLength,
			graphics,
			outerBorderLength,
			borderInnerColor,
		);
	}

	function renderSingleRoom(graphics: PIXIGraphics, state: State) {
		renderBaseRoom(graphics, state);
		// DRAW OUTER STROKE
		const filledBorderLength = outerBorderLength + innerBorderLength;
		const unfilledBorderLength = roomBorderLength - filledBorderLength;
		LinesUtils.drawFullBorder(
			props.room.x + unfilledBorderLength,
			props.room.y + unfilledBorderLength,
			roomWidth + 2 * filledBorderLength,
			roomDepth + 2 * filledBorderLength,
			graphics,
			innerBorderLength,
			borderOuterColor,
		);
	}

	function renderStartRoom(graphics: PIXIGraphics, state: State) {
		renderBaseRoom(graphics, state);
		const filledBorderLength = outerBorderLength + innerBorderLength;
		const unfilledBorderLength = roomBorderLength - filledBorderLength;
		// DRAW OUTER STROKE
		LinesUtils.drawPartialBorderRight(
			props.room.x + unfilledBorderLength,
			props.room.y + unfilledBorderLength,
			roomWidth + 2 * filledBorderLength,
			roomDepth + 2 * filledBorderLength,
			filledBorderLength,
			graphics,
			innerBorderLength,
			borderOuterColor,
			borderInnerColor,
		);
	}

	function renderEndRoom(graphics: PIXIGraphics, state: State) {
		renderBaseRoom(graphics, state);

		// DRAW OUTER STROKE
		const filledBorderLength = outerBorderLength + innerBorderLength;
		const unfilledBorderLength = roomBorderLength - filledBorderLength;
		LinesUtils.drawPartialBorderLeft(
			props.room.x + unfilledBorderLength,
			props.room.y + unfilledBorderLength,
			roomWidth + 2 * filledBorderLength,
			roomDepth + 2 * filledBorderLength,
			graphics,
			innerBorderLength,
			borderOuterColor,
		);
	}

	function renderMiddleRoom(graphics: PIXIGraphics, state: State) {
		renderBaseRoom(graphics, state);
		const filledBorderLength = outerBorderLength + innerBorderLength;
		const unfilledBorderLength = roomBorderLength - filledBorderLength;
		// DRAW STROKE
		LinesUtils.drawPartialBorders(
			props.room.x + unfilledBorderLength,
			props.room.y + unfilledBorderLength,
			roomWidth + 2 * filledBorderLength,
			roomDepth + 2 * filledBorderLength,
			filledBorderLength,
			graphics,
			innerBorderLength,
			borderOuterColor,
			borderInnerColor,
		);
	}

	return (
		<>
		<Graphics
			interactive={true}
			mouseup={handleClick}
			touchstart={handleClick}
			hitArea={new Rectangle(
				props.room.x,
				props.room.y,
				roomWidth + roomBorderLength * 2,
				roomDepth + roomBorderLength * 2,
			)}
			draw={(graphic) => {
				const state = props.stateEnabled ? props.room.state : State.Enabled;
				switch (props.room.position) {
					default:
					case Position.Single: {
						renderSingleRoom(graphic, state);
						break;
					}
					case Position.Start : {
						renderStartRoom(graphic, state);
						break;
					}
					case Position.Middle : {
						renderMiddleRoom(graphic, state);
						break;
					}
					case Position.End : {
						renderEndRoom(graphic, state);
						break;
					}
				}
			}}
		/>
		{props.room.type?.hasFloor &&
			(
				<TilingSprite
					width={roomWidth}
					height={roomDepth}
					x={props.room.x + roomBorderLength}
					y={props.room.y + roomBorderLength}
					texture={Texture.from('/assets/plancher-tile.svg')}
					tilePosition={{
						x: 0,
						y: 0,
					} as ObservablePoint}
				/>
			)
		}
		</>
	);
}
