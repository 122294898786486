import { useStore } from '@store';
import { Widths } from '@utils/navigation';
import classnames from 'classnames';
import React, { ReactElement, useState } from 'react';

import S from '../../routes/size-it/styles/SizeIt.styl';

interface ITabProps {
	readonly width: Widths;
	readonly children: ReactElement | ReactElement[];
}

export const TabTypes = {
	configuration: 'configuration',
	options: 'options',
	installation: 'installation',
};

export default function Tab({ children, width }: ITabProps) {
	const { state } = useStore();
	const [isHover, setIsHover] = useState<boolean>(false);

	return (
		<div
			className={classnames(S.tabs, {
				[S.tabsNormal]: width === Widths.NORMAL,
				[S.tabsFolded]: width === Widths.FOLDED,
				[S.tabsFoldedAnimated]: width === Widths.FOLDED && state.layout.shouldAnimate,
				[S.tabsFull]: width === Widths.FULL,
				[S.tabsHover]: isHover,
				[S.tabsAnimated]: state.layout.shouldAnimate,
				[S.tabsNoSummary]: width === Widths.HIDE_SUMMARY,
			})}
			onMouseLeave={() => setIsHover(false)}
			onMouseEnter={() => setIsHover(true)}
		>
			{children}
		</div>
	);
}
