import classnames from 'classnames';
import { uniqueId } from 'lodash';
import React, { ChangeEvent } from 'react';

import S from './styles/RadioButton.styl';

type TonChange = (event: ChangeEvent<HTMLInputElement>) => void;

interface IRadioButtonProps {
	readonly label: string;
	readonly onChange: TonChange;
	readonly checked: boolean | undefined;
	readonly active: boolean;
	readonly locked?: boolean;
}

export default function RadioButton(props: IRadioButtonProps) {
	const id = uniqueId('multi-radio');

	return (
		<div className={S.radioButton}>
			<label
				htmlFor={`${id}`}
				className={classnames(S.radioButtonContainer, {
					[S.radioButtonContainerActive]: props.active,
					[S.radioButtonContainerChecked]: props.checked && !props.active,
					[S.radioButtonContainerLocked]: props.locked,
				})}
			>
				{props.label}
				<input
					id={`${id}`}
					type="radio"
					className={S.radioButtonInput}
					checked={props.checked}
					onChange={props.locked ? () => void(0) : props.onChange}
					value={props.label}
				/>
				<span
					className={classnames(S.radioButtonButton, {
						[S.radioButtonButtonActive]: props.active,
						[S.radioButtonButtonLocked]: props.locked,
					})}
				/>
			</label>
		</div>
	);
}
