import { useLocale } from '@i18n';
import FormatUtils from '@utils/format';
import classname from 'classnames';
import Slider from 'rc-slider';
import { Handle } from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useEffect, useState } from 'react';
import { AnimationSettings } from '../../utils/animation';
import S from './styles/RoomSliderComponent.styl';

const trackFocusedStyle = {
	backgroundColor: '#f49800',
	borderColor: '#f49800',
	height: '6px',
};

const trackSelectedStyle = {
	backgroundColor: '#0095c8',
	borderColor: '#0095c8',
	height: '6px',
};

const handleSelectedStyle = {
	backgroundColor: '#0095c8',
	borderColor: '#0095c8',
};

const handleFocusedStyle = {
	backgroundColor: '#f49800',
	borderColor: '#f49800',
};

let styleTimer: number;

interface IRoomSliderComponentProps {
	readonly tag: string;
	readonly isDirty?: boolean;
	readonly defaultValue: number;
	readonly maxValue: number;
	readonly minValue: number;
	readonly step?: number;
	readonly onChange?: (value: number) => void;
	readonly onHandleSelected?: (value: number) => void;
	readonly onHandleDeselected?: () => void;
}

function getHandle(props: any) {
	const {
		value,
		dragging,
		index,
		offset,
		tag,
		isDirty,
		className,
		...rest } = props;

	return (
		<>
			<div className={S.rcSliderHandleText} style={{ left: `${offset}%` }}>
				<div>{tag}</div>
				<div>{isDirty ? FormatUtils.formatInches(value) : '...'}</div>
			</div>
			<Handle
				className={classname(className, {
					[S.rcSliderHandleHint]: !isDirty,
				})}
				value={value}
				offset={offset}
				{...rest}
			/>
		</>
	);
}

export default function RoomSliderComponent(props: IRoomSliderComponentProps) {
	const T = useLocale();
	const { tag, isDirty, defaultValue, maxValue, minValue, step } = props;
	const [isFocused, setIsFocused] = useState<boolean>(false);
	const [currentValue, setCurrentValue] = useState<number>(defaultValue);

	useEffect(() => {
		setCurrentValue(props.defaultValue);
	}, [props.defaultValue]);

	useEffect(() => {
		animateActiveStyle();
	}, [props.tag]);

	function animateActiveStyle() {
		setIsFocused(true);

		if (styleTimer) {
			clearTimeout(styleTimer);
		}

		styleTimer = window.setTimeout(() => {
			setIsFocused(false);
		}, AnimationSettings.FADE_DELAY);
	}

	function handleMouseUp() {
		animateActiveStyle();
		if (props.onHandleDeselected) {
			props.onHandleDeselected();
		}
	}

	function handleMouseDown() {
		setIsFocused(true);
		if (props.onHandleSelected) {
			props.onHandleSelected(currentValue);
		}
	}

	function handleOnChange(value: number) {
		setCurrentValue(value);
		if (props.onChange) {
			props.onChange(value);
		}
	}

	return (
		<div className={S.roomSliderComponent}>
			<div className={S.rcSliderContainer}>
				<div className={S.rcSliderBoundaries}>
					{`${T.GeneralMin} ${FormatUtils.formatInches(minValue)}`}
				</div>

				<div className={S.rcSliderSlider}>
					<Slider
						value={currentValue}
						max={maxValue}
						min={minValue}
						step={step}
						handle={(handleProps) => getHandle({ tag, isDirty, ...handleProps })}
						onChange={handleOnChange}
						onBeforeChange={handleMouseDown}
						onAfterChange={handleMouseUp}
						trackStyle={isFocused ? trackFocusedStyle : trackSelectedStyle}
						handleStyle={isFocused ? handleFocusedStyle : handleSelectedStyle}
					/>
				</div>

				<div className={S.rcSliderBoundaries}>
					{`${T.GeneralMax} ${FormatUtils.formatInches(maxValue)}`}
				</div>
			</div>
		</div>
	);
}

RoomSliderComponent.defaultProps = {
	step: 1,
};
