import { Graphics, Sprite } from '@inlet/react-pixi';
import { IRoomState, ISettingsState, State } from '@models';
import RoomUtils from '@utils/room';
import { InteractionEvent, Point } from 'pixi.js';
import React from 'react';

interface IDoorProps {
    room: IRoomState;
    wall: number;
    onClick?: (room: IRoomState, wall: number) => void;
    supportedWidths: number[];
    position?: number;
    showDoorsRampe?: boolean;
    settings: ISettingsState;
}

export enum Direction {
    DOWN = 'DOWN',
    RIGHT = 'RIGHT',
    TOP = 'TOP',
    LEFT = 'LEFT',
}

export const DirectionsMap = new Map<string, { roomWall: number, direction: Direction }>();
DirectionsMap.set('A1', { roomWall: 1, direction: Direction.DOWN });
DirectionsMap.set('A2', { roomWall: 2, direction: Direction.RIGHT });
DirectionsMap.set('A3', { roomWall: 3, direction: Direction.TOP });
DirectionsMap.set('A4', { roomWall: 4, direction: Direction.LEFT });

DirectionsMap.set('B1', { roomWall: 5, direction: Direction.DOWN });
DirectionsMap.set('B2', { roomWall: 6, direction: Direction.RIGHT });
DirectionsMap.set('B3', { roomWall: 7, direction: Direction.TOP });
DirectionsMap.set('B4', { roomWall: 2, direction: Direction.LEFT });

DirectionsMap.set('C1', { roomWall: 8, direction: Direction.DOWN });
DirectionsMap.set('C2', { roomWall: 9, direction: Direction.RIGHT });
DirectionsMap.set('C3', { roomWall: 10, direction: Direction.TOP });
DirectionsMap.set('C4', { roomWall: 6, direction: Direction.LEFT });

DirectionsMap.set('D1', { roomWall: 11, direction: Direction.DOWN });
DirectionsMap.set('D2', { roomWall: 12, direction: Direction.RIGHT });
DirectionsMap.set('D3', { roomWall: 13, direction: Direction.TOP });
DirectionsMap.set('D4', { roomWall: 9, direction: Direction.LEFT });

function getPosition(direction: Direction, room: IRoomState, doorPosition: number, doorWidth: number): { x: number, y: number } {
    switch (direction) {
        default:
        case Direction.DOWN:
            return {
                x: room.x + RoomUtils.toPixels(room.borderLength + doorPosition + doorWidth / 2),
                y: room.y + RoomUtils.toPixels(room.borderLength + room.depth),
            };
        case Direction.TOP:
            return {
                x: room.x + RoomUtils.toPixels(room.borderLength + doorPosition + doorWidth / 2),
                y: room.y + RoomUtils.toPixels(room.borderLength),
            };
        case Direction.LEFT:
            return {
                x: room.x + RoomUtils.toPixels(room.borderLength),
                y: room.y + RoomUtils.toPixels(room.borderLength + room.depth - doorPosition - doorWidth / 2),
            };
        case Direction.RIGHT:
            return {
                x: room.x + RoomUtils.toPixels(room.width + room.borderLength),
                y: room.y + RoomUtils.toPixels(room.borderLength + room.depth - doorPosition - doorWidth / 2),
            };
    }
}

function getDoorImgPath(room: IRoomState, direction: Direction, doorWidth: number): string {
    if (room.door && room.door.direction === direction) {
        switch (room.door.state) {
            case State.Ready:
                return `/assets/door-${doorWidth}-choice.svg`;
            case State.Enabled:
            case State.Done:
                return `/assets/door-${doorWidth}-rested.svg`;
            case State.Focused:
                return `/assets/door-${doorWidth}-focus.svg`;
            case State.Selected:
                return `/assets/door-${doorWidth}-selected.svg`;
        }
    }
    return '/assets/door-36-choice.svg';
}

function getAngle(direction: Direction) {
    switch (direction) {
        default:
        case Direction.DOWN:
            return 0;
        case Direction.TOP:
            return 180;
        case Direction.LEFT:
            return 90;
        case Direction.RIGHT:
            return -90;
    }
}

export default function Door(props: IDoorProps) {
    const map = DirectionsMap.get(`${props.room.code}${props.wall}`) || { roomWall: 1, direction: Direction.DOWN };

    function handleMouseDown(event: InteractionEvent) {
        event.stopPropagation();
        if (props.onClick) {
            props.onClick(props.room, props.wall);
        }
    }
    const showDoorsRampe = props.showDoorsRampe;
    const doorWidth = props.supportedWidths.includes(props.room?.door?.width || 0) ? props?.room?.door?.width || 0 : props.supportedWidths[0];
    const doorPosition = props.position ? props.position : RoomUtils.getDefaultDoorPosition(doorWidth, map.direction, props.room, props.settings);
    const drawingPosition = getPosition(map.direction, props.room, doorPosition, doorWidth);

    // Fonction pour dessiner un carré représentant la rampe
    const drawRamp = (x: number, y: number, width: number) => {
        const stripeSpacing = 5; // Espacement entre les rayures
        const stripeColor = 0xD3D3D3; // Couleur gris clair pour les rayures
        return (
            <Graphics
                draw={(graphics) => {
                    graphics.clear();
                    graphics.beginFill(0xD9E3E5);  // Couleur gris clair
                    // Ajustement de la position du carré en fonction de la direction de la porte
                    let adjustedX = x;
                    let adjustedY = y;
                    switch (map.direction) {
                        case Direction.DOWN:
                            adjustedX = adjustedX - width / 2;
                            adjustedY = adjustedY - width;
                            break;
                        case Direction.TOP:
                            adjustedX = adjustedX - width / 2;
                            break;
                        case Direction.LEFT:
                            adjustedY = adjustedY - width / 2;
                            break;
                        case Direction.RIGHT:
                            adjustedX = adjustedX - width;
                            adjustedY = adjustedY - width / 2;
                            break;
                    }

                    graphics.drawRect(adjustedX, adjustedY, width, width);  // Dimensions du carré
                    graphics.endFill();

                    // Dessiner les rayures horizontales
                    // graphics.lineStyle(1, stripeColor);  // Épaisseur et couleur de la ligne
                    // for (let i = adjustedY - width; i <= adjustedY; i += stripeSpacing) {
                    //     graphics.moveTo(adjustedX - width / 2, i);
                    //     graphics.lineTo(adjustedX + width / 2, i);
                    // }

                    // Dessiner les rayures verticales
                    // for (let i = adjustedX - width / 2; i <= adjustedX + width / 2; i += stripeSpacing) {
                    //     graphics.moveTo(i, adjustedY - width);
                    //     graphics.lineTo(i, adjustedY);
                    // }
                    // graphics.endFill();
                }}
            />
        );
    };

    // Calculer les dimensions de la rampe (par exemple, deux fois plus grande que la porte)
    const rampWidth = doorWidth * 2 - 10;

    return (
        <>
            {/* Dessiner la rampe */}
            {showDoorsRampe && drawRamp(drawingPosition.x, drawingPosition.y, rampWidth)}

            {/* Dessiner la porte */}
            <Sprite
                x={drawingPosition.x}
                y={drawingPosition.y}
                anchor={new Point(0.5, 0)}
                angle={getAngle(map.direction)}
                scale={[props.room?.door?.openingDirection === Direction.LEFT ? -(1) : 1, 1]}
                source={getDoorImgPath(props.room, map.direction, doorWidth)}
                interactive
                mousedown={(event) => handleMouseDown(event)}
            />
        </>
    );
}
