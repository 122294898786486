import React from 'react';

export default function DownloadIcon({ color }: {color?: string}) {
	return (
		<svg className="svg-download-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill={color ? color : '#ffffff'} fillRule="evenodd">
				<path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" transform="translate(-1001 -122) translate(965 110) translate(36 12)"/>
			</g>
		</svg>
	);
}
